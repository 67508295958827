import React, { ReactNode } from 'react';
import { CourseColor } from '../../../config/course';
// CSSはインポートした順に生成され、後にインポートしたCSSの方が優先されるので、
// まず他のコンポーネントや共通CSSをインポートして、その後でコンポーネントごとのCSSをインストールすること
// つまり、styles.module.cssのインポートはインポート文の複数行のうちの最後に置いておくのが安全
import styles from './styles.module.css';

export type SlidePracticeBackgroundProps = {
    className?: string;
    bgColor: CourseColor;
    children?: ReactNode;
};


const SlidePracticeBackground : React.FC<SlidePracticeBackgroundProps> = ({
    className = '',
    bgColor,
    children,
}) => {
    // bgColorを設定
    let classString = styles['slide-practice-background'];
    if (bgColor) {
        classString = [classString, styles['bg-'+bgColor]].join(' ');
    }
    // 上位コンポーネントからのclassを設定
    if (className) {
        classString = [classString, className].join(' ');
    }
    return (
        <div className={classString}>
            {children}
        </div>
    );
};

export default SlidePracticeBackground;
