import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit'
import type { RootState } from '../app/store'
import { API } from 'aws-amplify';
import moment from 'moment';
import xss from 'xss';
import { graphqlOperation, GraphQLResult } from '@aws-amplify/api-graphql';
import { NavigateFunction } from "react-router";
import {
    GetUserQuery,
    GetUserQueryVariables,
    ListUsersQuery,
    ListUsersQueryVariables,
    UpdateUserMutation,
    UpdateUserMutationVariables,

    Gender as DbGender,
} from '../../API';
import {
    loginSlice,
} from './loginSlice';
import {
    getUser,
    listUsers,
} from '../../graphql/queries';
import {
    updateUser,
} from '../../graphql/mutations';

interface SetupProfileState {
    [key: string]: any;

    preferredUsername: string,
    gender?: string,
    genderOpen: string,
    birthDate?: string,
    prefecture?: string,
    profession: string,
    selfIntroduction?: string,

    successMessage?: string;
    preferredUsernameValidationMessage: string,
    genderValidationMessage: string,
    genderOpenValidationMessage: string,
    birthDateValidationMessage: string,
    professionValidationMessage: string,
    prefectureValidationMessage: string,
    rankingOpenValidationMessage: string,
    selfIntroductionValidationMessage: string,
}


export const initialState: SetupProfileState = {
    preferredUsername: '',
    gender: undefined,
    genderOpen: '公開する',
    birthDate: undefined,
    prefecture: undefined,
    profession: '',
    selfIntroduction: '',

    successMessage: '',
    preferredUsernameValidationMessage: '',
    genderValidationMessage: '',
    genderOpenValidationMessage: '',
    birthDateValidationMessage: '',
    professionValidationMessage: '',
    prefectureValidationMessage: '',
    rankingOpenValidationMessage: '',
    selfIntroductionValidationMessage: '',
}

export interface SubmitParams {
    preferredUsername: string,
    gender?: string,
    genderOpen: string,
    birthDate?: string,
    prefecture?: string,
    profession: string,
    selfIntroduction?: string,

    preferredUsernameValidationMessage: string;
    genderValidationMessage: string;
    genderOpenValidationMessage: string;
    birthDateValidationMessage: string;
    professionValidationMessage: string;
    prefectureValidationMessage: string;
    rankingOpenValidationMessage: string;
    selfIntroductionValidationMessage: string;

    sub: string,

    navigate: NavigateFunction,
    redirectPage: string,
}

export const submit = createAsyncThunk(
  'setupProfileSlice/submit',
    async (submitParams: SubmitParams, thunkAPI) => {
        const {
            preferredUsername,
            gender,
            genderOpen,
            birthDate,
            prefecture,
            profession,
            selfIntroduction,

            preferredUsernameValidationMessage,
            genderValidationMessage,
            genderOpenValidationMessage,
            birthDateValidationMessage,
            professionValidationMessage,
            prefectureValidationMessage,
            rankingOpenValidationMessage,
            selfIntroductionValidationMessage,

            sub,
            navigate,
            redirectPage,
        } = submitParams;

        let isValid = true;

        // 名前が入力されていること
        if (preferredUsername.length === 0) {
            await thunkAPI.dispatch(setupProfileSlice.actions.changePreferredUsername(''));
            isValid = false;
        }

        // 性別が入力されていること
        if (typeof gender === 'undefined') {
            await thunkAPI.dispatch(setupProfileSlice.actions.changeGender(undefined));
            isValid = false;
        }

        // 生年月日が入力されていること
        if (typeof birthDate === 'undefined') {
            await thunkAPI.dispatch(setupProfileSlice.actions.changeBirthDate(birthDate));
            isValid = false;
        }

        // 居住都道府県が入力されていること
        if (typeof prefecture === 'undefined') {
            await thunkAPI.dispatch(setupProfileSlice.actions.changePrefecture(prefecture));
            isValid = false;
        }

        // validationMessageが空であること
        const validationMessages = [
            preferredUsernameValidationMessage,
            genderValidationMessage,
            genderOpenValidationMessage,
            birthDateValidationMessage,
            professionValidationMessage,
            prefectureValidationMessage,
            rankingOpenValidationMessage,
            selfIntroductionValidationMessage,
        ];
        if (validationMessages.some((elm, ind, arr) => (elm !== ''))) {
            isValid = false;
        }

        if (!isValid) {
            window.scrollTo(0, 0);
            throw new Error('エラーがあります');
        }

        // 異なる会員が同じ名前を持っていないことを確認
        // TODO: インデックスを使って全件取得しないようにする
        // 1回は必ずリクエストするので、do-while
        let nextToken : string | undefined | null = null;
        let sameNameUserCnt = 0;
        do {
            const listUserQueryVariables : ListUsersQueryVariables = {
                limit: 100,
                nextToken,
                filter: {
                    and: [
                        {
                            sub: { ne: sub, },
                        },
                        {
                            preferredUsername: { eq: preferredUsername },
                        },
                    ],
                },
            };

            let listUserQueryRes;
            try {
                listUserQueryRes = await API.graphql(graphqlOperation(listUsers, listUserQueryVariables)) as GraphQLResult<ListUsersQuery>;
            } catch(e: unknown) {
                if (e instanceof Error) {
                    const msg = e.message;
                    // alert(msg);
                    window.scrollTo(0, 0);
                    throw new Error(msg);
                } else {
                    // 200だがエラーがあるパターン
                    // alert(JSON.stringify(e.errors));
                    throw new Error('エラーが発生しました');
                }
            }

            if (!listUserQueryRes) {
                  throw new Error('');
            }

            // alert(JSON.stringify(listUserQueryRes));
            nextToken = listUserQueryRes.data?.listUsers?.nextToken;
            // alert(`nextToken: ${JSON.stringify(listUserQueryRes.data?.listUsers)}`);
            // alert(`nextToken: ${nextToken}`);
            const newUsers = listUserQueryRes.data?.listUsers?.items || [];

            // newUsersの中には他人のメールアドレスが含まれてしまっているので、
            // セキュリティ的によくない。
            // FIXME preferredUsernameだけをクエリするようなGraphqlクエリに書き換える
            sameNameUserCnt += newUsers.length;
        } while (nextToken !== null && typeof(nextToken) !== 'undefined')

        // alert(JSON.stringify(sameNameUsers));
        // alert(JSON.stringify(sameNameUsers.length));

        if (sameNameUserCnt > 0) {
            const msg = 'この会員名は既に使用されています';
            await thunkAPI.dispatch(setupProfileSlice.actions.changePreferredUsernameValidationMessage(msg));
            window.scrollTo(0, 0);
            throw new Error(msg);
        }

        const getUserQueryVariables : GetUserQueryVariables = {
            sub,
        };

        const getUserRes = await API.graphql(graphqlOperation(getUser, getUserQueryVariables)) as GraphQLResult<GetUserQuery>;
        const fetchedUser = getUserRes.data?.getUser;

        if (!fetchedUser) {
            throw new Error('データベースに会員が登録されていません');
        }
        const oldUserVersion = fetchedUser._version;
        const updateUserMutationVariables : UpdateUserMutationVariables = {
            input: {
                sub,
                _version: oldUserVersion,
                preferredUsername,
                gender: gender === '男性' ? DbGender.male : (gender === '女性' ? DbGender.female : DbGender.other),
                genderOpen: genderOpen === '公開する',
                birthDate: moment(birthDate).format('YYYY-MM-DD'),
                prefecture,
                profession,
                selfIntroduction,
            },
        };

        // alert(JSON.stringify(updateUserMutationVariables));
        await API.graphql(graphqlOperation(updateUser, updateUserMutationVariables)) as GraphQLResult<UpdateUserMutation>;

        await thunkAPI.dispatch(loginSlice.actions.changeForceReloadNext(true));
        await navigate(redirectPage);
        window.scrollTo(0, 0);
    });


export const setupProfileSlice = createSlice({
    name: 'setupProfile',
    initialState,
    reducers: {
        changePreferredUsername: (state, action: PayloadAction<string>) => {
            const preferredUsername = xss(action.payload.trim());
            state.preferredUsername = preferredUsername;

            const preferredUsernameMax = 9;
            if (1 <= preferredUsername.length && preferredUsername.length <= preferredUsernameMax) {
                state.preferredUsernameValidationMessage = '';
            } else {
                state.preferredUsernameValidationMessage = `1文字以上${preferredUsernameMax}文字以内で入力してください`;
            }

            // いちいち書かずに全体に適用できないか?
            state.successMessage = '';
        },
        changeGender: (state, action: PayloadAction<string|undefined>) => {
            const input = action.payload;

            if (typeof input === 'undefined' || input === '') {
                state.gender = undefined;
                state.genderValidationMessage = '性別を入力してください';
            } else {
                state.gender = input;
                state.genderValidationMessage = '';
            }

            // いちいち書かずに全体に適用できないか?
            state.successMessage = '';
        },
        changeGenderOpen: (state, action: PayloadAction<string>) => {
            state.genderOpen = action.payload;

            // いちいち書かずに全体に適用できないか?
            state.successMessage = '';
        },
        changeBirthDate: (state, action: PayloadAction<string|undefined>) => {
            const birthDate = action.payload;
            state.birthDate = birthDate;

            if (typeof birthDate === 'undefined') {
                state.birthDateValidationMessage = '生年月日を入力してください';
            } else {
                state.birthDateValidationMessage = '';
            }

            // いちいち書かずに全体に適用できないか?
            state.successMessage = '';
        },
        changeProfession: (state, action: PayloadAction<string>) => {
            const profession = xss(action.payload);
            state.profession = profession;

            const professionMax = 20;
            if (profession.length <= professionMax) {
                state.professionValidationMessage = '';
            } else {
                state.professionValidationMessage = `${professionMax}文字以内で入力してください`;
            }

            // いちいち書かずに全体に適用できないか?
            state.successMessage = '';
        },
        changePrefecture: (state, action: PayloadAction<string|undefined>) => {
            const prefecture = action.payload;
            state.prefecture = prefecture;

            if (prefecture === '' || typeof prefecture === 'undefined') {
                state.prefectureValidationMessage = '居住都道府県を入力してください';
            } else {
                state.prefectureValidationMessage = '';
            }

            // いちいち書かずに全体に適用できないか?
            state.successMessage = '';
        },
        changeSelfIntroduction: (state, action: PayloadAction<string>) => {
            const selfIntroduction = xss(action.payload);
            state.selfIntroduction = selfIntroduction;

            const selfIntroductionMax = 200;
            if (selfIntroduction.length <= selfIntroductionMax) {
                state.selfIntroductionValidationMessage = '';
            } else {
                state.selfIntroductionValidationMessage = `${selfIntroductionMax}文字以内で入力してください`;
            }

            // いちいち書かずに全体に適用できないか?
            state.successMessage = '';
        },
        // changeSuccessMessage: (state, action: PayloadAction<string>) => {
        //     state.successMessage = action.payload;
        // },
        changePreferredUsernameValidationMessage: (state, action: PayloadAction<string>) => {
            state.preferredUsernameValidationMessage = action.payload;

            // いちいち書かずに全体に適用できないか?
            state.successMessage = '';
        },
        // changeBirthDateValidationMessage: (state, action: PayloadAction<string>) => {
        //     state.birthDateValidationMessage = action.payload;
        // },
    },
    extraReducers: (builder) => {
        builder.addCase(submit.fulfilled, (state, action) => {
            state.successMessage = 'プロフィールを更新しました';
        });
    },
});

export const {
    changePreferredUsername,
    changeGender,
    changeGenderOpen,
    changeBirthDate,
    changeProfession,
    changePrefecture,
    changeSelfIntroduction,
 } = setupProfileSlice.actions;

export const selectSetupProfileState = (state: RootState) => {
    return {
        preferredUsername: state.setupProfile.preferredUsername,
        gender: state.setupProfile.gender,
        genderOpen: state.setupProfile.genderOpen,
        birthDate: state.setupProfile.birthDate,
        prefecture: state.setupProfile.prefecture,
        profession: state.setupProfile.profession,
        selfIntroduction: state.setupProfile.selfIntroduction,

        successMessage: state.setupProfile.successMessage,
        preferredUsernameValidationMessage: state.setupProfile.preferredUsernameValidationMessage,
        genderValidationMessage: state.setupProfile.genderValidationMessage,
        genderOpenValidationMessage: state.setupProfile.genderOpenValidationMessage,

        birthDateValidationMessage: state.setupProfile.birthDateValidationMessage,
        professionValidationMessage: state.setupProfile.professionValidationMessage,
        prefectureValidationMessage: state.setupProfile.prefectureValidationMessage,
        rankingOpenValidationMessage: state.setupProfile.rankingOpenValidationMessage,
        selfIntroductionValidationMessage: state.setupProfile.selfIntroductionValidationMessage,
    };
};

export default setupProfileSlice.reducer;
