/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-northeast-1",
    "aws_appsync_graphqlEndpoint": "https://sjn5lod22ffmrlsg56kombdrbi.appsync-api.ap-northeast-1.amazonaws.com/graphql",
    "aws_appsync_region": "ap-northeast-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cloud_logic_custom": [
        {
            "name": "memoacaapi",
            "endpoint": "https://wwh3omf7z1.execute-api.ap-northeast-1.amazonaws.com/production",
            "region": "ap-northeast-1"
        },
        {
            "name": "sender",
            "endpoint": "https://yrriqqi0ae.execute-api.ap-northeast-1.amazonaws.com/production",
            "region": "ap-northeast-1"
        }
    ],
    "aws_cognito_identity_pool_id": "ap-northeast-1:f1bcd876-a61d-4d10-85cd-473b95f81581",
    "aws_cognito_region": "ap-northeast-1",
    "aws_user_pools_id": "ap-northeast-1_vOTygGF8h",
    "aws_user_pools_web_client_id": "1ghiufjc9kn5tn00g57na46iuc",
    "oauth": {
        "domain": "memoryacademyd59ed08b-d59ed08b-production.auth.ap-northeast-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "http://localhost:3000/mypage/,https://www.memoaca.com/mypage/,https://develop.d2muee3d3kt4fc.amplifyapp.com/mypage/,https://release-demo-20220613.d2muee3d3kt4fc.amplifyapp.com/mypage,http://localhost:3002/",
        "redirectSignOut": "http://localhost:3000/logout/,https://www.memoaca.com/logout/,https://develop.d2muee3d3kt4fc.amplifyapp.com/logout/,https://release-demo-20220613.d2muee3d3kt4fc.amplifyapp.com/logout",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_AND_IDENTITY_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [
        "FACEBOOK",
        "GOOGLE"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ]
};


export default awsmobile;
