import React from 'react';
import FontTitle from '../../atoms/FontTitle';
import Img from '../../atoms/Img';
import { TrainingMode } from '../../../redux/constant';
// CSSはインポートした順に生成され、後にインポートしたCSSの方が優先されるので、
// まず他のコンポーネントや共通CSSをインポートして、その後でコンポーネントごとのCSSをインストールすること
// つまり、styles.module.cssのインポートはインポート文の複数行のうちの最後に置いておくのが安全
import styles from './styles.module.css';

export type TrainingTitleBarProps = {
    trainingMode: TrainingMode;
    children?: never;
};


const TrainingTitleBar: React.FC<TrainingTitleBarProps> = ({
    trainingMode,
}) => {
    return (
        <div className={styles['training-title-bar']}>
            <FontTitle>
                {trainingMode === TrainingMode.number &&
                    <h1 className={styles['training-title']}>
                        <Img className={styles['training-title-icon']} fileName="icon_training_number.svg" alt="数字を覚えよう" />
                        数字を覚えよう
                    </h1>
                }
                {trainingMode === TrainingMode.word &&
                    <h1 className={styles['training-title']}>
                        <Img className={styles['training-title-icon']} fileName="icon_training_word.svg" alt="単語を覚えよう" />
                        単語を覚えよう
                    </h1>
                }
                {trainingMode === TrainingMode.alphabet &&
                    <h1 className={styles['training-title']}>
                        <Img className={styles['training-title-icon']} fileName="icon_training_alphabet.svg" alt="アルファベットを覚えよう" />
                        アルファベットを覚えよう
                    </h1>
                }
                {trainingMode === TrainingMode.nameAndFace &&
                    <h1 className={styles['training-title']}>
                        <Img className={styles['training-title-icon']} fileName="icon_training_face.svg" alt="顔と名前を覚えよう" />
                        顔と名前を覚えよう
                    </h1>
                }
                {trainingMode === TrainingMode.circleRatio &&
                    <h1 className={styles['training-title']}>
                        <Img className={styles['training-title-icon']} fileName="icon_training_pi.svg" alt="円周率を覚えよう" />
                        円周率を覚えよう
                    </h1>
                }
                {trainingMode === TrainingMode.nationalCapitals &&
                    <h1 className={styles['training-title']}>
                        <Img className={styles['training-title-icon']} fileName="icon_training_national_capitals.svg" alt="国の名前と首都を覚えよう" />
                        国の名前と首都を覚えよう
                    </h1>
                }
            </FontTitle>
            { trainingMode !== TrainingMode.nationalCapitals && <p className={styles['training-title-comment']}>記憶時間：1分  回答時間：4分</p> }
            { trainingMode === TrainingMode.nationalCapitals && <p className={styles['training-title-comment']}>記憶フェーズなし 回答時間：30分</p> }
        </div>
    );
};

export default TrainingTitleBar;
