import React from 'react';
import templateProperties from '../../common/template-properties.module.css';
import InnerLink from '../../atoms/InnerLink';
import HeaderContainer from '../../organisms/HeaderContainer';
import Footer from '../../organisms/Footer';
import { PageContainer } from '../../layouts/Container';
import { TitleItemized } from '../../atoms/Title';
import FontTitle from '../../atoms/FontTitle';
import Img from '../../atoms/Img';
import PageTitleArea from '../../layouts/PageTitleArea';
import ColumnLayout from '../../layouts/ColumnLayout';
import { OneHalfColumn, OneThirdColumn, TwoThirdColumn } from '../../layouts/ColumnArea';
import { CourseLesson } from '../../../lib/props';
import CourseBox from '../../organisms/CourseBox';
import FontEn from '../../atoms/FontEn';
import NewsArea from '../../organisms/NewsArea';
// CSSはインポートした順に生成され、後にインポートしたCSSの方が優先されるので、
// まず他のコンポーネントや共通CSSをインポートして、その後でコンポーネントごとのCSSをインストールすること
// つまり、styles.module.cssのインポートはインポート文の複数行のうちの最後に置いておくのが安全
import styles from './styles.module.css';

Object.assign(styles, templateProperties);

export type MyPageTemplateProps = {
  isLogin: boolean;             // ログイン状態
  isPaid: boolean;              // 有料会員かどうか
  isSocialLogin: boolean;       // SNSログイン会員かどうか
  profileImg?: string;          // 会員画像
  preferredUsername?: string;  // 会員名

  userLevel?: number;           // ログイン会員のレベル
  userPoint?: number;           // ログイン会員の経験値
  lessonTotal?: number;         // 総レッスン数
  lessonDone?: number;          // 終了済みレッスン数
  registeredDate?: string;      // 登録日

  courseLessonList?: Array<CourseLesson>;   // コース名とそのレッスン一覧
};

const MyPageTemplate: React.FC<MyPageTemplateProps> = ({
  isLogin,
  isPaid,
  isSocialLogin,
  profileImg = 'profile_dummy.jpg',
  preferredUsername = 'guest',

  userLevel = 1,
  userPoint,
  lessonTotal = 0,
  lessonDone = 0,
  registeredDate = '',

  courseLessonList = undefined,
}) => {
  return (
    <div className={styles['my-page-template'] + ' ' + styles['template-properties-local']}>
      <HeaderContainer isLogin={isLogin} isPaid={isPaid} isSocialLogin={isSocialLogin} profileImg={profileImg} preferredUsername={preferredUsername} />
      <PageTitleArea isFreeFormat={true} className={styles['mypage-top-area']}>
        {/* <div className={styles['pao-message']}>
              <p className={styles['pao-message-text']}>おはよう！今日もがんばろうね！</p>
              <div className={styles['pao-illust']}><Img fileName="paozo_01.png" alt="PAOくん" /></div>
            </div> */}
        <div className={styles['box-main'] + ' ' + styles['user-info-area']}>
          <div className={styles['user-profile']}>
            <div className={styles['profile-box']}>
              {/* <div className={styles['profile-photo']}><Img fileName={profileImg} alt={preferredUsername+' プロフィール画像'} /></div> */}
              <div>
                <h1 className={styles['user-name']}><FontEn domType='span'>{preferredUsername}</FontEn></h1>
                <p className={styles['user-level']}><FontEn domType='span'>Lv.{userLevel}</FontEn></p>
                {userPoint && <p className={styles['user-point']}><FontEn domType='span'>{userPoint} pt</FontEn></p>}
                <p className={styles['registration-date']}>{registeredDate ? registeredDate + ' 登録' : '登録日不明'}</p>
              </div>
            </div>
            {/* <div className={styles['text-center']}><InnerLink to='/account'>プロフィールの編集</InnerLink></div> */}
          </div>
          <div className={styles['attendance-list']}>
            <dl>
              <dt>受講済レッスン</dt>
              <dd><FontEn domType='span' className={styles['fraction']}>{lessonDone}<span className={styles['denominator']}>{lessonTotal}</span></FontEn></dd>
            </dl>
          </div>
        </div>
      </PageTitleArea>
      <PageContainer>
        <ColumnLayout>
          <TwoThirdColumn>
            {!isPaid &&
              <p className={styles['message']}>
                一般会員の方は無料コースのレッスンと単語のトレーニングと円周率(回答のみ)のトレーニングをご利用いただけます
              </p>
            }
            {courseLessonList &&
              <React.Fragment>
                <TitleItemized id="course-list" >コース一覧</TitleItemized>
                {courseLessonList.map((courseLessonData, idx) => (
                  <CourseBox
                    key={idx}
                    courseTitle={courseLessonData.courseTitle}
                    courseColor={courseLessonData.courseColor}
                    lessonBoxList={courseLessonData.lessonBoxList}
                  />
                ))
                }
              </React.Fragment>
            }

            {isPaid && (
              <React.Fragment>
                <TitleItemized id="training-list" >トレーニング一覧</TitleItemized>
                <div className={styles['training-content']}>
                  <div className={styles['row-training']}>
                    <OneHalfColumn className={styles['training-box']}>
                      <InnerLink to="/training-word" >
                        <FontTitle className={styles['row-training'] + ' ' + styles['training-bnr']}>
                          <Img className={styles['training-bnr-icon']} fileName="icon_training_word.svg" alt="単語を覚えよう" />
                          <span className={styles['training-bnr-text']}>単語を覚えよう</span>
                        </FontTitle>
                      </InnerLink>
                    </OneHalfColumn>
                    <OneHalfColumn className={styles['training-box']}>
                      <InnerLink to="/training-number" >
                        <FontTitle className={styles['row-training'] + ' ' + styles['training-bnr']}>
                          <Img className={styles['training-bnr-icon']} fileName="icon_training_number.svg" alt="数字を覚えよう" />
                          <span className={styles['training-bnr-text']}>数字を覚えよう</span>
                        </FontTitle>
                      </InnerLink>
                    </OneHalfColumn>
                  </div>
                  <div className={styles['row-training']}>
                    <OneHalfColumn className={styles['training-box']}>
                      <InnerLink to="/training-alphabet">
                        <FontTitle className={styles['row-training'] + ' ' + styles['training-bnr']}>
                          <Img className={styles['training-bnr-icon']} fileName="icon_training_alphabet.svg" alt="アルファベットを覚えよう" />
                          <span className={styles['training-bnr-text']}>アルファベットを<br />覚えよう</span>
                        </FontTitle>
                      </InnerLink>
                    </OneHalfColumn>
                    <OneHalfColumn className={styles['training-box']}>
                      <InnerLink to="/training-name-and-face">
                        <FontTitle className={styles['row-training'] + ' ' + styles['training-bnr']}>
                          <Img className={styles['training-bnr-icon']} fileName="icon_training_face.svg" alt="顔と名前を覚えよう" />
                          <span className={styles['training-bnr-text']}>顔と名前を<br />覚えよう</span>
                        </FontTitle>
                      </InnerLink>
                    </OneHalfColumn>
                  </div>
                  <div className={styles['row-training']}>
                    <OneHalfColumn className={styles['training-box']}>
                      <InnerLink to="/training-circle-ratio">
                        <FontTitle className={styles['row-training'] + ' ' + styles['training-bnr']}>
                          <Img className={styles['training-bnr-icon']} fileName="icon_training_pi.svg" alt="円周率を覚えよう" />
                          <span className={styles['training-bnr-text']}>円周率を<br />覚えよう</span>
                        </FontTitle>
                      </InnerLink>
                    </OneHalfColumn>
                    <OneHalfColumn className={styles['training-box']}>
                      <InnerLink to="/training-national-capitals">
                        <FontTitle className={styles['row-training'] + ' ' + styles['training-bnr']}>
                          <Img className={styles['training-bnr-icon']} fileName="icon_training_national_capitals.svg" alt="国の名前と首都を覚えよう" />
                          <span className={styles['training-bnr-text']}>国の名前と首都を<br />覚えよう</span>
                        </FontTitle>
                      </InnerLink>
                    </OneHalfColumn>
                  </div>
                </div>
              </React.Fragment>
            )}

            {!isPaid && (
              <React.Fragment>
                <TitleItemized id="training-list" >トレーニング一覧 (一般会員は単語と円周率のみ選択可能)</TitleItemized>
                <div className={styles['training-content']}>
                  <div className={styles['row-training']}>
                    { /* 単語のみ、一般会員にも開放する */}
                    <OneHalfColumn className={styles['training-box']}>
                      <InnerLink to="/training-word" >
                        <FontTitle className={styles['row-training'] + ' ' + styles['training-bnr']}>
                          <Img className={styles['training-bnr-icon']} fileName="icon_training_word.svg" alt="単語を覚えよう" />
                          <span className={styles['training-bnr-text']}>単語を覚えよう</span>
                        </FontTitle>
                      </InnerLink>
                    </OneHalfColumn>
                    <OneHalfColumn className={styles['training-box']}>
                      { /* <InnerLink to="/training-number" > */}
                      <FontTitle className={styles['row-training'] + ' ' + styles['training-bnr'] + ' ' + styles['disabled']}>
                        <Img className={styles['training-bnr-icon']} fileName="icon_training_number.svg" alt="数字を覚えよう" />
                        <span className={styles['training-bnr-text']}>数字を覚えよう</span>
                      </FontTitle>
                      { /* </InnerLink> */}
                    </OneHalfColumn>
                  </div>
                  <div className={styles['row-training']}>
                    <OneHalfColumn className={styles['training-box']}>
                      { /* <InnerLink to="/training-alphabet"> */}
                      <FontTitle className={styles['row-training'] + ' ' + styles['training-bnr'] + ' ' + styles['disabled']}>
                        <Img className={styles['training-bnr-icon']} fileName="icon_training_alphabet.svg" alt="アルファベットを覚えよう" />
                        <span className={styles['training-bnr-text']}>アルファベットを<br />覚えよう</span>
                      </FontTitle>
                      { /* </InnerLink> */}
                    </OneHalfColumn>
                    <OneHalfColumn className={styles['training-box']}>
                      { /* <InnerLink to="/training-name-and-face"> */}
                      <FontTitle className={styles['row-training'] + ' ' + styles['training-bnr'] + ' ' + styles['disabled']}>
                        <Img className={styles['training-bnr-icon']} fileName="icon_training_face.svg" alt="顔と名前を覚えよう" />
                        <span className={styles['training-bnr-text']}>顔と名前を<br />覚えよう</span>
                      </FontTitle>
                      { /* </InnerLink> */}
                    </OneHalfColumn>
                  </div>
                  <div className={styles['row-training']}>
                    <OneHalfColumn className={styles['training-box']}>
                      { /* 円周率はマイページでの一覧としては選択可能。ただしモード設定画面で「回答のみ」だけ選べるように制限する */}
                      <InnerLink to="/training-circle-ratio">
                        <FontTitle className={styles['row-training'] + ' ' + styles['training-bnr']}>
                          <Img className={styles['training-bnr-icon']} fileName="icon_training_pi.svg" alt="円周率を覚えよう" />
                          <span className={styles['training-bnr-text']}>円周率を<br />覚えよう</span>
                        </FontTitle>
                      </InnerLink>
                    </OneHalfColumn>
                    <OneHalfColumn className={styles['training-box']}>
                      { /* <InnerLink to="/training-alphabet"> */}
                      <FontTitle className={styles['row-training'] + ' ' + styles['training-bnr'] + ' ' + styles['disabled']}>
                        <Img className={styles['training-bnr-icon']} fileName="icon_training_national_capitals.svg" alt="国の名前と首都を覚えよう" />
                        <span className={styles['training-bnr-text']}>国の名前と首都を<br />覚えよう</span>
                      </FontTitle>
                      { /* </InnerLink> */}
                    </OneHalfColumn>
                  </div>
                </div>
              </React.Fragment>
            )}
          </TwoThirdColumn>
          <OneThirdColumn>
            <NewsArea />
          </OneThirdColumn>
        </ColumnLayout>
      </PageContainer>
      <Footer isLogin={isLogin} />
    </div>
  );
};

export default MyPageTemplate;
