import React from 'react';
import PrivacyPolicyTemplate from '../../templates/PrivacyPolicyTemplate';
import { useAppSelector } from '../../../redux/app/hooks';
import {
    selectLoginState,
} from '../../../redux/slices/loginSlice';
import { selectPaymentState } from '../../../redux/slices/paymentSlice';


export type PrivacyPolicyPageProps = {
    children?: never;
};


const PrivacyPolicyPage : React.FC<PrivacyPolicyPageProps> = () => {
    const {
        isLogin,
        isSocialLogin,
        preferredUsername,
    } = useAppSelector(selectLoginState);

    const {
        isPaid,
    } = useAppSelector(selectPaymentState);

    // to_implement
    const profileImg = undefined;

    return (
        <PrivacyPolicyTemplate
          isLogin={isLogin}
          isPaid={isPaid}
          isSocialLogin={isSocialLogin}
          profileImg={profileImg}
          preferredUsername={preferredUsername}
        />
    );
};

export default PrivacyPolicyPage;
