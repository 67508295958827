import React from 'react';
import InnerLink from '../../atoms/InnerLink';
import { LogoWhite } from '../../atoms/Img';
import { NowYear } from '../../atoms/Time';
import { UrlLabel } from '../../../redux/constant';
// CSSはインポートした順に生成され、後にインポートしたCSSの方が優先されるので、
// まず他のコンポーネントや共通CSSをインポートして、その後でコンポーネントごとのCSSをインストールすること
// つまり、styles.module.cssのインポートはインポート文の複数行のうちの最後に置いておくのが安全
import styles from './styles.module.css';

const beforeLoginUrlLabels: Array<UrlLabel> = [
    { url: '/', label: 'メモアカとは' },
    { url: 'https://corp.memoaca.com', label: '運営元', targetBlank: true},
    { url: '/notation', label: '特定商取引法に基づく表記' },
    { url: '/terms-of-service', label: '利用規約' },
    { url: '/contact', label: 'お問い合わせ' },
    { url: '/privacy-policy', label: 'プライバシーポリシー' },
    { url: '/news', label: 'メモアカに関するお知らせ' },
];

const afterLoginUrlLabels: Array<UrlLabel> = [
    { url: '/mypage', label: 'マイページ' },
    { url: '/course-list', label: 'コース一覧' },
    { url: 'https://corp.memoaca.com', label: '運営元', targetBlank: true},
    { url: '/notation', label: '特定商取引法に基づく表記' },
    { url: '/terms-of-service', label: '利用規約' },
    { url: '/contact', label: 'お問い合わせ' },
    { url: '/privacy-policy', label: 'プライバシーポリシー' },
];


export type FooterProps = {
    isLogin?: boolean;
    className?: string;
};

const Footer : React.FC<FooterProps> = ({
    isLogin = false,
    className = '',
}) => {
    // ログイン状態によって表示するメニューを変える
    let UrlLabels = beforeLoginUrlLabels;
    if (isLogin) {
        UrlLabels = afterLoginUrlLabels;
    }

    // class属性を設定
    let classString = styles.footer;
    if (className) {
        classString = [classString, className].join(' ');
    }

    return (
        <footer className={classString}>
           <ul className={styles['footer-nav-list']}>
               { UrlLabels.map((urlLabel, idx) => (
                    <li key={ idx }  className={styles['footer-nav-item']}>
                        {urlLabel.targetBlank?
                            <a href={urlLabel.url} target="_blank" rel="noreferrer" className={styles['pseudo-inner-link']}>{urlLabel.label}</a>:
                            <InnerLink to={ urlLabel.url }>{urlLabel.label }</InnerLink>
                        }
                    </li>
                )) }
            </ul>

        <div className={styles['footer-logo']}><LogoWhite /></div>

        <p className={styles['copyright-area']}>
            <small>&copy;&ensp;<NowYear/>&ensp;株式会社メモアカ</small>
        </p>

        </footer>
    );
};

export default Footer;
