import React from 'react';
import templateProperties from '../../common/template-properties.module.css';
import { UrlLabel } from '../../../redux/constant';
import HeaderContainer from '../../organisms/HeaderContainer';
import PageTitleArea from '../../layouts/PageTitleArea';
import BreadcrumbBar from '../../organisms/BreadcrumbBar';
import { PageContainer } from '../../layouts/Container';
import Textarea from '../../atoms/Textarea';
import { AlertButton } from '../../atoms/Button';
import Footer from '../../organisms/Footer';
import InnerLink from '../../atoms/InnerLink';
import OkCancelModal from '../../organisms/OkCancelModal';
import moment from 'moment';
import Time from '../../atoms/Time';
import { AlertMessageBar } from '../../atoms/MessageBar';
// CSSはインポートした順に生成され、後にインポートしたCSSの方が優先されるので、
// まず他のコンポーネントや共通CSSをインポートして、その後でコンポーネントごとのCSSをインストールすること
// つまり、styles.module.cssのインポートはインポート文の複数行のうちの最後に置いておくのが安全
import styles from './styles.module.css';

Object.assign(styles, templateProperties);

const WithdrawalBL: Array<UrlLabel> = [
    { url: '/mypage', label: 'マイページ' },
    { url: '/profile', label: 'プロフィール設定' },
    { url: '', label: 'アカウントの削除' },
];

export type WithdrawalTemplateProps = {
    isLogin: boolean;             // ログイン状態
    isPaid: boolean;              // 有料会員かどうか
    isSocialLogin: boolean;       // SNSログイン会員かどうか
    profileImg?: string;          // 会員画像
    preferredUsername? : string;  // 会員名

    isSubscribing: boolean;
    now: number;                  // 現在の日時(UnixTimestamp)
    paymentExpiredOn: number;     // 支払いがなければ有料会員でなくなる日(UnixTimestamp)

    modalIsOpen?: boolean;
    modalButtonText?: string;
    clickOkButton: () => void;
    clickCancelButton: () => void;

    reasonForWithdrawal: string;
    changeReasonForWithdrawal: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;

    submit: (event: React.MouseEvent<HTMLButtonElement>) => void;
};

const WithdrawalTemplate : React.FC<WithdrawalTemplateProps> = ({
    isLogin,
    isPaid,
    isSocialLogin,
    profileImg,
    preferredUsername,

    isSubscribing,
    now,
    paymentExpiredOn,

    modalIsOpen = false,
    clickOkButton,
    clickCancelButton,

    reasonForWithdrawal,
    changeReasonForWithdrawal,

    submit,
}) => {
  // UnixTimestampの日時情報を処理
  const paymentExpiredOnMoment = moment.unix(paymentExpiredOn);

  return (
      <React.Fragment>
        <div className={ styles['withdrawal-template'] + ' ' + styles['template-properties-local'] }>
          <HeaderContainer isLogin={isLogin} isPaid={isPaid} isSocialLogin={isSocialLogin} profileImg={profileImg} preferredUsername={preferredUsername} />
          <PageTitleArea>アカウントの削除</PageTitleArea>
          <BreadcrumbBar UrlLabels={WithdrawalBL} />
          <PageContainer className={styles['form-area']}>
          <AlertMessageBar><strong>ご確認ください</strong><br/>アカウントを削除するとこれまでのデータはすべて削除されます</AlertMessageBar>

          {isSubscribing &&
              <React.Fragment>
              <p className={styles['explanation-text']}>
                あなたは有料会員です。<br/>
                アカウントを削除して退会される場合は、<br className={styles['d-md-none']} />まずお支払を停止してください。
              </p>
              <br/>
              <InnerLink to='/payment'>お支払い情報画面に移動</InnerLink>
              </React.Fragment>
           }


          { (!isSubscribing && now < paymentExpiredOn) &&
            <React.Fragment>
            <p className={styles['explanation-text']}>
              <strong>あなたは<Time timeValue={paymentExpiredOnMoment} format='YYYY年MM月DD日' />まで有料会員としての資格があります。<br/>それでもアカウントを削除しますか？</strong>
            </p>
            <p className={styles['explanation-text']}>よろしければ理由をお書きください。<br className={styles['d-md-none']} />(任意、1000文字以内)</p>
            <Textarea placeholder='ご自由にご記入ください' id='reason-for-withdrawal' value={reasonForWithdrawal} className={styles['textarea']} onChange={changeReasonForWithdrawal} />
            <AlertButton className={styles['button']} onClick={submit}>アカウントを削除して退会する</AlertButton>
            </React.Fragment>
          }

          {(!isSubscribing && now >= paymentExpiredOn) &&
            <React.Fragment>
            <p className={styles['explanation-text']}>よろしければ理由をお書きください。<br className={styles['d-md-none']} />(任意、1000文字以内)</p>
            <Textarea placeholder='ご自由にご記入ください' id='reason-for-withdrawal' value={reasonForWithdrawal} className={styles['textarea']} onChange={changeReasonForWithdrawal} />
            <AlertButton className={styles['button']} onClick={submit}>アカウントを削除して退会する</AlertButton>
            </React.Fragment>
          }

          </PageContainer>
          <Footer isLogin={isLogin} />
        </div>
        <OkCancelModal
          modalIsOpen={modalIsOpen}
          headerText='アカウントの削除の確認'
          clickOkButton={clickOkButton}
          clickCancelButton={clickCancelButton}
        >
          本当にアカウントを<br/>削除しますか？
        </OkCancelModal>
        </React.Fragment>
    );
};

export default WithdrawalTemplate;
