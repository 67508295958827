import React from 'react';
import templateProperties from '../../common/template-properties.module.css';
import DoorwayLayout from '../../layouts/DoorwayLayout';
import DoorwayFormLayout from '../../layouts/DoorwayFormLayout';
import { ErrorMessageBar } from '../../atoms/MessageBar';
import Anchor from '../../atoms/Anchor';
import InnerLink from '../../atoms/InnerLink';
import Button, { PrimaryWrapButton, GrayWrapButton } from '../../atoms/Button';
import { LogoFacebook, LogoGoogle } from '../../atoms/Img';
import Textlabel from '../../atoms/Textlabel';
import Textbox from '../../atoms/Textbox';
import PasswordBox from '../../molecules/PasswordBox';
import Checkbox from '../../atoms/Checkbox';
import { SignupPhase } from '../../../redux/constant';
import awsConfig from '../../../aws-exports.js';
// CSSはインポートした順に生成され、後にインポートしたCSSの方が優先されるので、
// まず他のコンポーネントや共通CSSをインポートして、その後でコンポーネントごとのCSSをインストールすること
// つまり、styles.module.cssのインポートはインポート文の複数行のうちの最後に置いておくのが安全
import styles from './styles.module.css';

Object.assign(styles, templateProperties);

export type SignupTemplateProps = {
  email: string;
  password: string;
  showPwdFlag: boolean;
  isCheckedTOS: boolean;
  isCheckedPP: boolean;
  signupPhase: SignupPhase;
  code: string;
  emailValidationMessage: string;
  passwordValidationMessage: string;
  codeValidationMessage: string;
  checkTOSValidationMessage: string;
  checkPPValidationMessage: string;

  changeEmail: (event: React.ChangeEvent<HTMLInputElement>) => void;
  changePassword: (event: React.ChangeEvent<HTMLInputElement>) => void;
  toggleShowPwdFlag: (event: React.MouseEvent<HTMLButtonElement>) => void;
  changeCheckTOS: (event: React.ChangeEvent<HTMLInputElement>) => void;
  changeCheckPP: (event: React.ChangeEvent<HTMLInputElement>) => void;

  submit: (event: React.MouseEvent<HTMLButtonElement>) => void;

  changeCodeAndValidationMessage: (event: React.ChangeEvent<HTMLInputElement>) => void;
  submitCode: (event: React.MouseEvent<HTMLButtonElement>) => void;

  children?: never;
};

const SignupTemplate: React.FC<SignupTemplateProps> = ({
  email,
  password,
  showPwdFlag,
  isCheckedTOS,
  isCheckedPP,

  signupPhase,
  code,
  emailValidationMessage,
  passwordValidationMessage,
  checkTOSValidationMessage,
  checkPPValidationMessage,
  codeValidationMessage,

  changeEmail,
  changePassword,
  toggleShowPwdFlag,
  changeCheckTOS,
  changeCheckPP,
  submit,

  changeCodeAndValidationMessage,
  submitCode,
}) => {
  const CognitoDomain = awsConfig.oauth.domain;
  const CognitoAppClientID = awsConfig.aws_user_pools_web_client_id;
  const RedirectURI = awsConfig.oauth.redirectSignIn;
  const facebookRedirectUrl = `https://${CognitoDomain}/oauth2/authorize?identity_provider=Facebook&redirect_uri=${RedirectURI}&response_type=code&client_id=${CognitoAppClientID}`;
  const googleRedirectUrl = `https://${CognitoDomain}/oauth2/authorize?identity_provider=Google&redirect_uri=${RedirectURI}&response_type=code&client_id=${CognitoAppClientID}`;

  return (
    <DoorwayLayout className={styles['template-properties-local']}>
      {signupPhase === SignupPhase.signup &&
        <React.Fragment>
          <DoorwayFormLayout title='新規会員登録'>
            <div className={styles['form-component-wrap']}>
              <Textlabel htmlFor="user-email">メールアドレス</Textlabel>
              <Textbox id="user-email" value={email} type="email" placeholder="Email" onChange={changeEmail} />
            </div>
            {emailValidationMessage && <ErrorMessageBar>{emailValidationMessage}</ErrorMessageBar>}

            <div className={styles['form-component-wrap']}>
              <Textlabel htmlFor="user-pw">パスワード</Textlabel>
              <PasswordBox className={styles['password-box']} id="user-pw" value={password} showPwdFlag={showPwdFlag} onChange={changePassword} toggleShowPwdFlag={toggleShowPwdFlag} />
            </div>
            <div>パスワードは8文字以上の半角英数字で設定してください</div>
            {passwordValidationMessage && <ErrorMessageBar>{passwordValidationMessage}</ErrorMessageBar>}

            <div className={styles['signup-check-area']}>
              <label className={styles['signup-check']}>
                <Checkbox id="terms-of-service" checked={isCheckedTOS} onChange={changeCheckTOS} />
                <span className={styles['signup-label-body']}><InnerLink to="/terms-of-service" target="_blank">利用規約</InnerLink>に同意する</span>
              </label>
              {checkTOSValidationMessage && <ErrorMessageBar>{checkTOSValidationMessage}</ErrorMessageBar>}
              <label className={styles['signup-check']}>
                <Checkbox id="privacy-policy" checked={isCheckedPP} onChange={changeCheckPP} />
                <span className={styles['signup-label-body']}><InnerLink to="/privacy-policy" target="_blank">プライバシーポリシー</InnerLink>に同意する</span>
              </label>
              {checkPPValidationMessage && <ErrorMessageBar>{checkPPValidationMessage}</ErrorMessageBar>}
            </div>
            {(email && password && isCheckedTOS && isCheckedPP && !emailValidationMessage && !passwordValidationMessage && !checkTOSValidationMessage && !checkPPValidationMessage) ? (
              <PrimaryWrapButton className={styles['button']} onClick={submit}>新規登録</PrimaryWrapButton>
            ) : (
              <GrayWrapButton className={styles['button']} disabled>新規登録</GrayWrapButton>
            )}

            <div className={styles['signup-other']}>
              <div className={styles['signup-other-txt']}>または</div>
              <div className={styles['form-component-wrap'] + ' ' + styles['signup-other-item']}>
                <Anchor href={facebookRedirectUrl}><Button className={styles['button']}><LogoFacebook />Facebookで登録</Button></Anchor>
              </div>
              <div className={styles['form-component-wrap'] + ' ' + styles['signup-other-item']}>
                <Anchor href={googleRedirectUrl}><Button className={styles['button']}><LogoGoogle />Googleで登録</Button></Anchor>
              </div>
            </div>
          </DoorwayFormLayout>
          <p className="login-link"><InnerLink to="/login">すでに登録されている方はログイン画面へ</InnerLink></p>
        </React.Fragment>
      }

      {signupPhase === SignupPhase.confirm &&
        <DoorwayFormLayout title='コード入力'>
          {codeValidationMessage && <ErrorMessageBar>{codeValidationMessage}</ErrorMessageBar>}
          <p>入力されたメールアドレス宛にメールを送りました。<br />その中に指定された認証コードを入力してください。</p>
          <div className={styles['form-component-wrap']}>
            <Textlabel htmlFor="user-code">認証コード</Textlabel>
            <Textbox id="user-code" value={code} onChange={changeCodeAndValidationMessage} />
          </div>
          {(code && !codeValidationMessage) ? (
            <PrimaryWrapButton className={styles['button']} onClick={submitCode}>コード確認</PrimaryWrapButton>
          ) : (
            <GrayWrapButton className={styles['button']} onClick={submitCode} disabled>コード確認</GrayWrapButton>
          )}
        </DoorwayFormLayout>
      }

    </DoorwayLayout>
  );
};

export default SignupTemplate;
