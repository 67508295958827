import React from 'react';
import { JustClickButton } from '../../atoms/Button';
import Textbox from '../../atoms/Textbox';
import { IconEyeOff, IconEyeOn } from '../../atoms/Img';
// CSSはインポートした順に生成され、後にインポートしたCSSの方が優先されるので、
// まず他のコンポーネントや共通CSSをインポートして、その後でコンポーネントごとのCSSをインストールすること
// つまり、styles.module.cssのインポートはインポート文の複数行のうちの最後に置いておくのが安全
import styles from './styles.module.css';

export type PasswordBoxProps = {
    className?: string;
    showPwdFlag: boolean;
    toggleShowPwdFlag: (event: React.MouseEvent<HTMLButtonElement>) => void;
} & JSX.IntrinsicElements['input']

const PasswordBox : React.FC<PasswordBoxProps> = ({
    className='',
    showPwdFlag=false,
    toggleShowPwdFlag,
    ...props
}) => {
    // class属性を設定
    let classString = styles['password-box'];
    if (className) {
        classString = [classString, className].join(' ');
    }

    // viewTypeをshowPwdFlagから設定
    let viewType;
    if (showPwdFlag) {
        viewType='text';
    } else {
        viewType='password';
    }

    return (
        <div className={ classString }>
           <Textbox placeholder='パスワード' type={viewType} {...props} />
           <JustClickButton onClick={toggleShowPwdFlag}>{showPwdFlag? <IconEyeOff /> : <IconEyeOn /> }</JustClickButton>
        </div>
    );
};

export default PasswordBox;
