import React from 'react';
import { CourseColor } from '../../../config/course';
import { CorrectAnswer } from '../../../lib/domSlide';
import { SDGsKeywordLessonSlide } from '../../../config/course';
import SlidePracticeBackground from '../../atoms/SlidePracticeBackground';
import FontTitle from '../../atoms/FontTitle';
import Textbox from '../../atoms/Textbox';
import { PrimaryButton } from '../../atoms/Button';
// CSSはインポートした順に生成され、後にインポートしたCSSの方が優先されるので、
// まず他のコンポーネントや共通CSSをインポートして、その後でコンポーネントごとのCSSをインストールすること
// つまり、styles.module.cssのインポートはインポート文の複数行のうちの最後に置いておくのが安全
import styles from './styles.module.css';

export type SlidePracticeSDGsKeywordProps = {
    pageIndex: number;
    slideColor: CourseColor;
    isCheckingAnswers: boolean;
    myAnswers: Array<CorrectAnswer>;
    updateMyAnswersWithIndex: (index:number) => (e: React.ChangeEvent<HTMLInputElement>) => void;
    updateIsCheckingAnswersTrue: (e: React.MouseEvent<HTMLButtonElement>) => void;
} & Omit<SDGsKeywordLessonSlide, 'type'>;

const SlidePracticeSDGsKeyword : React.FC<SlidePracticeSDGsKeywordProps> = ({
    pageIndex,
    slideColor,
    memorizationTitle,
    recallTitle,
    resultTitle = '結果を判定します',
    has6Rows,
    correctAnswers,
    myAnswers,
    isCheckingAnswers,

    updateMyAnswersWithIndex,
    updateIsCheckingAnswersTrue,
}) => {
    const domSlideClass = styles['dom-slide'];              // スライド領域
    const titleClass = styles['dom-slide-title'];           // タイトル設定
    let answerAreaClass = styles['dom-slide-answerarea'];   // 問題・回答エリア設定
    let itemGroupClass = styles['item-group'];              // 問題 ラベルと回答欄を合わせたエリア
    const itemLabelClass = styles['item-label'];            // 問題ラベル設定 
    let answerColumnClass = styles['input-item'];           // 問題・回答欄設定
    const buttonAreaClass = styles['button-area'];          // ボタン設定
    let rowNum = 5;                                         // 行数

    if (has6Rows) {
        answerAreaClass += ' '+ styles['has-6-rows'];
        answerColumnClass += ' '+ styles['has-6-rows'];
        itemGroupClass += ' '+ styles['has-6-rows'];
        rowNum = 6;
    }

    if (pageIndex === 1 && isCheckingAnswers) { // 結果画面
        answerAreaClass += ' '+ styles['result'];
        answerColumnClass += ' '+ styles['result'];
        itemGroupClass += ' '+ styles['result'];
    }

    return (
        <div className={ styles['slide-practice-sdgs-keyword'] }>
            <SlidePracticeBackground bgColor={slideColor} className={domSlideClass}>
                {/* 記憶画面 */}
                { pageIndex === 0 &&
                <div className={styles['dom-slide-area']}>
                    <FontTitle className={titleClass}>{memorizationTitle}</FontTitle>
                    <div className={styles['dom-slide-content']}>
                        <div className={answerAreaClass}>
                            {correctAnswers.map((correctAnswer, idx) => {
                                if (correctAnswer.label) { // ラベル有 最初のキーワード(絶対存在)
                                    return (
                                        <div className={itemGroupClass} key={idx}>
                                            <label className={itemLabelClass}>{correctAnswer.label}</label>
                                            <span className={answerColumnClass}>{correctAnswer.words[0]}</span>
                                        </div>
                                    );
                                } else { // ラベル無 2番目以降のキーワード
                                    if (correctAnswer.words[0]) {
                                        return (
                                            <div className={itemGroupClass+' '+styles['no-label']} key={idx}>
                                                <span className={answerColumnClass}>{correctAnswer.words[0]}</span>
                                            </div>
                                        );
                                    } else {
                                        return (<div className={itemGroupClass+' '+styles['no-label']+' '+styles['hidden-item']} key={idx}></div>);
                                    }
                                }
                            })}
                        </div>
                    </div>
                </div>
                }

                {/* 回答画面 */}
                { pageIndex === 1 && !isCheckingAnswers &&
                <div className={styles['dom-slide-area']}>
                    <FontTitle className={titleClass}>{recallTitle}</FontTitle>
                    <div className={styles['dom-slide-content']}>
                        <div className={answerAreaClass}>
                            {correctAnswers.map((correctAnswer, idx) => {
                                const tabIndex = 3*(idx%rowNum) + Math.floor(idx/rowNum) + 1; // 入力欄のタブインデックス番号を生成
                                if (correctAnswer.label) { // ラベル有 最初のキーワード(絶対存在)
                                    return (
                                        <div className={itemGroupClass} key={idx}>
                                            <label className={itemLabelClass}>{correctAnswer.label}</label>
                                            <Textbox key={idx} className={answerColumnClass} value={myAnswers[idx]?.words[0] || []} onChange={updateMyAnswersWithIndex(idx)} maxLength={8} tabIndex={tabIndex} />
                                        </div>
                                    );
                                } else { // ラベル無 2番目以降のキーワード
                                    if (correctAnswer.words[0]) {
                                        return (
                                            <div className={itemGroupClass+' '+styles['no-label']} key={idx}>
                                                <Textbox key={idx} className={answerColumnClass} value={myAnswers[idx]?.words[0] || []} onChange={updateMyAnswersWithIndex(idx)} maxLength={8} tabIndex={tabIndex} />
                                            </div>
                                        );
                                    } else {
                                        return (<div className={itemGroupClass+' '+styles['no-label']+' '+styles['hidden-item']} key={idx}></div>);
                                    }
                                }
                            })}
                        </div>
                        <div className={buttonAreaClass}>
                            { /* 入力欄が最大で6行*3列=18個あるので、ボタンのtabIndexはその次の19に設定 */ }
                            <PrimaryButton className={styles['button']} onClick={updateIsCheckingAnswersTrue} tabIndex={19}>答え合わせする</PrimaryButton>
                        </div>
                    </div>
                </div>
                }

                {/* 結果画面 */}
                { pageIndex === 1 && isCheckingAnswers &&
                <div className={styles['dom-slide-area']}>
                    <FontTitle className={titleClass}>{resultTitle}</FontTitle>
                    <div className={styles['dom-slide-content']}>
                        <div className={answerAreaClass}>
                            {correctAnswers.map((correctAnswer, idx) => {
                                const value = myAnswers[idx]?.words[0] || '';
                                const resultClass = (correctAnswer.words.includes(value))? styles['correct'] : styles['incorrect'];

                                if (correctAnswer.label) { // ラベル有 最初のキーワード(絶対存在)
                                    return (
                                        <div className={itemGroupClass} key={idx}>
                                            <label className={itemLabelClass}>{correctAnswer.label}</label>
                                            <span className={answerColumnClass+' '+resultClass}>{myAnswers[idx]?.words[0] || ''}</span><br/>
                                        </div>
                                    );
                                } else { // ラベル無 2番目以降のキーワード
                                    if (correctAnswer.words[0]) {
                                        return (
                                            <div className={itemGroupClass+' '+styles['no-label']} key={idx}>
                                                <span className={answerColumnClass+' '+resultClass}>{myAnswers[idx]?.words[0] || ''}</span><br/>
                                            </div>
                                        );
                                    } else {
                                        return (
                                            <div className={itemGroupClass+' '+styles['no-label']+' '+styles['hidden-item']} key={idx}></div>
                                        );
                                    }
                                }
                            })}
                        </div>
                    </div>
                </div>
                }
            </SlidePracticeBackground>
        </div>
    );
};

export default SlidePracticeSDGsKeyword;
