import React, {
    useEffect,
    useState,
    useRef,
} from 'react';
import { useParams, useLocation, useNavigate } from "react-router-dom";
import LessonTemplate from '../../templates/LessonTemplate';
import {
    CourseId,
    CourseColor,
    courseConfig,
    Course,
    Lesson,
} from '../../../config/course';
import { useAppSelector, useAppDispatch } from '../../../redux/app/hooks';
import {
    CompleteLessonParams,

    initLoadLessonConfig,
    updateMyAnswers,
    updateIsCheckingAnswers,
    completeLesson,
    closeModal,
    updateIsScaling,
    updateScaleRate,

    selectLessonState,
} from '../../../redux/slices/lessonSlice';
import {
    selectLoginState,
} from '../../../redux/slices/loginSlice';
import { selectPaymentState } from '../../../redux/slices/paymentSlice';
// CSSはインポートした順に生成され、後にインポートしたCSSの方が優先されるので、
// まず他のコンポーネントや共通CSSをインポートして、その後でコンポーネントごとのCSSをインストールすること
// つまり、styles.module.cssのインポートはインポート文の複数行のうちの最後に置いておくのが安全
import styles from './styles.module.css';


const pdfPassword = process.env.REACT_APP_PDF_PASSWORD;

type LessonPageProps = {
    children?: never,
};

// https://v5.reactrouter.com/web/example/query-parameters
function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}

const sleep = (msec: number) => new Promise((resolve) => setTimeout(resolve, msec));

const LessonPage: React.FC<LessonPageProps> = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const {
        isLogin,
        isSocialLogin,
        sub,
        experiencePoint,
        addedExperiencePoint,
        preferredUsername,
    } = useAppSelector(selectLoginState);

    const {
        isPaid,
    } = useAppSelector(selectPaymentState);

    const params = useParams();
    const courseId = params.courseId;
    const lessonNoStr: string = params.lessonNoStr || '';
    const lessonNo = parseInt(lessonNoStr) || undefined;

    const query = useQuery();
    const defaultPage = parseInt(query.get('defaultPage') || '1');
    const defaultPageInd = defaultPage >= 1 ? defaultPage - 1 : 0;

    const [course, setCourse] = useState<Course>();
    const [lessonInd, setLessonInd] = useState<number>();
    const [lesson, setLesson] = useState<Lesson | undefined>();
    const {
        lessonConfig,
        myAnswers,
        isCheckingAnswers,
        lessonModalIsOpen,
        isScaling,
        scaleRate,
    } = useAppSelector(selectLessonState);

    useEffect(() => {
        setLesson(lessonConfig);
    }, [lessonConfig]);

    useEffect(() => {
        if (typeof courseId === 'undefined') {
            return;
        }

        for (let tmpCourse of courseConfig) {
            if (tmpCourse.courseId !== courseId) {
                continue;
            }

            setCourse(tmpCourse);
        }
    }, [courseId]);

    const [completeLessonParams, setCompleteLessonParams] = useState<CompleteLessonParams>();
    useEffect(() => {
        if (typeof course === 'undefined' || typeof lessonNo === 'undefined') {
            return;
        }

        for (let i = 0; i < course.lessons.length; i++) {
            const tmpLesson = course.lessons[i];
            if (tmpLesson.lessonNo !== lessonNo) {
                continue;
            }

            setLessonInd(i);
            setLesson(tmpLesson);
            if (lesson !== tmpLesson) {
                dispatch(initLoadLessonConfig(tmpLesson));
            }
        }

        if (typeof courseId !== 'undefined' && typeof lessonNo !== 'undefined') {
            setCompleteLessonParams({
                sub,
                courseId,
                lessonNo,
                isAdvanced: course?.isAdvanced || false,
            });
        };
    }, [dispatch, sub, course, courseId, lessonNo, lesson]);

    const updateMyAnswersWithIndex = (index: number) => {
        return (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
            const payload = {
                index,
                word: e.target.value.trim(),
            };

            return dispatch(updateMyAnswers(payload));
        }
    };

    const updateIsCheckingAnswersTrue = (e: React.MouseEvent<HTMLButtonElement>) => {
        return dispatch(updateIsCheckingAnswers(true));
    }

    const [profileImg, setProfileImg] = useState();
    const [courseTitle, setCourseTitle] = useState('');
    const [courseColor, setCourseColor] = useState<CourseColor>(CourseColor.blue);

    const [previousLessonUrl, setPreviousLessonUrl] = useState('');
    const [nextLessonUrl, setNextLessonUrl] = useState('');
    useEffect(() => {
        // TODO
        // to_implement
        // 本来はここでログイン状況や会員画像・会員名を取得する処理が入る
        const fetchProfileImg = () => undefined;
        setProfileImg(fetchProfileImg());
        setCourseTitle(course?.courseTitle || '');
        setCourseColor(course?.courseColor || CourseColor.blue);

        if (course && typeof lessonInd !== 'undefined' && lessonInd >= 1 && course.lessons[lessonInd - 1].lessonNo) {
            setPreviousLessonUrl(`/course-list/${courseId}/${course.lessons[lessonInd - 1].lessonNo}`);
        } else {
            // 次のレッスンを押した後にまたひとつ前のレッスンに戻り#1にきたとき、disabled属性のボタンを表示する
            setPreviousLessonUrl('');
        }

        if (course && typeof lessonInd !== 'undefined' && lessonInd + 1 <= course.lessons.length - 1) {
            // FIXME ペグ法の最後(7)については、課金していない場合は「次のレッスン」を表示させない
            // バグの元なので、今のステータス (isPaid) で(courseId, lessonNo)を開けるかを判定する関数を作るべき
            if (courseId === CourseId.pegMethod && lessonNo === 7) {
                if (!isPaid) {
                    return;
                }
            }

            setNextLessonUrl(`/course-list/${courseId}/${course.lessons[lessonInd + 1].lessonNo}`);
        } else {
            // 前のレッスンを押した後にまた次のレッスンに戻り最後のまとめにきたとき、disabled属性のボタンを表示する
            setNextLessonUrl(``);
        }
    }, [course, courseId, lessonInd, setProfileImg, setPreviousLessonUrl, setNextLessonUrl]);

    const closeModalParams = {
        nextLessonUrl,
        navigate,
    }

    // ここがベストか分からないが、課金していない状態で有料ページを開いた場合にレッスン一覧に飛ばす
    useEffect(() => {
        // 読み込めていない間にはisFree = undefinedとなる。
        const isFree = lesson?.isFree;
        if (typeof lesson !== 'undefined' && !isFree && !isPaid) {
            // FIXME: 一度これが発動してcourse-listに戻った後に
            // 無課金で入れるページを見るとまたこのnavigateが発動してしまう
            // URL直打ちでしか発生しない問題なので受容中。
            navigate('/course-list');
        }
    });

    const calcFittingScaleRate = (innerWidth: number, innerHeight: number) => {
        const PDF_HEIGHT = 405.0;
        const ARROW_BUTTON_MARGIN = 15.0;
        const ARROW_BUTTON_HEIGHT = 36.25;

        const hScale = 1.0 * innerHeight / (PDF_HEIGHT + ARROW_BUTTON_MARGIN + ARROW_BUTTON_HEIGHT);

        const PDF_WIDTH = 720.0;
        const wScale = 1.0 * innerWidth / PDF_WIDTH;

        // 1.0よりは小さくならないようにする
        return Math.max(1.0, Math.min(hScale, wScale));
    };

    // TODO
    // Line 214:11:  The 'onResize' function makes the dependencies of useEffect Hook (at line 245) change on every render. To fix this, wrap the definition of 'onResize' in its own useCallback() Hook  react-hooks/exhaustive-deps
    const onResize = () => {
        const rate = calcFittingScaleRate(window.innerWidth, window.innerHeight);

        // 倍率が1.0以下になったら拡大モードを解除
        if (isScaling && rate === 1.0) {
            setIsScaling(false);
        } else {
            dispatch(updateScaleRate(rate));

            if (isScaling) {
                scroll();
            }
        }
    };

    // add,removeEventListenerするために、
    // 再描画時に関数が変わらないようにrefに入れておく
    const onResizeRef = useRef(onResize);

    useEffect(() => {
        // 以前のonResizeをremoveEventListenerしてから、新しいonResizeをaddEventListenerする
        window.removeEventListener('resize', onResizeRef.current);

        onResizeRef.current = onResize;
        window.addEventListener('resize', onResizeRef.current);

        onResizeRef.current();

        return () => {
            window.removeEventListener('resize', onResizeRef.current);
        };
    }, [onResize])

    const noScroll = (e: any) => {
        e.preventDefault();
    };

    // add,removeEventListenerするために、
    // 再描画時に関数が変わらないようにrefに入れておく
    const noScrollRef = useRef(noScroll);

    const noScrollArrow = (e: any) => {
        switch (e.code) {
            case 'ArrowLeft':
            // fall through
            case 'ArrowUp':
            // fall through
            case 'ArrowRight':
            // fall through
            case 'ArrowDown':
                e.preventDefault();
                break;
            case 'Escape':
                setIsScaling(false);

                e.preventDefault();
                break;
        }
    };

    // add,removeEventListenerするために、
    // 再描画時に関数が変わらないようにrefに入れておく
    const noScrollArrowRef = useRef(noScrollArrow);

    const setIsScaling = (newIsScaling: boolean) => {
        if (!newIsScaling) {
            dispatch(updateIsScaling(false));

            document.removeEventListener('touchmove', noScrollRef.current);
            document.removeEventListener('wheel', noScrollRef.current);
            document.removeEventListener('keydown', noScrollArrowRef.current)
        }

        if (newIsScaling && scaleRate > 1.0) {
            dispatch(updateIsScaling(true));

            document.addEventListener('touchmove', noScrollRef.current, { passive: false });
            document.addEventListener('wheel', noScrollRef.current, { passive: false });
            document.addEventListener('keydown', noScrollArrowRef.current, { passive: false });
        }
    }

    // スケール拡大用
    const chainedSlideRef = useRef<null | HTMLDivElement>(null);

    const scroll = () => {
        const block: ScrollLogicalPosition = 'start';
        const inline: ScrollLogicalPosition = 'center';

        const scrollIntoViewOptions = {
            block,
            inline,
        };

        chainedSlideRef?.current?.scrollIntoView(scrollIntoViewOptions);
    };

    const scaleAndScroll = async () => {
        if (!isScaling && scaleRate === 1.0) {
            return;
        }

        setIsScaling(!isScaling);

        // scaleRateの変更によって再描画されるのを待つ
        await sleep(10);

        scroll();
    };

    return (
        <div className={styles['lesson-page']}>
            {typeof courseId !== 'undefined' && typeof lessonNo !== 'undefined' && lesson &&
                <LessonTemplate
                    isLogin={isLogin}
                    isPaid={isPaid}
                    isSocialLogin={isSocialLogin}
                    profileImg={profileImg}
                    preferredUsername={preferredUsername}
                    courseId={courseId} courseTitle={courseTitle} courseColor={courseColor}
                    lessonNo={lessonNo} lesson={lesson}
                    previousLessonUrl={previousLessonUrl}
                    nextLessonUrl={nextLessonUrl}
                    myAnswers={myAnswers}
                    isCheckingAnswers={isCheckingAnswers}
                    modalIsOpen={lessonModalIsOpen}
                    experiencePoint={experiencePoint}
                    addedExperiencePoint={addedExperiencePoint}
                    isScaling={isScaling}
                    scaleRate={scaleRate}
                    chainedSlideRef={chainedSlideRef}
                    pdfPassword={pdfPassword}
                    defaultPageInd={defaultPageInd}
                    updateMyAnswersWithIndex={updateMyAnswersWithIndex}
                    updateIsCheckingAnswersTrue={updateIsCheckingAnswersTrue}
                    lastPageHook={async () => {
                        if (typeof completeLessonParams !== 'undefined') {
                            await sleep(500);
                            dispatch(completeLesson(completeLessonParams));
                        }
                    }}
                    closeModal={() => dispatch(closeModal(closeModalParams))}
                    scaleAndScroll={scaleAndScroll}
                />
            }
        </div>
    );
};

export default LessonPage;
