import React, { ReactNode, useEffect } from 'react';
import LogoutTemplate from '../../templates/LogoutTemplate';
import { useNavigate } from 'react-router-dom';
import { Auth } from 'aws-amplify'
import {
    // useAppSelector,
    useAppDispatch,
} from '../../../redux/app/hooks';
import {
    resetAllStates,
} from '../../../redux/slices/loginSlice';
// CSSはインポートした順に生成され、後にインポートしたCSSの方が優先されるので、
// まず他のコンポーネントや共通CSSをインポートして、その後でコンポーネントごとのCSSをインストールすること
// つまり、styles.module.cssのインポートはインポート文の複数行のうちの最後に置いておくのが安全
import styles from './styles.module.css';


export type LogoutPageProps = {
    pauseMiliSec?: number,
    children?: ReactNode,
};


const LogoutPage : React.FC<LogoutPageProps> = ({
    pauseMiliSec = 1000,
    children,
}) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        const affect = async () => {
            await Promise.all([
                dispatch(resetAllStates()),
                Auth.signOut(),
                new Promise(resolve => setTimeout(resolve, pauseMiliSec)),
            ]);
            navigate('/');
        };

        affect();

        // 初回レンダリングで別ページに遷移するため、1回だけuseEffect()が呼ばれることを期待したいる
        // よって、第2引数は[]としている。
        // eslint-disable-next-line react-hooks/exhaustive-deps
    });

    return (
        <div className={ styles['logout-page'] }>
            <LogoutTemplate/>
        </div>
    );
};

export default LogoutPage;
