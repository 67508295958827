import React, {
    useEffect,
} from 'react';
import ContactTemplate from '../../templates/ContactTemplate';
import { useAppSelector, useAppDispatch } from '../../../redux/app/hooks';
import {
    selectLoginState,
} from '../../../redux/slices/loginSlice';
import {
    changeContactPhase,
    changeUsername,
    changeEmail,
    changeEmail2,
    changeUsertext,

    sendEmail,

    selectContactState,
} from '../../../redux/slices/contactSlice';
import { ContactPhase } from '../../../redux/constant';
import { selectPaymentState } from '../../../redux/slices/paymentSlice';


export type ContactPageProps = {
    children?: never;
};


const ContactPage : React.FC<ContactPageProps> = () => {
    const dispatch = useAppDispatch();

    const {
        isLogin,
        isSocialLogin,
        preferredUsername,
    } = useAppSelector(selectLoginState);

    const {
        isPaid,
    } = useAppSelector(selectPaymentState);

    const {
        contactPhase,

        username,
        email,
        email2,
        usertext,

        usernameValidationMessage,
        emailValidationMessage,
        usertextValidationMessage,
    } = useAppSelector(selectContactState);

    // to_implement
    const profileImg = undefined;

    const confirm = () => dispatch(changeContactPhase(ContactPhase.confirm));
    const back = () => dispatch(changeContactPhase(ContactPhase.send));

    useEffect(() => {
        return () => {
            // ContactPageを離れる時にContactPhaseをリセットする
            dispatch(changeContactPhase(ContactPhase.send));
        }
    }, []);

    // 環境ごとに運営のメールアドレスを環境変数で指定する
    // user1@example.com,user2@example.comのようなコンマ区切り文字列
    const joinedToEmails = email;
    const joinedCcEmails : string | undefined = process.env.REACT_APP_ADMIN_EMAILS;
    const joinedBccEmails = '';

    const body = `${username}様

この度はお問い合わせいただきありがとうございました。
2営業日以内に担当者の方から回答させていただきます。
よろしくお願いいたします。

以下の内容で承りました。

----------
${usertext}
----------

このメールは配信専用です。
返信されても、内容の確認および回答はできません。
メモアカへのご質問・お問い合わせは、文末のURLをご参照ください。

＜このメールにお心当たりのない方＞
このメールは、お客様にご入力いただいたメールアドレスへ配信しています。
万一、このメールを誤って受信された場合には、破棄していただきますようお願いします。


メモアカ トップページ
https://www.memoaca.com

ご質問・お問い合わせ
https://www.memoaca.com/contact
`;

    const sendEmailParams = {
        joinedToEmails,
        joinedCcEmails,
        joinedBccEmails,
        subject: `【お問い合わせ】${username}様`,
        body,
        emailFrom: process.env.REACT_APP_ADMIN_EMAILS || 'inquiry@memoaca.com',
    };

    return (
        <ContactTemplate
          isLogin={isLogin}
          isPaid={isPaid}
          isSocialLogin={isSocialLogin}
          profileImg={profileImg}
          preferredUsername={preferredUsername}

          contactPhase={contactPhase}
          username={username}
          email={email}
          email2={email2}
          usertext={usertext}

          usernameValidationMessage={usernameValidationMessage}
          emailValidationMessage={emailValidationMessage}
          usertextValidationMessage={usertextValidationMessage}

          changeUsername={(e) => {dispatch(changeUsername(e.target.value))}}
          changeEmail={(e) => {dispatch(changeEmail({email: e.target.value, emailValidationMessage: e.target.validationMessage, }))}}
          changeEmail2={(e) => {dispatch(changeEmail2({email: e.target.value, emailValidationMessage: e.target.validationMessage, }))}}
          changeUsertext={(e) => {dispatch(changeUsertext(e.target.value))}}
        //   changeAttachedFile={() => {}}
          submit={() => dispatch(sendEmail(sendEmailParams))}
          confirm={confirm}
          back={back}
        />
    );
};

export default ContactPage;
