import React from 'react';
import templateProperties from '../../common/template-properties.module.css';
import LoadingMessage from '../../organisms/LoadingMessage';
// CSSはインポートした順に生成され、後にインポートしたCSSの方が優先されるので、
// まず他のコンポーネントや共通CSSをインポートして、その後でコンポーネントごとのCSSをインストールすること
// つまり、styles.module.cssのインポートはインポート文の複数行のうちの最後に置いておくのが安全
import styles from './styles.module.css';

Object.assign(styles, templateProperties);

export type WaitWithdrawalTemplateProps = {
    children?: never;
};

const WaitWithdrawalTemplate : React.FC<WaitWithdrawalTemplateProps> = () => {
    return (
        <div className={ styles['wait-withdrawal-template'] + ' '+ styles['template-properties-local'] }>
            <LoadingMessage>ご利用ありがとうございました。<br/>トップページに移動します。</LoadingMessage>
        </div>
    );
};

export default WaitWithdrawalTemplate;
