import React, { ReactNode } from 'react';
import FontTitle from '../../atoms/FontTitle';
import Container from '../Container';
// CSSはインポートした順に生成され、後にインポートしたCSSの方が優先されるので、
// まず他のコンポーネントや共通CSSをインポートして、その後でコンポーネントごとのCSSをインストールすること
// つまり、styles.module.cssのインポートはインポート文の複数行のうちの最後に置いておくのが安全
import styles from './styles.module.css';

export type PageTitleAreaProps = {
    isFreeFormat?: boolean;
    className?: string;
    children?: ReactNode;
} & JSX.IntrinsicElements['div'];


const PageTitleArea : React.FC<PageTitleAreaProps> = ({
    isFreeFormat = false,
    className = '',
    children,
}) => {
    // class属性を設定
    let classString = styles['page-title-area'];
    if (className) {
        classString = [classString, className].join(' ');
    }

    return (
        <div className={ classString }>
            <Container>
                {isFreeFormat? children:<h1 className={styles['page-title']}><FontTitle domType='span'>{children}</FontTitle></h1>}
            </Container>
        </div>
    );
};

export default PageTitleArea;
