import React, { ReactNode } from 'react';
// CSSはインポートした順に生成され、後にインポートしたCSSの方が優先されるので、
// まず他のコンポーネントや共通CSSをインポートして、その後でコンポーネントごとのCSSをインストールすること
// つまり、styles.module.cssのインポートはインポート文の複数行のうちの最後に置いておくのが安全
import styles from './styles.module.css';

export type AnchorProps = {
    className?: string,
    children?: ReactNode,
} & JSX.IntrinsicElements['a'];


const Anchor : React.FC<AnchorProps> = ({
    className = '',
    children,
    ...props
}) => {
    // class属性を設定
    let classString = styles.anchor;
    if (className) {
        classString = [classString, className].join(' ');
    }

    return (
        <a 
            className={classString}
            { ...props }
        >
        {children}
        </a>
    );
};

export default Anchor;
