import React from 'react';
import templateProperties from '../../common/template-properties.module.css';
import { UrlLabel } from '../../../redux/constant';
import HeaderContainer from '../../organisms/HeaderContainer';
import PageTitleArea from '../../layouts/PageTitleArea';
import BreadcrumbBar from '../../organisms/BreadcrumbBar';
import { PageContainer } from '../../layouts/Container';
import ContractPreamble from '../../molecules/ContractPreamble';
import ContractArticle from '../../molecules/ContractArticle';
import ContractSignature from '../../molecules/ContractSignature';
import Footer from '../../organisms/Footer';
// CSSはインポートした順に生成され、後にインポートしたCSSの方が優先されるので、
// まず他のコンポーネントや共通CSSをインポートして、その後でコンポーネントごとのCSSをインストールすること
// つまり、styles.module.cssのインポートはインポート文の複数行のうちの最後に置いておくのが安全
import styles from './styles.module.css';

Object.assign(styles, templateProperties);

const TermsOfServiceBL: Array<UrlLabel> = [
    { url: '/mypage', label: 'マイページ' },
    { url: '', label: '利用規約' },
];

export type TermsOfServiceTemplateProps = {
  isLogin: boolean;             // ログイン状態
  isPaid: boolean;              // 有料会員かどうか
  isSocialLogin: boolean;       // SNSログイン会員かどうか
  profileImg?: string;          // 会員画像
  preferredUsername? : string;  // 会員名
};

const TermsOfServiceTemplate : React.FC<TermsOfServiceTemplateProps> = ({
    isLogin,
    isPaid,
    isSocialLogin,
    profileImg,
    preferredUsername,
}) => {
    return (
        <div className={ styles['terms-of-service-template'] + ' ' + styles['template-properties-local'] }>
          <HeaderContainer isLogin={isLogin} isPaid={isPaid} isSocialLogin={isSocialLogin} profileImg={profileImg} preferredUsername={preferredUsername} />
          <PageTitleArea>メモアカ利用規約</PageTitleArea>
          {isLogin && <BreadcrumbBar UrlLabels={TermsOfServiceBL} />}
          <PageContainer>
          <ContractPreamble>
          メモアカ(以下「本サービス」といいます)は、株式会社メモアカ(以下「当社」といいます)が、開発運営する記憶術の学習サービスです。本利用規約(以下「本規約」といいます)は、本サービスが提供される条件、及び当社と登録会員の皆様との間に発生する権利義務関係について定めています。本サービスを利用される際には、本規約の全文をお読みいただいた上で、本規約に同意いただく必要があります。<br/>
          メモアカをご利用いただく前に、本規約への皆様の同意が必要になります。恐れ入りますが、下記をお読みください。
          </ContractPreamble>

          <ContractArticle title='第1条(適用)'>
          1. 本規約は、本サービスが提供される条件、及び当社と登録会員の皆様との間に発生する権利義務関係について定めることを目的としています。<br/><br/>
          2. 本規約は、登録会員が本サービスを利用する上で生じる当社とのすべての関係に適用されます。<br/><br/>
          3. 本規約の内容と、本規約外で本サービスについて説明している内容等が異なる場合は、本規約の規定が優先して適用されます。
          </ContractArticle>

          <ContractArticle title='第2条(定義)'>
          本サービスの会員とは、登録会員全体の総称です。登録会員とは、会員登録のために必要な内容をデータ入力・送信し、当社が承認した個人のことです。
          GoogleやFacebookなど、外部サービスの認証システムを経由して当社が承認した個人も含みます。
          </ContractArticle>

          <ContractArticle title='第3条(会員の責任)'>
          1. 本サービスを会員が利用する場合、インターネットにアクセスする必要がありますが、そのために必要な機器、ソフトウェア、通信手段等は会員ご自身が各自の責任と費用において適切に準備、設置、操作してください。当社は会員のアクセス環境について一切関与せず、これらの準備、設置、操作に関する責任を負いません。<br/>
          2. 当社より登録会員に対して連絡を行う際には、登録メールアドレスあての電子メール、もしくは本サービス上での「お知らせ」にて連絡を行います。登録会員は当社からの電子メールを受信できるよう、登録メールアドレス情報を適切に登録し、何らかの理由で受信ができなくなった場合には、問い合わせフォームなどから速やかに登録メールアドレスの変更を適切に行ってください。当社からの電子メールが受信できなかったために会員が被った不利益については、会員自身に責任があるものとし、当社は一切の責任を負わないものとします。<br/>
          メールマガジンのような、当社からの広告目的のメールに関しては受け取りを拒否することが可能です。
          </ContractArticle>

          <ContractArticle title='第4条(登録)'>
          1. 本サービスの利用を希望する方(以下「登録希望者」といいます)は、本規約を遵守する事に同意し、かつ当社の定める一定の情報(以下「登録事項」といいます)を当社の定める方法で当社に提供することにより、当社に対し、本サービスの利用の登録を申請することができます。<br/><br/>
          2. 前項につき、登録希望者が未成年者の場合は、登録に伴い親権者の同意を必要とし、本規約の同意をもって、親権者の同意があったものとみなします。<br/><br/>
          3. 当社は、当社の基準に従って、第1項に定める登録申請を行った登録希望者(以下「登録申請者」といいます)の登録の可否を判断し、登録を認められた場合は、その旨を登録申請者に通知致します。本サービスの利用の登録は、当社がこの通知を行ったことをもって完了したものとします。<br/><br/>
          4. 登録の完了時に、サービス利用契約が登録会員と当社の間に成立し、登録会員は本サービスを利用することができるようになります。<br/><br/>
          5. 当社は、登録申請者が、以下の各号のいずれかの事由に該当する場合は、登録及び再登録を拒否することがあり、またその理由について一切開示義務を負いません。<br/>
          (1) 当社に提供した登録事項の全部または一部につき虚偽、誤記または記載漏れがあった場合<br/>
          (2) 未成年者、成年被後見人、被保佐人または被補助人のいずれかであり、法定代理人、後見人、保佐人または補助人の同意等を得ていなかったことが判明した場合<br/>
          (3) 反社会的勢力等(暴力団、暴力団員、右翼団体、反社会的勢力、その他これに準ずる者を意味します。以下同じ。)である、または資金提供その他を通じて反社会的勢力等の維持、運営もしくは経営に協力もしくは関与する等反社会的勢力等との何らかの交流もしくは関与を行っていると当社が判断した場合<br/>
          (4) 登録希望者が過去当社との契約に違反した者またはその関係者であると当社が判断した場合<br/>
          (5) 第10条に定める措置を受けたことがある場合<br/>
          (6) その他、当社が登録を適当でないと判断した場合<br/>
          未成年の方は、登録に伴い親権者の同意が必要になります。
          </ContractArticle>

          <ContractArticle title='第5条(パスワード及びメールアドレスの管理)'>
          1. 登録会員は、自己の責任において、本サービスに関するパスワード及びメールアドレスを適切に管理、保管してください。これらを第三者に利用させたり、貸与、譲渡、名義変更、売買等をしたりしてはならないものとします。<br/><br/>
          2. パスワードまたはメールアドレスの管理不十分、使用上の過誤、第三者の使用等によって生じた損害に関する責任は登録会員が負うものとし、当社は一切の責任を負いません。
          </ContractArticle>

          <ContractArticle title='第6条(禁止事項)'>
          登録会員は、本サービスの利用にあたり、以下の各号のいずれかに該当する行為または該当すると当社が判断する行為をしてはなりません。<br/>
          (1) 法令に違反する行為または犯罪行為に関連する行為<br/>
          (2) 当社、本サービスの他の利用者またはその他の第三者に対する詐欺または脅迫行為<br/>
          (3) 公序良俗に反する行為<br/>
          (4) 当社、本サービスの他の利用者またはその他の第三者の知的財産権、肖像権、プライバシーの権利、名誉、その他の権利または利益を侵害する行為<br/>
          (5) 本サービスを通じ、以下に該当し、または該当すると当社が判断する情報を当社または本サービスの他の利用者に送信すること<br/>
          ・過度に暴力的または残虐な表現を含む情報<br/>
          ・コンピューター・ウィルスその他の有害なコンピューター・プログラムを含む情報<br/>
          ・当社、本サービスの他の利用者またはその他の第三者の名誉または信用を毀損する表現を含む情報<br/>
          ・過度にわいせつな表現を含む情報<br/>
          ・差別を助長する表現を含む情報<br/>
          ・自殺、自傷行為を助長する表現を含む情報<br/>
          ・薬物の不適切な利用を助長する表現を含む情報<br/>
          ・反社会的な表現を含む情報<br/>
          ・チェーンメールなどの第三者への情報の拡散を求める情報<br/>
          ・他人に不快感を与える表現を含む情報<br/>
          ・同性、異性との出会いを目的とした情報<br/>
          (6) 本サービスのネットワークまたはシステム等に過度な負荷をかける行為、本サービスの不具合を意図的に利用する行為<br/>
          (7) 本サービスの運営を妨害するおそれのある行為<br/>
          (8) 当社のネットワークまたはシステム等に不正にアクセスし、または不正なアクセスを試みる行為<br/>
          (9) 第三者に成りすます行為<br/>
          (10) 本サービスの他の利用者のIDまたはパスワードを利用する行為<br/>
          (11) 当社が事前に許諾しない本サービス上での宣伝、広告、勧誘、または営業行為<br/>
          (12) 本サービスの他の利用者の情報の収集<br/>
          (13) 当社、本サービスの他の利用者またはその他の第三者に不利益、損害、不快感を与える行為<br/>
          (14) 反社会的勢力等への利益供与<br/>
          (15) 本サービスを利用して行う商行為<br/>
          (16) 前各号の行為を直接または間接に惹起し、または容易にする行為<br/>
          (17) その他、当社が不適切と判断する行為
          </ContractArticle>

          <ContractArticle title='第7条(本サービスの停止など)'>
          1. 当社は、以下のいずれかに該当する場合には、登録会員に事前に通知することなく、本サービスの全部または一部の提供を停止または中断することができるものとします。<br/>
          ・本サービスに係るコンピューター・システムの点検または保守作業を緊急に行う場合<br/>
          ・コンピューター、通信回線等が事故により停止した場合<br/>
          ・地震、落雷、火災、風水害、停電、天災地変などの不可抗力によりサービスの運営ができなくなった場合<br/>
          ・その他、当社が停止または中断を必要と判断した場合<br/><br/>
          2. 当社は、本条に基づき当社が行った措置に基づき登録会員に生じた損害について一切の責任を負いません。
          </ContractArticle>

          <ContractArticle title='第8条(権利帰属)'>
          1. 当社ウェブサイト及び本サービスに関する知的財産権は全て当社または当社にライセンスを許諾している者(以下、「ライセンサー」といいます)に帰属しています。本規約に基づく本サービスの利用許諾は、当社ウェブサイトまたは本サービスに関する当社またはライセンサーの知的財産権の使用許諾を意味するものではありません。<br/><br/>
          2. 当社は本サービスに含まれるコンテンツ(スライド、テキスト、画像、ファイル、ソフトウェア、レッスン構成、キャラクター等をいいます。以下同じ。)に関する一切の著作権(著作権法27条及び28条に規定する権利を含む)、商標権、特許権、その他の知的財産権など(それらの権利を取得し、またはそれらの登録等を出願する権利を含む)を保有しており、日本の著作権法および著作権に関する国際法によって保護されています。<br/><br/>
          3. 本サービスに使用されている全てのソフトウェアは、知的財産権に関する法令等により保護されている財産権及び営業秘密を含んでいます。<br/><br/>
          4. 当社による事前の書面による承諾を得ることなく、いかなる手段によるものを問わず、本サービスのコンテンツを収集・複製することを禁じます。<br/><br/>
          5. 当社による事前の書面による承諾を得ることなく、本サービスのコンテンツを使用した学習教材の作成(スライドの埋め込み、レッスンへのリンク、転載、引用の範囲を超えた利用等を含む)、提供、および販売を行うことを禁じます。<br/><br/>
          6. 登録会員は、本サービス上で一般に公開される形でアップロードされるデータ(以下、「投稿データ」といいます)について、自らがアップロード及び公開(以下、「投稿」といいます)することについての適法な権利を有していること、及び投稿データが第三者の権利を侵害していないことを、当社に対し表明し、保証するものとします。<br/><br/>
          7. 登録会員は、投稿データについて、当社に対し、世界的、非独占的、無償、サブライセンス可能かつ譲渡可能な使用、複製、配布、派生著作物の作成、表示及び実行に関するライセンスを付与します。また、他の登録会員に対しても、無償で、自己の投稿データの使用、複製、配布、派生著作物を作成、表示及び実行することについての非独占的なライセンスを付与します。<br/><br/>
          8. 登録会員は、当社及び当社から権利を承継しまたは許諾された者、及び他の登録会員に対して著作者人格権を行使しないことに同意するものとします。
          </ContractArticle>

          <ContractArticle title='第9条(広告掲載)'>
          当社は、当社の判断により、本サービスに当社又は第三者の広告を掲載することができるものとします。
          </ContractArticle>

          <ContractArticle title='第10条(利用停止・登録抹消など)'>
          1. 当社は、登録会員が、以下の各号のいずれかの事由に該当する場合は、事前に通知または催告することなく、投稿データを削除することができるものとし、また、当該登録会員について本サービスの利用を一時的に停止すること、登録会員としての登録を抹消すること、もしくはサービス利用契約を解除することができます。<br/>
          ・本規約のいずれかの条項に違反した場合<br/>
          ・登録事項に虚偽の事実があることが判明した場合<br/>
          ・6か月以上本サービスの利用がない場合<br/>
          ・当社からの問い合わせその他の回答を求める連絡に対して30日間以上応答がない場合<br/>
          ・反社会的勢力等(暴力団、暴力団員、右翼団体、反社会的勢力、その他これに準ずるものを意味します。以下同じ。)である、または資金提供その他を通じて反社会的勢力などの維持、運営もしくは経営に協力もしくは関与する等反社会的勢力等との何らかの交流もしくは関与を行っていると当社が判断した場合<br/>
          ・過去当社との契約に違反した者、またはその関係者であると当社が判断した場合<br/>
          ・その他、当社が本サービスの利用、登録会員としての登録、またはサービス利用契約の継続を適当でないと判断した場合<br/><br/>
          2. 前項各号のいずれかの事由に該当した場合、登録会員は、当社に対して負っている債務の一切について当然に期限の利益を失い、直ちに当社に対して全ての債務の支払を行わなければなりません。<br/><br/>
          3. 当社は、本条に基づき当社が行った行為により登録会員に生じた損害について一切の責任を負いません。
          </ContractArticle>

          <ContractArticle title='第11条(退会)'>
          1. 登録会員は、マイページ内のプロフィール欄の「アカウント削除される場合はこちら」で本サービスから退会し、自己の登録会員としての登録を抹消することができます。<br/><br/>
          2. 退会にあたり、当社に対して負っている債務が有る場合は、登録会員は、当社に対して負っている債務の一切について当然に期限の利益を失い、直ちに当社に対して全ての債務の支払いを行わなければなりません。<br/><br/>
          3. 退会後の利用者情報の取り扱いについては、第15条の規定に従うものとします。
          </ContractArticle>

          <ContractArticle title='第12条(本サービスの内容の変更、終了)'>
          1. 当社は、当社の都合により、本サービスの内容を変更し、または提供を終了することができます。<br/><br/>
          2. 当社は、本条に基づき当社が行った措置に基づき登録会員に生じた損害について一切の責任を負いません。
          </ContractArticle>

          <ContractArticle title='第13条(保証の否認及び免責)'>
          1. 当社は、本サービスが登録会員の特定の目的に適合すること、期待する機能・商品的価値・正確性・有用性を有すること、登録会員による本サービスの利用が登録会員に適用のある法令または業界団体の内部規則などに適合すること、及び不具合が生じないことについて、何ら保証するものでもありません。当社は、サービス向上のため、随時改良、改善を行いますが、登録会員から特定の機能につき改善、修補の要求がなされた場合でも、当該要求に基づく改善、修補を行う義務は負わないものとします。<br/><br/>
          2. 当社は、当社による本サービスの中断、停止、終了、利用不能または変更、登録会員が本サービスに送信したメッセージまたは情報の削除または消失、登録会員の登録の抹消、本サービスの利用による登録データの消失または機器の故障もしくは損傷、その他本サービスに関して登録会員が被った損害(以下「会員損害」といいます)につき、賠償する責任を一切負わないものとします。<br/><br/>
          3. 何らかの理由により当社が責任を負う場合であっても、当社は、会員損害につき、過去6か月間に登録会員が当社に支払った対価の金額を超えて賠償する責任を負わないものとし、また、付随的損害、間接損害、特別損害、将来の損害及び逸失利益にかかる損害については、賠償する責任を負わないものとします。<br/><br/>
          4. 本サービスまたは当社ウェブサイトに関連して登録会員と他の登録会員または第三者との間において生じた取引、連絡、紛争などについては、当社は一切責任を負いません。
          </ContractArticle>

          <ContractArticle title='第14条(秘密保持)'>
          登録会員は、本サービスに関連して当社が登録会員に対して秘密に取り扱うことを求めて開示した非公知の情報について、当社の事前の書面による承諾がある場合を除き、秘密に取り扱うものとします。
          </ContractArticle>

          <ContractArticle title='第15条(利用者情報の取扱い)'>
          1. 当社による登録会員の利用者情報の取り扱いについては、別途当社プライバシーポリシーに定めております。登録会員はこのプライバシーポリシーに従って当社が登録会員の利用情報を取り扱うことに同意するものとします。<br/><br/>
          2. 当社は、登録会員が当社に提供した情報、データ等を、個人を特定できない形での統計的な情報として、当社の裁量で、利用及び公開ができるものとし、登録会員はこれに異議を唱えないものとします。<br/>
          詳細はプライバシーポリシーをご覧ください。<br/><br/>
          3. 退会後の利用者情報について、当社が判断した場合、一時的に保管する場合があります。
          </ContractArticle>

          <ContractArticle title='第16条(本規約等の変更)'>
          当社は、当社が必要と判断する場合に、本規約を予告なしに変更することができるものとします。変更後の本規約は、本サイト上で公表するものとし、登録会員は、変更後の本規約の公表後、本サービスを利用した場合、本規約の変更に同意したものとみなします。当該変更に同意いただけない場合は、本サービスを利用できませんので、直ちに第11条に従い、退会手続きを行うものとします。ただし登録会員の利用に重大な影響を与える変更の場合には、当社の判断により合理的な事前告知期間を設け、当社が定めた方法によって登録会員に通知するものとします。
          </ContractArticle>

          <ContractArticle title='第17条(連絡／通知)'>
          本サービスに関する問い合わせその他登録会員から当社に対する連絡または通知、及び本規約の変更に関する通知その他当社から登録会員に対する連絡または通知は、当社の定める方法で行うものとします。
          </ContractArticle>

          <ContractArticle title='第18条(サービス利用契約上の地位の譲渡等)'>
          1. 登録会員は、当社の書面による事前の承諾なく、サービス利用契約上の地位や本規約に基づく権利義務等を、第三者に対し譲渡、移転、担保設定、その他の処分をすることはできません。<br/><br/>
          2. 当社が本サービスにかかる事業を他社に譲渡した場合、当社は、その事業譲渡に伴い、サービス利用契約上の地位、本規約に基づく権利及び義務、登録会員の登録事項とその他の顧客情報をその事業譲渡の譲受人に対して譲渡することができます。登録会員は、このことについて本項で予め同意したものとします。<br/>
          なお、本項に定める事業譲渡につき本項に定める事業譲渡には、通常の事業譲渡のみならず、会社分割その他事業が移転するあらゆる場合を含むものとします。
          </ContractArticle>

          <ContractArticle title='第19条(分離可能性)'>
          本規約のいずれかの条項またはその一部が、消費者契約法その他の法令等により無効または執行不能と判断された場合であっても、本規約の残りの規定および一部が無効または執行不能と判断された規定の残りの部分は、継続して完全に効力を有するものとします。
          </ContractArticle>

          <ContractArticle title='第20条(準拠法および管轄裁判所)'>
          本規約の準拠法は日本法とします。<br/>
          本規約または本サービスに起因し、または関連する一切の紛争については、東京地方裁判所を第一審の専属的合意管轄裁判所とします。
          </ContractArticle>
          <br/><br/>
          <h2>メモアカ有料会員 利用規約</h2>
          <ContractPreamble>
          本メモアカ有料会員利用規約(以下「本有料会員規約」といいます)は、株式会社メモアカ(以下「当社」といいます)が運営するウェブサービス「メモアカ」の有料サービス(以下「有料サービス」といいます)の提供条件及び当社と有料サービスの登録会員(以下、「有料会員」といいます)の皆様との間の権利義務関係を定めるものです。有料サービスの利用に際しては、本有料会員規約の全文をお読みいただいた上で、本有料会員規約に同意いただく必要があります。<br/>
          有料会員は、有料会員への登録および有料サービスの利用に際して、上記のメモアカ利用規約に同意した上で、本有料会員規約に同意するものとします。
          </ContractPreamble>

          <ContractArticle title='第1条(適用)'>
          1. 本有料会員規約の内容と、本有料会員規約外における有料サービスの説明等とが異なる場合は、本有料会員規約の規定が優先して適用されるものとします。<br/><br/>
          2. 当社は、当社が必要と判断する場合に、本有料会員規約を予告なしに変更することができるものとします。変更後の本有料会員規約は、本サイト上で公表するものとし、有料会員は、変更後の本有料会員規約の公表後、有料サービスを利用した場合、本有料会員規約の変更に同意したものとみなします。当該変更に同意いただけない場合は、有料サービスを利用できませんので、直ちに第5条に従い、解約手続きを行うものとします。ただし有料会員の利用に重大な影響を与える変更の場合には、当社の判断により合理的な事前告知期間を設け、当社が定めた方法によって有料会員に通知するものとします。
          </ContractArticle>

          <ContractArticle title='第2条(有料会員登録)'>
          1. 有料サービスの利用を希望する方(以下「有料サービス登録希望者」といいます)は、本有料会員規約を遵守する事に同意し、かつ、有料サービスの利用に必要となる当社の定める一定の情報(以下「有料サービス登録事項」といいます)を当社の定める方法で当社に提供することにより、当社に対し、有料サービスの利用の登録を申請することができます。<br/><br/>
          2. 前項につき、有料サービス登録希望者が未成年者の場合は、登録に伴い親権者の同意を必要とし、本有料会員規約の同意をもって、親権者の同意があったものとみなします。<br/><br/>
          3. 当社は、当社の基準に従って、第1項に定める登録申請を行った有料サービス登録希望者(以下「有料サービス登録申請者」といいます)の登録の可否を判断し、登録を認められた場合は、その旨を登録申請者に通知致します。本サービスの利用の登録は、当社がこの通知を行ったことをもって完了したものとします。<br/><br/>
          4. 有料サービスの登録完了時に、有料サービス利用契約が有料会員と当社の間に成立し、有料会員は有料サービスを利用することができるようになります。<br/><br/>
          5. 当社は、有料サービス登録申請者が、以下の各号のいずれかの事由に該当する場合は、登録及び再登録を拒否することがあり、またその理由について一切開示義務を負いません。<br/>
          (1) 当社に提供した有料サービス登録事項の全部または一部につき虚偽、誤記または記載漏れがあった場合<br/>
          (2) 未成年者、成年被後見人、被保佐人または被補助人のいずれかであり、法定代理人、後見人、保佐人または補助人の同意等を得ていなかったことが判明した場合<br/>
          (3) 反社会的勢力等(暴力団、暴力団員、右翼団体、反社会的勢力、その他これに準ずる者を意味します。以下同じ。)である、または資金提供その他を通じて反社会的勢力等の維持、運営もしくは経営に協力もしくは関与する等反社会的勢力等との何らかの交流もしくは関与を行っていると当社が判断した場合<br/>
          (4) 有料サービス登録希望者が過去当社との契約に違反した者またはその関係者であると当社が判断した場合<br/>
          (5) 過去に当社からメモアカ又はその他の有料サービスの登録解除を受けたことがある場合<br/>
          (6) その他、当社が有料サービス登録を適当でないと判断した場合
          </ContractArticle>

          <ContractArticle title='第3条(利用料金及び支払方法)'>
          1. 有料会員は、有料サービスを利用するにあたり、当社に対し、当社が規定する月額利用料金または年間利用料金(以下「利用料金」といいます)をお支払いいただく必要があります。利用料金は、1,490円(月額)となります。キャンペーンなどにより料金が変更している場合がありますので、必ず各種有料サービスに関するページをご確認ください。<br/><br/>
          2. 有料サービスの月額利用における「1か月」とは、利用料金をお支払いいただいた時点から1か月(お支払日の翌月同日同時刻まで。翌月同日が存在しない場合は、翌月末日の同時刻まで)を指すものとし、同様に年間利用における「1年」とは、利用料金をお支払いいただいた時点から1年間(お支払日の翌年同月同日同時刻まで。翌年同月同日が存在しない場合は、翌年同月末日の同時刻まで)を指すものとします。<br/><br/>
          3. 有料サービスの最初の1か月分の利用料金は、有料サービスの登録時に、マイページ内の「お支払い情報」から支払うものとします。翌月以降の利用料金は、翌月以降の、有料サービス登録日時と同日同時刻(翌月以降に登録日の同日が存在しない場合は、当該月の末日となります。)に、翌1か月分の利用料金をお支払いいただくものとし、同様に年間利用においても、有料サービスの登録時に支払うものとします。翌年以降の利用料金は、翌年以降の、有料サービス登録日時と同日同時刻(翌年以降に登録日の同日が存在しない場合は、当該月の末日)となります。(以下「更新日時」といいます)<br/>
          なお、有料会員は、有料サービスの解約を希望する場合、更新日時までに、第5条に定める解約手続きを行う必要があります。更新日時までに解約手続きが行われなかった場合、有料サービスは自動的に1か月間更新(年間利用の場合、1年間更新)され、有料会員は、更新日時に翌1か月分(年間利用の場合は翌1年分)の利用料金を支払う義務が生じますのでご注意ください。<br/><br/>
          4. 当社は、サービス内容の変更等により、利用料金を改定する場合があります。なお、利用料金を改定する場合は、当社は所定の方法により有料会員に事前に通知するものとします。<br/><br/>
          5. 利用料金は、有料サービスの登録時、更新日時にクレジットカード決済により支払うものとします。なお、更新日時までに解約手続きが行われなかった場合は、更新日時に自動的にクレジットカード決済が行われます。<br/><br/>
          6. 有料会員が利用料金の支払を遅延した場合には、有料会員は年14.6%の割合による遅延損害金を当社に支払うものとします。<br/><br/>
          7. 有料会員が有料サービスを解約した場合(メモアカから退会した場合も含む)、お支払いただいた利用料金は、理由の如何にかかわらず、返金を行いません。また、利用料金のお支払から1か月以内もしくは1年以内に解約した場合においても、残存期間の利用料金の返金は行いませんので、ご注意ください。お支払いから1か月以内もしくは1年以内に解約した場合で、かつメモアカの登録会員である場合は、元の更新日時に至るまでは、それまで登録していた有料サービスを引き続きご利用頂くことができます。
          </ContractArticle>

          <ContractArticle title='第4条(有料会員登録の解除)'>
          有料会員がメモアカ利用規約および本有料会員規約に違反する行為または違反するおそれのある行為を行ったとき、メモアカ利用規約第10条に規定するいずれかの事由に該当した場合、または利用料金の支払いを怠ったときには、事前に通知または催告することなく、投稿データを削除することができるものとし、また、当該有料会員について有料サービスの利用を一時的に停止すること、有料会員としての登録を抹消すること、もしくは有料サービス利用契約を解除することができます。当該事由により解除となった場合、解除日を含む有料サービス提供期間の利用料金が発生するものとします。また、これらの措置によって有料会員が被った損害について、当社は一切の責任を負いません。
          </ContractArticle>

          <ContractArticle title='第5条(有料会員登録の解約)'>
          1. 有料会員は、マイページ内の「お支払い情報」から有料サービスから退会し、自己の有料会員としての登録を抹消することができます。 なお、更新日時までに解約手続きを行わない場合、有料サービスは自動更新されますのでご注意ください。<br/><br/>
          2. 前項に関して、会員の不注意のほか、当社の定期的もしくは緊急なメンテナンスやシステム障害、通信状態の不良等により、更新日時において解約することが出来なかった場合においても、当社は責任を負いかねますので、ご了承ください。<br/><br/>
          3. 退会にあたり、当社に対して負っている債務が有る場合は、有料会員は、当社に対して負っている債務の一切について当然に期限の利益を失い、直ちに当社に対して全ての債務の支払いを行わなければなりません。<br/>
          有料サービスの解約は、更新日の数日前までに余裕をもって行ってください。更新日時にメンテナンスやシステム障害等で解約手続きができなかった場合も、当社は責任を負うことができません。
          </ContractArticle>

          <ContractArticle title='第6条(有料サービス利用契約上の地位の譲渡等)'>
          有料会員は、当社の書面による事前の承諾なく、有料サービス利用契約上の地位または本有料会員規約に基づく権利もしくは義務につき、第三者に対し、譲渡、移転、担保設定、その他の処分をすることはできません。
          </ContractArticle>

          <ContractArticle title='第7条(メモアカ利用規約の準用)'>
          メモアカ利用規約第3条、第5条から第9条、第12条から第15条、第17条から第20条は本有料会員規約において準用されるものとします。
          </ContractArticle>

          <ContractSignature>
          (四版 2023 年 6月 17日制定)<br/>
          (三版 2022 年 10月 15日制定)<br/>
          (二版 2022 年 9月 3日制定)<br/>
          (初版 2022 年 8月 1日制定)
          </ContractSignature>
          </PageContainer>
          <Footer isLogin={isLogin} />
        </div>
    );
};

export default TermsOfServiceTemplate;
