import React, {
    useEffect,
    useRef,
} from 'react';
import PaymentTemplate from '../../templates/PaymentTemplate';
import { useAppSelector, useAppDispatch } from '../../../redux/app/hooks';
import {
    checkoutMonthly,
    checkoutYearly,
    stopMySubscription,
    fetchMySubscriptions,

    selectPaymentState,
    changeModalIsOpen,
} from '../../../redux/slices/paymentSlice';
import {
    selectLoginState,
} from '../../../redux/slices/loginSlice';
// CSSはインポートした順に生成され、後にインポートしたCSSの方が優先されるので、
// まず他のコンポーネントや共通CSSをインポートして、その後でコンポーネントごとのCSSをインストールすること
// つまり、styles.module.cssのインポートはインポート文の複数行のうちの最後に置いておくのが安全
import styles from './styles.module.css';


export type PaymentPageProps = {
    children?: never,
};


const PaymentPage : React.FC<PaymentPageProps> = () => {
    const {
        modalIsOpen,
        isSubscribing,
        subscriptionStatus,
        now,
        paymentExpiredOn,
        isPaid,
        nextPaymentAmount,
        nextPaymentUnixTimestamp,
    } = useAppSelector(selectPaymentState);
    const dispatch = useAppDispatch();

    const {
        isLogin,
        isSocialLogin,
        preferredUsername,
    } = useAppSelector(selectLoginState);

    // TODO
    // to_implement
    // 本来はここでログイン状況や会員画像・会員名を取得する処理が入る
    const fetchProfileImg = () => undefined;
    const profileImg = fetchProfileImg();

    const intervalId = useRef<number|undefined>(undefined);
    useEffect(() => {
        // カバレッジのため関数化
        const dispatchFetch = () => dispatch(fetchMySubscriptions({}));

        // 初回のみ発火
        // TODO else節をカバーするようなテストを書く
        if (!intervalId.current) {
            dispatchFetch();
        }

        clearInterval(intervalId.current);

        // ただのsetInterval()はNodeJS.Timerを返してしまうので、代わりにwindow.setInterval()を使用し、intervalIdが返るようにする
        intervalId.current = window.setInterval(dispatchFetch, 5000);

        // アンマウント時のクリーンアップ処理をする関数
        // 別画面に遷移する時にタイマーを消す
        return () => {
            clearInterval(intervalId.current);
        }
    });

    // ダブルクリック防止
    const processing = useRef(false);

    const dispatchCheckoutMonthly = () => {
        if (processing.current) {
            return;
        }

        processing.current = true;

        dispatch(checkoutMonthly({}));
    };

    const dispatchCheckoutYearly = () => {
        if (processing.current) {
            return;
        }

        processing.current = true;

        dispatch(checkoutYearly({}));
    };

    // past_due時にstopMySubscriptionして、すぐさま新しい課金を開始しようとした時に
    // processing.currentのせいで抑制されないように、checkoutする時のダブルクリック抑止とは別の変数で管理する
    // active状態でstopMySubscriptionしたらボタンが無い画面に遷移し、
    // past_due状態でstopMySubscriptionしたらcheckoutするボタンしかなく、checkoutしたら別のサイトに遷移するので、
    // 連続でstopMySubscriptionが叩かれることはない
    const dispatchStopMySubscription = () => {
        if (processing.current) {
            return;
        }

        processing.current = true;

        dispatch(stopMySubscription({}));
    }

    const clickCancelButton = () => { dispatch(changeModalIsOpen(false)) };

    const dispatchConfirmStopMySubscription = () => { dispatch(changeModalIsOpen(true)) };

    const clickOkButton = dispatchStopMySubscription;

    return (
        <div className={ styles['payment-page'] }>
            <PaymentTemplate
                isLogin={isLogin}
                isPaid={isPaid}
                isSocialLogin={isSocialLogin}
                profileImg={profileImg}
                preferredUsername={preferredUsername}
                isSubscribing={isSubscribing}
                now={now}
                subscriptionStatus={subscriptionStatus}
                paymentExpiredOn={paymentExpiredOn}
                nextPaymentAmount={nextPaymentAmount}
                nextPaymentUnixTimestamp={nextPaymentUnixTimestamp}
                modalIsOpen={modalIsOpen}
                checkoutMonthly={dispatchCheckoutMonthly}
                checkoutYearly={dispatchCheckoutYearly}
                stopMySubscription={dispatchStopMySubscription}
                clickCancelButton={clickCancelButton}
                dispatchConfirmStopMySubscription={dispatchConfirmStopMySubscription}
                clickOkButton={clickOkButton}
            />
        </div>
    );
};

export default PaymentPage;
