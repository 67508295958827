import React from 'react';
import templateProperties from '../../common/template-properties.module.css';
import { UrlLabel } from '../../../redux/constant';
import HeaderContainer from '../../organisms/HeaderContainer';
import PageTitleArea from '../../layouts/PageTitleArea';
import BreadcrumbBar from '../../organisms/BreadcrumbBar';
import { PageContainer } from '../../layouts/Container';
import ContractPreamble from '../../molecules/ContractPreamble';
import ContractArticle from '../../molecules/ContractArticle';
import ContractSignature from '../../molecules/ContractSignature';
import Footer from '../../organisms/Footer';
// CSSはインポートした順に生成され、後にインポートしたCSSの方が優先されるので、
// まず他のコンポーネントや共通CSSをインポートして、その後でコンポーネントごとのCSSをインストールすること
// つまり、styles.module.cssのインポートはインポート文の複数行のうちの最後に置いておくのが安全
import styles from './styles.module.css';

Object.assign(styles, templateProperties);

const PravacyPolicyBL: Array<UrlLabel> = [
    { url: '/mypage', label: 'マイページ' },
    { url: '', label: 'プライバシーポリシー' },
];

export type PrivacyPolicyTemplateProps = {
    isLogin: boolean;             // ログイン状態
    isPaid: boolean;              // 課金状態
    isSocialLogin: boolean;       // SNSログイン会員かどうか
    profileImg?: string;          // 会員画像
    preferredUsername? : string;  // 会員名
};

const PrivacyPolicyTemplate : React.FC<PrivacyPolicyTemplateProps> = ({
    isLogin,
    isPaid,
    isSocialLogin,
    profileImg,
    preferredUsername,
}) => {
    return (
        <div className={ styles['privacy-policy-template'] + ' ' + styles['template-properties-local'] }>
          <HeaderContainer isLogin={isLogin} isPaid={isPaid} isSocialLogin={isSocialLogin} profileImg={profileImg} preferredUsername={preferredUsername} />
          <PageTitleArea>プライバシーポリシー</PageTitleArea>
          {isLogin && <BreadcrumbBar UrlLabels={PravacyPolicyBL} />}
          <PageContainer>
            <ContractPreamble>
            株式会社メモアカ(以下「当社」といいます)は、当社の提供するサービス(以下「本サービス」といいます)における、
            会員についての個人情報を含む利用者情報の取り扱いについて、以下のとおりプライバシーポリシー(以下「本ポリシー」といいます)を定めます。
            </ContractPreamble>

            <ContractArticle title='第1条(収集する利用者情報及びその収集方法)'>
            本ポリシーにおける「利用者情報」とは、会員の識別にかかる情報、通信サービス上の行動履歴、その会員の端末において会員または会員の端末に関連して生成または蓄積された情報であって、本ポリシーに基づき当社が収集するものを意味することとします。
            本サービスにおいて当社が収集する利用者情報は、その収集方法に応じて、以下のようなものとなります。<br/>
            (1)会員からご提供いただく情報<br/>
            ・会員名<br/>
            ・メールアドレス<br/>
            ・パスワード<br/>
            ・その他当社が定める入力フォームに会員が入力する情報<br/><br/>
            (2)会員が本サービスを利用するにあたって、他サービスと連携を許可することにより、当該他サービスからご提供いただく情報<br/>
            ・当該外部サービスで会員が利用するID<br/>
            ・その他当該外部サービスのプライバシー設定により会員が連携先に開示を認めた情報<br/><br/>
            (3)会員が本サービスを利用するにあたって、当社が収集する情報<br/>
            ・ブラウザ情報<br/>
            ・ログ情報<br/>
            ・機器情報<br/>
            ・位置情報<br/>
            ・Cookie及び匿名ID<br/>
            当社では、本サービスの利便性をあげるために、Cookie、アクセス解析、統計データなどを保存、利用します。また当社ではCookieやJavaScriptなどの技術を利用し、会員の行動履歴を取得することがあります。なお、これらには個人情報は含まれません。<br/><br/>
            (4)Googleアナリティクスから収集する会員属性情報<br/>
            本サービスがトラフィックデータ収集のために利用しているGoogleアナリティクスから収集する以下の様な会員属性情報<br/>
            ・年齢<br/>
            ・性別<br/>
            ・興味<br/>
            ・関心<br/>
            なお、これらのデータは個人を特定できない形式に加工された上で収集されます。Googleアナリティクスの詳細についてはGoogle アナリティクス利用規約をご確認ください。
            </ContractArticle>

            <ContractArticle title='第2条(利用目的)'>
            会員から取得した利用者情報は、以下の目的のために利用されます。<br/>
            ・本サービスに関する登録の受付、本人確認等、本サービスの提供、維持、保護及び改善のため ・本サービスに関するご案内、お問い合わせ等への対応のため<br/>
            ・本サービスに関する当社の規約、ポリシー等(以下「規約等」といいます)に違反する行為に対する対応のため<br/>
            ・本サービスに関する規約等の変更、システムメンテナンスその他重要なお知らせ等について通知するため<br/>
            ・本サービス内におけるコミュニティ機能の提供のため<br/>
            ・当社の他のサービス、スクールやイベント等のご案内やメールマガジンの配信のため<br/>
            ・当社のサービスに関連して、個人を特定できない形式に加工した統計データを作成するため<br/>
            ・当社または第三者のキャンペーン等の告知、プレゼントなどの発送のため<br/>
            ・当社または第三者の広告配信または表示のため<br/>
            ・当社または第三者が提供しているサービスまたは将来提供するサービスに関するマーケティングなどに利用するため<br/>
            ・上記の利用目的に付随する利用目的のため
            </ContractArticle>

            <ContractArticle title='第3条(第三者提供)'>
            当社は、利用者情報のうち、個人情報については、個人情報保護法その他の法令に基づき開示が認められる場合を除くほか、あらかじめ会員の同意を得ないで、第三者に提供しません。
            </ContractArticle>

            <ContractArticle title='第4条(個人情報の開示等)'>
            当社は会員から、保有個人データの開示、訂正、追加若しくは削除、利用の停止若しくは消去又は第三者への提供の停止を求められた場合には、会員ご本人からのご請求であることを確認の上で、個人情報保護法に基づき、遅滞なく対応いたします。
            </ContractArticle>

            <ContractArticle title='第5条(免責)'>
            以下の場合は、第三者による個人情報の取得に関し、当社は何らの責任を負いません。<br/>
            ・会員自らが当サービス上の機能または別の手段を用いて第三者に個人情報を明らかにする場合<br/>
            ・会員が本サービス上に入力した情報、活動情報等により、期せずして個人が特定できてしまった場合<br/>
            ・第三者が当サイト外で個人情報その他の利用者情報を取得した場合<br/>
            ・会員ご本人以外が会員個人を識別できる情報(ID・パスワード等を含みます)を入手した場合
            </ContractArticle>

            <ContractArticle title='第6条(プライバシーポリシーの変更手続き)'>
            当社は利用者情報の取り扱いに関する運用状況を適宜見直し、継続的な改善に努めるものとし、必要に応じて、随時本ポリシーを変更することができるものとします。<br/>
            本ポリシーを変更する際は本サイト上にて公表させていただきます。
            </ContractArticle>

            <ContractArticle title='第7条(お問い合わせ)'>
            本ポリシーならびに利用者情報に関するお問い合わせは画面右下の「お問い合わせ」よりお願いいたします。
            </ContractArticle>

            <ContractSignature>
            〒113-0023  東京都文京区向丘2丁目3-10 東大前HiRAKU GATE 7F<br/>
            株式会社メモアカ 代表取締役 青木 健
            </ContractSignature>

            <ContractSignature>
           (四版 2023 年 6月 17日制定)<br/>
           (三版 2023 年 1月 14日制定)<br/>
           (二版 2022 年 10月 15日制定)<br/>
           (初版 2022 年 8月 1日制定)
            </ContractSignature>
          </PageContainer>
          <Footer isLogin={isLogin} />
        </div>
    );
};

export default PrivacyPolicyTemplate;
