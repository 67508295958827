/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      sub
      email
      iconUrl
      preferredUsername
      gender
      genderOpen
      birthDate
      profession
      prefecture
      rankingOpen
      selfIntroduction
      firstLoginDateTime
      lastLoginDateTime
      activeDays
      experienceLevel
      experiencePoint
      withdrawedDateTime
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      sub
      email
      iconUrl
      preferredUsername
      gender
      genderOpen
      birthDate
      profession
      prefecture
      rankingOpen
      selfIntroduction
      firstLoginDateTime
      lastLoginDateTime
      activeDays
      experienceLevel
      experiencePoint
      withdrawedDateTime
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      sub
      email
      iconUrl
      preferredUsername
      gender
      genderOpen
      birthDate
      profession
      prefecture
      rankingOpen
      selfIntroduction
      firstLoginDateTime
      lastLoginDateTime
      activeDays
      experienceLevel
      experiencePoint
      withdrawedDateTime
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const createLessonProgress = /* GraphQL */ `
  mutation CreateLessonProgress(
    $input: CreateLessonProgressInput!
    $condition: ModelLessonProgressConditionInput
  ) {
    createLessonProgress(input: $input, condition: $condition) {
      id
      sub
      user {
        sub
        email
        iconUrl
        preferredUsername
        gender
        genderOpen
        birthDate
        profession
        prefecture
        rankingOpen
        selfIntroduction
        firstLoginDateTime
        lastLoginDateTime
        activeDays
        experienceLevel
        experiencePoint
        withdrawedDateTime
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      courseId
      lessonNo
      iteration
      firstCompletedDateTime
      lastCompletedDateTime
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const updateLessonProgress = /* GraphQL */ `
  mutation UpdateLessonProgress(
    $input: UpdateLessonProgressInput!
    $condition: ModelLessonProgressConditionInput
  ) {
    updateLessonProgress(input: $input, condition: $condition) {
      id
      sub
      user {
        sub
        email
        iconUrl
        preferredUsername
        gender
        genderOpen
        birthDate
        profession
        prefecture
        rankingOpen
        selfIntroduction
        firstLoginDateTime
        lastLoginDateTime
        activeDays
        experienceLevel
        experiencePoint
        withdrawedDateTime
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      courseId
      lessonNo
      iteration
      firstCompletedDateTime
      lastCompletedDateTime
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const deleteLessonProgress = /* GraphQL */ `
  mutation DeleteLessonProgress(
    $input: DeleteLessonProgressInput!
    $condition: ModelLessonProgressConditionInput
  ) {
    deleteLessonProgress(input: $input, condition: $condition) {
      id
      sub
      user {
        sub
        email
        iconUrl
        preferredUsername
        gender
        genderOpen
        birthDate
        profession
        prefecture
        rankingOpen
        selfIntroduction
        firstLoginDateTime
        lastLoginDateTime
        activeDays
        experienceLevel
        experiencePoint
        withdrawedDateTime
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      courseId
      lessonNo
      iteration
      firstCompletedDateTime
      lastCompletedDateTime
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const createTrainingLog = /* GraphQL */ `
  mutation CreateTrainingLog(
    $input: CreateTrainingLogInput!
    $condition: ModelTrainingLogConditionInput
  ) {
    createTrainingLog(input: $input, condition: $condition) {
      id
      sub
      user {
        sub
        email
        iconUrl
        preferredUsername
        gender
        genderOpen
        birthDate
        profession
        prefecture
        rankingOpen
        selfIntroduction
        firstLoginDateTime
        lastLoginDateTime
        activeDays
        experienceLevel
        experiencePoint
        withdrawedDateTime
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      trainingMode
      numberOfMemorization
      numberOfCorrect
      memorizationSec
      recallSec
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const updateTrainingLog = /* GraphQL */ `
  mutation UpdateTrainingLog(
    $input: UpdateTrainingLogInput!
    $condition: ModelTrainingLogConditionInput
  ) {
    updateTrainingLog(input: $input, condition: $condition) {
      id
      sub
      user {
        sub
        email
        iconUrl
        preferredUsername
        gender
        genderOpen
        birthDate
        profession
        prefecture
        rankingOpen
        selfIntroduction
        firstLoginDateTime
        lastLoginDateTime
        activeDays
        experienceLevel
        experiencePoint
        withdrawedDateTime
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      trainingMode
      numberOfMemorization
      numberOfCorrect
      memorizationSec
      recallSec
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const deleteTrainingLog = /* GraphQL */ `
  mutation DeleteTrainingLog(
    $input: DeleteTrainingLogInput!
    $condition: ModelTrainingLogConditionInput
  ) {
    deleteTrainingLog(input: $input, condition: $condition) {
      id
      sub
      user {
        sub
        email
        iconUrl
        preferredUsername
        gender
        genderOpen
        birthDate
        profession
        prefecture
        rankingOpen
        selfIntroduction
        firstLoginDateTime
        lastLoginDateTime
        activeDays
        experienceLevel
        experiencePoint
        withdrawedDateTime
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      trainingMode
      numberOfMemorization
      numberOfCorrect
      memorizationSec
      recallSec
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
