import React from 'react';
import templateProperties from '../../common/template-properties.module.css';
import moment from 'moment';
import Button, {AlertButton} from '../../atoms/Button';
import InnerLink from '../../atoms/InnerLink';
import Time from '../../atoms/Time';
import {
    UrlLabel,
    StripeSubscriptionStatus,
} from '../../../redux/constant';
import HeaderContainer from '../../organisms/HeaderContainer';
import PageTitleArea from '../../layouts/PageTitleArea';
import BreadcrumbBar from '../../organisms/BreadcrumbBar';
import { PageContainer } from '../../layouts/Container';
import Footer from '../../organisms/Footer';
import OkCancelModal from '../../organisms/OkCancelModal';
// CSSはインポートした順に生成され、後にインポートしたCSSの方が優先されるので、
// まず他のコンポーネントや共通CSSをインポートして、その後でコンポーネントごとのCSSをインストールすること
// つまり、styles.module.cssのインポートはインポート文の複数行のうちの最後に置いておくのが安全
import styles from './styles.module.css';

Object.assign(styles, templateProperties);

const PaymentBL: Array<UrlLabel> = [
    { url: '/mypage', label: 'マイページ' },
    { url: '', label: 'お支払い情報' },
];

export type PaymentTemplateProps = {
    isLogin: boolean;             // ログイン状態
    isPaid: boolean;              // 有料会員かどうか
    isSocialLogin: boolean;       // SNSログイン会員かどうか
    profileImg?: string;          // 会員画像
    preferredUsername? : string;  // 会員名

    isSubscribing: boolean;
    now: number;
    subscriptionStatus?: StripeSubscriptionStatus;
    paymentExpiredOn: number;     // 支払いがなければ有料会員でなくなる日
    nextPaymentAmount?: number;    // 次回支払い額
    nextPaymentUnixTimestamp?: number;   // 次回支払日(UnixTimestamp)

    modalIsOpen?: boolean;
    clickOkButton: () => void;
    clickCancelButton: () => void;
    dispatchConfirmStopMySubscription: () => void;

    checkoutMonthly: (event: React.MouseEvent<HTMLButtonElement>) => void;
    checkoutYearly: (event: React.MouseEvent<HTMLButtonElement>) => void;
    stopMySubscription: (event: React.MouseEvent<HTMLButtonElement>) => void;
    children?: never;
};

const PaymentTemplate : React.FC<PaymentTemplateProps> = ({
    isLogin,
    isPaid,
    isSocialLogin,
    profileImg,
    preferredUsername,

    isSubscribing,
    now,
    subscriptionStatus,
    paymentExpiredOn,
    nextPaymentAmount = 0,
    nextPaymentUnixTimestamp = 0,

    modalIsOpen = false,
    clickOkButton,
    clickCancelButton,
    dispatchConfirmStopMySubscription,

    checkoutMonthly,
    checkoutYearly,
    stopMySubscription,
    children,
}) => {
    // 日付
    // const nowMoment = moment.unix(now);
    const paymentExpiredOnMoment = moment.unix(paymentExpiredOn);
    const nextPaymentOnMoment = moment.unix(nextPaymentUnixTimestamp);

    const NonPayingOrg = () => (
        <React.Fragment>
            <div className={styles['explanation-area']+' '+styles['align-center']}>
                あなたは現在、一般会員です。<br/><br/>
                有料会員になりますか？
            </div>

            <div className={styles['explanation-area']+' '+styles['align-center']}>
                <Button type="submit" onClick={checkoutMonthly}>月額課金 月々1,490円(税込)</Button>&nbsp;&nbsp;
                <Button type="submit" onClick={checkoutYearly}>年額課金 年14,900円(税込)</Button>
            </div>

            <div className={styles['align-center']}>
              ※支払い完了のメールを受信してから数分経っても有料会員に切り替わらない場合は、<br/>
              再度のお支払いはせずに、<InnerLink to="/contact">お問い合わせ</InnerLink>ページから運営にご連絡ください。<br/><br/>
            </div>

            <div className={styles['align-center']}>
                ※お支払いはクレジットカード決済もしくはウォレット決済でできます。<br/><br/>
                クレジットカード決済について 一括払いのみとなっております。<br/>
                お使いいただけるクレジットカードは以下になります。<br/>
                Visa ・MasterCard ・American Express<br/><br/>
                お使いいただけるウォレット決済は以下になります。<br/>
                ・GooglePay ・ApplePay
            </div>
        </React.Fragment>
    );

    const PayingOrg = () => (
        <React.Fragment>
            <div className={styles['explanation-area']+' '+styles['align-center']}>
                あなたは現在、有料会員です。<br/><br/>
                <table className={styles['payment-table']}>
                  <tbody>
                    { nextPaymentAmount > 0&& <tr><th>次回支払額：&nbsp;&nbsp;&nbsp;</th><td className={styles['align-right']}>{nextPaymentAmount}円</td></tr> }
                    { nextPaymentUnixTimestamp > 0 && <tr><th>支払予定日：&nbsp;&nbsp;&nbsp;</th><td className={styles['align-right']}><Time timeValue={nextPaymentOnMoment} format='YYYY年MM月DD日' /></td></tr> }
                  </tbody>
                </table>
            </div>

            <div className={styles['align-center']}>
                <AlertButton className={styles['button']} onClick={dispatchConfirmStopMySubscription}>課金を停止する</AlertButton>
            </div>
            <OkCancelModal
            modalIsOpen={modalIsOpen}
            headerText='課金の停止の確認'
            clickOkButton={clickOkButton}
            clickCancelButton={clickCancelButton}
        >
            本当に課金を停止<br/>しますか？
            </OkCancelModal>
        </React.Fragment>
    );

    const StopPayingOrg = () => (
        <React.Fragment>
            <div className={styles['explanation-area']+' '+styles['align-center']}>
                あなたは現在、有料会員です。<br/>
                <Time timeValue={paymentExpiredOnMoment} format='YYYY年MM月DD日' />に一般会員に戻ります。
            </div>

            <div className={styles['explanation-area']+' '+styles['align-center']}>
                有料会員の継続をご希望の場合は、<Time timeValue={paymentExpiredOnMoment} format='YYYY年MM月DD日' />以降に<br/>
                改めて課金手続きをしてください。
            </div>
        </React.Fragment>
    );

    const PastDueOrg = () => (
        <React.Fragment>
            <div className={styles['explanation-area']+' '+styles['align-center']}>
              自動支払いに失敗しており、一般会員に戻っています。<br/>
              数日以内に、自動支払いが再試行されます。<br/>
              今すぐ有料会員に戻りたい場合は、一度自動支払いを停止し、<br/>
              その後、改めて有料会員としての登録をお願いします。
            </div>

              <div className={styles['explanation-area']+' '+styles['align-center']}>
                <Button type="submit" onClick={stopMySubscription}>自動支払いを停止する</Button>
              </div>
        </React.Fragment>
    );

    return (
        <div className={ styles['payment-template'] + ' ' + styles['template-properties-local'] }>
          <HeaderContainer isLogin={isLogin} isPaid={isPaid} isSocialLogin={isSocialLogin} profileImg={profileImg} preferredUsername={preferredUsername} />
          <PageTitleArea>お支払い情報</PageTitleArea>
          <BreadcrumbBar UrlLabels={PaymentBL} />
          <PageContainer>
            {
                (() => {
                if (subscriptionStatus === StripeSubscriptionStatus.pastDue){
                    return (<PastDueOrg/>);
                }

                if (isSubscribing) {
                    return (<PayingOrg/>);
                }

                if (!isSubscribing && now < paymentExpiredOn) {
                    return (<StopPayingOrg/>);
                }

                if (!isSubscribing && now >= paymentExpiredOn) {
                    return (<NonPayingOrg/>);
                }

            })()
            }

          </PageContainer>
          <Footer isLogin={isLogin} />
        </div>
    );
};

export default PaymentTemplate;
