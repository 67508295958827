import React, { ReactNode } from 'react';
// CSSはインポートした順に生成され、後にインポートしたCSSの方が優先されるので、
// まず他のコンポーネントや共通CSSをインポートして、その後でコンポーネントごとのCSSをインストールすること
// つまり、styles.module.cssのインポートはインポート文の複数行のうちの最後に置いておくのが安全
import styles from './styles.module.css';

export type ColumnAreaProps = {
    portion?: string;
    isSide?: boolean;
    className?: string;
    children?: ReactNode;
} & JSX.IntrinsicElements['div'];


const ColumnArea : React.FC<ColumnAreaProps> = ({
    portion = '',
    isSide = false,
    className = '',
    children,
}) => {
    // class属性を設定
    let classString = styles['column-area'];
    if (portion) {
        classString = [classString, styles[portion]].join(' ');
    }
    if (isSide) {
        classString = [classString, styles['side-column']].join(' ');
    }
    if (className) {
        classString = [classString, className].join(' ');
    }

    return (
        <div className={classString}>{children}</div>
    );
};

const columnAreaFactory = (portion: string) => {
    return (cProps: Omit<ColumnAreaProps, 'portion'>) => {
        return (
            <ColumnArea portion={portion} {...cProps} />
        );
    };
}

export default ColumnArea;
export const OneThirdColumn =columnAreaFactory('one-third');
export const OneHalfColumn =columnAreaFactory('one-half');
export const TwoThirdColumn =columnAreaFactory('two-third');
