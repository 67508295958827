import React from 'react';
import templateProperties from '../../common/template-properties.module.css';
import DoorwayLayout from '../../layouts/DoorwayLayout';
import DoorwayFormLayout from '../../layouts/DoorwayFormLayout';
import { ErrorMessageBar } from '../../atoms/MessageBar';
import { OpenBadge, CloseBadge, RequiredAstariskBadge } from '../../atoms/Badge';
import Textbox from '../../atoms/Textbox';
import { OpenClosePulldown, GenderPulldown, PrefPulldown } from '../../atoms/PulldownList';
import InlineDatePicker from '../../molecules/InlineDatePicker';
import Textarea from '../../atoms/Textarea';
import { PrimaryWrapButton } from '../../atoms/Button';
// CSSはインポートした順に生成され、後にインポートしたCSSの方が優先されるので、
// まず他のコンポーネントや共通CSSをインポートして、その後でコンポーネントごとのCSSをインストールすること
// つまり、styles.module.cssのインポートはインポート文の複数行のうちの最後に置いておくのが安全
import styles from './styles.module.css';

Object.assign(styles, templateProperties);

export type SetupProfileTemplateProps = {
    // imgFile?: string,
    preferredUsername?: string,
    gender?: string,
    genderOpen?: string,
    birthDate?: Date,
    profession?: string,
    prefecture?: string,
    rankingOpen?: string,
    selfIntroduction?: string,

    // imgFileValidationMessage: string;
    preferredUsernameValidationMessage: string;
    genderValidationMessage: string;
    genderOpenValidationMessage: string;
    birthDateValidationMessage: string;
    professionValidationMessage: string;
    prefectureValidationMessage: string;
    rankingOpenValidationMessage: string;
    selfIntroductionValidationMessage: string;

    // changeImgFile: (event: React.ChangeEvent<HTMLInputElement>) => void;
    changePreferredUsername: (event: React.ChangeEvent<HTMLInputElement>) => void;
    changeGender: (event: React.ChangeEvent<HTMLSelectElement>) => void;
    changeGenderOpen: (event: React.ChangeEvent<HTMLSelectElement>) => void;
    changeBirthDate: (date:Date) => void;
    changeProfession: (event: React.ChangeEvent<HTMLInputElement>) => void;
    changePrefecture: (event: React.ChangeEvent<HTMLSelectElement>) => void;
    changeRankingOpen: (event: React.ChangeEvent<HTMLSelectElement>) => void;
    changeSelfIntroduction: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;

    submit: (event: React.MouseEvent<HTMLButtonElement>) => void;
};

const SetupProfileTemplate : React.FC<SetupProfileTemplateProps> = ({
    // imgFile,
    preferredUsername,
    gender,
    genderOpen,
    birthDate,
    profession,
    prefecture,
    rankingOpen,
    selfIntroduction,

    // imgFileValidationMessage,
    preferredUsernameValidationMessage,
    genderValidationMessage,
    genderOpenValidationMessage,
    birthDateValidationMessage,
    professionValidationMessage,
    prefectureValidationMessage,
    rankingOpenValidationMessage,
    selfIntroductionValidationMessage,

    // changeImgFile,
    changePreferredUsername,
    changeGender,
    changeGenderOpen,
    changeBirthDate,
    changeProfession,
    changePrefecture,
    changeRankingOpen,
    changeSelfIntroduction,

    submit,
}) => {
    return (
        <DoorwayLayout className={ styles['template-properties-local'] }>
            <DoorwayFormLayout title='初期プロフィール登録'>
                <div className={ styles['setup-profile-template'] }>
                    <p className={styles["explanation-text"]}>
                    <RequiredAstariskBadge />は必須項目です。<br/><br/>
                    <OpenBadge />は今後追加予定のランキング機能などで<br/>メモアカ内の他の会員に公開されます。
                        { /* 画像は正方形の画像をアップしてください。きれいに表示されます。 */ }
                    </p>

                    {/* <div className={styles["form-item"]}>
                        <label className={styles["form-item-label"]} htmlFor="image-file">
                            画像<OpenBadge />
                        </label>
                        <Textbox id="image-file" type="file" onChange={changeImgFile} />
                    </div>
                    { imgFileValidationMessage && <ErrorMessageBar>{imgFileValidationMessage}</ErrorMessageBar> } */}

                    <div className={styles["form-item"]}>
                        <label className={styles["form-item-label"]} htmlFor="preferred-username">
                            会員名<OpenBadge /><RequiredAstariskBadge />
                        </label>
                        <Textbox id="preferred-username" placeholder="表示したい名前を入力してください" value={preferredUsername} onChange={changePreferredUsername} maxLength={9} />
                    </div>
                    <div>9文字以内で設定してください</div>
                    { preferredUsernameValidationMessage && <ErrorMessageBar>{preferredUsernameValidationMessage}</ErrorMessageBar> }

                    <div className={styles["form-item"]}>
                        <label className={styles["form-item-label"]} htmlFor="gender">
                            性別<RequiredAstariskBadge />
                        </label>
                        <GenderPulldown id="gender" className={styles["pulldown"]} noValueOptionFlag={true} value={gender} onChange={changeGender} />
                        <OpenClosePulldown id="gender-open" data-testid="gender-open" className={styles["pulldown"]} value={genderOpen} onChange={changeGenderOpen} />
                    </div>
                    { genderValidationMessage && <ErrorMessageBar>{genderValidationMessage}</ErrorMessageBar> }
                    { genderOpenValidationMessage && <ErrorMessageBar>{genderOpenValidationMessage}</ErrorMessageBar> }

                    <div className={styles["form-item"]}>
                        <label className={styles["form-item-label"]} htmlFor="birth-date">
                            生年月日<CloseBadge /><RequiredAstariskBadge />
                        </label>
                        <InlineDatePicker userDate={birthDate} changeDatePicker={changeBirthDate} />
                    </div>
                    { birthDateValidationMessage && <ErrorMessageBar>{birthDateValidationMessage}</ErrorMessageBar> }

                    <div className={styles["form-item"]}>
                        <label className={styles["form-item-label"]} htmlFor="prefecture">
                            居住都道府県<CloseBadge /><RequiredAstariskBadge />
                        </label>
                        <PrefPulldown id="prefecture" className={styles["pulldown"]} noValueOptionFlag={true} value={prefecture} onChange={changePrefecture} />
                    </div>
                    { prefectureValidationMessage && <ErrorMessageBar>{prefectureValidationMessage}</ErrorMessageBar> }

                    <div className={styles["form-item"]}>
                        <label className={styles["form-item-label"]} htmlFor="profession">
                            職業<CloseBadge />
                        </label>
                        <Textbox id="profession" placeholder="自由にご記入ください" value={profession} onChange={changeProfession} />
                    </div>
                    { professionValidationMessage && <ErrorMessageBar>{professionValidationMessage}</ErrorMessageBar> }

                    { /* ランキング機能は後から公開するのでコメントアウト
                    <div className={styles["form-item"]}>
                        <label className={styles["form-item-label"]} htmlFor="ranking-open">
                            ランキング公開
                        </label>
                        <OpenClosePulldown id="ranking-open" className={styles["pulldown"]} value={rankingOpen} onChange={changeRankingOpen} />
                    </div>
                    { rankingOpenValidationMessage && <ErrorMessageBar>{rankingOpenValidationMessage}</ErrorMessageBar> }
                    */ }

                    <div className={styles["form-item"]}>
                        <label className={styles["form-item-label"]} htmlFor="self-introduction">
                            自己紹介<OpenBadge />
                        </label>
                        <Textarea placeholder="自由にご記入ください" id="self-introduction" value={selfIntroduction} className={styles['textarea']} onChange={changeSelfIntroduction} >
                        </Textarea>
                    </div>
                    { selfIntroductionValidationMessage && <ErrorMessageBar>{selfIntroductionValidationMessage}</ErrorMessageBar> }

                    <PrimaryWrapButton className={styles['button']} onClick={submit}>プロフィール登録</PrimaryWrapButton>
                </div>
            </DoorwayFormLayout>
        </DoorwayLayout>
    );
};

export default SetupProfileTemplate;
