import React from 'react';
// CSSはインポートした順に生成され、後にインポートしたCSSの方が優先されるので、
// まず他のコンポーネントや共通CSSをインポートして、その後でコンポーネントごとのCSSをインストールすること
// つまり、styles.module.cssのインポートはインポート文の複数行のうちの最後に置いておくのが安全
import styles from './styles.module.css';

export type ButtonProps = {
    kind?:string;
    buttonWrap?: boolean;
} & JSX.IntrinsicElements['button'];

const Button : React.FC<ButtonProps> = ({
    kind = '',
    buttonWrap = false,
    className = '',
    ...props
}) => {
        // class属性を設定
        let classString = styles.button;
        if (kind) {
            classString = [classString, styles[kind]].join(' ');
        }
        if (className) {
            classString = [classString, className].join(' ');
        }

        if (buttonWrap) {
            return (
                <div className={styles['button-wrap']} >
                    <button
                        className={classString}
                        {...props}
                    />
                </div>
            );
        } else {
            return (
                <button
                    className={classString}
                    {...props}
                />
            );
        }
    };

const buttonFactory = (kind: string, buttonWrap: boolean) => {
    return (bProps: Omit<ButtonProps, 'kind' | 'buttonWrap'>) => {
        return (
            <Button kind={kind} buttonWrap={buttonWrap} {...bProps} />
        );
    };
}

export default Button;
export const WrapButton = buttonFactory('', true);
export const PrimaryWrapButton = buttonFactory('primary', true);
export const PrimaryButton = buttonFactory('primary', false);
export const AlertButton = buttonFactory('alert', false);
export const JustClickButton = buttonFactory('just-click', false);
export const MoreButton = buttonFactory('more-btn', false);
export const GrayWrapButton = buttonFactory('gray', true);
export const GrayButton = buttonFactory('gray', false);
