import React from 'react';
import TopPageTemplate from '../../templates/TopPageTemplate';
// CSSはインポートした順に生成され、後にインポートしたCSSの方が優先されるので、
// まず他のコンポーネントや共通CSSをインポートして、その後でコンポーネントごとのCSSをインストールすること
// つまり、styles.module.cssのインポートはインポート文の複数行のうちの最後に置いておくのが安全
import styles from './styles.module.css';

type TopPagePageProps = {
  children?: never;
};


const TopPagePage : React.FC<TopPagePageProps> = () => {
    return (
        <div className={ styles['top-page-page'] }>
          <TopPageTemplate/>
        </div>
    );
};

export default TopPagePage;
