import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit'
import type { RootState } from '../app/store'
import { Auth, API } from "aws-amplify"
import {
    StripeSubscriptionStatus,
} from '../constant';


interface PaymentState {
    // モーダル
    modalIsOpen: boolean;

    // 自動課金がオンかどうか
    isSubscribing: boolean;

    // 現在のunix_timestamp
    now: number;

    // サブスクリプションのstatus
    subscriptionStatus?: StripeSubscriptionStatus;

    // 現在のsubscriptionの有効期限(unix_timestamp)
    paymentExpiredOn: number;

    // 有料会員かどうか
    isPaid: boolean;

    // 次回の課金額
    // VIPの場合は0?
    nextPaymentAmount?: number;

    // 次回の課金タイミング (unix_timestamp)
    // undefined: 未課金の場合
    // VIPの場合は?
    nextPaymentUnixTimestamp?: number;
}

export const initialState: PaymentState = {
    modalIsOpen: false,
    isSubscribing: false,
    now: 0,
    subscriptionStatus: undefined,
    paymentExpiredOn: 0,
    isPaid: false,
    nextPaymentAmount: 0,
    nextPaymentUnixTimestamp: undefined,
};

interface SubmitParams {
};

// 一般 → 有料(月額)
// 一般 → 有料(年額)
// 有料(月/年額) → 停止
// 停止 → 一般 (時間経過により)

const API_NAME = 'memoacaapi';

const checkout = async (endpoint: string) => {
    const user = await Auth.currentAuthenticatedUser();
    const token = user.signInUserSession.idToken.jwtToken;

    const myInit = {
        headers: {
            'X-TOKEN': token,
        },
    };

    // alert(JSON.stringify(myInit));

    const data = await API.post(API_NAME, endpoint, myInit);

    // alert(JSON.stringify(data));
    // return;

    const stripeCheckoutUrl = data.stripeCheckoutUrl;

    // APIが万が一乗っ取られて悪意のあるサイトへのURLが返ってきた時に弾けるようにする
    if (isStripeDomain(stripeCheckoutUrl)) {
        window.location.href = stripeCheckoutUrl;
    }
};

export const isStripeDomain = (url: string) => {
    const re = new RegExp('^https://[^/]*\\.stripe.com/');
    return url.startsWith('https://stripe.com/') || (url.match(re) !== null);
};

// https://redux-toolkit.js.org/api/createAsyncThunk
// https://docs.amplify.aws/lib/restapi/update/q/platform/js/
export const checkoutMonthly = createAsyncThunk(
    'payment/checkoutMonthly',
    async (submitParams: SubmitParams, thunkAPI) => {
        checkout('/create-monthly-checkout-session');
    }
);

export const checkoutYearly = createAsyncThunk(
    'payment/checkoutYearly',
    async (submitParams: SubmitParams, thunkAPI) => {
        checkout('/create-yearly-checkout-session');
    }
);

export const fetchMySubscriptions = createAsyncThunk(
    'payment/fetchMySubscriptions',
    async (submitParams: SubmitParams, thunkAPI) => {
        const user = await Auth.currentAuthenticatedUser();
        const token = user.signInUserSession.idToken.jwtToken;

        const myInit = {
            headers: {
                'X-TOKEN': token,
            },
        };

        // alert(JSON.stringify(myInit));

        const data = await API.post(API_NAME, '/fetch-my-subscriptions', myInit);
        // alert(JSON.stringify(data));
        return data;
    }
);

export const stopMySubscription = createAsyncThunk(
    'payment/stopMySubscription',
    async (submitParams: SubmitParams, thunkAPI) => {
        const user = await Auth.currentAuthenticatedUser();
        const token = user.signInUserSession.idToken.jwtToken;

        const myInit = {
            headers: {
                'X-TOKEN': token,
            },
        };

        const data = await API.post(API_NAME, '/stop-my-subscription', myInit);
        // alert(JSON.stringify(data));
        return data;
    }
);

// 有料会員かどうかを決定する
const decideIsPaid = (isSubscribing: boolean, now: number, paymentExpiredOn: number) => {
    return isSubscribing || now < paymentExpiredOn;
}

export const paymentSlice = createSlice({
    name: 'payment',
    initialState,
    reducers: {
        // 課金停止のモーダル用
        changeModalIsOpen: (state, action: PayloadAction<boolean>) => {
            state.modalIsOpen = action.payload;
        },
        // ログアウト後などにisPaidをオフにする用
        // trueにするかどうかはdecideIsPaid()を必ず利用して判定するので、
        // setIsNotPaidはfalseにするのみ
        setIsNotPaid: (state, action: PayloadAction<undefined>) => {
            state.isPaid = false;
        },
        // テスト時に強制的にisPaidをオンにする用
        // trueにするかどうかはdecideIsPaid()を必ず利用して判定するので、
        // テストコードのみで使用すること!
        // TODO: それを自動判定する
        setIsPaid: (state, action: PayloadAction<undefined>) => {
            state.isPaid = true;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchMySubscriptions.fulfilled, (state, action) => {
            // Date.now()の値は個々のPCに設定された時刻とタイムゾーンで決定するが、
            // subscriptionが有効(active)かどうかはそもそもStripe APIの返答で決まっているので、
            // PCの時刻をいじることで課金をごまかすことはできない作りになっている
            const now = Date.now() / 1000.0;
            state.now = now;

            const data = action.payload;
            // alert(JSON.stringify(data));

            if (data.results.length === 0) {
                state.isSubscribing = false;
                state.subscriptionStatus = undefined;
                state.paymentExpiredOn = 0;
                state.isPaid = decideIsPaid(false, now, 0);

                state.nextPaymentAmount = undefined;
                state.nextPaymentUnixTimestamp = undefined;
            } else if (data.results.length === 1) {
                const record = data.results[0];
                // alert(JSON.stringify(record));

                // StripeSubscriptionStatusとして用意している値ではなかった場合にも、ただの文字列としてそのまま入る (実行時はただのJSなので)
                // 意図しないステータスだった場合にサブスクリプション無しとして扱ってしまうと、二重課金が発生する元となる可能性があるため。
                const subscriptionStatus = record.status as StripeSubscriptionStatus;
                const isSubscribing = subscriptionStatus === StripeSubscriptionStatus.active && !record.cancel_at_period_end;
                const paymentExpiredOn = subscriptionStatus === StripeSubscriptionStatus.active ? (record.cancel_at || 0) : 0;

                state.isSubscribing = isSubscribing;
                state.subscriptionStatus = subscriptionStatus;
                state.paymentExpiredOn = paymentExpiredOn;
                state.isPaid = decideIsPaid(isSubscribing, now, paymentExpiredOn);

                state.nextPaymentAmount = (record.unitAmount || 0) - (record.amountOff || 0);
                state.nextPaymentUnixTimestamp = record.current_period_end;
            } else {
                // FIXME
                // alert('有効なサブスクリプションが複数あります');
            }
        });
        // builder.addCase(fetchMySubscriptions.rejected, (state, action) => {
        //     //
        // });
        builder.addCase(stopMySubscription.fulfilled, (state, action) => {
            // Date.now()の値は個々のPCに設定された時刻とタイムゾーンで決定するが、
            // subscriptionが有効(active)かどうかはそもそもStripe APIの返答で決まっているので、
            // PCの時刻をいじることで課金をごまかすことはできない作りになっている
            const now = Date.now() / 1000.0;
            state.now = now;

            const data = action.payload;
            const record = data.result;
            if (record) {
                // StripeSubscriptionStatusとして用意している値ではなかった場合にも、ただの文字列としてそのまま入る (実行時はただのJSなので)
                // 意図しないステータスだった場合にサブスクリプション無しとして扱ってしまうと、二重課金が発生する元となる可能性があるため。
                const subscriptionStatus = record.status as StripeSubscriptionStatus;
                const isSubscribing = subscriptionStatus === StripeSubscriptionStatus.active && !record.cancel_at_period_end;
                const paymentExpiredOn = subscriptionStatus === StripeSubscriptionStatus.active ? (record.cancel_at || record.current_period_end) : 0;
                state.isSubscribing = isSubscribing;
                state.subscriptionStatus =subscriptionStatus;
                state.paymentExpiredOn = paymentExpiredOn;
                state.isPaid = decideIsPaid(isSubscribing, now, paymentExpiredOn);
            } else {
                state.isSubscribing = false;
                state.subscriptionStatus = undefined;
                state.paymentExpiredOn = 0;
                state.isPaid = decideIsPaid(false, now, 0);
            }
        });
        // builder.addCase(stopMySubscription.rejected, (state, action) => {
        //     //
        // });
    },
})

export const {
    changeModalIsOpen,
    setIsNotPaid,
} = paymentSlice.actions;

export const selectPaymentState = (state: RootState) => {
    return {
        modalIsOpen: state.payment.modalIsOpen,
        isSubscribing: state.payment.isSubscribing,
        now: state.payment.now,
        subscriptionStatus: state.payment.subscriptionStatus,
        paymentExpiredOn: state.payment.paymentExpiredOn,
        isPaid: state.payment.isPaid,
        nextPaymentAmount: state.payment.nextPaymentAmount,
        nextPaymentUnixTimestamp: state.payment.nextPaymentUnixTimestamp
    } as PaymentState;
};

export default paymentSlice.reducer;
