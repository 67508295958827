import React from 'react';
import { TrainingMode, NumberOfMemorize, TrainingNumberSetting, TrainingWordSetting, TrainingAlphabetSetting, TrainingNameAndFaceSetting, TrainingCircleRatioSetting, TrainingNationalCapitalsSetting, TrainingRegionSelectionSetting } from '../../../redux/constant';
// CSSはインポートした順に生成され、後にインポートしたCSSの方が優先されるので、
// まず他のコンポーネントや共通CSSをインポートして、その後でコンポーネントごとのCSSをインストールすること
// つまり、styles.module.cssのインポートはインポート文の複数行のうちの最後に置いておくのが安全
import styles from './styles.module.css';

export type TrainingChoiceListProps = {
    trainingMode: TrainingMode;
    isPaid?: boolean;
    isAllRegion?: boolean;

    changeChoiceOfNumber: (index: number) => (e: React.ChangeEvent<HTMLInputElement>) => void;
    changeOnlyAnswerMode: (index: boolean) => (e: React.ChangeEvent<HTMLInputElement>) => void;
    changeAllRegionMode: (index: boolean) => (e: React.ChangeEvent<HTMLInputElement>) => void;
    changeRegionSelection: (index: number) => (e: React.ChangeEvent<HTMLInputElement>) => void;

    children?: never;
};

const TrainingChoiceList: React.FC<TrainingChoiceListProps> = ({
    trainingMode,
    isAllRegion,
    isPaid = false,

    changeChoiceOfNumber,
    changeOnlyAnswerMode,
    changeAllRegionMode,
    changeRegionSelection,
}) => {
    // 設問数選択肢をセット
    const trainingSetting: Array<NumberOfMemorize> = (() => {
        let trainingSetting: Array<NumberOfMemorize> = [];
        if (trainingMode === TrainingMode.number) trainingSetting = TrainingNumberSetting;
        if (trainingMode === TrainingMode.word) trainingSetting = TrainingWordSetting;
        if (trainingMode === TrainingMode.alphabet) trainingSetting = TrainingAlphabetSetting;
        if (trainingMode === TrainingMode.nameAndFace) trainingSetting = TrainingNameAndFaceSetting;
        if (trainingMode === TrainingMode.circleRatio) trainingSetting = TrainingCircleRatioSetting;
        if (trainingMode === TrainingMode.nationalCapitals) trainingSetting = TrainingNationalCapitalsSetting;
        return trainingSetting;
    })();

    return (
        <div>
            {trainingMode === TrainingMode.circleRatio && (
                <div>
                    <ul className={styles['training-choice-list']}>
                        <li className={styles['choice-list-item']}>
                            <label>
                                {
                                    isPaid ? (
                                        <React.Fragment>
                                            <input type="radio" value="answerMode" name="answer-mode" onChange={changeOnlyAnswerMode(false)} />
                                            <span className={styles['button']}>記憶＆回答モード</span>
                                        </React.Fragment>
                                    ) : (
                                        <React.Fragment>
                                            <input type="radio" value="answerMode" name="answer-mode" onChange={changeOnlyAnswerMode(false)} disabled={true} />
                                            <span className={styles['disabled-button']}>記憶＆回答モード</span>
                                            <br /><span>※有料会員のみ</span>
                                        </React.Fragment>
                                    )
                                }
                            </label>
                        </li>
                        <li className={styles['choice-list-item']}>
                            <label>
                                <input type="radio" value="memoryAnswerMode" name="answer-mode" onChange={changeOnlyAnswerMode(true)} />
                                <span className={styles.button}>回答モード</span>
                            </label>
                        </li>
                    </ul>
                </div>
            )}
            {trainingMode === TrainingMode.nationalCapitals && (
                <div>
                    <ul className={styles['training-choice-list']}>
                        <li className={styles['choice-list-item']}>
                            <label>
                                <input type="radio" value="allRegionMode" name="allRegion-mode" onChange={changeAllRegionMode(true)} />
                                <span className={styles.button}>すべての地域</span>
                            </label>
                        </li>
                        <li className={styles['choice-list-item']}>
                            <label>
                                <input type="radio" value="selectRegionMode" name="allRegion-mode" onChange={changeAllRegionMode(false)} />
                                <span className={styles.button}>地域別</span>
                            </label>
                        </li>
                    </ul>
                </div>
            )}
            {trainingMode === TrainingMode.nationalCapitals && isAllRegion === false && (
                <div>
                    <ul className={styles['training-choice-list']}>
                        {TrainingRegionSelectionSetting.map((regionSelection, index) => (
                            <li key={index} className={styles['choice-list-item']}>
                                <label>
                                    <input type="radio" value={regionSelection.number} name="region-selection" onChange={changeRegionSelection(regionSelection.number)} />
                                    <span className={styles.button}>{regionSelection.label}</span>
                                </label>
                            </li>
                        ))}
                    </ul>
                </div>
            )}
            <ul className={styles['training-choice-list']}>
                {trainingSetting &&
                    trainingSetting.map((numberOfMememorize, index) => (
                        <li key={index} className={styles['choice-list-item']}>
                            <label>
                                <input type="radio" value={numberOfMememorize.number} name="number-of-memorize" onChange={changeChoiceOfNumber(numberOfMememorize.number)} />
                                <span className={styles.button}>{numberOfMememorize.label}</span>
                            </label>
                        </li>
                    ))
                }
            </ul>
        </div>
    );
};

export default TrainingChoiceList;
