import React, {
    RefObject,
    MutableRefObject,
} from 'react';
import templateProperties from '../../common/template-properties.module.css';
import { TrainingMode, TrainingPhase, TrainingWordBL, TrainingAlphabetBL, TrainingNumberBL, TrainingNameAndFaceBL, TrainingCircleRatioBL, TrainingNationalCapitalsBL } from '../../../redux/constant';
import HeaderContainer from '../../organisms/HeaderContainer';
import Footer from '../../organisms/Footer';
import TrainingTitleBar from '../../organisms/TrainingTitleBar';
import TrainingChoiceList from '../../organisms/TrainingChoiceList';
import BreadcrumbBar from '../../organisms/BreadcrumbBar';
import Container from '../../layouts/Container';
import Anchor from '../../atoms/Anchor';
import FontTitle from '../../atoms/FontTitle';
import FontMonospacedNum from '../../atoms/FontMonospacedNum';
import Img from '../../atoms/Img';
import Button, { PrimaryWrapButton, GrayWrapButton } from '../../atoms/Button';
import TrainingArea from '../../organisms/TrainingArea';
import PointModal from '../../organisms/PointModal';
import {
    TwitterShareButton,
    TwitterIcon,
} from "@ciaran0/react-share";
// CSSはインポートした順に生成され、後にインポートしたCSSの方が優先されるので、
// まず他のコンポーネントや共通CSSをインポートして、その後でコンポーネントごとのCSSをインストールすること
// つまり、styles.module.cssのインポートはインポート文の複数行のうちの最後に置いておくのが安全
import styles from './styles.module.css';

Object.assign(styles, templateProperties);

export type TrainingTemplateProps = {
    isLogin: boolean;             // ログイン状態
    isPaid: boolean;              // 有料会員かどうか
    isSocialLogin: boolean;       // SNSログイン会員かどうか
    profileImg?: string;          // 会員画像
    preferredUsername?: string;  // 会員名

    trainingMode?: TrainingMode;    // トレーニングモード
    trainingPhase: TrainingPhase;   // 画面状態

    numberOfMemorization?: number;          // 問題数
    isAnswerOnlyMode?: boolean;        // 回答のみフラグ
    numberOfCorrect?: number;               // 正解数
    regionSelection?: number;               // 地域選択
    isAllRegion?: boolean;                  // 全地域のフラグ

    currentPage: number;               // 現在のページ
    remainingWaitingTimeStr: string;        // カウントダウン残り時間
    remainingMemorizationTimeStr: string;   // 記憶残り時間
    remainingRecallTimeStr: string;         // 回答残り時間

    consumedMemorizationTimeStr: string;   // 記憶時間
    consumedRecallTimeStr: string;         // 回答時間

    modalIsOpen: boolean;
    recallButtonFlg: boolean;
    experiencePoint: number; // 現在の(加算後の)経験値
    addedExperiencePoint: number; // 加算された経験値

    memorizationFaceImages?: Array<string>; // 顔と名前 問題時顔画像
    recallFaceImages?: Array<string>;       // 顔と名前 回答時顔画像
    memorizationNames?: Array<string>;      // 顔と名前 問題時名前
    correctAnswers: Array<string>;          // 問題＝正解(単語、アルファベット、数字)正解(顔と名前)
    correctNationalCapitals: Array<Array<string>>; // 国と首都の正解
    myAnswers: Array<string>;               // 会員の回答

    recallInputRefs?: MutableRefObject<RefObject<HTMLInputElement>[]>;

    changeChoiceOfNumber: (index: number) => (e: React.ChangeEvent<HTMLInputElement>) => void;
    changeOnlyAnswerMode: (index: boolean) => (e: React.ChangeEvent<HTMLInputElement>) => void;
    changeAllRegionMode: (index: boolean) => (e: React.ChangeEvent<HTMLInputElement>) => void;
    changeRegionSelection: (index: number) => (e: React.ChangeEvent<HTMLInputElement>) => void;
    changeMyAnswer: (index: number) => (e: React.ChangeEvent<HTMLInputElement>) => void;
    moveFocus: (index: number) => (e: React.KeyboardEvent<HTMLInputElement>) => void;

    changePrePage: (index: number) => (e: React.MouseEvent<HTMLButtonElement>) => void;
    changeNextPage: (currentPage: number, maxPage: number) => (e: React.MouseEvent<HTMLButtonElement>) => void;

    submitSetting: (e: React.MouseEvent<HTMLButtonElement>) => void;
    submitMemorization: (e: React.MouseEvent<HTMLButtonElement>) => void;
    submitRecall: (e: React.MouseEvent<HTMLButtonElement>) => void;
    submitAgain: (e: React.MouseEvent<HTMLButtonElement>) => void;

    closeModal: () => void;

    children?: never;
};

export const makeMessageToShare = (trainingMode: TrainingMode | undefined, numberOfMemorization: number | null, numberOfCorrect: number | null, consumedMemorizationTimeStr: string, consumedRecallTimeStr: string) => {
    if (typeof trainingMode === 'undefined') {
        return '今すぐ独学で記憶術が学べるオンライン学習サービス メモアカ';
    }

    const mode = (() => {
        switch (trainingMode) {
            case TrainingMode.number:
                return '数字';
            case TrainingMode.word:
                return '単語';
            case TrainingMode.alphabet:
                return 'アルファベット';
            case TrainingMode.nameAndFace:
                return '顔と名前';
            case TrainingMode.circleRatio:
                return '円周率';
            case TrainingMode.nationalCapitals:
                return '国と首都';
            default:
                return 'その他の';
        }
    })();

    const unit = (trainingMode === TrainingMode.number) ? '桁' : '問';
    const suffix = numberOfCorrect === 0 ? '…' : '！';
    const scoreMsg = `#メモアカトレーニング #${mode}記憶 のトレーニングで${numberOfMemorization || 0}${unit}中${numberOfCorrect || 0}${unit}正解${suffix}`
    const title = `${scoreMsg}
記憶時間 ${consumedMemorizationTimeStr} 回答時間 ${consumedRecallTimeStr}

今すぐ独学で記憶術が学べるオンライン学習サービス メモアカ`;

    return title;
};

const TrainingTemplate: React.FC<TrainingTemplateProps> = ({
    isLogin,
    isPaid,
    isSocialLogin,
    profileImg,
    preferredUsername,

    trainingMode,
    trainingPhase,

    numberOfMemorization = null,
    isAnswerOnlyMode,
    regionSelection,
    isAllRegion = undefined,
    numberOfCorrect = 0,

    remainingWaitingTimeStr,
    remainingMemorizationTimeStr,
    remainingRecallTimeStr,

    consumedMemorizationTimeStr,
    consumedRecallTimeStr,

    memorizationFaceImages = [],
    recallFaceImages = [],
    memorizationNames,
    correctAnswers,
    correctNationalCapitals,
    myAnswers,

    currentPage = 1,
    modalIsOpen = false,
    recallButtonFlg = false,
    experiencePoint,
    addedExperiencePoint,

    recallInputRefs,

    changeChoiceOfNumber,
    changeOnlyAnswerMode,
    changeRegionSelection,
    changeAllRegionMode,
    changeMyAnswer,
    moveFocus,

    changePrePage,
    changeNextPage,

    submitSetting,
    submitMemorization,
    submitRecall,
    submitAgain,

    closeModal,
}) => {
    const title = makeMessageToShare(trainingMode, numberOfMemorization, numberOfCorrect, consumedMemorizationTimeStr, consumedRecallTimeStr);
    const shareUrl = 'https://www.memoaca.com';
    //const NumberOfMemorize = useSelector((state: any) => state.training.numberOfMemorization);

    return (
        <React.Fragment>
            <div className={styles['training-template'] + ' ' + styles['template-properties-local']}>
                <HeaderContainer isLogin={isLogin} isPaid={isPaid} isSocialLogin={isSocialLogin} profileImg={profileImg} preferredUsername={preferredUsername} />
                {trainingMode === TrainingMode.word && <BreadcrumbBar UrlLabels={TrainingWordBL} />}
                {trainingMode === TrainingMode.alphabet && <BreadcrumbBar UrlLabels={TrainingAlphabetBL} />}
                {trainingMode === TrainingMode.number && <BreadcrumbBar UrlLabels={TrainingNumberBL} />}
                {trainingMode === TrainingMode.nameAndFace && <BreadcrumbBar UrlLabels={TrainingNameAndFaceBL} />}
                {trainingMode === TrainingMode.circleRatio && <BreadcrumbBar UrlLabels={TrainingCircleRatioBL} />}
                {trainingMode === TrainingMode.nationalCapitals && <BreadcrumbBar UrlLabels={TrainingNationalCapitalsBL} />}

                {trainingMode && trainingPhase === TrainingPhase.setting &&
                    <div className={styles['training-wrap']}>
                        <Container className={styles['training-content']} >
                            <TrainingTitleBar trainingMode={trainingMode} />

                            {trainingMode === TrainingMode.nationalCapitals && <p>※有料会員の方は画面右上メニューの「ダウンロード」から、<br/>出題される全ての国の首都の覚え方の例をダウンロードできます!</p>}

                            <FontTitle className={styles['training-header']}>
                                  {trainingMode === TrainingMode.nationalCapitals ?
                                      <>何か国テストしますか？</>
                                    : <>
                                        {trainingMode === TrainingMode.number && <>何桁</>}
                                        {trainingMode === TrainingMode.word && <>単語をいくつ</>}
                                        {trainingMode === TrainingMode.alphabet && <>アルファベットをいくつ</>}
                                        {trainingMode === TrainingMode.nameAndFace && <>何人</>}
                                        {trainingMode === TrainingMode.circleRatio && <>何桁</>}
                                        記憶しますか？
                                      </>
                                }
                            </FontTitle>
                            <TrainingChoiceList trainingMode={trainingMode} changeChoiceOfNumber={changeChoiceOfNumber} changeOnlyAnswerMode={changeOnlyAnswerMode} isPaid={isPaid} changeRegionSelection={changeRegionSelection} changeAllRegionMode={changeAllRegionMode} isAllRegion={isAllRegion} />
                            {((trainingMode !== TrainingMode.circleRatio && trainingMode !== TrainingMode.nationalCapitals && numberOfMemorization === null)
                                || (trainingMode === TrainingMode.circleRatio && (isAnswerOnlyMode === undefined || numberOfMemorization === null))
                                || (trainingMode === TrainingMode.nationalCapitals && (isAllRegion === undefined || numberOfMemorization === null || (isAllRegion === false && !regionSelection)))) ? (
                                <GrayWrapButton className={styles.button} disabled>スタート！</GrayWrapButton>
                            ) : (
                                <PrimaryWrapButton className={styles.button} onClick={submitSetting}>スタート！</PrimaryWrapButton>
                            )}
                            {trainingMode === TrainingMode.nameAndFace &&
                                <p>photos by <Anchor href="https://generated.photos/">Generated Photos</Anchor></p>
                            }
                        </Container>
                    </div>
                }

                {trainingMode && trainingPhase === TrainingPhase.waiting &&
                    <div className={styles['training-wrap']}>
                        <Container className={styles['training-content']} >
                            <FontTitle className={styles['training-header']}>記憶開始まで</FontTitle>
                            <FontTitle className={styles['training-waitingtime']}><FontMonospacedNum>{remainingWaitingTimeStr}</FontMonospacedNum></FontTitle>
                        </Container>
                    </div>
                }

                {trainingMode && trainingPhase === TrainingPhase.memorization &&
                    <div className={styles['training-wrap']}>
                        <Container className={styles['training-content']} >
                            <FontTitle className={styles['training-header']}>1分間で記憶してください</FontTitle>
                            <dl className={styles['time-list']}><dt>残り時間</dt><dd><FontMonospacedNum>{remainingMemorizationTimeStr}</FontMonospacedNum></dd></dl>
                            <TrainingArea trainingMode={trainingMode} trainingPhase={trainingPhase} memorizationFaceImages={memorizationFaceImages} memorizationNames={memorizationNames} correctAnswers={correctAnswers} correctNationalCapitals={correctNationalCapitals} myAnswers={myAnswers} currentPage={currentPage} changePrePage={changePrePage} changeNextPage={changeNextPage} changeMyAnswer={changeMyAnswer} moveFocus={moveFocus} />
                            <FontTitle className={styles['training-comment']}>記憶が終わったら [回答する] ボタンを押してください</FontTitle>
                            <PrimaryWrapButton className={styles.button} onClick={submitMemorization}>回答する</PrimaryWrapButton>
                        </Container>
                    </div>
                }

                {trainingMode && trainingPhase === TrainingPhase.recall &&
                    <div className={styles['training-wrap']}>
                        <Container className={styles['training-content']} >
                            <FontTitle className={styles['training-header']}>
                               {trainingMode === TrainingMode.nationalCapitals ?
                                   <>首都を入力してください</>
                                 : <>
                                   覚えた
                                   {trainingMode === TrainingMode.number && <>数字</>}
                                   {trainingMode === TrainingMode.word && <>単語</>}
                                   {trainingMode === TrainingMode.alphabet && <>内容</>}
                                   {trainingMode === TrainingMode.nameAndFace && <>名前</>}
                                   {trainingMode === TrainingMode.circleRatio && <>数字</>}
                                   を入力してください
                                 </>
                                }
                            </FontTitle>
                            <dl className={styles['time-list']}><dt>残り時間</dt><dd><FontMonospacedNum>{remainingRecallTimeStr}</FontMonospacedNum></dd></dl>
                            <TrainingArea trainingMode={trainingMode} trainingPhase={trainingPhase} correctAnswers={correctAnswers} correctNationalCapitals={correctNationalCapitals} recallFaceImages={recallFaceImages} recallInputRefs={recallInputRefs} myAnswers={myAnswers} currentPage={currentPage} changePrePage={changePrePage} changeNextPage={changeNextPage} changeMyAnswer={changeMyAnswer} moveFocus={moveFocus} />
                            <FontTitle className={styles['training-comment']}>回答が終わったら [結果を見る] ボタンを押してください</FontTitle>
                            {recallButtonFlg ? (<PrimaryWrapButton className={styles.button} onClick={submitRecall}>結果を見る</PrimaryWrapButton>)
                                : (<GrayWrapButton className={styles.button} onClick={submitRecall} disabled >結果を見る</GrayWrapButton>)}
                        </Container>
                    </div>
                }

                {trainingMode && trainingPhase === TrainingPhase.result &&
                    <div className={styles['training-wrap']}>
                        <Container className={styles['training-content']} >
                            <FontTitle className={styles['check-header'] + ' ' + styles['color-blue']}>
                                {trainingMode === TrainingMode.nationalCapitals ? (<FontMonospacedNum className={styles['fraction']}>{numberOfCorrect}<span>{correctNationalCapitals.length}<FontTitle domType='span' className={styles['unit']}>個</FontTitle></span></FontMonospacedNum>)
                                    : (<FontMonospacedNum className={styles['fraction']}>{numberOfCorrect}<span>{numberOfMemorization}<FontTitle domType='span' className={styles['unit']}>個</FontTitle></span></FontMonospacedNum>)}
                                {trainingMode !== TrainingMode.circleRatio && <div className={styles['check-ttl']}>正解！</div>}
                                {trainingMode === TrainingMode.circleRatio && <div className={styles['check-ttl']}>連続正解！</div>}
                            </FontTitle>
                            {numberOfCorrect === numberOfMemorization && // 全問正解時
                                <>
                                    <FontTitle className={styles['training-comment'] + ' ' + styles['color-red']}>パーフェクト、やったゾウ！</FontTitle>
                                    <div className={styles['training-finish-paozo']}><Img fileName="paozo_delighted.png" alt="よろこぶパオゾウ" /></div>
                                </>
                            }
                            <TrainingArea trainingMode={trainingMode} trainingPhase={trainingPhase} recallFaceImages={recallFaceImages} correctAnswers={correctAnswers} correctNationalCapitals={correctNationalCapitals} myAnswers={myAnswers} currentPage={currentPage} changePrePage={changePrePage} changeNextPage={changeNextPage} changeMyAnswer={changeMyAnswer} moveFocus={moveFocus} />
                            <div className={styles['time-list-group']}>
                                <dl className={styles['time-list']}><dt>記憶時間</dt><dd><FontMonospacedNum>{consumedMemorizationTimeStr}</FontMonospacedNum></dd></dl>
                                <dl className={styles['time-list']}><dt>回答時間</dt><dd><FontMonospacedNum>{consumedRecallTimeStr}</FontMonospacedNum></dd></dl>
                            </div>
                            <Button buttonWrap={true} className={styles.button} onClick={submitAgain}>もう一回やる</Button>
                            <TwitterShareButton url={shareUrl} title={title}>
                                <TwitterIcon size={32} round />
                            </TwitterShareButton>
                        </Container>

                    </div>
                }
                <Footer isLogin={isLogin} className={styles['footer']} />
            </div>
            <PointModal
                modalIsOpen={modalIsOpen}
                experiencePoint={experiencePoint}
                addedExperiencePoint={addedExperiencePoint}
                clickButton={closeModal}
            />
        </React.Fragment>
    );
};

export default TrainingTemplate;
