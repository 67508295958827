import React from 'react';
import MaintenanceTemplate from '../../templates/MaintenanceTemplate';
// CSSはインポートした順に生成され、後にインポートしたCSSの方が優先されるので、
// まず他のコンポーネントや共通CSSをインポートして、その後でコンポーネントごとのCSSをインストールすること
// つまり、styles.module.cssのインポートはインポート文の複数行のうちの最後に置いておくのが安全
// import styles from './styles.module.css';

export type MaintenancePageProps = {
    children?: never;
};


const MaintenancePage : React.FC<MaintenancePageProps> = () => {
    return (
        <MaintenanceTemplate/>
    );
};

export default MaintenancePage;
