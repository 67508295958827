import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit'
import type { RootState } from '../app/store'
import { Auth } from 'aws-amplify';
import {
    SendPasswordPhase,
} from '../../redux/constant';
import {
    isValidPasswordCharset,
} from '../../lib/textUtils';


interface ResetPasswordState {
    [key: string]: any;

    resetPasswordPhase: SendPasswordPhase,

    email: string;
    confirmCode: string,
    newPassword: string,
    showPwdFlag: boolean;

    emailValidationMessage: string;
    confirmCodeValidationMessage: string;
    newPasswordValidationMessage: string;
}

export const initialState: ResetPasswordState = {
    resetPasswordPhase: SendPasswordPhase.sendCode,

    email: '',
    confirmCode: '',
    newPassword: '',
    showPwdFlag: false,

    emailValidationMessage: '',
    confirmCodeValidationMessage: '',
    newPasswordValidationMessage: '',
};

export interface SubmitEmailParams {
    email: string,
    emailValidationMessage: string,
}

export const submitEmail = createAsyncThunk(
  'resetPasswordSlice/submitEmail',
    async (submitEmailParams: SubmitEmailParams, thunkAPI) => {
        const {
            email,
            emailValidationMessage,
        } = submitEmailParams;

        if (email === '') {
            const payload = {
                email,
                emailValidationMessage: 'Emailが空です',
            };

            await thunkAPI.dispatch(resetPasswordSlice.actions.changeEmail(payload));
            throw new Error('Emailが空です');
        }

        if (emailValidationMessage !== '') {
            throw new Error(emailValidationMessage);
        }

        try {
            await Auth.forgotPassword(email);
        } catch (e: unknown) {
            if (e instanceof Error) {
                let msg = e.message;
                if (e.name === 'UserNotFoundException') {
                    msg = 'このメールアドレスの会員は存在しません';
                }
                thunkAPI.dispatch(resetPasswordSlice.actions.changeEmailValidationMessage(msg));
                throw new Error(msg);
            }
        }
    }
);

export interface SubmitNewPasswordParams {
    email: string,
    confirmCode: string,
    newPassword: string,
    confirmCodeValidationMessage: string,
    newPasswordValidationMessage: string,
}

export const submitNewPassword = createAsyncThunk(
  'resetPasswordSlice/submitNewPassword',
    async (submitNewPasswordParams: SubmitNewPasswordParams, thunkAPI) => {
        const {
            email,
            confirmCode,
            newPassword,
            confirmCodeValidationMessage,
            newPasswordValidationMessage,
        } = submitNewPasswordParams;

        let isValid = true;
        if (confirmCode === '') {
            await thunkAPI.dispatch(resetPasswordSlice.actions.changeConfirmCode(''));
            isValid = false;
        }

        if (newPassword === '') {
            await thunkAPI.dispatch(resetPasswordSlice.actions.changeNewPassword(''));
            isValid = false;
        }

        if (confirmCodeValidationMessage !== '') {
            throw new Error(confirmCodeValidationMessage);
        }

        if (newPasswordValidationMessage !== '') {
            throw new Error(newPasswordValidationMessage);
        }

        if (!isValid) {
            throw new Error('入力に誤りがあります');
        }

        try {
            await Auth.forgotPasswordSubmit(email, confirmCode, newPassword);
        } catch (e: unknown) {
            if (e instanceof Error) {
                let msg = e.message;
                if (e.name === 'CodeMismatchException') {
                    msg = '確認コードが正しくありません';
                }

                thunkAPI.dispatch(resetPasswordSlice.actions.changeNewPasswordValidationMessage(msg));
            }

            return Promise.reject();
        }
        return Promise.resolve();
    }
);

interface IChangeEmail {
    email: string;
    emailValidationMessage: string;
}

export const resetPasswordSlice = createSlice({
    name: 'resetPassword',
    initialState,
    reducers: {
        changeEmail: (state, action: PayloadAction<IChangeEmail>) => {
            const { email, emailValidationMessage } = action.payload;

            state.email = email;
            state.emailValidationMessage = emailValidationMessage;
        },
        changeConfirmCode: (state, action: PayloadAction<string>) => {
            const confirmCode = action.payload.trim();

            state.confirmCode = confirmCode;
            if (confirmCode === '') {
               state.confirmCodeValidationMessage = '確認コードが空です';
            } else {
                state.confirmCodeValidationMessage = '';
            }
        },
        changeNewPassword: (state, action: PayloadAction<string>) => {
            const newPassword = action.payload;
            state.newPassword = newPassword;

            if (!isValidPasswordCharset(newPassword)) {
                state.newPasswordValidationMessage = 'パスワードは8文字以上で設定してください';
            } else {
                state.newPasswordValidationMessage = '';
            }
        },
        changeShowPwdFlag: (state, action: PayloadAction<boolean>) => {
            state.showPwdFlag = action.payload;
        },
        changeEmailValidationMessage: (state, action: PayloadAction<string>) => {
            state.emailValidationMessage = action.payload;
        },
        changeNewPasswordValidationMessage: (state, action: PayloadAction<string>) => {
            state.newPasswordValidationMessage = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(submitEmail.fulfilled, (state, action) => {
            state.resetPasswordPhase = SendPasswordPhase.newPassword;
        });

        builder.addCase(submitNewPassword.fulfilled, (state, action) => {
            state.resetPasswordPhase = SendPasswordPhase.postprocess;
        });
    },
});

export const {
    changeEmail,
    changeConfirmCode,
    changeNewPassword,
    changeShowPwdFlag,
    changeEmailValidationMessage,
    changeNewPasswordValidationMessage,
 } = resetPasswordSlice.actions;

export const selectResetPasswordState = (state: RootState) => {
    return {
        resetPasswordPhase: state.resetPassword.resetPasswordPhase,
        email: state.resetPassword.email,
        confirmCode: state.resetPassword.confirmCode,
        newPassword: state.resetPassword.newPassword,
        showPwdFlag: state.resetPassword.showPwdFlag,

        emailValidationMessage: state.resetPassword.emailValidationMessage,
        confirmCodeValidationMessage: state.resetPassword.confirmCodeValidationMessage,
        newPasswordValidationMessage: state.resetPassword.newPasswordValidationMessage,
    };
};

export default resetPasswordSlice.reducer;
