import { configureStore } from '@reduxjs/toolkit'
import {
    combineReducers,
} from 'redux';
import signupReducer, {
    initialState as signupInitialState,
} from '../slices/signupSlice'
import loginReducer, {
    initialState as loginInitialState,
} from '../slices/loginSlice'
import paymentReducer, {
    initialState as paymentInitialState,
} from '../slices/paymentSlice'
import lessonReducer, {
    initialState as lessonInitialState,
} from '../slices/lessonSlice'
import trainingReducer, {
    initialState as trainingInitialState,
} from '../slices/trainingSlice'
import contactReducer, {
    initialState as contactInitialState,
} from '../slices/contactSlice'
import setupProfileReducer, {
    initialState as setupProfileInitialState,
} from '../slices/setupProfileSlice'
import resetPasswordReducer, {
    initialState as resetPasswordInitialState,
} from '../slices/resetPasswordSlice'
import withdrawalReducer, {
    initialState as withdrawalInitialState,
} from '../slices/withdrawalSlice';
import changePasswordReducer, {
    initialState as changePasswordInitialState,
} from '../slices/changePasswordSlice';

// ログアウト時に状態をリセットできるようにする
// https://stackoverflow.com/questions/59061161/how-to-reset-state-of-redux-store-when-using-configurestore-from-reduxjs-toolki
const combinedReducer = combineReducers({
    signup: signupReducer,
    login: loginReducer,
    payment: paymentReducer,
    lesson: lessonReducer,
    training: trainingReducer,
    contact: contactReducer,
    setupProfile: setupProfileReducer,
    resetPassword: resetPasswordReducer,
    withdrawal: withdrawalReducer,
    changePassword: changePasswordReducer,
});

const rootReducer = (state:any, action:any) => {
    if (action.type === 'login/resetAllStates') {
        state = undefined;
    }
    return combinedReducer(state, action);
};

export const storeFactory = () => {
    return configureStore({
        reducer: rootReducer,
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware({
                serializableCheck: {
                    // Set型はシリアライズできないので対象から外す
                    ignoredActions: ['login/changeLessonDoneSet'],
                    ignoredPaths: ['login.lessonDoneSet'],
                },
            }),
    });
};

const store = storeFactory();

// selectAppSelectorの単体テスト用
// あるSliceで状態が増えた時に、他のSliceのテストファイルまで修正するのはコストが大きいし、本質的な変更ではないため。
export const combinedInitialState = {
    signup: {
        ...signupInitialState,
    },
    login: {
        ...loginInitialState,
    },
    payment: {
        ...paymentInitialState,
    },
    lesson: {
        ...lessonInitialState,
    },
    training: {
        ...trainingInitialState,
    },
    contact: {
        ...contactInitialState,
    },
    setupProfile: {
        ...setupProfileInitialState,
    },
    resetPassword: {
        ...resetPasswordInitialState,
    },
    withdrawal: {
        ...withdrawalInitialState,
    },
    changePassword: {
        ...changePasswordInitialState,
    },
};

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

export default store;
