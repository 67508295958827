import React from 'react';
import templateProperties from '../../common/template-properties.module.css';
import DoorwayLayout from '../../layouts/DoorwayLayout';
import DoorwayFormLayout from '../../layouts/DoorwayFormLayout';
import { ErrorMessageBar } from '../../atoms/MessageBar';
import Textlabel from '../../atoms/Textlabel';
import Textbox from '../../atoms/Textbox';
import PasswordBox from '../../molecules/PasswordBox';
import InnerLink from '../../atoms/InnerLink';
import Anchor from '../../atoms/Anchor';
import { LogoFacebook, LogoGoogle } from '../../atoms/Img';
import Button from '../../atoms/Button';
import { PrimaryWrapButton, GrayWrapButton } from '../../atoms/Button';
import awsConfig from '../../../aws-exports.js';
// CSSはインポートした順に生成され、後にインポートしたCSSの方が優先されるので、
// まず他のコンポーネントや共通CSSをインポートして、その後でコンポーネントごとのCSSをインストールすること
// つまり、styles.module.cssのインポートはインポート文の複数行のうちの最後に置いておくのが安全
import styles from './styles.module.css';

Object.assign(styles, templateProperties);

export type LoginTemplateProps = {
    email: string;
    password: string;
    showPwdFlag: boolean;

    loginValidationMessage: string;

    changeEmail: (event: React.ChangeEvent<HTMLInputElement>) => void;
    changePassword: (event: React.ChangeEvent<HTMLInputElement>) => void;
    toggleShowPwdFlag: (event: React.MouseEvent<HTMLButtonElement>) => void;
    submit: (event: React.MouseEvent<HTMLButtonElement>) => void;

    children?: never;
};

const LoginTemplate : React.FC<LoginTemplateProps> = ({
    email,
    password,
    showPwdFlag,

    loginValidationMessage,

    changeEmail,
    changePassword,
    toggleShowPwdFlag,
    submit,
}) => {
    const CognitoDomain = awsConfig.oauth.domain;
    const CognitoAppClientID = awsConfig.aws_user_pools_web_client_id;
    const RedirectURI = awsConfig.oauth.redirectSignIn;
    const facebookRedirectUrl = `https://${CognitoDomain}/oauth2/authorize?identity_provider=Facebook&redirect_uri=${RedirectURI}&response_type=code&client_id=${CognitoAppClientID}`;
    const googleRedirectUrl = `https://${CognitoDomain}/oauth2/authorize?identity_provider=Google&redirect_uri=${RedirectURI}&response_type=code&client_id=${CognitoAppClientID}`;

    return (
      <DoorwayLayout className={ styles['template-properties-local'] }>
        <DoorwayFormLayout>
        <div className={styles['login-standard']}>
        { loginValidationMessage && <ErrorMessageBar>{loginValidationMessage}</ErrorMessageBar> }
          <div className={styles['form-component-wrap']}>
            <Textlabel htmlFor="user-email">メールアドレス</Textlabel>
            <Textbox className='' placeholder="Email" id="user-email" value={email} onChange={changeEmail}/>
          </div>
          <div className={styles['form-component-wrap']}>
            <Textlabel htmlFor="user-pw">パスワード</Textlabel>
            <PasswordBox className={styles['password-box']} id="user-pw" value={password} showPwdFlag={showPwdFlag} onChange={changePassword} toggleShowPwdFlag={toggleShowPwdFlag} />
          </div>
          <p className={styles['forget-pw-link']}><InnerLink to="/forgot-password">パスワードを忘れた方はこちら</InnerLink></p>
          { ( email && password && loginValidationMessage === "") ? (
            <PrimaryWrapButton className={styles.button} type="submit" onClick={submit}>ログイン</PrimaryWrapButton>
          ) : (
            <GrayWrapButton className={styles.button} type="submit" disabled>ログイン</GrayWrapButton>
          )}
        </div>

        <div className={styles['-other']}>
          <div className={styles['login-other-txt']}>または</div>

          <div className={styles['form-component-wrap']+' '+styles['login-other-item']}>
          <Anchor href={facebookRedirectUrl}><Button className={styles['button']}><LogoFacebook />Facebookでログイン</Button></Anchor>
          </div>
          <div className={styles['form-component-wrap']+' '+styles['login-other-item']}>
          <Anchor href={googleRedirectUrl}><Button className={styles['button']}><LogoGoogle />Googleでログイン</Button></Anchor>
          </div>
        </div>
        </DoorwayFormLayout>
        <p className="sign-up-link"><InnerLink to="/signup">新規会員登録はこちら</InnerLink></p>
      </DoorwayLayout>
  );
};

export default LoginTemplate;
