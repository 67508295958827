import React from 'react';
import { CourseColor } from '../../../config/course';
import { CorrectAnswer } from '../../../lib/domSlide';
import { FillInTheBlankLessonSlide } from '../../../config/course';
import SlidePracticeBackground from '../../atoms/SlidePracticeBackground';
import FontTitle from '../../atoms/FontTitle';
import Textbox from '../../atoms/Textbox';
import { PrimaryButton } from '../../atoms/Button';
// CSSはインポートした順に生成され、後にインポートしたCSSの方が優先されるので、
// まず他のコンポーネントや共通CSSをインポートして、その後でコンポーネントごとのCSSをインストールすること
// つまり、styles.module.cssのインポートはインポート文の複数行のうちの最後に置いておくのが安全
import styles from './styles.module.css';

export type SlidePracticeFillInTheBlankProps = {
    pageIndex: number;
    slideColor: CourseColor;
    isCheckingAnswers: boolean;
    myAnswers: Array<CorrectAnswer>;
    updateMyAnswersWithIndex: (index:number) => (e: React.ChangeEvent<HTMLInputElement>) => void;
    updateIsCheckingAnswersTrue: (e: React.MouseEvent<HTMLButtonElement>) => void;
} & Omit<FillInTheBlankLessonSlide, 'type'>;


const SlidePracticeFillInTheBlank : React.FC<SlidePracticeFillInTheBlankProps> = ({
    pageIndex,
    slideColor,
    memorizationTitle,
    recallTitle,
    resultTitle = '結果を判定します',
    correctAnswers,
    myAnswers,
    isCheckingAnswers,

    updateMyAnswersWithIndex,
    updateIsCheckingAnswersTrue,
}) => {
    // CSS準備
    let titleClass = styles['dom-slide-title'];           // タイトル設定
    let answerAreaClass = styles['dom-slide-answerarea']; // 問題・回答エリア設定
    let answerColumnClass = styles['input-item'];         // 問題・回答欄設定
    let buttonAreaClass = styles['button-area'];          // ボタン設定

    return (
        <div className={ styles['slide-practice-histrical-dates'] }>
            <SlidePracticeBackground bgColor={slideColor} className={styles['dom-slide']}>
                {/* 記憶画面 */}
                { pageIndex === 0 &&
                <div className={styles['dom-slide-area']}>
                    <FontTitle className={titleClass}>{memorizationTitle}</FontTitle>
                    <div className={styles['dom-slide-content']}>
                        <div className={answerAreaClass}>
                            {correctAnswers.map((correctAnswer, idx) => {
                                return (
                                    <div className={styles['item-group']} key={idx}>
                                        <div className={styles['label']}>{correctAnswer.label}</div>
                                        <div className={styles['sentence']}>
                                            <span className={styles['plain-text']}>{correctAnswer.sentence}</span>
                                            <span className={styles['keyword']}>{correctAnswer.words[0]}</span>
                                            <span className={styles['plain-text']}>{correctAnswer.sentence2}</span>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
                }

                {/* 回答画面 */}
                { pageIndex === 1 && !isCheckingAnswers &&
                <div className={styles['dom-slide-area']}>
                    <FontTitle className={titleClass}>{recallTitle}</FontTitle>
                    <div className={styles['dom-slide-content']}>
                        <div className={answerAreaClass}>
                            {correctAnswers.map((correctAnswer, idx) => {
                                // 正答の文字数
                                const numOfAnswerCharactors = correctAnswer.words[0].length;
                                return (
                                    <div className={styles['item-group']} key={idx}>
                                      <div className={styles['label']}>{correctAnswer.label}</div>
                                      <div className={styles['sentence']}>
                                        <span className={styles['plain-text']}>{correctAnswer.sentence}</span>
                                        <Textbox
                                            key={idx}
                                            className={answerColumnClass + ' ' + styles['answer-width-'+ numOfAnswerCharactors]}
                                            value={myAnswers[idx]?.words[0] || []}
                                            onChange={updateMyAnswersWithIndex(idx)}
                                            maxLength={numOfAnswerCharactors}
                                        />
                                        <span className={styles['plain-text']}>{correctAnswer.sentence2}</span>
                                    </div>
                                    </div>
                                );
                            })}
                        </div>
                        <div className={buttonAreaClass}>
                            <PrimaryButton className={styles['button']} onClick={updateIsCheckingAnswersTrue}>答え合わせする</PrimaryButton>
                        </div>
                    </div>
                </div>
                }

                {/* 結果画面 */}
                { pageIndex === 1 && isCheckingAnswers &&
                <div className={styles['dom-slide-area']}>
                    <FontTitle className={titleClass}>{resultTitle}</FontTitle>
                    <div className={styles['dom-slide-content']}>
                        <div className={answerAreaClass}>
                            {correctAnswers.map((correctAnswer, idx) => {
                                // 回答の正誤判定
                                const value = myAnswers[idx]?.words[0] || ' ';
                                const resultClass = (correctAnswer.words.includes(value))? styles['correct'] : styles['incorrect'];

                                // 正答の文字数
                                const numOfAnswerCharactors = correctAnswer.words[0].length;
                                const doubleByteSpace = '　' // 半角にすると行の間隔が崩れてしまう。IGNORE_TERM_CONSISTENCY_RULES
                                return (
                                    <div className={styles['item-group']} key={idx}>
                                        <div className={styles['label']}>{correctAnswer.label}</div>
                                        <div className={styles['sentence']}>
                                            <span className={styles['plain-text']}>{correctAnswer.sentence}</span>
                                            <span className={answerColumnClass + ' ' + styles['answer-width-'+ numOfAnswerCharactors] + ' ' + resultClass}>{myAnswers[idx]?.words[0] || doubleByteSpace}</span>
                                            <span className={styles['plain-text']}>{correctAnswer.sentence2}</span>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
                }
            </SlidePracticeBackground>
        </div>
    );
};

export default SlidePracticeFillInTheBlank;
