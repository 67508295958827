import React, {
    useEffect,
    useRef,
    // useCallback,
    RefObject,
    createRef,
} from 'react';
import {
    TrainingMode,
    TrainingPhase,
} from '../../../redux/constant';
import TrainingTemplate from '../../templates/TrainingTemplate';
import { useNavigate } from 'react-router-dom';
import { useAppSelector, useAppDispatch } from '../../../redux/app/hooks';
import {
    initialRemainingWaitingTimeMiliSec,
    initialRemainingMemorizationTimeMiliSec,

    initializeWithTrainingMode,
    updateTrainingPhase,

    updateNumberOfMemorization,
    updateAnswerOnlyMode,
    updateRegionSelection,
    updateAllRegionMode,

    updateCurrentPage,
    updateMyAnswers,

    updateTrainingModalIsOpen,
    updateRecallButtonFlg,

    updateRemainingWaitingTimeMiliSec,
    updateRemainingMemorizationTimeMiliSec,
    updateRemainingRecallTimeMiliSec,

    submitRecallAndTransformAlphaNumeralToOneByte,

    updateExperienceIsAdded,

    generateCorrectAnswersRandomly,
    generateCorrectNamesAndFacesRandomly,

    addExperience,

    selectTrainingState,
    generateCorrectNationalCapitalsRandomly,
} from '../../../redux/slices/trainingSlice';
import {
    selectLoginState,
} from '../../../redux/slices/loginSlice';
import { selectPaymentState } from '../../../redux/slices/paymentSlice';
import { useIdleTimer } from 'react-idle-timer'


export type TrainingPageProps = {
    chosenTrainingMode?: TrainingMode;
};

export const formatTimeStr = (remainingTimeMiliSec: number) => {
    const ceiledSec = Math.ceil(remainingTimeMiliSec / 1000);
    const remainingTimeMinute = Math.floor(ceiledSec / 60);
    const remainingTimeSec = ceiledSec - remainingTimeMinute * 60;
    return `${remainingTimeMinute}:${String(remainingTimeSec).padStart(2, '0')}`
};


export const formatConsumedTimeStr = (consumedTimeMiliSec: number) => {
    const flooredSec = Math.floor(consumedTimeMiliSec / 1000);
    const consumedTimeMinute = Math.floor(flooredSec / 60);
    const consumedTimeSec = flooredSec - consumedTimeMinute * 60;
    return `${consumedTimeMinute}:${String(consumedTimeSec).padStart(2, '0')}`
};

const TrainingPage: React.FC<TrainingPageProps> = ({
    chosenTrainingMode,
}) => {
    const navigate = useNavigate();

    const {
        trainingMode: stateTrainingMode,
        trainingPhase,

        numberOfMemorization,
        isAnswerOnlyMode,
        isAllRegion,
        regionSelection,
        numberOfCorrect,

        correctAnswers,
        correctNationalCapitals,
        myAnswers,

        currentPage,
        trainingModalIsOpen,
        recallButtonFlg,

        recallFaceImages,
        memorizationNames,
        memorizationFaceImages,

        initialRemainingRecallTimeMiliSec,
        remainingWaitingTimeMiliSec,
        remainingMemorizationTimeMiliSec,
        remainingRecallTimeMiliSec,

        experienceIsAdded,
    } = useAppSelector(selectTrainingState);

    const {
        sub,
        isLogin,
        isSocialLogin,
        experiencePoint,
        addedExperiencePoint,
        preferredUsername,
    } = useAppSelector(selectLoginState);

    const {
        isPaid,
    } = useAppSelector(selectPaymentState);

    const dispatch = useAppDispatch();

    // stateTrainingModeとchosenTrainingMode(URL由来で決定したモード)が違っていたら、URLのほうを優先する。
    // モードが変わった場合に、状態をリセットする
    const trainingMode = chosenTrainingMode || stateTrainingMode;
    if (trainingMode !== stateTrainingMode) {
        dispatch(initializeWithTrainingMode(trainingMode));
    }

    // 課金しておらず、かつ単語以外の種目を選択している場合はマイページに戻す
    // = 一般会員は単語のみは選択可能
    useEffect(() => {
        const trainingWhitelistWord = trainingMode === TrainingMode.word;
        const trainingWhitelistCircleRatio = trainingMode === TrainingMode.circleRatio && (typeof isAnswerOnlyMode === 'undefined' || isAnswerOnlyMode);

        if (!isPaid && !trainingWhitelistWord && !trainingWhitelistCircleRatio) {
            navigate('/mypage#training-list');
        }
    });

    const addExperienceParams = {
        sub,
        trainingMode,
        numberOfCorrect,
    };

    const submitRecall = () => {
        pauseRecallTimer();
        dispatch(submitRecallAndTransformAlphaNumeralToOneByte());
        dispatch(updateCurrentPage(1));
    };

    const {
        start: startRecallTimer,
        pause: pauseRecallTimer,
        getRemainingTime: getRecallTimerRemainingTime,
        // isIdle: isIdleRecallTimer,
    } = useIdleTimer({
        events: [],
        onIdle: submitRecall,
        timeout: initialRemainingRecallTimeMiliSec,
        startManually: true,
        stopOnIdle: true,
        crossTab: false,
    });

    const submitMemorization = () => {
        pauseMemorizationTimer();
        dispatch(updateTrainingPhase(TrainingPhase.recall));
        startRecallTimer();
        dispatch(updateCurrentPage(1));
    };

    const {
        start: startMemorizationTimer,
        pause: pauseMemorizationTimer,
        getRemainingTime: getMemorizationTimerRemainingTime,
        // isIdle: isIdleMemorizationTimer,
    } = useIdleTimer({
        events: [],
        onIdle: submitMemorization,
        timeout: initialRemainingMemorizationTimeMiliSec,
        startManually: true,
        stopOnIdle: true,
        crossTab: false,
    });


    const onIdleWaitingTimer = () => {
        dispatch(updateTrainingPhase(TrainingPhase.memorization));
        startMemorizationTimer();
    }

    const {
        start: startWaitingTimer,
        // pause: pauseWaitingTimer,
        getRemainingTime: getWaitingTimerRemainingTime,
        // isIdle: isIdleWaitingTimer,
    } = useIdleTimer({
        events: [],
        onIdle: onIdleWaitingTimer,
        timeout: initialRemainingWaitingTimeMiliSec,
        startManually: true,
        stopOnIdle: true,
        crossTab: false,
    });

    const intervalId = useRef<number | undefined>(undefined);
    useEffect(() => {
        const tick = () => {
            const wt = getWaitingTimerRemainingTime()
            dispatch(updateRemainingWaitingTimeMiliSec(wt));

            const mt = getMemorizationTimerRemainingTime()
            dispatch(updateRemainingMemorizationTimeMiliSec(mt));

            const rt = getRecallTimerRemainingTime()
            dispatch(updateRemainingRecallTimeMiliSec(rt));
        };

        clearInterval(intervalId.current);
        // ただのsetInterval()はNodeJS.Timerを返してしまうので、代わりにwindow.setInterval()を使用し、intervalIdが返るようにする
        intervalId.current = window.setInterval(tick, 500);

        // アンマウント時のクリーンアップ処理をする関数
        // 別画面に遷移する時に
        return () => {
            clearInterval(intervalId.current);

            // TrainingPageを離れる時にトレーニングに関する状態をリセットする
            dispatch(initializeWithTrainingMode(undefined));
        };
    }, [
        dispatch,
        getWaitingTimerRemainingTime,
        getMemorizationTimerRemainingTime,
        getRecallTimerRemainingTime
    ]);

    const submitSetting = () => {
        if (!trainingMode || !numberOfMemorization) {
            return;
        }

        if (trainingMode === TrainingMode.nameAndFace) {
            const params = {
                numberOfMemorization,
            };

            dispatch(generateCorrectNamesAndFacesRandomly(params));
        } else if (trainingMode === TrainingMode.nationalCapitals) {

            if (isAllRegion === undefined) {
                return;
            }
            const params = {
                isAllRegion,
                regionSelection,
                numberOfMemorization,
            };

            dispatch(generateCorrectNationalCapitalsRandomly(params));

            submitMemorization();
            return;
        } else {
            const params = {
                trainingMode,
                numberOfMemorization,
            };
            dispatch(generateCorrectAnswersRandomly(params));

            if (trainingMode === TrainingMode.circleRatio && isAnswerOnlyMode) {
                submitMemorization();
                return;
            }
        }
        dispatch(updateTrainingPhase(TrainingPhase.waiting));
        startWaitingTimer();
    };

    // 「結果を見る」を1秒待ってから押下可能にする
    useEffect(() => {
        if (trainingPhase === TrainingPhase.recall) {
            const timeoutId = setTimeout(() => {
                dispatch(updateRecallButtonFlg(true));
            }, 1000);

            return () => {
                clearTimeout(timeoutId);
            };
        }

    }, [trainingPhase]);

    const remainingWaitingTimeStr = formatTimeStr(remainingWaitingTimeMiliSec);
    const remainingMemorizationTimeStr = formatTimeStr(remainingMemorizationTimeMiliSec);
    const remainingRecallTimeStr = formatTimeStr(remainingRecallTimeMiliSec);

    const consumedMemorizationTimeStr = formatConsumedTimeStr(initialRemainingMemorizationTimeMiliSec - remainingMemorizationTimeMiliSec);
    const consumedRecallTimeStr = formatConsumedTimeStr(initialRemainingRecallTimeMiliSec - remainingRecallTimeMiliSec);

    const changeChoiceOfNumber = (n: number) => () => {
        dispatch(updateNumberOfMemorization(n));
    };

    const changeOnlyAnswerMode = (flag: boolean) => () => {
        dispatch(updateAnswerOnlyMode(flag));
    }

    const changeAllRegionMode = (flag: boolean) => () => {
        dispatch(updateAllRegionMode(flag));
    }

    const changeRegionSelection = (n: number) => () => {
        dispatch(updateRegionSelection(n));
    }

    const changePrePage = (n: number) => () => {
        if (currentPage === 1) {
            return;
        }
        dispatch(updateCurrentPage(currentPage - 1));
    }

    const changeNextPage = (n: number, maxPage: number) => () => {
        if (n === maxPage) {
            return;
        }
        dispatch(updateCurrentPage(currentPage + 1));
    }

    const recallInputRefs = useRef<RefObject<HTMLInputElement>[]>([]);
    [...new Array(numberOfMemorization)].forEach((_, index) => {
        recallInputRefs.current[index] = createRef<HTMLInputElement>();
    });

    const moveFocus = (index: number) => {
        return (e: React.KeyboardEvent<HTMLInputElement>) => {
            switch (e.code) {
                case 'Backspace': // バックスペース
                    // 文字を消すバックスペースで移動しないようにする
                    if (e.currentTarget.value.length === 0) {
                        // 移動時に移動先の文字を消さないようにする
                        e.preventDefault();
                        recallInputRefs.current[index - 1]?.current?.focus();
                    }
                    break;

                case 'ArrowLeft': // 左
                    // カーソルがinputタグ内で左端にある時左隣の欄に移動する
                    if (e.currentTarget.selectionStart === 0) {
                        e.preventDefault();
                        recallInputRefs.current[index - 1]?.current?.focus();
                    }
                    break;

                case 'ArrowRight': // 右
                    // カーソルがinputタグ内で右端にある時右隣の欄に移動する
                    if (e.currentTarget.selectionStart === e.currentTarget.value.length) {
                        e.preventDefault();
                        recallInputRefs.current[index + 1]?.current?.focus();
                    }
                    break;
            }
        };
    };

    const changeMyAnswer = (index: number) => {
        return (e: React.ChangeEvent<HTMLInputElement> | React.KeyboardEvent<HTMLInputElement>) => {
            let word = (e.target as HTMLInputElement).value;

            // Safariにて、変換中にJavascriptの機能によってフォーカスを移動すると、変換中の文字列がそのまま次のテキストボックスにも引き継がれてしまう。
            // 例えば、「あいう」と打ちながら、1文字入力ごとにフォーカスを移動すると、それぞれのテキストボックスに「あ」「あい」「あいう」と入力される。
            // 引き継がれてしまった文字を捨てて、最後に入力した文字だけ採用するように、slice()を使用している
            if (trainingMode === TrainingMode.number || trainingMode === TrainingMode.alphabet || trainingMode === TrainingMode.circleRatio) {
                word = word.slice(-1);
            }

            const numberOmmitTarget = /[^\d０-９]/g; // IGNORE_TERM_CONSISTENCY_RULES
            const alphabetOmmitTarget = /[^a-zA-Zａ-ｚＡ-Ｚあ-お]/g; // IGNORE_TERM_CONSISTENCY_RULES
            const alphabetConvertTarget = /[あ-お]/g;

            if (trainingMode === TrainingMode.number || trainingMode === TrainingMode.circleRatio) {
                // 半角全角数字以外を削除した結果をwordに代入
                word = word.replace(numberOmmitTarget, '');
            } else if (trainingMode === TrainingMode.alphabet) {

                if (alphabetOmmitTarget.test((e.target as HTMLInputElement).value)) {
                    // 半角全角アルファベット以外を削除した結果をwordに代入
                    word = word.replace(alphabetOmmitTarget, '');
                } else if (alphabetConvertTarget.test((e.target as HTMLInputElement).value)) {
                    // 母音の場合はアルファベットに置換した結果をwordに代入
                    word = word.replace(alphabetConvertTarget, (match) => {
                        const vowels = ['あ', 'い', 'う', 'え', 'お'];
                        const replacements = ['a', 'i', 'u', 'e', 'o'];
                        const index = vowels.indexOf(match);
                        return replacements[index];
                    });
                }

            }

            const payload = {
                index,
                word,
            };

            dispatch(updateMyAnswers(payload));

            // trainingModeに依存しているので注意
            if ((trainingMode === TrainingMode.number || trainingMode === TrainingMode.alphabet || trainingMode === TrainingMode.circleRatio) && (e.target as HTMLInputElement).value.length !== 0) {
                // バックスペースした時には移動しないようにする
                if ((trainingMode === TrainingMode.alphabet && !alphabetOmmitTarget.test((e.target as HTMLInputElement).value)) || ((trainingMode === TrainingMode.number && !numberOmmitTarget.test((e.target as HTMLInputElement).value)))
                    || ((trainingMode === TrainingMode.circleRatio && !numberOmmitTarget.test((e.target as HTMLInputElement).value)))) {
                    // WindowsのEdgeブラウザですぐに次のマスにフォーカスを移してしまうと次のマスにも同じ文字が入ってしまうので、
                    // 一度フォーカスを外して、数十ミリ秒待ってから次のマスにフォーカスを移す
                    recallInputRefs.current[index]?.current?.blur();
                    setTimeout(() => recallInputRefs.current[index + 1]?.current?.focus(), 50);
                } else {
                    // 入力対象外の文字が入った場合にIMEなどの予測候補を出さないよう一瞬フォーカスを外す
                    recallInputRefs.current[index]?.current?.blur();
                    setTimeout(() => recallInputRefs.current[index]?.current?.focus(), 50);
                }
            }
        };
    };

    // 元々はRecallフェーズからResultフェーズに変えるのと同時に経験値の追加を行っていたが、
    // 全角から半角への変換をフェーズの切り換わり時に行うようにしたことによりnumberOfCorrectがその時に確定するので、
    // 経験値追加はResultフェーズになってから行うようにした。
    // 経験値追加処理が何回も発動しないように、trainingPhaseとexperienceIsAddedへの依存を明示している
    // 画面を離れる時のuseEffectによるクリーン処理でinitializeWithTrainingMode(undefined)をしているため、
    // その時にexperienceIsAddedがfalseに戻り、もう一度トレーニングした時には経験値が加算されるようになる
    useEffect(() => {
        if (trainingPhase === TrainingPhase.result && !experienceIsAdded) {
            dispatch(addExperience(addExperienceParams));
            dispatch(updateExperienceIsAdded(true));
        }
    }, [
        trainingPhase,
        experienceIsAdded,
    ]);

    return (
        <TrainingTemplate
            isLogin={isLogin}
            isPaid={isPaid}
            isSocialLogin={isSocialLogin}
            preferredUsername={preferredUsername}
            trainingMode={trainingMode}
            trainingPhase={trainingPhase}

            numberOfMemorization={numberOfMemorization}
            isAnswerOnlyMode={isAnswerOnlyMode}
            regionSelection={regionSelection}
            isAllRegion={isAllRegion}
            numberOfCorrect={numberOfCorrect}

            remainingWaitingTimeStr={remainingWaitingTimeStr}
            remainingMemorizationTimeStr={remainingMemorizationTimeStr}
            remainingRecallTimeStr={remainingRecallTimeStr}

            consumedMemorizationTimeStr={consumedMemorizationTimeStr}
            consumedRecallTimeStr={consumedRecallTimeStr}

            memorizationFaceImages={memorizationFaceImages}
            recallFaceImages={recallFaceImages}
            memorizationNames={memorizationNames}

            correctAnswers={correctAnswers}
            correctNationalCapitals={correctNationalCapitals}
            myAnswers={myAnswers}

            modalIsOpen={trainingModalIsOpen}
            recallButtonFlg={recallButtonFlg}
            experiencePoint={experiencePoint}
            addedExperiencePoint={addedExperiencePoint}

            recallInputRefs={recallInputRefs}
            currentPage={currentPage}

            changeChoiceOfNumber={changeChoiceOfNumber}
            changeOnlyAnswerMode={changeOnlyAnswerMode}
            changeRegionSelection={changeRegionSelection}
            changeAllRegionMode={changeAllRegionMode}
            changeMyAnswer={changeMyAnswer}
            moveFocus={moveFocus}
            changePrePage={changePrePage}
            changeNextPage={changeNextPage}

            submitSetting={submitSetting}
            submitMemorization={submitMemorization}
            submitRecall={submitRecall}
            submitAgain={() => { dispatch(initializeWithTrainingMode(trainingMode)); }}
            closeModal={() => { dispatch(updateTrainingModalIsOpen(false)); }}
        />
    );
};

export default TrainingPage;
