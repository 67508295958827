export function levelToPoint(level: number) : number {
// const levelToPoint = (level) => {
    if (level <= 1) {
        return 0;
    }

    if (level <= 8) {
        return (level - 1) * 50;
    }

    if (level < 30) {
        const f = level / 0.43;
        return Math.floor(f * f);
    }

    if (level === 30) {
        return 5000;
    }

    if (level <= 59) {
        return 5000 + 500 * (level - 30);
    }

    if (level === 60) {
        return 20000;
    }

    if (level <= 70) {
        return 20000 + 600 * (level - 60);
    }

    if (level <= 80) {
        return 26000 + 700 * (level - 70);
    }

    if (level <= 90) {
        return 33000 + 800 * (level - 80);
    }

    if (level < 100) {
        return 41000 + 900 * (level - 90);
    }

    // 100 <= level
    return 50000;
}

export function pointToLevel(point: number) : number {
    if (point === 0) {
        return 1;
    }

    if (point >= levelToPoint(100)) {
        return 100;
    }

    // 両端のケースを通過したので、求める値はどこかのレベルの間となる
    // 求めるレベルxは、
    // levelToPoint(x) <= exp < levelToPoint(x+1)であるようなx
    for (let x = 1; x <= 100; x += 1) {
        if (levelToPoint(x) <= point && point < levelToPoint(x+1)) {
            return x;
        }
    }

    return 100;
};
