import React, { useEffect, useState } from 'react';
import InnerLink from '../../atoms/InnerLink';
import { Helmet } from "react-helmet";
import { imageResources } from '../../../lib/imageResources';
import Radium from 'radium';


const bsaImage = imageResources.get('bsa-image.jpg')?.path;
const bsaImageSp = imageResources.get('bsa-image-sp.jpg')?.path;
const featureImage01 = imageResources.get('feature-image01.jpg')?.path;
const featureImage02 = imageResources.get('feature-image02.jpg')?.path;
const featureImage03 = imageResources.get('feature-image03.jpg')?.path;
const featureImage04 = imageResources.get('feature-image04.jpg')?.path;
const frontLogo = imageResources.get('front-logo.svg')?.path;
const frontLogoWhite = imageResources.get('front-logo-white.png')?.path;
const illustPaoFront = imageResources.get('illust-pao-front.png')?.path;
const illustPaoSide = imageResources.get('illust-pao-side.png')?.path;
const topMovie = imageResources.get('top_movie.mp4')?.path;
const topMvSp = imageResources.get('top_mv_sp.png')?.path;

// 便宜的に残しておく。最終的には、classNameにstyles[]せずに直接クラス名を指定すればよいはず
// const styles : any = {};

export type TopPageTemplateProps = {
    defaultIsLoadedHead?: boolean;
    children?: never;
};

const TopPageTemplate : React.FC<TopPageTemplateProps> = ({
    defaultIsLoadedHead = false,
}) => {
    const [isLoadedHead, setIsLoadedHead] = useState(defaultIsLoadedHead);

    useEffect(() => {
        // クリーンアップ
        return () => {
            setIsLoadedHead(false);
        }
    }, []);

    return (
        <>
          {/* CSSロード後に数十ミリ秒経ってからDOM描画フラグをオンにする */}
          <Helmet
            onChangeClientState={(newState, addedTags, removedTags) => { setTimeout(() => setIsLoadedHead(true), 50); }}
            >
            <link rel="stylesheet" href={`${process.env.PUBLIC_URL}/css/normalize.css`}/>
            <link rel="stylesheet" href={`${process.env.PUBLIC_URL}/css/skeleton.css`}/>
            <link rel="stylesheet" href={`${process.env.PUBLIC_URL}/css/front-page.css`}/>
          </Helmet>

          {/* js/common.jsはRender後のDOMに1回だけ変更を加える (イメージ) */}
          {/* そのため、react-routerの機能でトップページに戻ってきた時に再度発火させる必要があるので、Helmetでそれを実現する。 */}
          {/* 【注意】セキュリティの観点で、<script>で何を埋め込むかは固定的にして、利用者(攻撃者)が任意のコード/URLを埋め込めないようにすること！ */}
            { isLoadedHead && (
                <Helmet>
                  <script src={`${process.env.PUBLIC_URL}/js/common.js`}></script>
                </Helmet>
            )}

          {/* Header
          –––––––––––––––––––––––––––––––––––––––––––––––––– */}
        {
            isLoadedHead && (
          <header className={ "header top-page-template-local" }>
            <div className={ "header-logo-group" }>
              <div className={ "header-logo" }>
                <a href="https://www.memoaca.com">
                  <img className={ "top-page-template-local" } src={frontLogo} alt="メモアカ" />
                </a>
              </div>
              <p className={ "header-copy" }>今すぐ独学で記憶術が学べる</p>
            </div>
            <nav className={ "gnav" }>
              <div className={ "sp-nav-logo" }>
                <InnerLink to="/" className={ "header-logo" }>
                  <img className={ "top-page-template-local" } src={frontLogo} alt="メモアカ" />
                </InnerLink>
              </div>
              <ul className={ "gnav-list" }>
                <li className={ "gnav-item" }>
                  {/* jQueryでスクロール処理をするため、jQueryが発火できるように */}
                  {/* <InnerLink>ではなく<a>としている */}
                  <a href="#feature" className={ "top-page-template-local" }>特徴</a>
                </li>
                <li className={ "gnav-item" }>
                  {/* jQueryでスクロール処理をするため、jQueryが発火できるように */}
                  {/* <InnerLink>ではなく<a>としている */}
                  <a href="#contents" className={ "top-page-template-local" }>コンテンツ一覧</a>
                </li>
                <li className={ "gnav-item" }>
                  {/* jQueryでスクロール処理をするため、jQueryが発火できるように */}
                  {/* <InnerLink>ではなく<a>としている */}
                  <a href="#plan" className={ "top-page-template-local" }>料金プラン</a>
                </li>
                <li className={ "gnav-item gnav-button login" }>
                  <InnerLink to="/login">ログイン</InnerLink>
                </li>
                <li className={ "gnav-item gnav-button touroku" }>
                  <InnerLink to="/signup">無料登録はこちら</InnerLink>
                </li>
              </ul>
            </nav>
            <div className={ "nav_btn" }>
              <span />
              <span />
              <span />
            </div>
          </header>
            )
        }

          {/* main
          –––––––––––––––––––––––––––––––––––––––––––––––––– */}
        {
            isLoadedHead && (
          <main className={ "top-page-template-local" }>
            {/* top
            –––––––––––––––––––––––––––––––––––––––––––––––––– */}
            <section className={ "top" }>
              <div className={ "top-titlearea" }>
                <h1 className={ "top-title font-tit" }>
                  きのうより、
                  <br />
                  <span>覚えられる自分</span>になる
                </h1>
                <p className={ "top-subtitle font-en" }>
                  今すぐ独学で記憶術が学べる
                  <br className={ "d-md-none" } />
                  オンライン学習サービス
                </p>
              </div>
              <div className={ "video-area" }>
                <video
                  src={topMovie}
                  className={ "d-none d-md-block" }
                  autoPlay={true}
                  muted={true}
                  loop={true}
                  playsInline={true}
                  />
                <img
                  src={topMvSp}
                  className={ "d-md-none top-page-template-local" }
                  alt="スマホメインビジュアル"
                  />
              </div>
              <div className={ "illust-area" }>
                <div className={ "fukidashi" }>
                  <p>ぼくと一緒に学ぼう！</p>
                </div>
                <div className={ "illust-box" }>
                  <img className={ "top-page-template-local" } src={illustPaoFront} alt="パオゾーイラスト" />
                </div>
              </div>
            </section>
            {/* about記憶力って？
            –––––––––––––––––––––––––––––––––––––––––––––––––– */}
            <section className={ "about wrapper" }>
              <div className={ "about-box" }>
                <h2 className={ "title font-tit text-center" }>
                  <span>記憶力</span>って<span>成長</span>するの？
                </h2>
                <p className={ "about-text text-center" }>
                  記憶術とは、勉強や日常生活など私達の生活に必要不可欠な記憶を、
                  <br className={ "d-none d-md-inline" } />
                  速く正確に大量に記憶できるようになる技術です。
                  <br />
                  記憶力は生まれつきの才能だと思われがちですが、
                  <br className={ "d-none d-md-inline" } />
                  正しい方法で正しい練習を積めば誰でも向上させることが可能です。
                </p>
                <p className={ "about-copy text-center font-en" }>
                  メモアカには
                  <br className={ "d-md-none" } />
                  <span>記憶術</span>を身につけるために
                  <br />
                  <span>必要な環境が全て</span>揃っています。
                </p>
                <div className={ "recomend text-center" }>
                  <span>こんな方におすすめです</span>
                </div>
                <div className={ "recomend-box" }>
                  <div className={ "recomend-inner" }>
                    <p>
                      記憶術を身につけて
                      <br className={ "d-md-none" } />
                      受験勉強や資格試験に生かしたい
                    </p>
                    <p>
                      記憶術を身につけて
                      <br className={ "d-md-none" } />
                      日常生活やビジネスに生かしたい
                    </p>
                    <p>メモリースポーツを始めてみたい</p>
                  </div>
                </div>
              </div>
              <div className={ "illust-area" }>
                <div className={ "illust-box" }>
                  <img
                    src={illustPaoSide}
                    alt="パオゾーイラスト"
                    className={ "illust top-page-template-local" }
                    />
                </div>
                <div className={ "fukidashi" }>
                  <div className={ "fukidashi-area" }>
                    <p>記憶力がよくなると</p>
                    <p className={ "fukidashi-text" }>
                      <span>● </span>社会や理科、語学学習など
                      <br className={ "d-md-none" } />
                      暗記科目に強くなる
                    </p>
                    <p className={ "fukidashi-text" }>
                      <span>● </span>人の顔と名前が覚えられるようになる
                    </p>
                    <p className={ "fukidashi-text" }>
                      <span>● </span>電話番号やパスワード、
                      <br className={ "d-md-none" } />
                      会社の売上の数字に強くなる
                    </p>
                    <p>など、勉強や日常生活にとても役に立つよ！</p>
                  </div>
                </div>
              </div>
            </section>
            {/* feature特徴
            –––––––––––––––––––––––––––––––––––––––––––––––––– */}
            <section className={ "feature" } id="feature">
              <h2 className={ "title font-tit text-center" }>
                メモアカの
                <span>
                  <span className={ "accent" }>4</span>つの特徴
                </span>
              </h2>
              <div className={ "feature-block" }>
                <div className={ "feature-inner" }>
                  <div className={ "image-area" }>
                    <img
                      src={featureImage01}
                      alt="パソコンを操作する女性の写真"
                      className={ "feature-image top-page-template-local" }
                      />
                  </div>
                  <div className={ "text-area" }>
                    <p className={ "font-en number" }>feature 01</p>
                    <h3 className={ "feature-title" }>
                      誰でもいつでもどこからでも学べて
                      <br />
                      続けやすい
                    </h3>
                    <p className={ "feature-text" }>
                      子どもから大人まで、パソコンやタブレット1台と
                      <br className={ "d-none d-lg-inline" } />
                      インターネット環境さえあれば、
                        <br className={ "d-none d-lg-inline" } />
                      自宅でも移動中でも自分のペースでいつでもどこでも学習できます。
                      <br />
                      1レッスンあたり5〜10分程度で学習できるため、
                      <br className={ "d-none d-lg-inline" } />
                      スキマ時間に取り組めます。
                    </p>
                  </div>
                </div>
              </div>
              <div className={ "feature-block" }>
                <div className={ "feature-inner" }>
                  <div className={ "image-area shadow" }>
                    <img
                      src={featureImage02}
                      alt="オンライン学習の画面"
                      className={ "feature-image top-page-template-local" }
                      />
                  </div>
                  <div className={ "text-area" }>
                    <p className={ "font-en number" }>feature 02</p>
                    <h3 className={ "feature-title" }>良質でわかりやすいスライド</h3>
                    <p className={ "feature-text" }>
                      メモリースポーツのチャンピオン達が培ってきた技術や教えてきた経験を
                      <br className={ "d-none d-lg-inline" } />
                      注ぎ込んでつくった、初心者でも直感的でわかりやすいスライド形式で
                        <br className={ "d-none d-lg-inline" } />
                      学習できます。
                      <br />
                      基本的な記憶術から勉強やメモリースポーツに生かす高度な方法まで
                      <br className={ "d-none d-lg-inline" } />
                      学ぶことができます。
                    </p>
                  </div>
                </div>
              </div>
              <div className={ "feature-block" }>
                <div className={ "feature-inner" }>
                  <div className={ "image-area shadow" }>
                    <img
                      src={featureImage03}
                      alt="オンライン学習の画面"
                      className={ "feature-image top-page-template-local" }
                      />
                  </div>
                  <div className={ "text-area" }>
                    <p className={ "font-en number" }>feature 03</p>
                    <h3 className={ "feature-title" }>
                      成長がわかりやすいトレーニング機能
                    </h3>
                    <p className={ "feature-text" }>
                      何度も挑戦できるトレーニング機能を用意しています。
                      <br />
                      記憶時間は1分と取り組みやすく、
                      <br className={ "d-none d-lg-inline" } />
                      練習する度にポイントが貯まりレベルアップします。
                      <br />
                      練習を通して自分の成長を感じましょう。
                    </p>
                  </div>
                </div>
              </div>
              <div className={ "feature-block" }>
                <div className={ "feature-inner" }>
                  <div className={ "image-area" }>
                    <img
                      src={featureImage04}
                      alt="オンライン学習の画面が写ってるノートパソコン"
                      className={ "feature-image top-page-template-local" }
                      />
                  </div>
                  <div className={ "text-area" }>
                    <p className={ "font-en number" }>feature 04</p>
                    <h3 className={ "feature-title" }>
                      無料から利用でき、
                      <br className={ "d-md-none" } />
                      有料会員も安価な月額制
                    </h3>
                    <p className={ "feature-text" }>
                      初歩的なレッスンの一部は、無料で学習できます。有料会員も月単位で
                      <br className={ "d-none d-lg-inline" } />
                      解約でき、解約費用なども一切ないので、気軽に始めることができます。
                    </p>
                  </div>
                </div>
              </div>
            </section>
            {/* contentsコンテンツ
            –––––––––––––––––––––––––––––––––––––––––––––––––– */}
            <section className={ "contents" } id="contents">
              <h2 className={ "title font-tit text-center" }>
                スライド＆トレーニングで
                <br className={ "d-md-none" } />
                <span>効率よく学べる</span>
              </h2>
              <div className={ "contents-inner wrapper" }>
                <div className={ "contents-box" }>
                  <p className={ "contents-title" }>
                    スライド学習<span>(一例)</span>
                  </p>
                  <div className={ "contents-listblock" }>
                    <ul className={ "contents-list" }>
                      <li>記憶術を学ぶ前に</li>
                      <li>ストーリー法</li>
                      <li>ペグ法</li>
                      <li>場所法</li>
                      <li>数字記憶法</li>
                      <li>年号記憶法</li>
                      <li>アルファベット記憶法</li>
                      <li>顔と名前記憶法</li>
                      <li>英単語記憶法</li>
                      <li>本＆教科書記憶法</li>
                      <li>記憶を長期化する方法</li>
                      <li>メモリースポーツとは</li>
                      <li>トランプ記憶法</li>
                      <li>SDGs記憶</li>
                      <li>世界遺産記憶</li>
                      <li>
                        国旗記憶<sup>※</sup>
                      </li>
                      <li>
                        西洋絵画記憶<sup>※</sup>
                      </li>
                      <li>
                        インド式計算<sup>※</sup>
                      </li>
                      <li>
                        犬記憶<sup>※</sup>
                      </li>
                      <li>
                        猫記憶<sup>※</sup>
                      </li>
                      <li>
                        記憶と生活
                        <br className={ "d-md-none" } />
                        (メンタル・運動・睡眠など)<sup>※</sup>
                      </li>
                      <li>
                        漢字記憶法<sup>※</sup>
                      </li>
                      <li>
                        目隠しキューブについて<sup>※</sup>
                      </li>
                    </ul>
                    <p>※は今後随時公開予定</p>
                  </div>
                  <p className={ "note" }>
                    ＊コンテンツ修正などにより公開していないものがあります
                  </p>
                </div>
                <div className={ "contents-subbox" }>
                  <p className={ "contents-title" }>実践トレーニング</p>
                  <div className={ "subcontents-listblock" }>
                    <ul className={ "contents-list" }>
                      <li>数字を覚えよう</li>
                      <li>アルファベットを覚えよう</li>
                      <li>単語を覚えよう</li>
                      <li>顔と名前を覚えよう</li>
                    </ul>
                  </div>
                  <div className={ "illust-area" }>
                    <div className={ "fukidashi" }>
                      <p>
                        コンテンツは随時
                        <br />
                        改修・追加していくよ！
                      </p>
                    </div>
                    <div className={ "illust-box" }>
                      <img className={ "top-page-template-local" } src={illustPaoFront} alt="パオゾーイラスト" />
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* comment推薦コメント
            –––––––––––––––––––––––––––––––––––––––––––––––––– */}
            {/* <section class="comment">
                  <h2 class="title font-tit text-center"><span>推薦</span>コメント</h2>
                      <div class="comment-area">
                            <div class="comment-inner wrapper">
                                  <div class="comment-card shadow">
                                        <div class="comment-profile">
                                              <img src={commentImage} alt="推薦コメントのプロフィール写真" class="profile-image">
                                                    <p class="profile-name">〇〇 〇〇さん</p>
                                                        <p class="profile-data">肩書き・属性など(◯◯代)</p>
                                                        </div>
                                                        <div class="comment-text">
                                                              <p>
                                                                    テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。(100〜110文字)
                                                                    </p>
                                                                    </div>
                                                                    </div>

                                                                    <div class="comment-card shadow">
                                                                          <div class="comment-profile">
                                                                                <img src={commentImage} alt="推薦コメントのプロフィール写真" class="profile-image">
                                                                                      <p class="profile-name">〇〇 〇〇さん</p>
                                                                                          <p class="profile-data">肩書き・属性など(◯◯代)</p>
                                                                                          </div>
                                                                                          <div class="comment-text">
                                                                                                <p>
                                                                                                      テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。(100〜110文字)
                                                                                                      </p>
                                                                                                      </div>
                                                                                                      </div>
                                                                                                      </div>

                                                                                                      <div class="more-button">
                                                                                                            <p>もっと見る</p>
                                                                                                            </div>

                                                                                                            <div class="comment-inner wrapper">
                                                                                                                  <div class="comment-card shadow">
                                                                                                                        <div class="comment-profile">
                                                                                                                              <img src={commentImage} alt="推薦コメントのプロフィール写真" class="profile-image">
                                                                                                                                    <p class="profile-name">〇〇 〇〇さん</p>
                                                                                                                                        <p class="profile-data">肩書き・属性など(◯◯代)</p>
                                                                                                                                        </div>
                                                                                                                                        <div class="comment-text">
                                                                                                                                              <p>
                                                                                                                                                    テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。(100〜110文字)
                                                                                                                                                    </p>
                                                                                                                                                    </div>
                                                                                                                                                    </div>

                                                                                                                                                    <div class="comment-card shadow">
                                                                                                                                                          <div class="comment-profile">
                                                                                                                                                                <img src={commentImage} alt="推薦コメントのプロフィール写真" class="profile-image">
                                                                                                                                                                      <p class="profile-name">〇〇 〇〇さん</p>
                                                                                                                                                                          <p class="profile-data">肩書き・属性など(◯◯代)</p>
                                                                                                                                                                          </div>
                                                                                                                                                                          <div class="comment-text">
                                                                                                                                                                                <p>
                                                                                                                                                                                      テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。(100〜110文字)
                                                                                                                                                                                      </p>
                                                                                                                                                                                      </div>
                                                                                                                                                                                      </div>
                                                                                                                                                                                      </div>
                                                                                                                                                                                      </div>

            </section> */}
            {/* plan料金プラン
            –––––––––––––––––––––––––––––––––––––––––––––––––– */}
            <section className={ "plan" } id="plan">
              <h2 className={ "title font-tit text-center" }>料金プラン</h2>
              <div className={ "plan-area" }>
                <div className={ "plan-box text-center" }>
                  <h3 className={ "plan-title font-tit" }>一般会員</h3>
                  <div className={ "price-box" }>
                    <p className={ "free-price" }>無料</p>
                  </div>
                  <div className={ "plan-point-area" }>
                    <p className={ "plan-point" }>スライド(最初の3コース)</p>
                    <p className={ "plan-point" }>単語のトレーニング</p>
                    {/* <p class="plan-point">スライド(一部)</p>
                    <p class="plan-point">スライド(一部)</p> */}
                  </div>
                  {/* <p class="plan-point">スライド(一部)スライド(一部)</p> */}
                </div>
                <div className={ "plan-box text-center" }>
                  <h3 className={ "plan-title font-tit" }>有料会員</h3>
                  <div className={ "price-box" }>
                    {/* <p className={ "basic-price" }>
                      <del>
                        月額<span className={ "price font-en" }>1,490</span>円
                        <span className={ "tax" }>(税込)</span>
                      </del>
                    </p>
                    <p className={ "now-price" }>
                      今なら月額<span className={ "price font-en" }>990</span>
                      <span className={ "yen" }>円</span>
                      <span className={ "tax" }>(税込)</span>
                    </p> */}
                    <p className={ "now-price" }>
                        月額<span className={ "price font-en" }>1,490</span>円
                        <span className={ "tax" }>(税込)</span>
                    </p>
                  </div>
                  <div className={ "plan-point-area" }>
                    <p className={ "plan-point" }>全コーススライド</p>
                    <p className={ "plan-point" }>全トレーニング</p>
                    <p className={ "plan-point" }>ダウンロード資料</p>
                    {/* <p class="plan-point">フォーラム</p>
                        <p class="plan-point">スライド(一部)</p>
                        <p class="plan-point">スライド(一部)スライド(一部)</p> */}
                  </div>
                </div>
              </div>
            </section>
            {/* company運営元
            –––––––––––––––––––––––––––––––––––––––––––––––––– */}
            <section className={ "company" } id="administrator">
              <div className={ "wrapper" }>
                <div className={ "company-inner" }>
                  <p className={ "company-title" }>運営元</p>
                  <div className={ "logo-area" }>
                    <img
                      className={ "top-page-template-local" }
                      src={frontLogoWhite}
                      alt="運営元の「株式会社メモアカ」ロゴ"
                      width="240"
                      />
                  </div>
                  <p className={ "company-text" }>
                  今すぐ独学で記憶術が学べるオンライン学習サービス「メモアカ」の運営事業、記憶術・メモリースポーツ・キューブなどの指導を行うスクール事業「メモアカclass」を行っています。2022年9月に前身のBSA(Brain Sports Academy)から株式会社メモアカに社名変更を行いました。
                  </p>
                  <div className={ "bsa-button-area" }>
                    <a
                      href="https://corp.memoaca.com"
                      className={ "bsa-button" }
                      rel="noreferrer"
                      target="_blank"
                      >
                      株式会社メモアカはこちら
                    </a>
                  </div>
                  <div className={ "bsa-button-area" }>
                    <a
                      href="https://class.memoaca.com"
                      className={ "bsa-button" }
                      rel="noreferrer"
                      target="_blank"
                      >
                      メモアカclassはこちら
                    </a>
                  </div>
                </div>
                <div className={ "photo-area" }>
                  <picture>
                    <source
                      media="(max-width:749.999px)"
                      srcSet={bsaImageSp}
                      />
                    <img
                      src={bsaImage}
                      alt="BSAの様子"
                      className={ "company-photo top-page-template-local" }
                      />
                  </picture>
                </div>
              </div>
            </section>
            {/* CTA
            –––––––––––––––––––––––––––––––––––––––––––––––––– */}
            <section className={ "cta" }>
              <div className={ "cta-inner" }>
                <div className={ "cta-box" }>
                  <h2 className={ "cta-title font-tit text-center" }>
                    記憶術を学んで、
                    <br />
                    きのうより覚えられる
                    <br className={ "d-md-none" } />
                    自分になろう！
                  </h2>
                  <div className={ "cta-button-area" }>
                    {/* <InnerLink to="/signup">無料で会員登録</InnerLink> */}
                    <a href ="/signup">無料で会員登録</a>
                  </div>
                </div>
              </div>
            </section>
          </main>
            )
        }

          {/* Footer
          –––––––––––––––––––––––––––––––––––––––––––––––––– */}
        {
            isLoadedHead && (
          <footer className={ "footer top-page-template-local" }>
            <ul className={ "footer-nav-list" }>
              <li className={ "footer-nav-item" }>
                <a href="#administrator" className={ "pseudo-inner-link" }>運営元</a>
              </li>
              <li className={ "footer-nav-item" }>
                <InnerLink to="/notation">特定商取引法に基づく表記</InnerLink>
              </li>
              <li className={ "footer-nav-item" }>
                <InnerLink to="/terms-of-service">利用規約</InnerLink>
              </li>
              <li className={ "footer-nav-item" }>
                <InnerLink to="/contact">お問い合わせ</InnerLink>
              </li>
              <li className={ "footer-nav-item" }>
                <InnerLink to="/privacy-policy">プライバシーポリシー</InnerLink>
              </li>
              <li className={ "footer-nav-item" }>
                <InnerLink to="/news">メモアカに関するお知らせ</InnerLink>
              </li>
            </ul>
            <div className={ "footer-logo" }>
              <img className={ "top-page-template-local" } src={frontLogo} alt="メモアカ" />
            </div>
            <ul className={ "footer-subnav-list" }>
              <li className={ "footer-nav-item" }>
                <a href="https://corp.memoaca.com" rel="noreferrer" target="_blank" className={ "top-page-template-local" }>
                  株式会社メモアカ
                </a>
              </li>
              <li className={ "footer-nav-item" }>
                <a href="https://class.memoaca.com" rel="noreferrer" target="_blank" className={ "top-page-template-local" }>
                  メモアカclass
                </a>
              </li>
              <li className={ "footer-nav-item" }>
                <a href="https://suzuri.jp/memoaca" rel="noreferrer" target="_blank" className={ "top-page-template-local" }>
                  公式オンラインショップ
                </a>
              </li>
              <li className={ "footer-nav-item" }>
                <a href="https://twitter.com/memoacaJP" rel="noreferrer" target="_blank" className={ "top-page-template-local" }>
                  公式Twitter
                </a>
              </li>
              <li className={ "footer-nav-item" }>
                <a
                  href="https://www.youtube.com/channel/UCWrX5Nt6OZPn_Zz7xldXiVQ"
                  rel="noreferrer"
                  target="_blank"
                  className={ "top-page-template-local" }
                  >
                  公式YouTube
                </a>
              </li>
            </ul>
            <p className={ "copyright-area" }>
              <small>© {new Date().getFullYear()} 株式会社メモアカ</small>
            </p>
          </footer>
            )
        }
        </>
    );
};

export default Radium(TopPageTemplate);
