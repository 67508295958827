import React, { ReactNode } from 'react';
// CSSはインポートした順に生成され、後にインポートしたCSSの方が優先されるので、
// まず他のコンポーネントや共通CSSをインポートして、その後でコンポーネントごとのCSSをインストールすること
// つまり、styles.module.cssのインポートはインポート文の複数行のうちの最後に置いておくのが安全
import styles from './styles.module.css';

export type MessageBarProps = {
    kind?: string,
    prefix?: string,
    className?: string,
    children?: ReactNode,
} & JSX.IntrinsicElements['div'];


const MessageBar : React.FC<MessageBarProps> = ({
    kind = '',
    prefix = '',
    className = '',
    children,
    ...props
}) => {
    // class属性を設定
    let classString = styles['message-bar'];
    if (kind) {
        classString = [classString, styles[kind]].join(' ');
    }
    if (className) {
        classString = [classString, className].join(' ');
    }

    return (
        <div
            className={ classString }
            { ...props }
        >
            { prefix }
            { children }
        </div>
    );
};


const messageBarFactory = (kind: string, prefix: string) => {
    return (mProps: Omit<MessageBarProps, 'kind' | 'prefix'>) => {
        return (
            <MessageBar kind={ kind } prefix={ prefix } { ...mProps } />
        );
    };
}

export default MessageBar;
export const ErrorMessageBar = messageBarFactory('error', 'エラー：');
export const AlertMessageBar = messageBarFactory('error', '');
export const SuccessMessageBar = messageBarFactory('success', '');