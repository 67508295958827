import React from 'react';
import { ConstOpenClose, ConstGender, ConstPrefecture } from '../../../redux/constant';
// CSSはインポートした順に生成され、後にインポートしたCSSの方が優先されるので、
// まず他のコンポーネントや共通CSSをインポートして、その後でコンポーネントごとのCSSをインストールすること
// つまり、styles.module.cssのインポートはインポート文の複数行のうちの最後に置いておくのが安全
import styles from './styles.module.css';

export type PulldownListProps = {
    className?: string,
    optionsArray?: Array<string>;
    noValueOptionFlag?: boolean;
    noValueOptionText?: string;
} & JSX.IntrinsicElements['select'];;


const PulldownList : React.FC<PulldownListProps> = ({
    className = '',
    optionsArray = [],
    noValueOptionFlag = false,
    noValueOptionText = '選択してください',
    ...props
}) => {
    // class属性を設定
    let classString = styles['pulldown-list'];
    if (className) {
        classString = [classString, className].join(' ');
    }
    return (
            <select className={classString} { ...props }>
                {noValueOptionFlag && <option value="">{noValueOptionText}</option>}
                {optionsArray.map(((data,index)=><option value={data} key={index}>{data}</option>))}
            </select>
    );
};

const pulldownFactory = (options: string[]) => {
    return (pProps: Omit<PulldownListProps, 'optionsArray'>) => {
        return (
            <PulldownList optionsArray={options} {...pProps} />
        );
    };
}

export default PulldownList;
export const OpenClosePulldown = pulldownFactory(ConstOpenClose);
export const GenderPulldown = pulldownFactory(ConstGender);
export const PrefPulldown = pulldownFactory(ConstPrefecture);
