import React, { ReactNode } from 'react';
// CSSはインポートした順に生成され、後にインポートしたCSSの方が優先されるので、
// まず他のコンポーネントや共通CSSをインポートして、その後でコンポーネントごとのCSSをインストールすること
// つまり、styles.module.cssのインポートはインポート文の複数行のうちの最後に置いておくのが安全
import styles from './styles.module.css';

export type TextareaProps = {
    className?: string,
    placeholder?: string,
    type?: string,
    children?: ReactNode,
} & JSX.IntrinsicElements['textarea']

const Textarea : React.FC<TextareaProps> = ({
    className='',
    placeholder='',
    type='text',
    children,
    ...props
}) => {
    // class属性を設定
    let classString = styles.textarea;
    if (className) {
        classString = [classString, className].join(' ');
    }

    return (
            <textarea
              className={ classString }
              placeholder={ placeholder }
              {...props}
            >
                {children}
            </textarea>
    );
};

export default Textarea;
