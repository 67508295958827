/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type CreateUserInput = {
  sub: string,
  email?: string | null,
  iconUrl?: string | null,
  preferredUsername?: string | null,
  gender?: Gender | null,
  genderOpen?: boolean | null,
  birthDate?: string | null,
  profession?: string | null,
  prefecture?: string | null,
  rankingOpen?: boolean | null,
  selfIntroduction?: string | null,
  firstLoginDateTime: string,
  lastLoginDateTime: string,
  activeDays: number,
  experienceLevel: number,
  experiencePoint: number,
  withdrawedDateTime?: string | null,
  _version?: number | null,
};

export enum Gender {
  male = "male",
  female = "female",
  other = "other",
}


export type ModelUserConditionInput = {
  email?: ModelStringInput | null,
  iconUrl?: ModelStringInput | null,
  preferredUsername?: ModelStringInput | null,
  gender?: ModelGenderInput | null,
  genderOpen?: ModelBooleanInput | null,
  birthDate?: ModelStringInput | null,
  profession?: ModelStringInput | null,
  prefecture?: ModelStringInput | null,
  rankingOpen?: ModelBooleanInput | null,
  selfIntroduction?: ModelStringInput | null,
  firstLoginDateTime?: ModelStringInput | null,
  lastLoginDateTime?: ModelStringInput | null,
  activeDays?: ModelIntInput | null,
  experienceLevel?: ModelIntInput | null,
  experiencePoint?: ModelIntInput | null,
  withdrawedDateTime?: ModelStringInput | null,
  and?: Array< ModelUserConditionInput | null > | null,
  or?: Array< ModelUserConditionInput | null > | null,
  not?: ModelUserConditionInput | null,
};

export type ModelStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null",
}


export type ModelSizeInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type ModelGenderInput = {
  eq?: Gender | null,
  ne?: Gender | null,
};

export type ModelBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type ModelIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type User = {
  __typename: "User",
  sub: string,
  email?: string | null,
  iconUrl?: string | null,
  preferredUsername?: string | null,
  gender?: Gender | null,
  genderOpen?: boolean | null,
  birthDate?: string | null,
  profession?: string | null,
  prefecture?: string | null,
  rankingOpen?: boolean | null,
  selfIntroduction?: string | null,
  firstLoginDateTime: string,
  lastLoginDateTime: string,
  activeDays: number,
  experienceLevel: number,
  experiencePoint: number,
  withdrawedDateTime?: string | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  owner?: string | null,
};

export type UpdateUserInput = {
  sub: string,
  email?: string | null,
  iconUrl?: string | null,
  preferredUsername?: string | null,
  gender?: Gender | null,
  genderOpen?: boolean | null,
  birthDate?: string | null,
  profession?: string | null,
  prefecture?: string | null,
  rankingOpen?: boolean | null,
  selfIntroduction?: string | null,
  firstLoginDateTime?: string | null,
  lastLoginDateTime?: string | null,
  activeDays?: number | null,
  experienceLevel?: number | null,
  experiencePoint?: number | null,
  withdrawedDateTime?: string | null,
  _version?: number | null,
};

export type DeleteUserInput = {
  sub: string,
  _version?: number | null,
};

export type CreateLessonProgressInput = {
  id?: string | null,
  sub: string,
  courseId: string,
  lessonNo: number,
  iteration: number,
  firstCompletedDateTime: string,
  lastCompletedDateTime: string,
  _version?: number | null,
};

export type ModelLessonProgressConditionInput = {
  sub?: ModelStringInput | null,
  courseId?: ModelStringInput | null,
  lessonNo?: ModelIntInput | null,
  iteration?: ModelIntInput | null,
  firstCompletedDateTime?: ModelStringInput | null,
  lastCompletedDateTime?: ModelStringInput | null,
  and?: Array< ModelLessonProgressConditionInput | null > | null,
  or?: Array< ModelLessonProgressConditionInput | null > | null,
  not?: ModelLessonProgressConditionInput | null,
};

export type LessonProgress = {
  __typename: "LessonProgress",
  id: string,
  sub: string,
  user: User,
  courseId: string,
  lessonNo: number,
  iteration: number,
  firstCompletedDateTime: string,
  lastCompletedDateTime: string,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  owner?: string | null,
};

export type UpdateLessonProgressInput = {
  id: string,
  sub?: string | null,
  courseId?: string | null,
  lessonNo?: number | null,
  iteration?: number | null,
  firstCompletedDateTime?: string | null,
  lastCompletedDateTime?: string | null,
  _version?: number | null,
};

export type DeleteLessonProgressInput = {
  id: string,
  _version?: number | null,
};

export type CreateTrainingLogInput = {
  id?: string | null,
  sub: string,
  trainingMode: TrainingMode,
  numberOfMemorization: number,
  numberOfCorrect: number,
  memorizationSec: number,
  recallSec: number,
  _version?: number | null,
};

export enum TrainingMode {
  number = "number",
  word = "word",
  alphabet = "alphabet",
  nameAndFace = "nameAndFace",
}


export type ModelTrainingLogConditionInput = {
  sub?: ModelStringInput | null,
  trainingMode?: ModelTrainingModeInput | null,
  numberOfMemorization?: ModelIntInput | null,
  numberOfCorrect?: ModelIntInput | null,
  memorizationSec?: ModelIntInput | null,
  recallSec?: ModelIntInput | null,
  and?: Array< ModelTrainingLogConditionInput | null > | null,
  or?: Array< ModelTrainingLogConditionInput | null > | null,
  not?: ModelTrainingLogConditionInput | null,
};

export type ModelTrainingModeInput = {
  eq?: TrainingMode | null,
  ne?: TrainingMode | null,
};

export type TrainingLog = {
  __typename: "TrainingLog",
  id: string,
  sub: string,
  user: User,
  trainingMode: TrainingMode,
  numberOfMemorization: number,
  numberOfCorrect: number,
  memorizationSec: number,
  recallSec: number,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  owner?: string | null,
};

export type UpdateTrainingLogInput = {
  id: string,
  sub?: string | null,
  trainingMode?: TrainingMode | null,
  numberOfMemorization?: number | null,
  numberOfCorrect?: number | null,
  memorizationSec?: number | null,
  recallSec?: number | null,
  _version?: number | null,
};

export type DeleteTrainingLogInput = {
  id: string,
  _version?: number | null,
};

export type ModelUserFilterInput = {
  sub?: ModelStringInput | null,
  email?: ModelStringInput | null,
  iconUrl?: ModelStringInput | null,
  preferredUsername?: ModelStringInput | null,
  gender?: ModelGenderInput | null,
  genderOpen?: ModelBooleanInput | null,
  birthDate?: ModelStringInput | null,
  profession?: ModelStringInput | null,
  prefecture?: ModelStringInput | null,
  rankingOpen?: ModelBooleanInput | null,
  selfIntroduction?: ModelStringInput | null,
  firstLoginDateTime?: ModelStringInput | null,
  lastLoginDateTime?: ModelStringInput | null,
  activeDays?: ModelIntInput | null,
  experienceLevel?: ModelIntInput | null,
  experiencePoint?: ModelIntInput | null,
  withdrawedDateTime?: ModelStringInput | null,
  and?: Array< ModelUserFilterInput | null > | null,
  or?: Array< ModelUserFilterInput | null > | null,
  not?: ModelUserFilterInput | null,
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC",
}


export type ModelUserConnection = {
  __typename: "ModelUserConnection",
  items:  Array<User | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelLessonProgressFilterInput = {
  id?: ModelIDInput | null,
  sub?: ModelStringInput | null,
  courseId?: ModelStringInput | null,
  lessonNo?: ModelIntInput | null,
  iteration?: ModelIntInput | null,
  firstCompletedDateTime?: ModelStringInput | null,
  lastCompletedDateTime?: ModelStringInput | null,
  and?: Array< ModelLessonProgressFilterInput | null > | null,
  or?: Array< ModelLessonProgressFilterInput | null > | null,
  not?: ModelLessonProgressFilterInput | null,
};

export type ModelIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export type ModelLessonProgressConnection = {
  __typename: "ModelLessonProgressConnection",
  items:  Array<LessonProgress | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelStringKeyConditionInput = {
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
};

export type ModelTrainingLogFilterInput = {
  id?: ModelIDInput | null,
  sub?: ModelStringInput | null,
  trainingMode?: ModelTrainingModeInput | null,
  numberOfMemorization?: ModelIntInput | null,
  numberOfCorrect?: ModelIntInput | null,
  memorizationSec?: ModelIntInput | null,
  recallSec?: ModelIntInput | null,
  and?: Array< ModelTrainingLogFilterInput | null > | null,
  or?: Array< ModelTrainingLogFilterInput | null > | null,
  not?: ModelTrainingLogFilterInput | null,
};

export type ModelTrainingLogConnection = {
  __typename: "ModelTrainingLogConnection",
  items:  Array<TrainingLog | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type CreateUserMutationVariables = {
  input: CreateUserInput,
  condition?: ModelUserConditionInput | null,
};

export type CreateUserMutation = {
  createUser?:  {
    __typename: "User",
    sub: string,
    email?: string | null,
    iconUrl?: string | null,
    preferredUsername?: string | null,
    gender?: Gender | null,
    genderOpen?: boolean | null,
    birthDate?: string | null,
    profession?: string | null,
    prefecture?: string | null,
    rankingOpen?: boolean | null,
    selfIntroduction?: string | null,
    firstLoginDateTime: string,
    lastLoginDateTime: string,
    activeDays: number,
    experienceLevel: number,
    experiencePoint: number,
    withdrawedDateTime?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type UpdateUserMutationVariables = {
  input: UpdateUserInput,
  condition?: ModelUserConditionInput | null,
};

export type UpdateUserMutation = {
  updateUser?:  {
    __typename: "User",
    sub: string,
    email?: string | null,
    iconUrl?: string | null,
    preferredUsername?: string | null,
    gender?: Gender | null,
    genderOpen?: boolean | null,
    birthDate?: string | null,
    profession?: string | null,
    prefecture?: string | null,
    rankingOpen?: boolean | null,
    selfIntroduction?: string | null,
    firstLoginDateTime: string,
    lastLoginDateTime: string,
    activeDays: number,
    experienceLevel: number,
    experiencePoint: number,
    withdrawedDateTime?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type DeleteUserMutationVariables = {
  input: DeleteUserInput,
  condition?: ModelUserConditionInput | null,
};

export type DeleteUserMutation = {
  deleteUser?:  {
    __typename: "User",
    sub: string,
    email?: string | null,
    iconUrl?: string | null,
    preferredUsername?: string | null,
    gender?: Gender | null,
    genderOpen?: boolean | null,
    birthDate?: string | null,
    profession?: string | null,
    prefecture?: string | null,
    rankingOpen?: boolean | null,
    selfIntroduction?: string | null,
    firstLoginDateTime: string,
    lastLoginDateTime: string,
    activeDays: number,
    experienceLevel: number,
    experiencePoint: number,
    withdrawedDateTime?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type CreateLessonProgressMutationVariables = {
  input: CreateLessonProgressInput,
  condition?: ModelLessonProgressConditionInput | null,
};

export type CreateLessonProgressMutation = {
  createLessonProgress?:  {
    __typename: "LessonProgress",
    id: string,
    sub: string,
    user:  {
      __typename: "User",
      sub: string,
      email?: string | null,
      iconUrl?: string | null,
      preferredUsername?: string | null,
      gender?: Gender | null,
      genderOpen?: boolean | null,
      birthDate?: string | null,
      profession?: string | null,
      prefecture?: string | null,
      rankingOpen?: boolean | null,
      selfIntroduction?: string | null,
      firstLoginDateTime: string,
      lastLoginDateTime: string,
      activeDays: number,
      experienceLevel: number,
      experiencePoint: number,
      withdrawedDateTime?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    },
    courseId: string,
    lessonNo: number,
    iteration: number,
    firstCompletedDateTime: string,
    lastCompletedDateTime: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type UpdateLessonProgressMutationVariables = {
  input: UpdateLessonProgressInput,
  condition?: ModelLessonProgressConditionInput | null,
};

export type UpdateLessonProgressMutation = {
  updateLessonProgress?:  {
    __typename: "LessonProgress",
    id: string,
    sub: string,
    user:  {
      __typename: "User",
      sub: string,
      email?: string | null,
      iconUrl?: string | null,
      preferredUsername?: string | null,
      gender?: Gender | null,
      genderOpen?: boolean | null,
      birthDate?: string | null,
      profession?: string | null,
      prefecture?: string | null,
      rankingOpen?: boolean | null,
      selfIntroduction?: string | null,
      firstLoginDateTime: string,
      lastLoginDateTime: string,
      activeDays: number,
      experienceLevel: number,
      experiencePoint: number,
      withdrawedDateTime?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    },
    courseId: string,
    lessonNo: number,
    iteration: number,
    firstCompletedDateTime: string,
    lastCompletedDateTime: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type DeleteLessonProgressMutationVariables = {
  input: DeleteLessonProgressInput,
  condition?: ModelLessonProgressConditionInput | null,
};

export type DeleteLessonProgressMutation = {
  deleteLessonProgress?:  {
    __typename: "LessonProgress",
    id: string,
    sub: string,
    user:  {
      __typename: "User",
      sub: string,
      email?: string | null,
      iconUrl?: string | null,
      preferredUsername?: string | null,
      gender?: Gender | null,
      genderOpen?: boolean | null,
      birthDate?: string | null,
      profession?: string | null,
      prefecture?: string | null,
      rankingOpen?: boolean | null,
      selfIntroduction?: string | null,
      firstLoginDateTime: string,
      lastLoginDateTime: string,
      activeDays: number,
      experienceLevel: number,
      experiencePoint: number,
      withdrawedDateTime?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    },
    courseId: string,
    lessonNo: number,
    iteration: number,
    firstCompletedDateTime: string,
    lastCompletedDateTime: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type CreateTrainingLogMutationVariables = {
  input: CreateTrainingLogInput,
  condition?: ModelTrainingLogConditionInput | null,
};

export type CreateTrainingLogMutation = {
  createTrainingLog?:  {
    __typename: "TrainingLog",
    id: string,
    sub: string,
    user:  {
      __typename: "User",
      sub: string,
      email?: string | null,
      iconUrl?: string | null,
      preferredUsername?: string | null,
      gender?: Gender | null,
      genderOpen?: boolean | null,
      birthDate?: string | null,
      profession?: string | null,
      prefecture?: string | null,
      rankingOpen?: boolean | null,
      selfIntroduction?: string | null,
      firstLoginDateTime: string,
      lastLoginDateTime: string,
      activeDays: number,
      experienceLevel: number,
      experiencePoint: number,
      withdrawedDateTime?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    },
    trainingMode: TrainingMode,
    numberOfMemorization: number,
    numberOfCorrect: number,
    memorizationSec: number,
    recallSec: number,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type UpdateTrainingLogMutationVariables = {
  input: UpdateTrainingLogInput,
  condition?: ModelTrainingLogConditionInput | null,
};

export type UpdateTrainingLogMutation = {
  updateTrainingLog?:  {
    __typename: "TrainingLog",
    id: string,
    sub: string,
    user:  {
      __typename: "User",
      sub: string,
      email?: string | null,
      iconUrl?: string | null,
      preferredUsername?: string | null,
      gender?: Gender | null,
      genderOpen?: boolean | null,
      birthDate?: string | null,
      profession?: string | null,
      prefecture?: string | null,
      rankingOpen?: boolean | null,
      selfIntroduction?: string | null,
      firstLoginDateTime: string,
      lastLoginDateTime: string,
      activeDays: number,
      experienceLevel: number,
      experiencePoint: number,
      withdrawedDateTime?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    },
    trainingMode: TrainingMode,
    numberOfMemorization: number,
    numberOfCorrect: number,
    memorizationSec: number,
    recallSec: number,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type DeleteTrainingLogMutationVariables = {
  input: DeleteTrainingLogInput,
  condition?: ModelTrainingLogConditionInput | null,
};

export type DeleteTrainingLogMutation = {
  deleteTrainingLog?:  {
    __typename: "TrainingLog",
    id: string,
    sub: string,
    user:  {
      __typename: "User",
      sub: string,
      email?: string | null,
      iconUrl?: string | null,
      preferredUsername?: string | null,
      gender?: Gender | null,
      genderOpen?: boolean | null,
      birthDate?: string | null,
      profession?: string | null,
      prefecture?: string | null,
      rankingOpen?: boolean | null,
      selfIntroduction?: string | null,
      firstLoginDateTime: string,
      lastLoginDateTime: string,
      activeDays: number,
      experienceLevel: number,
      experiencePoint: number,
      withdrawedDateTime?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    },
    trainingMode: TrainingMode,
    numberOfMemorization: number,
    numberOfCorrect: number,
    memorizationSec: number,
    recallSec: number,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type GetUserQueryVariables = {
  sub: string,
};

export type GetUserQuery = {
  getUser?:  {
    __typename: "User",
    sub: string,
    email?: string | null,
    iconUrl?: string | null,
    preferredUsername?: string | null,
    gender?: Gender | null,
    genderOpen?: boolean | null,
    birthDate?: string | null,
    profession?: string | null,
    prefecture?: string | null,
    rankingOpen?: boolean | null,
    selfIntroduction?: string | null,
    firstLoginDateTime: string,
    lastLoginDateTime: string,
    activeDays: number,
    experienceLevel: number,
    experiencePoint: number,
    withdrawedDateTime?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type ListUsersQueryVariables = {
  sub?: string | null,
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListUsersQuery = {
  listUsers?:  {
    __typename: "ModelUserConnection",
    items:  Array< {
      __typename: "User",
      sub: string,
      email?: string | null,
      iconUrl?: string | null,
      preferredUsername?: string | null,
      gender?: Gender | null,
      genderOpen?: boolean | null,
      birthDate?: string | null,
      profession?: string | null,
      prefecture?: string | null,
      rankingOpen?: boolean | null,
      selfIntroduction?: string | null,
      firstLoginDateTime: string,
      lastLoginDateTime: string,
      activeDays: number,
      experienceLevel: number,
      experiencePoint: number,
      withdrawedDateTime?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncUsersQueryVariables = {
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncUsersQuery = {
  syncUsers?:  {
    __typename: "ModelUserConnection",
    items:  Array< {
      __typename: "User",
      sub: string,
      email?: string | null,
      iconUrl?: string | null,
      preferredUsername?: string | null,
      gender?: Gender | null,
      genderOpen?: boolean | null,
      birthDate?: string | null,
      profession?: string | null,
      prefecture?: string | null,
      rankingOpen?: boolean | null,
      selfIntroduction?: string | null,
      firstLoginDateTime: string,
      lastLoginDateTime: string,
      activeDays: number,
      experienceLevel: number,
      experiencePoint: number,
      withdrawedDateTime?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetLessonProgressQueryVariables = {
  id: string,
};

export type GetLessonProgressQuery = {
  getLessonProgress?:  {
    __typename: "LessonProgress",
    id: string,
    sub: string,
    user:  {
      __typename: "User",
      sub: string,
      email?: string | null,
      iconUrl?: string | null,
      preferredUsername?: string | null,
      gender?: Gender | null,
      genderOpen?: boolean | null,
      birthDate?: string | null,
      profession?: string | null,
      prefecture?: string | null,
      rankingOpen?: boolean | null,
      selfIntroduction?: string | null,
      firstLoginDateTime: string,
      lastLoginDateTime: string,
      activeDays: number,
      experienceLevel: number,
      experiencePoint: number,
      withdrawedDateTime?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    },
    courseId: string,
    lessonNo: number,
    iteration: number,
    firstCompletedDateTime: string,
    lastCompletedDateTime: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type ListLessonProgressesQueryVariables = {
  id?: string | null,
  filter?: ModelLessonProgressFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListLessonProgressesQuery = {
  listLessonProgresses?:  {
    __typename: "ModelLessonProgressConnection",
    items:  Array< {
      __typename: "LessonProgress",
      id: string,
      sub: string,
      user:  {
        __typename: "User",
        sub: string,
        email?: string | null,
        iconUrl?: string | null,
        preferredUsername?: string | null,
        gender?: Gender | null,
        genderOpen?: boolean | null,
        birthDate?: string | null,
        profession?: string | null,
        prefecture?: string | null,
        rankingOpen?: boolean | null,
        selfIntroduction?: string | null,
        firstLoginDateTime: string,
        lastLoginDateTime: string,
        activeDays: number,
        experienceLevel: number,
        experiencePoint: number,
        withdrawedDateTime?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      },
      courseId: string,
      lessonNo: number,
      iteration: number,
      firstCompletedDateTime: string,
      lastCompletedDateTime: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncLessonProgressesQueryVariables = {
  filter?: ModelLessonProgressFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncLessonProgressesQuery = {
  syncLessonProgresses?:  {
    __typename: "ModelLessonProgressConnection",
    items:  Array< {
      __typename: "LessonProgress",
      id: string,
      sub: string,
      user:  {
        __typename: "User",
        sub: string,
        email?: string | null,
        iconUrl?: string | null,
        preferredUsername?: string | null,
        gender?: Gender | null,
        genderOpen?: boolean | null,
        birthDate?: string | null,
        profession?: string | null,
        prefecture?: string | null,
        rankingOpen?: boolean | null,
        selfIntroduction?: string | null,
        firstLoginDateTime: string,
        lastLoginDateTime: string,
        activeDays: number,
        experienceLevel: number,
        experiencePoint: number,
        withdrawedDateTime?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      },
      courseId: string,
      lessonNo: number,
      iteration: number,
      firstCompletedDateTime: string,
      lastCompletedDateTime: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SearchLessonProgressesByUserBylastCompletedDateTimeQueryVariables = {
  sub: string,
  lastCompletedDateTime?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelLessonProgressFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type SearchLessonProgressesByUserBylastCompletedDateTimeQuery = {
  searchLessonProgressesByUserBylastCompletedDateTime?:  {
    __typename: "ModelLessonProgressConnection",
    items:  Array< {
      __typename: "LessonProgress",
      id: string,
      sub: string,
      user:  {
        __typename: "User",
        sub: string,
        email?: string | null,
        iconUrl?: string | null,
        preferredUsername?: string | null,
        gender?: Gender | null,
        genderOpen?: boolean | null,
        birthDate?: string | null,
        profession?: string | null,
        prefecture?: string | null,
        rankingOpen?: boolean | null,
        selfIntroduction?: string | null,
        firstLoginDateTime: string,
        lastLoginDateTime: string,
        activeDays: number,
        experienceLevel: number,
        experiencePoint: number,
        withdrawedDateTime?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      },
      courseId: string,
      lessonNo: number,
      iteration: number,
      firstCompletedDateTime: string,
      lastCompletedDateTime: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetTrainingLogQueryVariables = {
  id: string,
};

export type GetTrainingLogQuery = {
  getTrainingLog?:  {
    __typename: "TrainingLog",
    id: string,
    sub: string,
    user:  {
      __typename: "User",
      sub: string,
      email?: string | null,
      iconUrl?: string | null,
      preferredUsername?: string | null,
      gender?: Gender | null,
      genderOpen?: boolean | null,
      birthDate?: string | null,
      profession?: string | null,
      prefecture?: string | null,
      rankingOpen?: boolean | null,
      selfIntroduction?: string | null,
      firstLoginDateTime: string,
      lastLoginDateTime: string,
      activeDays: number,
      experienceLevel: number,
      experiencePoint: number,
      withdrawedDateTime?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    },
    trainingMode: TrainingMode,
    numberOfMemorization: number,
    numberOfCorrect: number,
    memorizationSec: number,
    recallSec: number,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type ListTrainingLogsQueryVariables = {
  id?: string | null,
  filter?: ModelTrainingLogFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListTrainingLogsQuery = {
  listTrainingLogs?:  {
    __typename: "ModelTrainingLogConnection",
    items:  Array< {
      __typename: "TrainingLog",
      id: string,
      sub: string,
      user:  {
        __typename: "User",
        sub: string,
        email?: string | null,
        iconUrl?: string | null,
        preferredUsername?: string | null,
        gender?: Gender | null,
        genderOpen?: boolean | null,
        birthDate?: string | null,
        profession?: string | null,
        prefecture?: string | null,
        rankingOpen?: boolean | null,
        selfIntroduction?: string | null,
        firstLoginDateTime: string,
        lastLoginDateTime: string,
        activeDays: number,
        experienceLevel: number,
        experiencePoint: number,
        withdrawedDateTime?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      },
      trainingMode: TrainingMode,
      numberOfMemorization: number,
      numberOfCorrect: number,
      memorizationSec: number,
      recallSec: number,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncTrainingLogsQueryVariables = {
  filter?: ModelTrainingLogFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncTrainingLogsQuery = {
  syncTrainingLogs?:  {
    __typename: "ModelTrainingLogConnection",
    items:  Array< {
      __typename: "TrainingLog",
      id: string,
      sub: string,
      user:  {
        __typename: "User",
        sub: string,
        email?: string | null,
        iconUrl?: string | null,
        preferredUsername?: string | null,
        gender?: Gender | null,
        genderOpen?: boolean | null,
        birthDate?: string | null,
        profession?: string | null,
        prefecture?: string | null,
        rankingOpen?: boolean | null,
        selfIntroduction?: string | null,
        firstLoginDateTime: string,
        lastLoginDateTime: string,
        activeDays: number,
        experienceLevel: number,
        experiencePoint: number,
        withdrawedDateTime?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      },
      trainingMode: TrainingMode,
      numberOfMemorization: number,
      numberOfCorrect: number,
      memorizationSec: number,
      recallSec: number,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type OnCreateUserSubscriptionVariables = {
  owner?: string | null,
};

export type OnCreateUserSubscription = {
  onCreateUser?:  {
    __typename: "User",
    sub: string,
    email?: string | null,
    iconUrl?: string | null,
    preferredUsername?: string | null,
    gender?: Gender | null,
    genderOpen?: boolean | null,
    birthDate?: string | null,
    profession?: string | null,
    prefecture?: string | null,
    rankingOpen?: boolean | null,
    selfIntroduction?: string | null,
    firstLoginDateTime: string,
    lastLoginDateTime: string,
    activeDays: number,
    experienceLevel: number,
    experiencePoint: number,
    withdrawedDateTime?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type OnUpdateUserSubscriptionVariables = {
  owner?: string | null,
};

export type OnUpdateUserSubscription = {
  onUpdateUser?:  {
    __typename: "User",
    sub: string,
    email?: string | null,
    iconUrl?: string | null,
    preferredUsername?: string | null,
    gender?: Gender | null,
    genderOpen?: boolean | null,
    birthDate?: string | null,
    profession?: string | null,
    prefecture?: string | null,
    rankingOpen?: boolean | null,
    selfIntroduction?: string | null,
    firstLoginDateTime: string,
    lastLoginDateTime: string,
    activeDays: number,
    experienceLevel: number,
    experiencePoint: number,
    withdrawedDateTime?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type OnDeleteUserSubscriptionVariables = {
  owner?: string | null,
};

export type OnDeleteUserSubscription = {
  onDeleteUser?:  {
    __typename: "User",
    sub: string,
    email?: string | null,
    iconUrl?: string | null,
    preferredUsername?: string | null,
    gender?: Gender | null,
    genderOpen?: boolean | null,
    birthDate?: string | null,
    profession?: string | null,
    prefecture?: string | null,
    rankingOpen?: boolean | null,
    selfIntroduction?: string | null,
    firstLoginDateTime: string,
    lastLoginDateTime: string,
    activeDays: number,
    experienceLevel: number,
    experiencePoint: number,
    withdrawedDateTime?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type OnCreateLessonProgressSubscriptionVariables = {
  owner?: string | null,
};

export type OnCreateLessonProgressSubscription = {
  onCreateLessonProgress?:  {
    __typename: "LessonProgress",
    id: string,
    sub: string,
    user:  {
      __typename: "User",
      sub: string,
      email?: string | null,
      iconUrl?: string | null,
      preferredUsername?: string | null,
      gender?: Gender | null,
      genderOpen?: boolean | null,
      birthDate?: string | null,
      profession?: string | null,
      prefecture?: string | null,
      rankingOpen?: boolean | null,
      selfIntroduction?: string | null,
      firstLoginDateTime: string,
      lastLoginDateTime: string,
      activeDays: number,
      experienceLevel: number,
      experiencePoint: number,
      withdrawedDateTime?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    },
    courseId: string,
    lessonNo: number,
    iteration: number,
    firstCompletedDateTime: string,
    lastCompletedDateTime: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type OnUpdateLessonProgressSubscriptionVariables = {
  owner?: string | null,
};

export type OnUpdateLessonProgressSubscription = {
  onUpdateLessonProgress?:  {
    __typename: "LessonProgress",
    id: string,
    sub: string,
    user:  {
      __typename: "User",
      sub: string,
      email?: string | null,
      iconUrl?: string | null,
      preferredUsername?: string | null,
      gender?: Gender | null,
      genderOpen?: boolean | null,
      birthDate?: string | null,
      profession?: string | null,
      prefecture?: string | null,
      rankingOpen?: boolean | null,
      selfIntroduction?: string | null,
      firstLoginDateTime: string,
      lastLoginDateTime: string,
      activeDays: number,
      experienceLevel: number,
      experiencePoint: number,
      withdrawedDateTime?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    },
    courseId: string,
    lessonNo: number,
    iteration: number,
    firstCompletedDateTime: string,
    lastCompletedDateTime: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type OnDeleteLessonProgressSubscriptionVariables = {
  owner?: string | null,
};

export type OnDeleteLessonProgressSubscription = {
  onDeleteLessonProgress?:  {
    __typename: "LessonProgress",
    id: string,
    sub: string,
    user:  {
      __typename: "User",
      sub: string,
      email?: string | null,
      iconUrl?: string | null,
      preferredUsername?: string | null,
      gender?: Gender | null,
      genderOpen?: boolean | null,
      birthDate?: string | null,
      profession?: string | null,
      prefecture?: string | null,
      rankingOpen?: boolean | null,
      selfIntroduction?: string | null,
      firstLoginDateTime: string,
      lastLoginDateTime: string,
      activeDays: number,
      experienceLevel: number,
      experiencePoint: number,
      withdrawedDateTime?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    },
    courseId: string,
    lessonNo: number,
    iteration: number,
    firstCompletedDateTime: string,
    lastCompletedDateTime: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type OnCreateTrainingLogSubscriptionVariables = {
  owner?: string | null,
};

export type OnCreateTrainingLogSubscription = {
  onCreateTrainingLog?:  {
    __typename: "TrainingLog",
    id: string,
    sub: string,
    user:  {
      __typename: "User",
      sub: string,
      email?: string | null,
      iconUrl?: string | null,
      preferredUsername?: string | null,
      gender?: Gender | null,
      genderOpen?: boolean | null,
      birthDate?: string | null,
      profession?: string | null,
      prefecture?: string | null,
      rankingOpen?: boolean | null,
      selfIntroduction?: string | null,
      firstLoginDateTime: string,
      lastLoginDateTime: string,
      activeDays: number,
      experienceLevel: number,
      experiencePoint: number,
      withdrawedDateTime?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    },
    trainingMode: TrainingMode,
    numberOfMemorization: number,
    numberOfCorrect: number,
    memorizationSec: number,
    recallSec: number,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type OnUpdateTrainingLogSubscriptionVariables = {
  owner?: string | null,
};

export type OnUpdateTrainingLogSubscription = {
  onUpdateTrainingLog?:  {
    __typename: "TrainingLog",
    id: string,
    sub: string,
    user:  {
      __typename: "User",
      sub: string,
      email?: string | null,
      iconUrl?: string | null,
      preferredUsername?: string | null,
      gender?: Gender | null,
      genderOpen?: boolean | null,
      birthDate?: string | null,
      profession?: string | null,
      prefecture?: string | null,
      rankingOpen?: boolean | null,
      selfIntroduction?: string | null,
      firstLoginDateTime: string,
      lastLoginDateTime: string,
      activeDays: number,
      experienceLevel: number,
      experiencePoint: number,
      withdrawedDateTime?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    },
    trainingMode: TrainingMode,
    numberOfMemorization: number,
    numberOfCorrect: number,
    memorizationSec: number,
    recallSec: number,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type OnDeleteTrainingLogSubscriptionVariables = {
  owner?: string | null,
};

export type OnDeleteTrainingLogSubscription = {
  onDeleteTrainingLog?:  {
    __typename: "TrainingLog",
    id: string,
    sub: string,
    user:  {
      __typename: "User",
      sub: string,
      email?: string | null,
      iconUrl?: string | null,
      preferredUsername?: string | null,
      gender?: Gender | null,
      genderOpen?: boolean | null,
      birthDate?: string | null,
      profession?: string | null,
      prefecture?: string | null,
      rankingOpen?: boolean | null,
      selfIntroduction?: string | null,
      firstLoginDateTime: string,
      lastLoginDateTime: string,
      activeDays: number,
      experienceLevel: number,
      experiencePoint: number,
      withdrawedDateTime?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    },
    trainingMode: TrainingMode,
    numberOfMemorization: number,
    numberOfCorrect: number,
    memorizationSec: number,
    recallSec: number,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};
