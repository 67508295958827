import React, { ReactNode } from 'react';
// CSSはインポートした順に生成され、後にインポートしたCSSの方が優先されるので、
// まず他のコンポーネントや共通CSSをインポートして、その後でコンポーネントごとのCSSをインストールすること
// つまり、styles.module.cssのインポートはインポート文の複数行のうちの最後に置いておくのが安全
import styles from './styles.module.css';

export type FontEnProps = {
    className?: string;
    domType?: string;
    children?: ReactNode;
};


const FontEn : React.FC<FontEnProps> = ({
    className = '',
    domType = undefined,
    children,
}) => {
    // class属性を設定
    let classString = styles['font-en'];
    if (className) {
        classString = [classString, className].join(' ');
    }

    if (domType === 'span') {
        return (
            <span className={classString} data-testid='span-font-en'>{children}</span>
        )
    } else if(domType === 'p') {
        return (
            <span className={classString} data-testid='p-font-en'>{children}</span>
        )
    } else { // デフォルトはdiv
        return (
            <div className={classString} data-testid='div-font-en'>{children}</div>
        )
    }
};

export default FontEn;
