import React,
{
    // useEffect,
} from 'react';
import SetupProfileTemplate from '../../templates/SetupProfileTemplate';
import { useAppSelector, useAppDispatch } from '../../../redux/app/hooks';
import {
    selectLoginState,
} from '../../../redux/slices/loginSlice';
import { useNavigate } from 'react-router-dom';

import {
    changePreferredUsername,
    changeGender,
    changeGenderOpen,
    changeBirthDate,
    changeProfession,
    changePrefecture,
    changeSelfIntroduction,

    SubmitParams,
    submit,

    selectSetupProfileState,
} from '../../../redux/slices/setupProfileSlice';
// CSSはインポートした順に生成され、後にインポートしたCSSの方が優先されるので、
// まず他のコンポーネントや共通CSSをインポートして、その後でコンポーネントごとのCSSをインストールすること
// つまり、styles.module.cssのインポートはインポート文の複数行のうちの最後に置いておくのが安全
// import styles from './styles.module.css';


export type SetupProfilePageProps = {
    children?: never;
};


const SetupProfilePage : React.FC<SetupProfilePageProps> = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const {
        // isLogin,
        sub,
    } = useAppSelector(selectLoginState);

    const {
        preferredUsername,
        gender,
        genderOpen,
        birthDate,
        prefecture,
        profession,
        selfIntroduction,

        preferredUsernameValidationMessage,
        genderValidationMessage,
        genderOpenValidationMessage,
        birthDateValidationMessage,
        professionValidationMessage,
        prefectureValidationMessage,
        rankingOpenValidationMessage,
        selfIntroductionValidationMessage,
    } = useAppSelector(selectSetupProfileState);

    const params : SubmitParams = {
        preferredUsername,
        gender,
        genderOpen,
        birthDate,
        prefecture,
        profession,
        selfIntroduction,

        preferredUsernameValidationMessage,
        genderValidationMessage,
        genderOpenValidationMessage,
        birthDateValidationMessage,
        professionValidationMessage,
        prefectureValidationMessage,
        rankingOpenValidationMessage,
        selfIntroductionValidationMessage,

        sub,
        navigate,
        redirectPage: '/mypage',
    };

    return (
        <SetupProfileTemplate
          preferredUsername={preferredUsername}
          gender={gender}
          genderOpen={genderOpen}
          birthDate={typeof birthDate === 'undefined' ? new Date() : new Date(birthDate)}
          profession={profession}
          prefecture={prefecture}
          rankingOpen=""
          selfIntroduction={selfIntroduction}

          preferredUsernameValidationMessage={preferredUsernameValidationMessage}
          genderValidationMessage={genderValidationMessage}
          genderOpenValidationMessage={genderOpenValidationMessage}
          birthDateValidationMessage={birthDateValidationMessage}
          professionValidationMessage={professionValidationMessage}
          prefectureValidationMessage={prefectureValidationMessage}
          rankingOpenValidationMessage={rankingOpenValidationMessage}
          selfIntroductionValidationMessage={selfIntroductionValidationMessage}

          changePreferredUsername={(e) => { dispatch(changePreferredUsername(e.target.value)) }}
          changeGender={(e) => { dispatch(changeGender(e.target.value)) }}
          changeGenderOpen={(e) => { dispatch(changeGenderOpen(e.target.value)) }}
          changeBirthDate={(date) => { dispatch(changeBirthDate(date.toISOString())) }}
          changeProfession={(e) => { dispatch(changeProfession(e.target.value)) }}
          changePrefecture={(e) => { dispatch(changePrefecture(e.target.value) )}}
          changeRankingOpen={() => {}}
          changeSelfIntroduction={(e) => { dispatch(changeSelfIntroduction(e.target.value)) }}

          submit={() => { dispatch(submit(params)) }}
        />
    );
};

export default SetupProfilePage;
