import React, { ReactNode } from 'react';
import FontEn from '../../atoms/FontEn';
// CSSはインポートした順に生成され、後にインポートしたCSSの方が優先されるので、
// まず他のコンポーネントや共通CSSをインポートして、その後でコンポーネントごとのCSSをインストールすること
// つまり、styles.module.cssのインポートはインポート文の複数行のうちの最後に置いておくのが安全
import styles from './styles.module.css';

export type NewsListProps = {
    date: string;
    children?: ReactNode;
};


const NewsList : React.FC<NewsListProps> = ({
    date,
    children,
}) => {
    return (
        <dl className={ styles['news-list'] }>
            <dt><FontEn>{date}</FontEn></dt>
            <dd>{children}</dd>
        </dl>
    );
};

export default NewsList;
