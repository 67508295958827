import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import GtagPage from './components/pages/GtagPage';
import AuthorizationPage from './components/pages/AuthorizationPage';
import CoursePage from './components/pages/CoursePage';
import LessonPage from './components/pages/LessonPage';
import LoginPage from './components/pages/LoginPage';
import LogoutPage from './components/pages/LogoutPage';
// import ForumPage from './components/pages/ForumPage';
import MyPagePage from './components/pages/MyPagePage';
import TopPagePage from './components/pages/TopPagePage';
import ContactPage from './components/pages/ContactPage';
import SetupProfilePage from './components/pages/SetupProfilePage';
import ProfilePage from './components/pages/ProfilePage';
import NewsPage from './components/pages/NewsPage';
import NotationPage from './components/pages/NotationPage';
import PrivacyPolicyPage from './components/pages/PrivacyPolicyPage';
import TermsOfServicePage from './components/pages/TermsOfServicePage';
import SignupPage from './components/pages/SignupPage';
import PaymentPage from './components/pages/PaymentPage';
import WaitPaymentActivationPage from './components/pages/WaitPaymentActivationPage';
import AppendixPage from './components/pages/AppendixPage';
import TrainingPage from './components/pages/TrainingPage';
import ResetPasswordPage from './components/pages/ResetPasswordPage'
import WithdrawalPage from './components/pages/WithdrawalPage';
import WaitWithdrawalPage from './components/pages/WaitWithdrawalPage';
import MaintenancePage from './components/pages/MaintenancePage';
import MaintenanceRedirectorPage from './components/pages/MaintenanceRedirectorPage';
import ChangePasswordPage from './components/pages/ChangePasswordPage';
import { TrainingMode } from './redux/constant';

// メンテナンスモードを発動する場合には、 '2022-09-03T00:00:00.000Z' のようにUTCでの時刻を指定する。(おそらく、タイムゾーン込みで入力すればJSTでも可)
const maintenanceStartsAt: string | undefined = undefined;
const isMaintenceMode = typeof maintenanceStartsAt !== 'undefined';

function App() {
  return (
    <BrowserRouter>
      { /* gtag */}
      <Routes>
        <Route path="*" element={<GtagPage />} />
      </Routes>

      {/* メンテナンスページへの誘導 */}
      {isMaintenceMode &&
        <Routes>
          <Route path="/" element={<></>} />
          <Route path="/maintenance" element={<></>} />
          <Route path="*" element={<MaintenanceRedirectorPage maintenanceStartsAt={maintenanceStartsAt} />} />
        </Routes>
      }

      { /* ログイン関係 */}
      { /* 基本はログイン必須で、ログイン不要なページだけ明示的に指定する */}
      <Routes>
        <Route path="/" element={<></>} />
        <Route path="/maintenance" element={<></>} />
        <Route path="/news" element={<></>} />
        <Route path="/login" element={<></>} />
        <Route path="/logout" element={<></>} />
        <Route path="/signup" element={<></>} />
        <Route path="/forgot-password" element={<></>} />
        <Route path="/contact" element={<></>} />
        <Route path="/notation" element={<></>} />
        <Route path="/privacy-policy" element={<></>} />
        <Route path="/terms-of-service" element={<></>} />
        <Route path="*" element={<AuthorizationPage />} />
      </Routes>

      { /* メインコンテンツ */}
      <Routes>
        <Route path="/" element={<TopPagePage />} />
        {isMaintenceMode && <Route path="/maintenance" element={<MaintenancePage />} />}
        <Route path="/course-list">
          <Route path="" element={<CoursePage />} />
          <Route path=":courseId" element={<CoursePage />} />
          <Route path=":courseId/:lessonNoStr" element={<LessonPage />} />
        </Route>
        <Route path="/login" element={<LoginPage />} />
        <Route path="/logout" element={<LogoutPage />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/notation" element={<NotationPage />} />
        <Route path="/news" element={<NewsPage />} />
        <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
        <Route path="/terms-of-service" element={<TermsOfServicePage />} />
        <Route path="/mypage" element={<MyPagePage />} />
        <Route path="/profile" element={<ProfilePage />} />
        <Route path="/setup-profile" element={<SetupProfilePage />} />
        <Route path="/payment" element={<PaymentPage />} />
        <Route path="/wait-payment-activation" element={<WaitPaymentActivationPage />} />
        <Route path="/appendix" element={<AppendixPage />} />
        <Route path="/signup" element={<SignupPage />} />
        <Route path="/forgot-password" element={<ResetPasswordPage />} />
        <Route path="/training-number" element={<TrainingPage chosenTrainingMode={TrainingMode.number} />} />
        <Route path="/training-word" element={<TrainingPage chosenTrainingMode={TrainingMode.word} />} />
        <Route path="/training-alphabet" element={<TrainingPage chosenTrainingMode={TrainingMode.alphabet} />} />
        <Route path="/training-name-and-face" element={<TrainingPage chosenTrainingMode={TrainingMode.nameAndFace} />} />
        <Route path="/training-circle-ratio" element={<TrainingPage chosenTrainingMode={TrainingMode.circleRatio} />} />
        <Route path="/training-national-capitals" element={<TrainingPage chosenTrainingMode={TrainingMode.nationalCapitals} />} />
        <Route path="/withdrawal" element={<WithdrawalPage />} />
        <Route path="/wait-withdrawal" element={<WaitWithdrawalPage />} />
        <Route path="/change-password" element={<ChangePasswordPage />} />
        <Route path="*" element={<MyPagePage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
