import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit'
import type { RootState } from '../app/store'
import {
    TrainingMode,
    TrainingPhase,
    allWords,
    allNames,
    circleRatioStr,
    EuropeCapitals,
    AsiaCapitals,
    AfricaCapitals,
    NorthAmericaCapitals,
    SouthAmericaCapitals,
    OceaniaCapitals,
    all_capitals_num,
} from '../constant';
import range from 'lodash-es/range';
import sample from 'lodash-es/sample';
import shuffle from 'lodash-es/shuffle';
import take from 'lodash-es/take';
import zip from 'lodash-es/zip';

import { API } from "aws-amplify";
import { graphqlOperation, GraphQLResult } from '@aws-amplify/api-graphql';
import {
    pointToLevel,
} from '../../lib/experience';
import {
    GetUserQuery,
    GetUserQueryVariables,
    UpdateUserMutation,
    UpdateUserMutationVariables,
} from "../../API";
import {
    getUser,
} from '../../graphql/queries';
import {
    updateUser,
} from '../../graphql/mutations';
import {
    changeExperienceLevel,
    changeExperiencePoint,
    changeAddedExperiencePoint,
} from './loginSlice';

export const initialRemainingWaitingTimeMiliSec = 5 * 1000;
export const initialRemainingMemorizationTimeMiliSec = 60 * 1000;

const initialRemainingRecallTimeMiliSec = 4 * 60 * 1000;
const initialRemainingRecallTimeMiliSecOfNationalCapital = 30 * 60 * 1000;

interface TrainingState {
    [key: string]: any;

    trainingMode?: TrainingMode;
    trainingPhase: TrainingPhase;

    numberOfMemorization?: number;
    isAnswerOnlyMode?: boolean;
    regionSelection?: number;
    isAllRegion?: boolean;
    numberOfCorrect: number;

    currentPage: number;
    correctAnswers: Array<string>;
    correctNationalCapitals: Array<Array<string>>;
    myAnswers: Array<string>;

    trainingModalIsOpen: boolean;
    recallButtonFlg: boolean;

    recallFaceImages: Array<string>,
    memorizationNames: Array<string>,
    memorizationFaceImages: Array<string>,

    initialRemainingRecallTimeMiliSec: number;

    remainingWaitingTimeMiliSec: number;
    remainingMemorizationTimeMiliSec: number;
    remainingRecallTimeMiliSec: number;

    experienceIsAdded: boolean;
}

export const initialState: TrainingState = {
    trainingMode: undefined,
    trainingPhase: TrainingPhase.setting,

    correctAnswers: [],
    correctNationalCapitals: [[]],
    myAnswers: [],

    trainingModalIsOpen: false,
    recallButtonFlg: false,

    recallFaceImages: [],
    memorizationNames: [],
    memorizationFaceImages: [],

    numberOfMemorization: undefined,
    isAnswerOnlyMode: undefined,
    regionSelection: undefined,
    isAllRegion: undefined,
    numberOfCorrect: 0,

    currentPage: 1,

    initialRemainingRecallTimeMiliSec,
    remainingWaitingTimeMiliSec: initialRemainingWaitingTimeMiliSec,
    remainingMemorizationTimeMiliSec: initialRemainingMemorizationTimeMiliSec,
    remainingRecallTimeMiliSec: initialRemainingRecallTimeMiliSec,

    experienceIsAdded: false,
}

interface TrainingMyAnswerPayload {
    index: number,
    word: string,
};

interface Params {
    trainingMode?: TrainingMode;
    numberOfMemorization?: number;
};

export const generateCorrectAnswersRandomly = createAsyncThunk(
    'training/generateCorrectAnswersRandomly',
    async (params: Params, thunkAPI) => {
        const { trainingMode, numberOfMemorization } = params;

        if (!numberOfMemorization || !trainingMode) {
            return undefined;
        }

        if (trainingMode === TrainingMode.number) {
            const newCorrectAnswers = generateRandomNumbers(numberOfMemorization);
            return newCorrectAnswers;
        } else if (trainingMode === TrainingMode.alphabet) {
            const newCorrectAnswers = generateRandomAlphabets(numberOfMemorization);
            return newCorrectAnswers;
        } else if (trainingMode === TrainingMode.word) {
            const newCorrectAnswers = generateRandomWords(numberOfMemorization);
            return newCorrectAnswers;
        } else if (trainingMode === TrainingMode.circleRatio) {
            const newCorrectAnswers = generateCircleRatio(numberOfMemorization);
            return newCorrectAnswers;
        }

        return undefined;
    }
);

const generateRandomNumbers = (numberOfMemorization: number) => {
    const allNumberStrs = range(10);

    const newCorrectAnswers: Array<string> = [];

    for (let i = 0; i < numberOfMemorization; i++) {
        const c = String(sample(allNumberStrs));
        newCorrectAnswers.push(c);
    }

    return newCorrectAnswers;
};

const generateRandomAlphabets = (numberOfMemorization: number) => {
    const allAlphabets = [];
    const smallACode = 'a'.charCodeAt(0);
    const largeACode = 'A'.charCodeAt(0);
    for (let i = 0; i < 26; i++) {
        const smallCode = String.fromCharCode(smallACode + i);
        const largeCode = String.fromCharCode(largeACode + i);

        allAlphabets.push(smallCode);
        allAlphabets.push(largeCode);
    }

    const newCorrectAnswers: Array<string> = [];

    for (let i = 0; i < numberOfMemorization; i++) {
        const c = String(sample(allAlphabets));
        newCorrectAnswers.push(c);
    }

    return newCorrectAnswers;
};

const generateRandomWords = (numberOfMemorization: number) => {
    // 同じ単語が複数回出現しないように、sample()で抽出するのではなくシャッフルしてから先頭n単語を取る
    const newCorrectAnswers: Array<string> = take(shuffle(allWords), numberOfMemorization);
    return newCorrectAnswers;
};

const generateCircleRatio = (numberOfMemorization: number) => {
    return circleRatioStr.split('').slice(0, numberOfMemorization);
};

export const generateRandomCapitals = (isAllRegion: boolean, numberOfMemorization: number, regionSelection?: number) => {

    if (isAllRegion === undefined || !numberOfMemorization || (isAllRegion === false && !regionSelection)) {
        return undefined;
    }

    if (isAllRegion) {
        if (numberOfMemorization === all_capitals_num) {
            let targetCapitals: string[][];
            targetCapitals = [...EuropeCapitals, ...AsiaCapitals, ...AfricaCapitals, ...NorthAmericaCapitals, ...SouthAmericaCapitals, ...OceaniaCapitals];
            const correctNationalCapitals = targetCapitals;
            return correctNationalCapitals;
        } else {
            let targetCapitals: string[][];
            targetCapitals = [...EuropeCapitals, ...AsiaCapitals, ...AfricaCapitals, ...NorthAmericaCapitals, ...SouthAmericaCapitals, ...OceaniaCapitals];
            const correctNationalCapitals = take(shuffle(targetCapitals), numberOfMemorization);
            return correctNationalCapitals;
        }
    } else {
        if (numberOfMemorization === all_capitals_num) {
            if (regionSelection === 1) {
                const correctNationalCapitals = EuropeCapitals;
                return correctNationalCapitals;
            } else if (regionSelection === 2) {
                const correctNationalCapitals = AsiaCapitals;
                return correctNationalCapitals;
            } else if (regionSelection === 3) {
                const correctNationalCapitals = AfricaCapitals;
                return correctNationalCapitals;
            } else if (regionSelection === 4) {
                const correctNationalCapitals = NorthAmericaCapitals;
                return correctNationalCapitals;
            } else if (regionSelection === 5) {
                const correctNationalCapitals = SouthAmericaCapitals;
                return correctNationalCapitals;
            } else if (regionSelection === 6) {
                const correctNationalCapitals = OceaniaCapitals;
                return correctNationalCapitals;
            }
        } else {
            if (regionSelection === 1) {
                const correctNationalCapitals = take(shuffle(EuropeCapitals), numberOfMemorization);
                return correctNationalCapitals;
            } else if (regionSelection === 2) {
                const correctNationalCapitals = take(shuffle(AsiaCapitals), numberOfMemorization);
                return correctNationalCapitals;
            } else if (regionSelection === 3) {
                const correctNationalCapitals = take(shuffle(AfricaCapitals), numberOfMemorization);
                return correctNationalCapitals;
            } else if (regionSelection === 4) {
                const correctNationalCapitals = take(shuffle(NorthAmericaCapitals), numberOfMemorization);
                return correctNationalCapitals;
            } else if (regionSelection === 5) {
                const correctNationalCapitals = take(shuffle(SouthAmericaCapitals), numberOfMemorization);
                return correctNationalCapitals;
            } else if (regionSelection === 6) {
                const correctNationalCapitals = take(shuffle(OceaniaCapitals), numberOfMemorization);
                return correctNationalCapitals;
            }
        }
    }

}

interface NationalCapitalsParams {
    isAllRegion?: boolean;
    numberOfMemorization?: number;
    regionSelection?: number;
};

export const generateCorrectNationalCapitalsRandomly = createAsyncThunk(
    'training/generateCorrectNationalCapitalsRandomly',
    async (params: NationalCapitalsParams, thunkAPI) => {
        const { isAllRegion, numberOfMemorization, regionSelection } = params;

        if (isAllRegion === undefined || !numberOfMemorization || (isAllRegion === false && !regionSelection)) {
            return undefined;
        }

        const correctNationalCapitals = generateRandomCapitals(isAllRegion, numberOfMemorization, regionSelection);
        return correctNationalCapitals;
    }
);

interface NameAndFaceParams {
    numberOfMemorization?: number;
};

export const generateCorrectNamesAndFacesRandomly = createAsyncThunk(
    'training/generateCorrectNamesAndFacesRandomly',
    async (params: NameAndFaceParams, thunkAPI) => {
        const { numberOfMemorization } = params;

        if (!numberOfMemorization) {
            return undefined;
        }

        let newNames = generateRandomNames(numberOfMemorization);
        let newFaces = generateRandomFaces(numberOfMemorization);

        // 通常ケースでもMath.min()がそのまま使えるが、パターンをカバーしたこと明確になるのであえてif文でブロックを作る
        if (newNames.length !== newFaces.length) {
            const m = Math.min(newNames.length, newFaces.length);
            newNames = take(newNames, m);
            newFaces = take(newFaces, m);

            // mを作っているので(string | undeinfed)にはらなない
            return zip(newNames, newFaces) as Array<Array<string>>;
        }

        return zip(newNames, newFaces) as Array<Array<string>>;
        // return undefined;
    }
);

const generateRandomNames = (numberOfMemorization: number) => {
    const newCorrectAnswers: Array<string> = take(shuffle(allNames), numberOfMemorization);
    return newCorrectAnswers;
};

const getAllFaces = () => {
    return [...new Array(1000)].map((_, ind) => {
        const padInd = `${ind}`.padStart(5, '0');
        return `https://bsa.xsrv.jp/memoaca_dsgn/face-images/face_${padInd}.jpg`;
    });
};

const generateRandomFaces = (numberOfMemorization: number) => {
    const allFaces = getAllFaces();

    const newCorrectAnswers: Array<string> = take(shuffle(allFaces), numberOfMemorization);
    return newCorrectAnswers;
};


export const calcAddedExperiencePoint = (trainingMode: TrainingMode, numberOfCorrect: number) => {
    if (trainingMode === TrainingMode.number) {
        return numberOfCorrect;
    } else if (trainingMode === TrainingMode.word) {
        return numberOfCorrect * 2;
    } else if (trainingMode === TrainingMode.alphabet) {
        // 覚えるイメージ数は数字と同じだが、大文字・小文字の区別があるので *2 する仕様
        return numberOfCorrect * 2;
    } else if (trainingMode === TrainingMode.nameAndFace) {
        // 10/3倍
        return Math.floor(numberOfCorrect * 10 / 3);
    } else if (trainingMode === TrainingMode.circleRatio) {
        return numberOfCorrect;
    } else if (trainingMode === TrainingMode.nationalCapitals) {
        return numberOfCorrect;
    }

    return 0;
};

interface AddExperienceParams {
    sub?: string;
    trainingMode?: TrainingMode;
    numberOfCorrect?: number;
};

export const addExperience = createAsyncThunk(
    'training/addExperience',
    async (params: AddExperienceParams, thunkAPI) => {
        const {
            sub,
            trainingMode,
            numberOfCorrect,
        } = params;

        if (!sub || !trainingMode || !numberOfCorrect) {
            throw new Error(`sub, trainingMode or numberOfCorrect is blank: ${sub} ${trainingMode} ${numberOfCorrect}`);
        }

        const getUserQueryVariables: GetUserQueryVariables = {
            sub,
        };

        const getUserRes = await API.graphql(graphqlOperation(getUser, getUserQueryVariables)) as GraphQLResult<GetUserQuery>;

        const fetchedUser = getUserRes.data?.getUser;
        if (!fetchedUser) {
            return;
        }

        const oldUserVersion = fetchedUser._version;
        const oldExperiencePoint = fetchedUser.experiencePoint;

        const addedExperiencePoint = calcAddedExperiencePoint(trainingMode, numberOfCorrect);

        const newExperiencePoint = oldExperiencePoint + addedExperiencePoint;

        // 大量に経験値を獲得するケースを処理できるように、次のレベルの必要経験値かどうかを判定するのではなく、
        // 獲得経験値からレベルを再計算する
        const newExperienceLevel = pointToLevel(newExperiencePoint);

        const updateUserMutationVariables: UpdateUserMutationVariables = {
            input: {
                sub,
                experiencePoint: newExperiencePoint,
                experienceLevel: newExperienceLevel,
                _version: oldUserVersion,
            },
        };

        await API.graphql(graphqlOperation(updateUser, updateUserMutationVariables)) as GraphQLResult<UpdateUserMutation>;

        // 再度APIをリクエストしなくていいように、stateを更新しておく
        // experienceLevel,
        // experiencePoint,
        // addedExperiencePoint,

        if (typeof newExperienceLevel !== 'undefined') {
            await thunkAPI.dispatch(changeExperienceLevel(newExperienceLevel));
        }

        if (typeof newExperiencePoint !== 'undefined') {
            await thunkAPI.dispatch(changeExperiencePoint(newExperiencePoint));
        }

        if (typeof addedExperiencePoint !== 'undefined') {
            await thunkAPI.dispatch(changeAddedExperiencePoint(addedExperiencePoint));
        }
    }
);


export const transformAlphaNumeralToOneByte = (word: string) => {
    return word.replace(/[Ａ-Ｚａ-ｚ０-９]/g, (ch) => String.fromCharCode(ch.charCodeAt(0) - 0xFEE0)); // IGNORE_TERM_CONSISTENCY_RULES
};

const calcNumberOfCorrect = (correctAnswers: Array<string>, myAnswers: Array<string>) => {
    let cnt = 0;
    for (let i = 0; i < correctAnswers.length; i++) {
        const c = correctAnswers[i];
        const m = myAnswers[i];
        if (c && m && c === m) {
            cnt += 1;
        }
    }

    return cnt;
}

export const calcConsecutiveNumberOfCorrect = (correctAnswers: Array<string>, myAnswers: Array<string>) => {
    let cnt = 0;
    for (let i = 0; i < correctAnswers.length; i++) {
        const c = correctAnswers[i];
        const m = myAnswers[i];
        if (c && m && c === m) {
            cnt += 1;
        } else {
            break;
        }
    }
    return cnt;
}

export const trainingSlice = createSlice({
    name: 'training',
    initialState,
    reducers: {
        updateTrainingMode: (state, action: PayloadAction<TrainingMode>) => {
            const trainingMode = action.payload
            state.trainingMode = trainingMode;

            // 国と首都のみ、回答時間が異なるので合わせて変更する。
            if  (trainingMode === TrainingMode.nationalCapitals){
                state.initialRemainingRecallTimeMiliSec = initialRemainingRecallTimeMiliSecOfNationalCapital;
            } else {
                state.initialRemainingRecallTimeMiliSec = initialState.remainingRecallTimeMiliSec;
            }

            // 残り時間も初期化しておく
            state.remainingRecallTimeMiliSec = state.initialRemainingRecallTimeMiliSec;
        },
        initializeWithTrainingMode: (state, action: PayloadAction<TrainingMode | undefined>) => {
            const trainingMode = action.payload;

            // 国と首都のみ、回答時間が異なる
            for (let k of Object.keys(initialState)) {
                if (k === 'trainingMode') {
                    state[k] = trainingMode;
                } else if (k === 'initialRemainingRecallTimeMiliSec') {
                    if (trainingMode === TrainingMode.nationalCapitals) {
                        state.initialRemainingRecallTimeMiliSec = initialRemainingRecallTimeMiliSecOfNationalCapital;
                    } else {
                        state.initialRemainingRecallTimeMiliSec = initialState.initialRemainingRecallTimeMiliSec;
                    }
                } else {
                    state[k] = initialState[k];
                }
            }

            // 残り時間も初期化しておく
            state.remainingRecallTimeMiliSec = state.initialRemainingRecallTimeMiliSec;
        },
        updateTrainingPhase: (state, action: PayloadAction<TrainingPhase>) => {
            state.trainingPhase = action.payload;
        },
        updateNumberOfMemorization: (state, action: PayloadAction<number>) => {
            state.numberOfMemorization = action.payload;
        },
        updateAnswerOnlyMode: (state, action: PayloadAction<boolean>) => {
            state.isAnswerOnlyMode = action.payload;
        },
        updateRegionSelection: (state, action: PayloadAction<number>) => {
            state.regionSelection = action.payload;
        },
        updateAllRegionMode: (state, action: PayloadAction<boolean>) => {
            state.isAllRegion = action.payload;
        },
        updateCurrentPage: (state, action: PayloadAction<number>) => {
            state.currentPage = action.payload;
        },
        updateMyAnswers: (state, action: PayloadAction<TrainingMyAnswerPayload>) => {
            const { index, word } = action.payload;

            state.myAnswers[index] = word.trim();

            if (state.trainingMode === TrainingMode.circleRatio) {
                state.numberOfCorrect = calcConsecutiveNumberOfCorrect(state.correctAnswers, state.myAnswers);
            } else if (state.trainingMode === TrainingMode.nationalCapitals) {
                const correctArray: Array<string> = [];
                for (let i = 0; i < state.correctNationalCapitals.length; i++) {
                    correctArray.push(state.correctNationalCapitals[i][1]);
                }
                state.numberOfCorrect = calcNumberOfCorrect(correctArray, state.myAnswers);
            } else {
                state.numberOfCorrect = calcNumberOfCorrect(state.correctAnswers, state.myAnswers);
            }
        },
        updateTrainingModalIsOpen: (state, action: PayloadAction<boolean>) => {
            state.trainingModalIsOpen = action.payload;
        },
        updateRecallButtonFlg: (state, action: PayloadAction<boolean>) => {
            state.recallButtonFlg = action.payload;
        },
        updateRemainingWaitingTimeMiliSec: (state, action: PayloadAction<number>) => {
            state.remainingWaitingTimeMiliSec = action.payload;
        },
        updateRemainingMemorizationTimeMiliSec: (state, action: PayloadAction<number>) => {
            state.remainingMemorizationTimeMiliSec = action.payload;
        },
        updateRemainingRecallTimeMiliSec: (state, action: PayloadAction<number>) => {
            state.remainingRecallTimeMiliSec = action.payload;
        },
        submitRecallAndTransformAlphaNumeralToOneByte: (state, action: PayloadAction<undefined>) => {
            // 全角->半角への変換をsliceのupdateMyAnswers()内で行おうとすると、単語モードの入力中に再描画が行われて変換が切れてしまい、「文字」と入力したい時に「ｍおｊい」となってしまうので、変換はRecallフェーズの完了後に行う
            // 状態変更のタイミングがズレる可能性があるので、文字の全角半角変換とtrainingPhaseの変更は2つのアクションに分けずにこのアクション内で同時に行う
            for (let i = 0; i < state.myAnswers.length; i++) {
                const word = state.myAnswers[i];
                const replacedAnswer = transformAlphaNumeralToOneByte(word);
                state.myAnswers[i] = replacedAnswer;
            }

            if (state.trainingMode === TrainingMode.circleRatio) {
                state.numberOfCorrect = calcConsecutiveNumberOfCorrect(state.correctAnswers, state.myAnswers);
            } else if (state.trainingMode === TrainingMode.nationalCapitals) {
                const correctArray: Array<string> = [];
                for (let i = 0; i < state.correctNationalCapitals.length; i++) {
                    correctArray.push(state.correctNationalCapitals[i][1]);
                }
                state.numberOfCorrect = calcNumberOfCorrect(correctArray, state.myAnswers);
            } else {
                state.numberOfCorrect = calcNumberOfCorrect(state.correctAnswers, state.myAnswers);
            }

            state.trainingPhase = TrainingPhase.result;
        },
        updateExperienceIsAdded: (state, action: PayloadAction<boolean>) => {
            state.experienceIsAdded = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(generateCorrectAnswersRandomly.fulfilled, (state, action) => {
            const newCorrectAnswers = action.payload;

            if (newCorrectAnswers) {
                state.correctAnswers = newCorrectAnswers;

                const newMyAnswers = newCorrectAnswers.map(c => '');
                state.myAnswers = newMyAnswers;
            }
        });
        builder.addCase(generateCorrectNationalCapitalsRandomly.fulfilled, (state, action) => {
            const newCorrectNationalCapitals = action.payload;

            if (newCorrectNationalCapitals) {
                state.correctNationalCapitals = newCorrectNationalCapitals;

                const newMyAnswers = newCorrectNationalCapitals.map(c => '');
                state.myAnswers = newMyAnswers;
            }
        });
        builder.addCase(generateCorrectNamesAndFacesRandomly.fulfilled, (state, action) => {
            const newCorrectNamesAndFaces = action.payload;

            if (!newCorrectNamesAndFaces) {
                return;
            }

            const newCorrectAnswers = newCorrectNamesAndFaces.map(p => p[0]);
            const newRecallFaceImages = newCorrectNamesAndFaces.map(p => p[1]);

            state.correctAnswers = newCorrectAnswers;
            state.recallFaceImages = newRecallFaceImages;

            const shuffledNamesAndFaces = shuffle(newCorrectNamesAndFaces);
            const newMemorizationNames = shuffledNamesAndFaces.map(p => p[0]);
            const newMemorizationFaceImages = shuffledNamesAndFaces.map(p => p[1]);

            state.memorizationNames = newMemorizationNames;
            state.memorizationFaceImages = newMemorizationFaceImages;


            const newMyAnswers = newCorrectAnswers.map(c => '');
            state.myAnswers = newMyAnswers;
        });
        builder.addCase(addExperience.fulfilled, (state, action) => {
            state.trainingModalIsOpen = true;
        });
    },
})

export const {
    updateTrainingMode,
    initializeWithTrainingMode,
    updateTrainingPhase,
    updateNumberOfMemorization,
    updateAnswerOnlyMode,
    updateRegionSelection,
    updateAllRegionMode,
    updateCurrentPage,
    updateMyAnswers,
    updateTrainingModalIsOpen,
    updateRecallButtonFlg,
    updateRemainingWaitingTimeMiliSec,
    updateRemainingMemorizationTimeMiliSec,
    updateRemainingRecallTimeMiliSec,
    submitRecallAndTransformAlphaNumeralToOneByte,
    updateExperienceIsAdded,
} = trainingSlice.actions;

export const selectTrainingState = (state: RootState) => {
    return {
        trainingMode: state.training.trainingMode,
        trainingPhase: state.training.trainingPhase,

        numberOfMemorization: state.training.numberOfMemorization,
        isAnswerOnlyMode: state.training.isAnswerOnlyMode,
        isAllRegion: state.training.isAllRegion,
        regionSelection: state.training.regionSelection,
        numberOfCorrect: state.training.numberOfCorrect,

        correctAnswers: state.training.correctAnswers,
        correctNationalCapitals: state.training.correctNationalCapitals,
        myAnswers: state.training.myAnswers,

        currentPage: state.training.currentPage,
        trainingModalIsOpen: state.training.trainingModalIsOpen,
        recallButtonFlg: state.training.recallButtonFlg,

        recallFaceImages: state.training.recallFaceImages,
        memorizationNames: state.training.memorizationNames,
        memorizationFaceImages: state.training.memorizationFaceImages,

        initialRemainingRecallTimeMiliSec: state.training.initialRemainingRecallTimeMiliSec,
        remainingWaitingTimeMiliSec: state.training.remainingWaitingTimeMiliSec,
        remainingMemorizationTimeMiliSec: state.training.remainingMemorizationTimeMiliSec,
        remainingRecallTimeMiliSec: state.training.remainingRecallTimeMiliSec,

        experienceIsAdded: state.training.experienceIsAdded,
    } as TrainingState;
};

export default trainingSlice.reducer;
