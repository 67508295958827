import React from 'react';
import LessonBox, {LessonBoxProps} from '../../molecules/LessonBox';
// CSSはインポートした順に生成され、後にインポートしたCSSの方が優先されるので、
// まず他のコンポーネントや共通CSSをインポートして、その後でコンポーネントごとのCSSをインストールすること
// つまり、styles.module.cssのインポートはインポート文の複数行のうちの最後に置いておくのが安全
import styles from './styles.module.css';

export type CourseBoxProps = {
    courseTitle: string;
    courseColor: string;
    lessonBoxList: Array<LessonBoxProps>;
};


const CourseBox : React.FC<CourseBoxProps> = ({
    courseTitle,
    courseColor,
    lessonBoxList,
}) => {
    return (
        <div className={ styles['course-box'] }>
            <h2 className={styles['title-bar'] + ' ' + styles['bg-color-'+courseColor]}>{courseTitle}</h2>
            <div className={styles['lesson-wrap']}>
            {lessonBoxList &&
                lessonBoxList.map((lessonBox, idx) => (
                    <LessonBox
                        key={idx}
                        isDone={lessonBox.isDone}
                        isReadable={lessonBox.isReadable}
                        lessonUrl={lessonBox.lessonUrl}
                        lessonColor={courseColor}
                        lessonNo={lessonBox.lessonNo}
                        lessonTitle={lessonBox.lessonTitle}
                        lessonPage={lessonBox.lessonPage}
                        lessonText={lessonBox.lessonText}
                    />
                ))
            }
            </div>
        </div>
    );
};

export default CourseBox;
