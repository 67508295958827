import React, {
    useState,
    useRef,
    useEffect,
    MutableRefObject,
} from 'react';
import Slide from '../Slide/index';
import { pdfResources } from '../../../lib/pdfResources';
import { JustClickButton } from '../../atoms/Button';
import Img from '../../atoms/Img';
import { LessonSlide } from '../../../config/course';
import { CorrectAnswer } from '../../../lib/domSlide';
import FontEn from '../../atoms/FontEn';
import { CourseColor } from '../../../config/course';
import { useSwipeable, LEFT, RIGHT } from "react-swipeable";
// CSSはインポートした順に生成され、後にインポートしたCSSの方が優先されるので、
// まず他のコンポーネントや共通CSSをインポートして、その後でコンポーネントごとのCSSをインストールすること
// つまり、styles.module.cssのインポートはインポート文の複数行のうちの最後に置いておくのが安全
import styles from './styles.module.css';

export type ChainedSlideProps = {
    slides: Array<LessonSlide>;

    // 全体の中で、何ページ目(0-based)を表示するか。
    // 親コンポーネントに対しては、それぞれのスライドが
    // 何ページずつあるのかは隠蔽する
    defaultThroughoutPageIndex?: number;

    // PDFスライドのみの場合は不要だが、渡し忘れに気付けるようにするためnullは許容しない
    myAnswers: Array<CorrectAnswer>;
    isCheckingAnswers: boolean;

    pdfPassword?: string;

    slideColor: CourseColor;

    chainedSlideRef?: MutableRefObject<HTMLDivElement | null>,

    updateMyAnswersWithIndex: (index: number) => (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void;
    updateIsCheckingAnswersTrue: (e: React.MouseEvent<HTMLButtonElement>) => void;
    lastPageHook: () => void;
    scaleAndScroll: () => void;

    children?: never;
};

export const mapThroughoutPageIndexToDocPage = (numPagesArray: Array<number>, defaultThroughoutPageIndex: number) => {
    const numPagesAcc = [0];
    let acc = 0;
    for (let i of numPagesArray) {
        acc += i;
        numPagesAcc.push(acc);
    }

    // defaultThroughoutPageIndex => docIndex, pageIndex
    let tmpDocIndex = 0;
    let tmpPageIndex = 0;

    for (let i = 0; i < numPagesArray.length; i++) {
        if (numPagesAcc[i] <= defaultThroughoutPageIndex) {
            tmpDocIndex = i;
            tmpPageIndex = defaultThroughoutPageIndex - numPagesAcc[i];
        }
    }

    return {
        tmpDocIndex,
        tmpPageIndex,
    };
};

export const getPrevPage = (docIndex: number, pageIndex: number, numPagesArray: Array<number | null>) => {
    const noMoveAns = {
        newDocIndex: docIndex,
        newPageIndex: pageIndex,
    };

    if (pageIndex === 0) {
        if (docIndex >= 1) {
            const newDocIndex = docIndex - 1;
            const newNumPages = numPagesArray[newDocIndex];
            if (newNumPages === null) {
                return noMoveAns;
            }

            return {
                newDocIndex,
                newPageIndex: newNumPages - 1,
            };
        } else {
            return noMoveAns;
        }
    } else {
        return {
            newDocIndex: docIndex,
            newPageIndex: pageIndex - 1,
        };
    }
};

export const getNextPage = (docIndex: number, pageIndex: number, numPagesArray: Array<number | null>) => {
    const noMoveAns = {
        newDocIndex: docIndex,
        newPageIndex: pageIndex,
    };

    const numPages = numPagesArray[docIndex];
    if (numPages === null) {
        return noMoveAns;
    }

    if (pageIndex === numPages - 1) {
        if (docIndex < numPagesArray.length - 1) {
            const newDocIndex = docIndex + 1;
            const newNumPages = numPagesArray[newDocIndex];

            if (newNumPages === null) {
                return noMoveAns;
            }

            return {
                newDocIndex,
                newPageIndex: 0,
            };
        } else {
            return noMoveAns;
        }
    } else {
        return {
            newDocIndex: docIndex,
            newPageIndex: pageIndex + 1,
        };
    }
};


// 何スライド目の何ページ目か vs 全体を通して何ページ目か
// 下位のコンポーネントの管理はChainedSlidesコンポーネントに任せて、
// 上位からは「全体を通して何ページ目か」で渡す
// …と思ったが、スライドのサムネイルを押してジャンプするとかを考えると、
// もしかするとグローバルに状態を持ったほうがいいかもしれない FIXME

const ChainedSlide: React.FC<ChainedSlideProps> = ({
    slides,
    defaultThroughoutPageIndex = 0,
    myAnswers,
    isCheckingAnswers,
    pdfPassword,
    slideColor,
    chainedSlideRef,
    updateMyAnswersWithIndex,
    updateIsCheckingAnswersTrue,
    lastPageHook,
    scaleAndScroll,
}) => {
    // FIXME: ちょっとマズいかもしれないが、存在しないスライド名が渡された時は、そのスライドを無視するようにしている。後で検討
    // DomSlideの場合は、回答のみなら1ページ、問題あれば2ページ
    const numPagesArray: Array<number> = slides
        .map(slide => {
            if (slide.type === 'pdf') {
                return pdfResources.get(slide.fileName)?.numPages || 0;
            } else if (slide.type === 'wordList' || slide.type === 'keywords' || slide.type === 'longWords') {
                return slide.hasMemorizationSlide ? 2 : 1;
            } else if (slide.type === 'conversionTable' || slide.type === 'cardConversionTable' || slide.type === 'sdgsConversionTable' ||
                slide.type === 'characters' || slide.type === 'images' || slide.type === 'imagesWithSentence' || slide.type === 'manyImages' ||
                slide.type === 'cardImages' || slide.type === 'histricalDates' || slide.type === 'sdgsKeyword' || slide.type === 'fillInTheBlank' ||
                slide.type === 'fillInTheBlankArticle' || slide.type === 'rankTable' || slide.type === 'sentence') {
                return 2;
            } else {
                return 0;
            }
        });

    const acc = [0];
    let s = 0;
    for (let i of numPagesArray) {
        if (i !== null) {
            s += i;
            acc.push(s);
        }
    }

    const totalPageNum = acc[acc.length - 1];

    const { tmpDocIndex, tmpPageIndex } = mapThroughoutPageIndexToDocPage(numPagesArray, defaultThroughoutPageIndex);

    // docIndexとpageIndexが更新されるタイミングにズレがあると不整合が発生するので、
    // 同時に更新できるように1つのオブジェクトで管理する
    const initialIndexObj = {
        docIndex: tmpDocIndex,
        pageIndex: tmpPageIndex,
    };
    const [docPageIndexPair, setDocPageIndexPair] = useState(initialIndexObj);
    const {
        docIndex,
        pageIndex,
    } = docPageIndexPair;

    // ChainedSlideが画面上にあるまま別のスライド(レッスン)に移った際に、最初のページに移動する
    // 別のレッスンに移ったときはtmpSlides(再描画されてても変わらない) !== slides(新しい値になる) になる
    const [tmpSlides, setTmpSlides] = useState(slides);
    if (tmpSlides !== slides) {
        setTmpSlides(slides);

        setDocPageIndexPair(
            {
                docIndex: 0,
                pageIndex: 0,
            }
        );
    }

    const goToPrevPage = () => {
        const { newDocIndex, newPageIndex } = getPrevPage(docIndex, pageIndex, numPagesArray);

        setDocPageIndexPair(
            {
                docIndex: newDocIndex,
                pageIndex: newPageIndex,
            }
        );
    };

    const goToNextPage = () => {
        const { newDocIndex, newPageIndex } = getNextPage(docIndex, pageIndex, numPagesArray);

        setDocPageIndexPair(
            {
                docIndex: newDocIndex,
                pageIndex: newPageIndex,
            }
        );
    };

    const currentPageNum = acc[docIndex] + pageIndex + 1;

    // lastPageHook()が多重に起動することがあったので、
    // 最初のページに戻らないと再発火できないようにした
    const lastPageHookIsFired = useRef(true);
    useEffect(() => {
        if (currentPageNum === totalPageNum) {
            if (!lastPageHookIsFired.current) {
                lastPageHookIsFired.current = true;
                lastPageHook();
            }
        } else if (currentPageNum === 1) {
            lastPageHookIsFired.current = false;
        }
    }, [currentPageNum, totalPageNum, lastPageHookIsFired]);

    const moveOnToPage = (e: any) => {
        if (e.isComposing) {
            return;
        }

        switch (e.code) {
            case 'ArrowLeft': // 左
                goToPrevPage();

                // スクロールはしないようにする
                e.preventDefault();
                break;
            case 'ArrowRight': // 右
                goToNextPage();

                // スクロールはしないようにする
                e.preventDefault();
                break;
        }
    };

    // スワイプでスライドを変更させる
    const swipeSlide = useSwipeable({
        onSwiped: (event: any) => {

            if (event.dir == LEFT) {
                goToNextPage();
            }
            if (event.dir == RIGHT) {
                goToPrevPage();
            }
        },
        //マウス操作でのスワイプを許可する場合はtrue
        trackMouse: true,
    });

    // add,removeEventListenerするために、
    // 再描画時に関数が変わらないようにrefに入れておく
    const moveOnToPageRef = useRef(moveOnToPage);

    useEffect(() => {
        // 進むべきページがpageIndexなどの更新に応じて変わるようにremove/add Eventlistenerする
        document.removeEventListener('keydown', moveOnToPageRef.current);

        moveOnToPageRef.current = moveOnToPage;
        document.addEventListener('keydown', moveOnToPageRef.current, { passive: false });
    }, [docIndex, pageIndex, numPagesArray]);

    const isBlocked = false; //currentPageNum === 7;

    return (
        <div className={styles['chained-slides']} ref={chainedSlideRef} >
            {/* swipeSlideを含むdiv内でのスワイプ動作を検出する*/}
            <div {...swipeSlide} >
                {docIndex !== -1 && pageIndex !== -1 &&
                    (<Slide
                        slide={slides[docIndex]}
                        pageIndex={pageIndex}
                        myAnswers={myAnswers}
                        isCheckingAnswers={isCheckingAnswers}
                        pdfPassword={pdfPassword}
                        slideColor={slideColor}
                        updateMyAnswersWithIndex={updateMyAnswersWithIndex}
                        updateIsCheckingAnswersTrue={updateIsCheckingAnswersTrue}
                    />
                    )}
            </div>

            <div className={styles['slide-control']}>
                <div className={styles['slide-pager']}>
                    {(currentPageNum === 1) ? (
                        // 最初のスライド
                        // ここのopacityの変更をお願いします
                        <JustClickButton className={styles['btn-before'] + ' ' + styles['dead-end']} onClick={goToPrevPage}><Img fileName="icon_trg03.svg" alt="前へ" /></JustClickButton>
                    ) : (
                        <JustClickButton className={styles['btn-before']} onClick={goToPrevPage}><Img fileName="icon_trg03.svg" alt="前へ" /></JustClickButton>
                    )}

                    <FontEn className={styles['slide-page-number']}>{currentPageNum}/{totalPageNum}</FontEn>

                    {(currentPageNum === totalPageNum && !isBlocked) ? (
                        // 最後のスライド
                        // ここのopacityの変更をお願いします
                        <JustClickButton className={styles['btn-next'] + ' ' + styles['dead-end']} onClick={goToNextPage} disabled={isBlocked}><Img fileName="icon_trg03.svg" alt="次へ" /></JustClickButton>
                    ) : (
                        <JustClickButton className={styles['btn-next']} onClick={goToNextPage} disabled={isBlocked}><Img fileName="icon_trg03.svg" alt="次へ" /></JustClickButton>
                    )}
                </div>
                <div className={styles['btn-fullscreen']}><JustClickButton onClick={() => { if (typeof scaleAndScroll !== 'undefined') { scaleAndScroll(); } }}><Img fileName="icon_fullscreen.svg" alt="拡大" /></JustClickButton></div>
            </div>
        </div>
    );
};

export default ChainedSlide;
