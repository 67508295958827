import React from 'react';
import NotationTemplate from '../../templates/NotationTemplate';
import { useAppSelector } from '../../../redux/app/hooks';
import {
    selectLoginState,
} from '../../../redux/slices/loginSlice';
import { selectPaymentState } from '../../../redux/slices/paymentSlice';

export type NotationPageProps = {
    children?: never;
};


const NotationPage : React.FC<NotationPageProps> = () => {
    const {
        isLogin,
        isSocialLogin,
        preferredUsername,
    } = useAppSelector(selectLoginState);

    const {
        isPaid,
    } = useAppSelector(selectPaymentState);

    // to_implement
    const profileImg = undefined;

    return (
        <NotationTemplate
          isLogin={isLogin}
          isPaid={isPaid}
          isSocialLogin={isSocialLogin}
          profileImg={profileImg}
          preferredUsername={preferredUsername}
        />
    );
};

export default NotationPage;
