import React from 'react';
import templateProperties from '../../common/template-properties.module.css';
import { UrlLabel } from '../../../redux/constant';
import InnerLink from '../../atoms/InnerLink';
import HeaderContainer from '../../organisms/HeaderContainer';
import PageTitleArea from '../../layouts/PageTitleArea';
import BreadcrumbBar from '../../organisms/BreadcrumbBar';
import { PageContainer } from '../../layouts/Container';
import NewsList from '../../molecules/NewsList';
import Footer from '../../organisms/Footer';
// import Anchor from '../../atoms/Anchor';
// CSSはインポートした順に生成され、後にインポートしたCSSの方が優先されるので、
// まず他のコンポーネントや共通CSSをインポートして、その後でコンポーネントごとのCSSをインストールすること
// つまり、styles.module.cssのインポートはインポート文の複数行のうちの最後に置いておくのが安全
import styles from './styles.module.css';

Object.assign(styles, templateProperties);

const NewsBL: Array<UrlLabel> = [
    { url: '/mypage', label: 'マイページ' },
    { url: '', label: 'お知らせ一覧' },
];

export type NewsTemplateProps = {
    isLogin: boolean;             // ログイン状態
    isPaid: boolean;              // 有料会員かどうか
    isSocialLogin: boolean;       // SNSログイン会員かどうか
    profileImg?: string;          // 会員画像
    preferredUsername? : string;  // 会員名
};

const NewsTemplate : React.FC<NewsTemplateProps> = ({
    isLogin,
    isPaid,
    isSocialLogin,
    profileImg,
    preferredUsername,
}) => {
    return (
        <div className={ styles['news-template'] + ' ' + styles['template-properties-local'] }>
          <HeaderContainer isLogin={isLogin} isPaid={isPaid} isSocialLogin={isSocialLogin} profileImg={profileImg} preferredUsername={preferredUsername} />
          <PageTitleArea>お知らせ一覧</PageTitleArea>
          {isLogin && <BreadcrumbBar UrlLabels={NewsBL} />}
          <PageContainer>
            <div className={styles['news-area']+' '+styles['news-body']}>
                <div className={styles['news-wrap']}>
                  <NewsList date='2024.06.29'>「国の名前と首都」のトレーニングの回答時間を30分に延長しました。</NewsList>
                </div>
                <div className={styles['news-wrap']}>
                  <NewsList date='2024.05.11'>新たに「国の名前と首都」のトレーニングを追加しました!<br/>有料会員の方は出題される全ての国の首都の覚え方の例を右上のメニューからダウンロードできます!</NewsList>
                </div>
                <div className={styles['news-wrap']}>
                  <NewsList date='2024.03.03'>新たに「円周率記憶法」のコースを追加しました!</NewsList>
                </div>
                <div className={styles['news-wrap']}>
                  <NewsList date='2024.01.30'>新たに「円周率記憶」のトレーニングを追加しました!</NewsList>
                </div>
                <div className={styles['news-wrap']}>
                  <NewsList date='2023.11.28'>「英単語記憶法」の内容を更新しました!</NewsList>
                </div>
                <div className={styles['news-wrap']}>
                  <NewsList date='2023.10.16'>Androidでトレーニングモードの結果をX(旧Twitter)に投稿できない不具合を修正しました。</NewsList>
                </div>
                <div className={styles['news-wrap']}>
                  <NewsList date='2023.10.16'>軽微な修正を行いました。</NewsList>
                </div>
                <div className={styles['news-wrap']}>
                  <NewsList date='2023.9.1'>レッスン画面で左右スワイプでページをめくれるようになりました！</NewsList>
                </div>
                <div className={styles['news-wrap']}>
                  <NewsList date='2023.9.1'>軽微な修正を行いました。</NewsList>
                </div>
                <div className={styles['news-wrap']}>
                  <NewsList date='2023.8.5'>軽微な修正を行いました。</NewsList>
                </div>
                <div className={styles['news-wrap']}>
                  <NewsList date='2023.7.31'>「ペグ法応用編」のコースを追加しました！</NewsList>
                </div>
                <div className={styles['news-wrap']}>
                  <NewsList date='2023.7.31'>軽微な修正を行いました。</NewsList>
                </div>
                <div className={styles['news-wrap']}>
                  <NewsList date='2023.7.13'>軽微な修正を行いました。</NewsList>
                </div>
                <div className={styles['news-wrap']}>
                  <NewsList date='2023.7.3'>軽微な修正を行いました。</NewsList>
                </div>
                <div className={styles['news-wrap']}>
                  <NewsList date='2023.6.17'>「世界遺産記憶」のコースを追加しました！</NewsList>
                </div>
                <div className={styles['news-wrap']}>
                  <NewsList date='2023.6.17'>トレーニングモードの数字記憶やアルファベット記憶で、対象としている種類の文字だけが入力できるようになりました。</NewsList>
                </div>
                <div className={styles['news-wrap']}>
                  <NewsList date='2023.6.17'>軽微な修正を行いました。</NewsList>
                </div>
                <div className={styles['news-wrap']}>
                  <NewsList date='2023.5.11'>新規会員登録画面で、特定の方法を使うと英数字以外の文字をパスワードとして入力することができてしまう不具合を修正しました。もしログインできなくなった方がいましたら、お手数ですが<InnerLink to='/forgot-password'>パスワード再設定</InnerLink>をお願いします。</NewsList>
                </div>
                <div className={styles['news-wrap']}>
                  <NewsList date='2023.5.11'>課金停止を誤って押してしまうことがないように、確認モーダルを表示するようにしました。</NewsList>
                </div>
                <div className={styles['news-wrap']}>
                  <NewsList date='2023.5.11'>軽微な修正を行いました。</NewsList>
                </div>
                <div className={styles['news-wrap']}>
                  <NewsList date='2023.4.11'>「記憶を長期化する方法」の内容を更新しました!</NewsList>
                </div>
                <div className={styles['news-wrap']}>
                  <NewsList date='2023.4.11'>各画面のボタンが押せない状態の時にグレーで表示されるように変更しました!</NewsList>
                </div>
                <div className={styles['news-wrap']}>
                  <NewsList date='2023.4.11'>軽微な修正を行いました。</NewsList>
                </div>
                <div className={styles['news-wrap']}>
                  <NewsList date='2023.4.2'><InnerLink to='/'>トップページ</InnerLink>にメモアカのコーポレートサイトとメモアカclassの説明を追加しました。</NewsList>
                </div>
                <div className={styles['news-wrap']}>
                  <NewsList date='2023.3.26'>軽微な修正を行いました。</NewsList>
                </div>
                <div className={styles['news-wrap']}>
                  <NewsList date='2023.3.12'>軽微な修正を行いました。</NewsList>
                </div>
                <div className={styles['news-wrap']}>
                  <NewsList date='2023.2.28'>コース一覧画面で基本コースと応用コースでタブ分けして表示するように変更しました!</NewsList>
                </div>
                <div className={styles['news-wrap']}>
                  <NewsList date='2023.2.19'>軽微な修正を行いました。</NewsList>
                </div>
                <div className={styles['news-wrap']}>
                  <NewsList date='2023.2.14'>「場所法」「数字記憶法」「年号記憶法」の内容を更新しました。</NewsList>
                </div>
                <div className={styles['news-wrap']}>
                  <NewsList date='2023.2.14'>軽微な修正を行いました。</NewsList>
                </div>
                <div className={styles['news-wrap']}>
                  <NewsList date='2023.2.5'>トレーニングモードで左右の方向キーやバックスペースで入力欄を移動できるようになりました！</NewsList>
                </div>
                <div className={styles['news-wrap']}>
                  <NewsList date='2023.2.5'>軽微な修正を行いました。</NewsList>
                </div>
                <div className={styles['news-wrap']}>
                  <NewsList date='2023.1.24'>軽微な修正を行いました。</NewsList>
                </div>
                <div className={styles['news-wrap']}>
                  <NewsList date='2023.1.17'>「SDGs記憶」のコースを追加しました！</NewsList>
                </div>
                <div className={styles['news-wrap']}>
                  <NewsList date='2023.1.14'>トレーニングモードで全角でも半角でも回答できるようになりました！</NewsList>
                </div>
                <div className={styles['news-wrap']}>
                  <NewsList date='2023.1.14'>軽微な修正を行いました。</NewsList>
                </div>
                <div className={styles['news-wrap']}>
                  <NewsList date='2023.1.9'>軽微な修正を行いました。</NewsList>
                </div>
                <div className={styles['news-wrap']}>
                  <NewsList date='2023.1.4'>トレーニングの結果をツイートできるようになりました！</NewsList>
                </div>
                <div className={styles['news-wrap']}>
                  <NewsList date='2022.12.25'>トレーニングモードのアルファベットのフォントを変更し、文字が区別しやすくなりました！</NewsList>
                </div>
                <div className={styles['news-wrap']}>
                  <NewsList date='2022.12.17'>ログイン画面でエンターキーが使えるようになりました！</NewsList>
                </div>
                <div className={styles['news-wrap']}>
                  <NewsList date='2022.12.11'>トレーニングモードの結果に表示される記憶と回答に関する時間を、残り時間から使った時間に変更しました。</NewsList>
                </div>
                <div className={styles['news-wrap']}>
                  <NewsList date='2022.12.11'>レッスンのスライドを拡大表示している時にEscキーで解除できるようになりました！</NewsList>
                </div>
                <div className={styles['news-wrap']}>
                  <NewsList date='2022.12.11'>レッスンのスライドを十字キーで移動できるようになりました！</NewsList>
                </div>
                <div className={styles['news-wrap']}>
                  <NewsList date='2022.11.27'>「トランプ記憶法」のコースを追加しました！</NewsList>
                </div>
                <div className={styles['news-wrap']}>
                  <NewsList date='2022.11.27'>数字とアルファベットのトレーニングで、入力した時に自動的に次の回答欄に進むようになりました！</NewsList>
                </div>
                <div className={styles['news-wrap']}>
                  <NewsList date='2022.11.27'>レッスンのスライドを拡大表示できるようになりました！</NewsList>
                </div>
                <div className={styles['news-wrap']}>
                  <NewsList date='2022.10.02'>「メモリースポーツとは」のコースを追加しました！</NewsList>
                </div>
                <div className={styles['news-wrap']}>
                  <NewsList date='2022.10.02'>ヘッダーメニューからパスワードを変更できるようになりました。</NewsList>
                </div>
                <div className={styles['news-wrap']}>
                  <NewsList date='2022.09.19'>「記憶術を学ぶ前に」「ストーリー法」「ペグ法」の内容を更新しました。</NewsList>
                </div>
                <div className={styles['news-wrap']}>
                  <NewsList date='2022.09.03'>画面右上のメニューからプロフィール情報を編集できるようになりました！</NewsList>
                </div>
                <div className={styles['news-wrap']}>
                  <NewsList date='2022.08.25'>9月3日(土)の9:00-12:00にシステムメンテナンスを行うため、メモアカをご利用いただくことができなくなります。<br/>ご不便をおかけしますが何卒ご容赦ください。</NewsList>
                </div>
                <div className={styles['news-wrap']}>
                  <NewsList date='2022.08.15'>2桁数字変換表の72, 73の画像に誤りがあったので修正しました。</NewsList>
                </div>
                <div className={styles['news-wrap']}>
                    <NewsList date='2022.08.11'>コース3「ペグ法」までのレッスンと単語トレーニングを一般会員にも開放しました！</NewsList>
                </div>
                <div className={styles['news-wrap']}>
                    <NewsList date='2022.08.11'>有料会員になった時の画面をアップデートしました！</NewsList>
                </div>
                <div className={styles['news-wrap']}>
                    <NewsList date='2022.08.01'>メモアカ サイトをオープンしました！</NewsList>
                </div>
            </div>
          </PageContainer>
          <Footer isLogin={isLogin} />
        </div>
    );
};

export default NewsTemplate;
