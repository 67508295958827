import React from 'react';
import { CourseColor } from '../../../config/course';
import { CorrectAnswer } from '../../../lib/domSlide';
import { SentenceLessonSlide } from '../../../config/course';
import SlidePracticeBackground from '../../atoms/SlidePracticeBackground';
import FontTitle from '../../atoms/FontTitle';
import Textbox from '../../atoms/Textbox';
import { PrimaryButton } from '../../atoms/Button';
// CSSはインポートした順に生成され、後にインポートしたCSSの方が優先されるので、
// まず他のコンポーネントや共通CSSをインポートして、その後でコンポーネントごとのCSSをインストールすること
// つまり、styles.module.cssのインポートはインポート文の複数行のうちの最後に置いておくのが安全
import styles from './styles.module.css';

export type SlidePracticeSentenceProps = {
    pageIndex: number;
    slideColor: CourseColor;
    isCheckingAnswers: boolean;
    myAnswers: Array<CorrectAnswer>;
    updateMyAnswersWithIndex: (index:number) => (e: React.ChangeEvent<HTMLInputElement>) => void;
    updateIsCheckingAnswersTrue: (e: React.MouseEvent<HTMLButtonElement>) => void;
} & Omit<SentenceLessonSlide, 'type'>;


// 2023.05.25 by t.nakata
// 年文章もしくは長い単語を記憶する演習
// 世界遺産記憶コースにおいて6個の長い単語を覚えるためのレイアウト
// 表示問題数が6個以外の場合は調整が必要
const SlidePracticeSentence : React.FC<SlidePracticeSentenceProps> = ({
    pageIndex,
    slideColor,
    memorizationTitle,
    recallTitle,
    resultTitle = '結果を判定します',
    correctAnswers,
    myAnswers,
    isCheckingAnswers,

    updateMyAnswersWithIndex,
    updateIsCheckingAnswersTrue,
}) => {
    return (
        <div className={styles['slide-practice-sentence']}>
            <SlidePracticeBackground bgColor={slideColor} className={styles['dom-slide']}>
                {/* 記憶画面 */}
                { pageIndex === 0 &&
                <div className={styles['dom-slide-area']}>
                    <FontTitle className={styles['dom-slide-title']}>{memorizationTitle}</FontTitle>
                    <div className={styles['dom-slide-content']}>
                        <div className={styles['dom-slide-answerarea']}>
                            {correctAnswers.map((correctAnswer, idx) => {
                                return (
                                    <div className={styles['item-group']} key={idx}>
                                        <span className={styles['item-label']}>{correctAnswer.label}</span>
                                        <span className={styles['input-item']}>{correctAnswer.words[0]}</span>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
                }

                {/* 回答画面 */}
                { pageIndex === 1 && !isCheckingAnswers &&
                <div className={styles['dom-slide-area']}>
                    <FontTitle className={styles['dom-slide-title']}>{recallTitle}</FontTitle>
                    <div className={styles['dom-slide-content']}>
                        <div className={styles['dom-slide-answerarea']}>
                            {correctAnswers.map((correctAnswer, idx) => {
                                return (
                                    <div className={styles['item-group']} key={idx}>
                                        <span className={styles['item-label']}>{correctAnswer.label}</span>
                                        <Textbox
                                            key={idx}
                                            className={styles['input-item']}
                                            value={myAnswers[idx]?.words[0] || []}
                                            onChange={updateMyAnswersWithIndex(idx)}
                                            maxLength={20}
                                        />
                                    </div>
                                );
                            })}
                        </div>
                        <div className={styles['button-area']}>
                            <PrimaryButton className={styles['button']} onClick={updateIsCheckingAnswersTrue}>答え合わせする</PrimaryButton>
                        </div>
                    </div>
                </div>
                }

                {/* 結果画面 */}
                { pageIndex === 1 && isCheckingAnswers &&
                <div className={styles['dom-slide-area']}>
                    <FontTitle className={styles['dom-slide-title']}>{resultTitle}</FontTitle>
                    <div className={styles['dom-slide-content']}>
                        <div className={styles['dom-slide-answerarea']}>
                            {correctAnswers.map((correctAnswer, idx) => {
                                const value = myAnswers[idx]?.words[0] || '';
                                const resultClass = (correctAnswer.words.includes(value))? styles['correct'] : styles['incorrect'];
                                return (
                                    <div className={styles['item-group']} key={idx}>
                                        <span className={styles['item-label']}>{correctAnswer.label}</span>
                                        <span className={styles['input-item']+' '+resultClass}>{myAnswers[idx]?.words[0] || ''}</span>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
                }
            </SlidePracticeBackground>
        </div>
    );
};

export default SlidePracticeSentence;
