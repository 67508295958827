import React, { ReactNode } from 'react';
// CSSはインポートした順に生成され、後にインポートしたCSSの方が優先されるので、
// まず他のコンポーネントや共通CSSをインポートして、その後でコンポーネントごとのCSSをインストールすること
// つまり、styles.module.cssのインポートはインポート文の複数行のうちの最後に置いておくのが安全
import styles from './styles.module.css';

export type DoorwayFormLayoutProps = {
    className?: string,
    title?: string,
    children?: ReactNode,
};


const DoorwayFormLayout : React.FC<DoorwayFormLayoutProps> = ({
    className = '',
    title = 'ログイン',
    children,
}) => {
    // class属性を設定
    let classString = styles['doorway-form-container'];
    if (className) {
        classString = [classString, className].join(' ');
    }

    return (
        <div className={styles['doorway-form-layout']}>
            <h2 className={styles['doorway-form-title']}>{title}</h2>
            <div className={ classString }>
                { children }
            </div>
        </div>
    );
};

export default DoorwayFormLayout;
