import React, { ReactNode, useEffect } from 'react';
import WaitPaymentActivationTemplate from '../../templates/WaitPaymentActivationTemplate';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../../redux/app/hooks';
// import { Auth } from 'aws-amplify'
import {
    fetchMySubscriptions,
} from '../../../redux/slices/paymentSlice';
// CSSはインポートした順に生成され、後にインポートしたCSSの方が優先されるので、
// まず他のコンポーネントや共通CSSをインポートして、その後でコンポーネントごとのCSSをインストールすること
// つまり、styles.module.cssのインポートはインポート文の複数行のうちの最後に置いておくのが安全
import styles from './styles.module.css';


export type WaitPaymentActivationPageProps = {
    // pauseMiliSec?: number,
    children?: ReactNode,
};


const pauseMiliSec = 20000;
const WaitPaymentActivationPage : React.FC<WaitPaymentActivationPageProps> = ({
    // pauseMiliSec = 15000,
    children,
}) => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    useEffect(() => {
        const affect = async () => {
            await dispatch(fetchMySubscriptions({}));
            await Promise.all([
                new Promise(resolve => setTimeout(resolve, pauseMiliSec)),
            ]);
            await dispatch(fetchMySubscriptions({}));
            navigate('/payment');
        };

        affect();

        // 初回レンダリングで別ページに遷移するため、1回だけuseEffect()が呼ばれることを期待したいる
        // よって、第2引数は[]としている。
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className={ styles['logout-page'] }>
            <WaitPaymentActivationTemplate/>
        </div>
    );
};

export default WaitPaymentActivationPage;
