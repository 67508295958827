import React from 'react';
import { CorrectAnswer } from '../../../lib/domSlide';
import SlidePracticeWordListMemorization from '../SlidePracticeWordListMemorization';
import SlidePracticeWordListRecall from '../SlidePracticeWordListRecall';
import { WordListLessonSlide } from '../../../config/course';
import { CourseColor } from '../../../config/course';
// CSSはインポートした順に生成され、後にインポートしたCSSの方が優先されるので、
// まず他のコンポーネントや共通CSSをインポートして、その後でコンポーネントごとのCSSをインストールすること
// つまり、styles.module.cssのインポートはインポート文の複数行のうちの最後に置いておくのが安全
import styles from './styles.module.css';

export type SlidePracticeWordListProps = {
    pageIndex: number;
    slideColor: CourseColor;
    isCheckingAnswers: boolean;
    myAnswers: Array<CorrectAnswer>;
    updateMyAnswersWithIndex: (index:number) => (e: React.ChangeEvent<HTMLInputElement>) => void;
    updateIsCheckingAnswersTrue: (e: React.MouseEvent<HTMLButtonElement>) => void;

    children?: never;
} & Omit<WordListLessonSlide, 'type'>;


const SlidePracticeWordList : React.FC<SlidePracticeWordListProps> = ({
    pageIndex,
    slideColor,
    hasMemorizationSlide = true,
    memorizationTitle,
    recallTitle,
    numCol,
    hasFreeTextarea = false,
    freeTextareaPlaceholder = '',
    correctAnswers,
    myAnswers,
    isCheckingAnswers,

    updateMyAnswersWithIndex,
    updateIsCheckingAnswersTrue,
}) => {
    return (
        <div className={ styles['dom-slide'] }>
            { pageIndex === 0 && <SlidePracticeWordListMemorization
              slideColor={slideColor}
              memorizationTitle={memorizationTitle}
              numCol={numCol}
              hasFreeTextarea={hasFreeTextarea}
              freeTextareaPlaceholder={freeTextareaPlaceholder}
              correctAnswers={correctAnswers}
              />
            }

            { pageIndex === 1 && <SlidePracticeWordListRecall
              slideColor={slideColor}
              recallTitle={recallTitle}
              numCol={numCol}
              correctAnswers={correctAnswers}
              myAnswers={myAnswers}
              isCheckingAnswers={isCheckingAnswers}
              updateMyAnswersWithIndex={updateMyAnswersWithIndex}
              updateIsCheckingAnswersTrue={updateIsCheckingAnswersTrue}
              />
            }
        </div>
    );
};

export default SlidePracticeWordList;
