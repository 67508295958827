import React, {
    useEffect,
} from 'react';
import ProfileTemplate from '../../templates/ProfileTemplate';
import {
    selectLoginState,
} from '../../../redux/slices/loginSlice';
import { useAppSelector, useAppDispatch } from '../../../redux/app/hooks';
import { useNavigate } from 'react-router-dom';
import { selectPaymentState } from '../../../redux/slices/paymentSlice';
// CSSはインポートした順に生成され、後にインポートしたCSSの方が優先されるので、
// まず他のコンポーネントや共通CSSをインポートして、その後でコンポーネントごとのCSSをインストールすること
// つまり、styles.module.cssのインポートはインポート文の複数行のうちの最後に置いておくのが安全
// import styles from './styles.module.css';


import {
    changePreferredUsername,
    changeGender,
    changeGenderOpen,
    changeBirthDate,
    changeProfession,
    changePrefecture,
    changeSelfIntroduction,

    SubmitParams,
    submit,

    selectSetupProfileState,
} from '../../../redux/slices/setupProfileSlice';

export type ProfilePageProps = {
    children?: never;
};


const ProfilePage : React.FC<ProfilePageProps> = (
) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const {
        isLogin,
        isSocialLogin,
        sub,
        preferredUsername: savedPreferredUsername,
        gender: savedGender,
        genderOpen: savedGenderOpen,
        birthDate: savedBirthDate,
        prefecture: savedPrefecture,
        profession: savedProfession,
        selfIntroduction: savedSelfIntroduction,
    } = useAppSelector(selectLoginState);


    useEffect(() => {
        dispatch(changePreferredUsername(savedPreferredUsername || ''));
    },[
        dispatch,
        savedPreferredUsername,
    ]);

    useEffect(() => {
        const convGender = (g: string|undefined) => {
            if (g === 'male') {
                return '男性';
            }
            if (g === 'female') {
                return '女性';
            }
            if (g === 'other') {
                return 'その他';
            }

            return undefined;
        }

        dispatch(changeGender(convGender(savedGender)));
    },[
        dispatch,
        savedGender,
    ]);

    useEffect(() => {
        dispatch(changeGenderOpen(savedGenderOpen || ''));
    },[
        dispatch,
        savedGenderOpen,
    ]);

    useEffect(() => {
        dispatch(changeBirthDate(savedBirthDate));
    },[
        dispatch,
        savedBirthDate,
    ]);

    useEffect(() => {
        dispatch(changePrefecture(savedPrefecture || ''));
    },[
        dispatch,
        savedPrefecture,
    ]);

    useEffect(() => {
        dispatch(changeProfession(savedProfession || ''));
    },[
        dispatch,
        savedProfession,
    ]);

    useEffect(() => {
        dispatch(changeSelfIntroduction(savedSelfIntroduction || ''));
    },[
        dispatch,
        savedSelfIntroduction,
    ]);


    const {
        preferredUsername,
        gender,
        genderOpen,
        birthDate,
        prefecture,
        profession,
        selfIntroduction,

        successMessage,
        preferredUsernameValidationMessage,
        genderValidationMessage,
        genderOpenValidationMessage,
        birthDateValidationMessage,
        professionValidationMessage,
        prefectureValidationMessage,
        rankingOpenValidationMessage,
        selfIntroductionValidationMessage,
    } = useAppSelector(selectSetupProfileState);

    const params : SubmitParams = {
        preferredUsername,
        gender,
        genderOpen,
        birthDate,
        prefecture,
        profession,
        selfIntroduction,

        preferredUsernameValidationMessage,
        genderValidationMessage,
        genderOpenValidationMessage,
        birthDateValidationMessage,
        professionValidationMessage,
        prefectureValidationMessage,
        rankingOpenValidationMessage,
        selfIntroductionValidationMessage,

        sub,
        navigate,
        redirectPage: '/profile',
    };

    const {
        isPaid,
    } = useAppSelector(selectPaymentState);

    return (
        <ProfileTemplate
          isLogin={isLogin}
          isPaid={isPaid}
          isSocialLogin={isSocialLogin}
          // editImgFile=""
          headerPreferredUsername={savedPreferredUsername}
          preferredUsername={preferredUsername}
          gender={gender}
          genderOpen={genderOpen}
          birthDate={typeof birthDate === 'undefined' ? new Date() : new Date(birthDate)}
          profession={profession}
          prefecture={prefecture}
          rankingOpen=""
          selfIntroduction={selfIntroduction}

          successMessage={successMessage}
          // imgFileValidationMessage=""
          preferredUsernameValidationMessage={preferredUsernameValidationMessage}
          genderValidationMessage={genderValidationMessage}
          genderOpenValidationMessage={genderOpenValidationMessage}
          birthDateValidationMessage={birthDateValidationMessage}
          professionValidationMessage={professionValidationMessage}
          prefectureValidationMessage={prefectureValidationMessage}
          rankingOpenValidationMessage={rankingOpenValidationMessage}
          selfIntroductionValidationMessage={selfIntroductionValidationMessage}
          // changeImgFile={nop}

          changePreferredUsername={(e) => { dispatch(changePreferredUsername(e.target.value)) }}
          changeGender={(e) => { dispatch(changeGender(e.target.value)) }}
          changeGenderOpen={(e) => { dispatch(changeGenderOpen(e.target.value)) }}
          changeBirthDate={(date) => { dispatch(changeBirthDate(date.toISOString())) }}
          changeProfession={(e) => { dispatch(changeProfession(e.target.value)) }}
          changePrefecture={(e) => { dispatch(changePrefecture(e.target.value) )}}
          changeRankingOpen={() => {}}
          changeSelfIntroduction={(e) => { dispatch(changeSelfIntroduction(e.target.value)) }}

          submit={() => { dispatch(submit(params)) }}
        />
    );
};

export default ProfilePage;
