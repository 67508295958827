import React, { ReactNode } from 'react';
// CSSはインポートした順に生成され、後にインポートしたCSSの方が優先されるので、
// まず他のコンポーネントや共通CSSをインポートして、その後でコンポーネントごとのCSSをインストールすること
// つまり、styles.module.cssのインポートはインポート文の複数行のうちの最後に置いておくのが安全
import styles from './styles.module.css';

export type BadgeProps = {
    className?: string,
    children?: ReactNode,
} & JSX.IntrinsicElements['span'];


const Badge : React.FC<BadgeProps> = ({
    className,
    children,
}) => {
    // class属性を設定
    let classString = styles['badge'];
    if (className) {
        classString = [classString, className].join(' ');
    }
    return (
        <span className={classString}>{children}</span>
    );
};

const badgeFactory = (className: string, children: ReactNode) => {
    return (bProps: Omit<BadgeProps, 'className' | 'children'>) => {
        return (
            <Badge className={className}  {...bProps} >{children}</Badge>
        );
    };
}

export default Badge;
export const OpenBadge = badgeFactory(styles['open'], '公開');
export const CloseBadge = badgeFactory(styles['close'], '非公開');
// 必須項目
export const RequiredBadge = badgeFactory(styles['open'], '必須');  // 単体で使う時
export const RequiredAstariskBadge = badgeFactory(styles['required-astarisk'], '＊');   // 公開非公開と同時に使う時
