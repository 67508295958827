import React from 'react';
import ReactModal from 'react-modal';
import ExperienceInfo from '../ExperienceInfo';
import Button from '../../atoms/Button';
// CSSはインポートした順に生成され、後にインポートしたCSSの方が優先されるので、
// まず他のコンポーネントや共通CSSをインポートして、その後でコンポーネントごとのCSSをインストールすること
// つまり、styles.module.cssのインポートはインポート文の複数行のうちの最後に置いておくのが安全
import styles from './styles.module.css';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        borderRadius: '15px',
        width: '300px',
        height: '200px',
        //   padding: '20px 25px 10px',
        padding: '0',
        transform: 'translate(-50%, -50%)',
        zIndex: '99999',
    },
}

export type PointModalProps = {
    modalIsOpen: boolean;
    experiencePoint: number;        // 経験値
    addedExperiencePoint: number;   // 獲得経験値

    buttonText?: string;             // ボタンに表示するテキスト
    clickButton: (e: React.MouseEvent<HTMLButtonElement>) => void;  // ボタンをクリックしたときの動作
};

// ReactModal.setAppElement('modal-target');
const PointModal: React.FC<PointModalProps> = ({
    modalIsOpen,
    experiencePoint,
    addedExperiencePoint,

    buttonText = '閉じる',
    clickButton,
}) => {

    return (
        <ReactModal
            contentLabel="経験値のお知らせ"
            isOpen={modalIsOpen}
            style={customStyles}
            ariaHideApp={false}
            overlayClassName={styles['modal-overlay']}
        >
            {/* 獲得経験値が0の時は獲得ポイントを表示しない */}
            {(addedExperiencePoint === 0) ? (
                <><div className={styles['modal-content-0pt-area']}>
                    <div className={styles['modal-content']}>
                        <ExperienceInfo experiencePoint={experiencePoint} addedExperiencePoint={addedExperiencePoint} />
                        <div className={styles['button-wrap']}>
                            <Button className={styles['button']} onClick={clickButton}>{buttonText}</Button>
                        </div>
                    </div>
                </div></>
            ) : (
                <><h2 className={styles['modal-header']}>経験値 {addedExperiencePoint}pt 獲得！</h2>
                    <div className={styles['modal-content-area']}>
                        <div className={styles['modal-content']}>
                            <ExperienceInfo experiencePoint={experiencePoint} addedExperiencePoint={addedExperiencePoint} />
                            <div className={styles['button-wrap']}>
                                <Button className={styles['button']} onClick={clickButton}>{buttonText}</Button>
                            </div>
                        </div>
                    </div></>
            )}
        </ReactModal>
    );
};

export default PointModal;
