import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { UrlLabel } from '../../../redux/constant';
import InnerLink from '../../atoms/InnerLink';
import 'react-tabs/style/react-tabs.css';
import "./react-tabs.customized.css";       // 上書きcss
import { CourseLesson } from '../../../lib/props';
// CSSはインポートした順に生成され、後にインポートしたCSSの方が優先されるので、
// まず他のコンポーネントや共通CSSをインポートして、その後でコンポーネントごとのCSSをインストールすること
// つまり、styles.module.cssのインポートはインポート文の複数行のうちの最後に置いておくのが安全
import styles from './styles.module.css';

export type CourseListProps = {
    basicCourseList?: Array<UrlLabel>;      // 基本コース一覧
    advancedCourseList?: Array<UrlLabel>;   // 応用コース一覧
    courseLessonList?: Array<CourseLesson>; // 直近視聴したコース名とそのレッスン一覧
    courseDefaultTabIndex?: number;         // 直近視聴したコースに応じたコース一覧の初期タブ選択
};

const CourseList: React.FC<CourseListProps> = ({
    basicCourseList,
    advancedCourseList,
    courseLessonList,
    courseDefaultTabIndex = 0,
}) => {

    return (
        <div className={styles['course-list']}>
            <Tabs defaultIndex={courseDefaultTabIndex}>
                <TabList>
                    <Tab>基本コース</Tab>
                    <Tab>応用コース</Tab>
                </TabList>

                <TabPanel>
                    <div className={styles['course-nav']}>
                        <ul>
                            {basicCourseList &&
                                basicCourseList.map((urlLabel, idx) => {
                                    // APIから取得コース名
                                    const courseTitle = courseLessonList && courseLessonList[0] && courseLessonList[0].courseTitle;

                                    // 表示上のコース名には[無料]や[一部無料]が入ることがあるので、それを除いて比較する
                                    const isMatching = courseTitle === urlLabel.label.replace(/\[.*?\]/, '');

                                    return (
                                        <li key={idx}>
                                            {isMatching ? (
                                                <InnerLink to={urlLabel.url} className={styles['present-course']}>{urlLabel.label}</InnerLink>
                                            ) : (
                                                <InnerLink to={urlLabel.url}>{urlLabel.label}</InnerLink>
                                            )}
                                        </li>
                                    );
                                })
                            }
                        </ul>
                    </div>
                </TabPanel>
                <TabPanel>
                    <div className={styles['course-nav']}>
                        <ul>
                            {advancedCourseList &&
                                advancedCourseList.map((urlLabel, idx) => {
                                    const courseTitle = courseLessonList && courseLessonList[0] && courseLessonList[0].courseTitle;
                                    const isMatching = courseTitle === urlLabel.label;

                                    return (
                                        <li key={idx}>
                                            {isMatching ? (
                                                <InnerLink to={urlLabel.url} className={styles['present-course']}>{urlLabel.label}</InnerLink>
                                            ) : (
                                                <InnerLink to={urlLabel.url}>{urlLabel.label}</InnerLink>
                                            )}
                                        </li>
                                    );
                                })
                            }
                        </ul>
                    </div>
                </TabPanel>
            </Tabs>
        </div>
    );
};

export default CourseList;
