import React, { ReactNode } from 'react';
import ReactModal from 'react-modal';
import Button from '../../atoms/Button';
// CSSはインポートした順に生成され、後にインポートしたCSSの方が優先されるので、
// まず他のコンポーネントや共通CSSをインポートして、その後でコンポーネントごとのCSSをインストールすること
// つまり、styles.module.cssのインポートはインポート文の複数行のうちの最後に置いておくのが安全
import styles from './styles.module.css';

const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      borderRadius: '15px',
      width: '280px',
      height: '180px',
    //   padding: '20px 25px 10px',
      padding: '0',
      transform: 'translate(-50%, -50%)',
      zIndex: '99999',
    },
}


export type OkCancelModalProps = {
    modalIsOpen: boolean;
    headerText: string;
    children: ReactNode;
    okButtonText?: string;
    cancelButtonText?: string;
    clickOkButton: (e: React.MouseEvent<HTMLButtonElement>) => void;  // イエスボタンをクリックしたときの動作
    clickCancelButton: (e: React.MouseEvent<HTMLButtonElement>) => void;  // イエスボタンをクリックしたときの動作
};

// ReactModal.setAppElement('modal-target');
const OkCancelModal : React.FC<OkCancelModalProps> = ({
    modalIsOpen,
    headerText,
    children,
    okButtonText = 'OK',
    cancelButtonText = 'キャンセル',
    clickOkButton,
    clickCancelButton,
}) => {
    return (
        <ReactModal
        contentLabel={headerText}
        isOpen={modalIsOpen}
        style={customStyles}
        ariaHideApp={false}
        overlayClassName={styles['modal-overlay']}
    >
        <h2 className={styles['modal-header']}>{headerText}</h2>
        <div className={styles['modal-content-area']}>
            <div className={styles['content-text-area']}>
                {children}
            </div>
            <div className={styles['button-wrap']}>
                <Button className={styles['button']+' '+styles['ok']} onClick={clickOkButton}>{okButtonText}</Button>
                <Button className={styles['button']} onClick={clickCancelButton}>{cancelButtonText}</Button>
            </div>
        </div>
    </ReactModal>
    );
};

export default OkCancelModal;
