import React, {
    useEffect,
} from 'react';
import WaitWithdrawalTemplate from '../../templates/WaitWithdrawalTemplate';
// CSSはインポートした順に生成され、後にインポートしたCSSの方が優先されるので、
// まず他のコンポーネントや共通CSSをインポートして、その後でコンポーネントごとのCSSをインストールすること
// つまり、styles.module.cssのインポートはインポート文の複数行のうちの最後に置いておくのが安全
// import styles from './styles.module.css';

export type WaitWithdrawalPageProps = {
    children?: never;
};


const WaitWithdrawalPage : React.FC<WaitWithdrawalPageProps> = () => {
    const affect = async () => {
        const sleep = (msec:number) => new Promise((resolve) => setTimeout(resolve, msec));
        await sleep(3000);
        window.location.href = 'https://www.memoaca.com';
    }

    useEffect(() => {
        affect();
    });

    return (
        <WaitWithdrawalTemplate/>
    );
};

export default WaitWithdrawalPage;
