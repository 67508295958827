import React, { useState, useRef, useEffect } from 'react';
import HeaderPresenter from '../HeaderPresenter';

export type HeaderContainerProps = {
    isLogin?: boolean,
    isPaid: boolean,
    isSocialLogin: boolean,
    profileImg?: string;
    preferredUsername?: string;
    defaultShowMenu?: boolean;    
};

const HeaderContainer : React.FC<HeaderContainerProps> = ({
    isLogin = false,
    isPaid,
    isSocialLogin,
    profileImg = "profile_dummy.jpg",
    preferredUsername = "guest",
    defaultShowMenu = false,
}) => {    
    const [isShowMenu, setShowMenu] = useState(defaultShowMenu);
    const changeShowMenuState = (argument: string) => {
        switch (argument) {
            case 'close':
                // fall through
            case 'false':
                return () => {
                    setShowMenu(false);
                }
            case 'toggle':
                // fall through
            default:
                return () => {
                    setShowMenu(isShowMenu => !isShowMenu);
                }
        }
    }

    const minPCWindowWidth = 750;
    const [isPCWindow, setPCWindow] = useState(true);
    
    const onResize = () => {
        if (window.innerWidth < minPCWindowWidth) {
            setPCWindow(false);
        } else {
            setPCWindow(true);
        }
    };
    
    // add,removeEventListenerするために、
    // 再描画時に関数が変わらないようにrefに入れておく
    const onResizeRef = useRef(onResize);
    
    useEffect(() => {
        // 以前のonResizeをremoveEventListenerしてから、新しいonResizeをaddEventListenerする
        window.removeEventListener('resize', onResizeRef.current);
    
        onResizeRef.current = onResize;
        window.addEventListener('resize', onResizeRef.current);
    
        onResizeRef.current();
    
        return () => {
            window.removeEventListener('resize', onResizeRef.current);
        };
    }, [onResize])
  
    return (
        <HeaderPresenter
            isLogin={isLogin}
            isShowMenu={isShowMenu}
            isPaid={isPaid}
            isSocialLogin={isSocialLogin}
            profileImg={profileImg}
            preferredUsername={preferredUsername}
            isPCWindow={isPCWindow}
            changeShowMenuState={changeShowMenuState}
        />
    );
};

export default HeaderContainer;
