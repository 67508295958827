/*
    SignupTemplate
    画面状態を表す定数
*/
export enum SignupPhase {
    signup = 'signup',
    confirm = 'confirm',            // コード確認画面
};

/*
    SendPasswordTemplate
    画面状態を表す定数
*/
export enum SendPasswordPhase {
    sendCode = 'sendCode',          // 確認コード発行画面
    newPassword = 'newPassword',    // コード確認と新パスワード設定画面
    postprocess = 'postprocess',    // 後処理画面
}

/*
    ContactTemplate
    画面状態を表す定数
*/
export enum ContactPhase {
    send = 'send',                  // 送信画面
    confirm = 'confirm',            // 確認画面
    postprocess = 'postprocess',    // 後処理画面
}


/* トレーニング関連 */
export enum TrainingMode {
    number = 'number',              // 数字
    word = 'word',                  // 単語
    alphabet = 'alphabet',          // アルファベット
    nameAndFace = 'nameAndFace',    // 顔と名前
    circleRatio = 'circleRatio',    // 円周率
    nationalCapitals = 'nationalCapitals' // 国と首都
}

export enum TrainingPhase {
    setting = 'setting',            // いくつ覚えますか
    waiting = 'waiting',            // カウントダウン画面
    memorization = 'memorization',  // 記憶画面
    recall = 'recall',              // 回答画面
    result = 'result',              // 結果画面
}

export type NumberOfMemorize = {
    number: number,
    label: string,
}

export type RegionSelection = {
    number: number,
    label: string,
}

// Stripe Documentに記載のあるサブスクリプションの状態
// https://stripe.com/docs/api/subscriptions/object
// Stripeから返ってくる文字列が決まっているので、''内の文字列はメモアカ側で勝手に変更できないので注意
export enum StripeSubscriptionStatus {
    active = 'active',
    pastDue = 'past_due',
    unpaid = 'unpaid',
    canceled = 'canceled',
    incomplete = 'incomplete',
    incompleteExpired = 'incomplete_expired',
    trialing = 'trialing',
    paused = 'paused',
}

export const TrainingNumberSetting: Array<NumberOfMemorize> = [
    { number: 10, label: '10桁' },
    { number: 20, label: '20桁' },
    { number: 30, label: '30桁' },
    { number: 40, label: '40桁' },
    { number: 50, label: '50桁' },
];

export const TrainingWordSetting: Array<NumberOfMemorize> = [
    { number: 5, label: '5個' },
    { number: 10, label: '10個' },
    { number: 15, label: '15個' },
    { number: 20, label: '20個' },
    { number: 25, label: '25個' },
];

export const TrainingAlphabetSetting: Array<NumberOfMemorize> = [
    { number: 5, label: '5個' },
    { number: 10, label: '10個' },
    { number: 15, label: '15個' },
    { number: 20, label: '20個' },
    { number: 25, label: '25個' },
];

export const TrainingNameAndFaceSetting: Array<NumberOfMemorize> = [
    { number: 3, label: '3人' },
    { number: 6, label: '6人' },
    { number: 9, label: '9人' },
    { number: 12, label: '12人' },
    { number: 15, label: '15人' },
];

export const TrainingCircleRatioSetting: Array<NumberOfMemorize> = [
    { number: 20, label: '20桁' },
    { number: 50, label: '50桁' },
    { number: 100, label: '100桁' },
];

/* 国の名前と首都：すべての国の問題数に使用する定数 */
export const all_capitals_num = 999;

export const TrainingNationalCapitalsSetting: Array<NumberOfMemorize> = [
    { number: all_capitals_num, label: 'すべての国' },
    { number: 10, label: '10か国' },
];

export const TrainingRegionSelectionSetting: Array<RegionSelection> = [
    { number: 1, label: 'ヨーロッパ' },
    { number: 2, label: 'アジア' },
    { number: 3, label: 'アフリカ' },
    { number: 4, label: '北アメリカ' },
    { number: 5, label: '南アメリカ' },
    { number: 6, label: 'オセアニア' },
];

/* トレーニングページ：1ページあたりの表示数 */
// 直接エクスポートせず、getMaxCell()を経由して使う
const maxCellDict: Partial<{ [key in TrainingMode]: number }> = {
    [TrainingMode.circleRatio]: 50,
    [TrainingMode.nationalCapitals]: 48,
};

export const getMaxCell = (trainingMode: TrainingMode): number => {
    const defaultValue = 50;
    return maxCellDict[trainingMode] || defaultValue;
};

/* URLとラベルの組み合せ */
export type UrlLabel = {
    url: string,
    label: string,
    targetBlank?: boolean,
}

/* パンくずリスト設定 */
export const TrainingNumberBL: Array<UrlLabel> = [
    { url: '/mypage', label: 'マイページ' },
    { url: '/mypage#training-list', label: 'トレーニング一覧' },
    { url: '', label: '数字を覚えよう' },
];

export const TrainingWordBL: Array<UrlLabel> = [
    { url: '/mypage', label: 'マイページ' },
    { url: '/mypage#training-list', label: 'トレーニング一覧' },
    { url: '', label: '単語を覚えよう' },
];

export const TrainingAlphabetBL: Array<UrlLabel> = [
    { url: '/mypage', label: 'マイページ' },
    { url: '/mypage#training-list', label: 'トレーニング一覧' },
    { url: '', label: 'アルファベットを覚えよう' },
];

export const TrainingNameAndFaceBL: Array<UrlLabel> = [
    { url: '/mypage', label: 'マイページ' },
    { url: '/mypage#training-list', label: 'トレーニング一覧' },
    { url: '', label: '顔と名前を覚えよう' },
];

export const TrainingCircleRatioBL: Array<UrlLabel> = [
    { url: '/mypage', label: 'マイページ' },
    { url: '/mypage#training-list', label: 'トレーニング一覧' },
    { url: '', label: '円周率を覚えよう' },
];

export const TrainingNationalCapitalsBL: Array<UrlLabel> = [
    { url: '/mypage', label: 'マイページ' },
    { url: '/mypage#training-list', label: 'トレーニング一覧' },
    { url: '', label: '国の名前と首都を覚えよう' },
];

/*
    PulldownList
    Options定数
*/
// 公開非公開
export const ConstOpenClose = [
    '公開する',
    '公開しない'
];

// 公開非公開
export const ConstGender = [
    '男性',
    '女性',
    'その他'
];

// 都道府県
export const ConstPrefecture = [
    '北海道',
    '青森県',
    '岩手県',
    '宮城県',
    '秋田県',
    '山形県',
    '福島県',
    '茨城県',
    '栃木県',
    '群馬県',
    '埼玉県',
    '千葉県',
    '東京都',
    '神奈川県',
    '新潟県',
    '富山県',
    '石川県',
    '福井県',
    '山梨県',
    '長野県',
    '岐阜県',
    '静岡県',
    '愛知県',
    '三重県',
    '滋賀県',
    '京都府',
    '大阪府',
    '兵庫県',
    '奈良県',
    '和歌山県',
    '鳥取県',
    '島根県',
    '岡山県',
    '広島県',
    '山口県',
    '徳島県',
    '香川県',
    '愛媛県',
    '高知県',
    '福岡県',
    '佐賀県',
    '長崎県',
    '熊本県',
    '大分県',
    '宮崎県',
    '鹿児島県',
    '沖縄県',
];

export const allWords = [
    '100万',
    '10億',
    '10月',
    '11月',
    '12月',
    '1時間',
    '1月',
    '2回',
    '2月',
    '3月',
    '4月',
    '5月',
    '6月',
    '7月',
    '8月',
    '9月',
    'いす',
    'いつか',
    'いとこ',
    'えんぴつ',
    'おいしい',
    'おかしな',
    'おしゃれな',
    'おじ',
    'おば',
    'おもちゃ',
    'お互い',
    'お化け',
    'お気に入り',
    'お湯',
    'お茶',
    'お金',
    'お願いする',
    'かせぐ',
    'かたい',
    'かっこいい',
    'かど',
    'かばん',
    'かわいい',
    'がん',
    'きれいな',
    'くさり',
    'くず',
    'くつ',
    'こぐ',
    'ここ',
    'こわがる',
    'ごみ',
    'さびしい',
    'さわる',
    'じゅうたん',
    'すばらしい',
    'そうじする',
    'その他',
    'たくさん',
    'ちがい',
    'ちらりと見る',
    'つかむ',
    'つづる',
    'つながる',
    'のぼる',
    'はかる',
    'ばね',
    'ひげ',
    'ひざ',
    'ひどい',
    'びっくりする',
    'ふつうの',
    'ふるまい',
    'ほほえむ',
    'まわり',
    'みがく',
    'みんな',
    'やさしい',
    'やさしく',
    'やわらかい',
    'ゆでる',
    'ろうそく',
    'わくわくする',
    'アジ',
    'アメリカ',
    'アリ',
    'イヌ',
    'イヤホン',
    'ウイルス',
    'ウサギ',
    'ウシ',
    'ウマ',
    'エプロン',
    'オペラ',
    'オリーブ',
    'カエル',
    'カップ',
    'カツオ',
    'ガソリン',
    'ガラス',
    'キジ',
    'キット',
    'キツネ',
    'ギター',
    'クッキー',
    'クッション',
    'クマ',
    'グミ',
    'ケーキ',
    'コメディ',
    'コーヒー',
    'ゴミ箱',
    'ゴリラ',
    'サイレン',
    'サイン',
    'サッカー',
    'サラダ',
    'サラリーマン',
    'サル',
    'サングラス',
    'シャイ',
    'シャツ',
    'シュークリーム',
    'ジャケット',
    'ジュース',
    'スイカ',
    'スープ',
    'ゾウ',
    'タイヤ',
    'タオル',
    'タンバリン',
    'ダム',
    'チョコレート',
    'テレビ',
    'トマト',
    'トラ',
    'トランプ',
    'トロフィー',
    'ドア',
    'ナイフ',
    'ネクタイ',
    'ネズミ',
    'ネックレス',
    'ノート',
    'バス',
    'バナナ',
    'パスタ',
    'パン',
    'パーカー',
    'ヒツジ',
    'ビスケット',
    'ビデオ',
    'ビニールぶくろ',
    'ビール',
    'ファイル',
    'ブタ',
    'ブドウ',
    'ブラジル',
    'プロテイン',
    'ヘビ',
    'ヘリコプター',
    'ベッド',
    'ペア',
    'ホール',
    'ボウル',
    'ポット',
    'マグロ',
    'マスク',
    'メダル',
    'モモ',
    'ライト',
    'ラクダ',
    'ラジオ',
    'リズム',
    'リモコン',
    'リンゴ',
    'ワサビ',
    '一生懸命',
    '一種',
    '一緒',
    '一般的',
    '上',
    '上級',
    '下',
    '不公平な',
    '不審者',
    '不幸な',
    '不快な',
    '不思議',
    '不自由な',
    '与える',
    '世界',
    '世紀',
    '世話',
    '丘',
    '両方',
    '中国',
    '中国語',
    '中学校',
    '中心',
    '中身',
    '中退する',
    '中間',
    '丸',
    '丸い',
    '主人',
    '主催する',
    '乗りこえる',
    '乗る',
    '乾く',
    '乾燥',
    '予約',
    '事例',
    '事務所',
    '事故',
    '二酸化炭素',
    '交換',
    '人',
    '人々',
    '人口',
    '人形',
    '人権',
    '人気',
    '人気のある',
    '人生',
    '人間',
    '今',
    '今夜',
    '今日',
    '仕事',
    '他',
    '他人',
    '以前',
    '企業',
    '休む',
    '休息',
    '休日',
    '休暇',
    '会う',
    '会社',
    '会議',
    '伝える',
    '伝統',
    '伝統的',
    '低い',
    '住む',
    '住所',
    '体育館',
    '体験',
    '作る',
    '作家',
    '使い果たす',
    '使う',
    '例',
    '例外',
    '供給',
    '依存する',
    '価値がある',
    '便利',
    '便利な',
    '促す',
    '保つ',
    '保存する',
    '保護する',
    '信じる',
    '信号',
    '信用する',
    '俳優',
    '個人',
    '個人の',
    '倒れる',
    '借りる',
    '借金',
    '値段',
    '偉大',
    '停止する',
    '健康',
    '健康な',
    '側面',
    '傘',
    '傷つける',
    '像',
    '元気',
    '元気になる',
    '兄弟',
    '充電する',
    '先生',
    '光',
    '入る',
    '入口',
    '入浴',
    '全部',
    '公園',
    '共有する',
    '共通',
    '内側',
    '内部',
    '円',
    '再生',
    '写真',
    '冷たい',
    '冷房',
    '処理する',
    '出席する',
    '出身',
    '分',
    '分かる',
    '切る',
    '切手',
    '列',
    '列車',
    '初心者',
    '利口な',
    '到着する',
    '到達',
    '制服',
    '前',
    '前向き',
    '創立する',
    '創造する',
    '創造力',
    '劇',
    '劇場',
    '加える',
    '助言',
    '努力',
    '効果的',
    '効果的な',
    '勇敢な',
    '勇気',
    '勇気づける',
    '勉強',
    '勉強する',
    '動かす',
    '動く',
    '動物',
    '動物園',
    '勝つ',
    '勝者',
    '包む',
    '化学物質',
    '化石',
    '化石燃料',
    '北',
    '医者',
    '千',
    '千円',
    '午前',
    '午後',
    '半分',
    '卒業',
    '卒業する',
    '南',
    '単純',
    '博物館',
    '印',
    '印刷する',
    '危険',
    '危険な',
    '卵',
    '厚い',
    '原子力',
    '厳しい',
    '去る',
    '参加する',
    '友だち',
    '友達',
    '反対する',
    '取り除く',
    '取る',
    '受け入れる',
    '受け取る',
    '口',
    '口紅',
    '古い',
    '古代',
    '叫ぶ',
    '可笑しい',
    '可能',
    '可能性',
    '右',
    '各々',
    '同じ',
    '同じもの',
    '同一',
    '同級生',
    '名前',
    '名札',
    '向く',
    '否定的な',
    '含む',
    '吹く',
    '周囲',
    '和服',
    '唇',
    '商人',
    '商売',
    '問題',
    '喜び',
    '嘘',
    '器',
    '困難',
    '囲む',
    '図書館',
    '図表',
    '国',
    '国内',
    '国境',
    '国家の',
    '国際',
    '国際的な',
    '土',
    '土地',
    '土曜日',
    '地下鉄',
    '地図',
    '地帯',
    '地平線',
    '地方',
    '地球',
    '地震',
    '地面',
    '埋める',
    '基準',
    '報告',
    '場合',
    '場所',
    '塀',
    '塔',
    '塩',
    '増やす',
    '増加',
    '壊れる',
    '壮大',
    '声の大きい',
    '売り物',
    '売る',
    '変える',
    '変わる',
    '変化',
    '夏',
    '夕方',
    '夕食',
    '外',
    '外側の',
    '外出する',
    '外国',
    '外食する',
    '多くの',
    '多分',
    '夜',
    '夢',
    '大きい',
    '大切',
    '大学',
    '大学院',
    '大統領',
    '大部分',
    '天使',
    '天国',
    '天気',
    '太る',
    '太陽',
    '太鼓',
    '夫',
    '夫婦',
    '失う',
    '失敗する',
    '奇妙な',
    '女王',
    '好き',
    '好む',
    '好奇心',
    '好意的な',
    '妻',
    '姉妹',
    '始まる',
    '始める',
    '娘',
    '嫌う',
    '嬉しい',
    '子ども',
    '子供',
    '子守歌',
    '存続',
    '季節',
    '季節の',
    '学ぶ',
    '学校',
    '宇宙',
    '守備',
    '安い',
    '安全',
    '完全',
    '完全な',
    '定規',
    '宝物',
    '宝石',
    '実現する',
    '実用的な',
    '客',
    '宮殿',
    '家',
    '家庭',
    '家族',
    '容器',
    '宿題',
    '寂しい',
    '寄付する',
    '寝る',
    '審判員',
    '寺',
    '封筒',
    '将来',
    '尊敬する',
    '導く',
    '小さい',
    '小さな',
    '小型の',
    '小学校',
    '小説',
    '小麦粉',
    '少し',
    '少女',
    '少年',
    '少年時代',
    '少数',
    '居住者',
    '屋外',
    '屋根',
    '展示',
    '山',
    '岩',
    '島',
    '川',
    '巣',
    '工事',
    '工場',
    '左',
    '差別',
    '市場',
    '市民',
    '市長',
    '布',
    '希望',
    '帰り',
    '帰る',
    '帽子',
    '幅が広い',
    '幅の広い',
    '平らな',
    '平和',
    '平和な',
    '年',
    '年をとる',
    '年下',
    '年配',
    '年齢',
    '幸せ',
    '幸せな',
    '幸福',
    '幸運',
    '幸運な',
    '広い',
    '広がる',
    '広げる',
    '広まる',
    '床屋',
    '店',
    '店員',
    '座る',
    '廃棄物',
    '廊下',
    '建てる',
    '建物',
    '弁護士',
    '引く',
    '引っ越す',
    '弱い',
    '強い',
    '当てはまる',
    '当時は',
    '彗星',
    '形',
    '影',
    '影響',
    '影響を与える',
    '役に立つ',
    '役割',
    '彼',
    '彼女',
    '待つ',
    '後',
    '後ろ',
    '従う',
    '従業員',
    '得る',
    '心',
    '心配する',
    '必要',
    '忘れる',
    '忙しい',
    '忠告',
    '怒る',
    '怖がる',
    '思い出す',
    '思う',
    '怠け者',
    '急ぐ',
    '急速な',
    '恐ろしい',
    '恐怖',
    '恐竜',
    '恥じる',
    '息子',
    '悪い',
    '悪魔',
    '悲しい',
    '情報',
    '惑星',
    '想像する',
    '意味',
    '意味する',
    '意見',
    '愚かな',
    '感じる',
    '感覚',
    '感謝',
    '成功',
    '成功する',
    '成功者',
    '成長する',
    '我慢する',
    '戦う',
    '戦争',
    '戻る',
    '手',
    '手の指',
    '手伝う',
    '手渡す',
    '手紙',
    '手荷物',
    '手術',
    '打つ',
    '払う',
    '承知',
    '技術',
    '投げる',
    '抗議',
    '抗議する',
    '折りたたむ',
    '折り返す',
    '折り鶴',
    '押す',
    '拍手',
    '招待する',
    '持って来る',
    '持つ',
    '指示',
    '指輪',
    '挙げる',
    '捕まえる',
    '掃除機',
    '授業',
    '採掘',
    '接続',
    '接触',
    '推測する',
    '描く',
    '握る',
    '揺れ',
    '損害',
    '摘む',
    '撃つ',
    '撮る',
    '支持者',
    '支援する',
    '支配',
    '改善する',
    '攻撃',
    '放出する',
    '放射線',
    '政府',
    '救う',
    '敗北',
    '教える',
    '教会',
    '教室',
    '教授',
    '教科',
    '教科書',
    '数字',
    '数学',
    '敵',
    '文',
    '文化',
    '文字',
    '文房具',
    '料理する',
    '断片',
    '新しい',
    '新幹線',
    '新聞',
    '新鮮',
    '新鮮な',
    '方法',
    '方角',
    '施設',
    '旅行',
    '旅行者',
    '日',
    '日付',
    '日傘',
    '日光',
    '日常',
    '日曜日',
    '日本',
    '日本人',
    '日本語',
    '日没',
    '日記',
    '早い',
    '早く',
    '旬',
    '昇る',
    '明かり',
    '明らか',
    '明るい',
    '明日',
    '易しい',
    '星',
    '映画',
    '春',
    '昨日',
    '昼食',
    '時代',
    '時刻',
    '時間',
    '普段',
    '普通',
    '晴れ',
    '晴天',
    '暇',
    '暑い',
    '暮らす',
    '暴力',
    '曇り',
    '曇る',
    '曲',
    '曲がる',
    '書いてある',
    '書き',
    '書く',
    '書道',
    '書類',
    '最初',
    '最後',
    '最近',
    '月',
    '月曜日',
    '有名な',
    '服',
    '望む',
    '朝',
    '朝食',
    '期間',
    '木',
    '木曜日',
    '木材',
    '未使用',
    '未来',
    '本',
    '本屋',
    '本当',
    '机',
    '村',
    '来る',
    '東',
    '板',
    '果物',
    '枝',
    '株',
    '株式会社',
    '核',
    '根',
    '案内する',
    '案内人',
    '棒',
    '森',
    '森林',
    '植民地',
    '植物',
    '検査',
    '楽しい',
    '楽しげな',
    '楽しみ',
    '楽しむ',
    '様々な',
    '標識',
    '権利',
    '横たわる',
    '横切る',
    '横断',
    '橋',
    '機械',
    '欠席',
    '欲しい',
    '歌',
    '歌う',
    '歌手',
    '歓喜',
    '止める',
    '正しい',
    '正午',
    '正方形',
    '正直な',
    '正面',
    '武器',
    '歩道',
    '歯',
    '歴史',
    '死',
    '死ぬ',
    '残り',
    '残念な',
    '殺す',
    '殺到',
    '母',
    '毎年',
    '毎月',
    '比較的',
    '気づかせる',
    '気づく',
    '気にする',
    '気に入る',
    '気候',
    '水',
    '水平線',
    '水泳',
    '氷',
    '氷河',
    '池',
    '決める',
    '決定',
    '決心する',
    '沈む',
    '油',
    '沼',
    '法学',
    '法律',
    '波',
    '泣く',
    '注射',
    '注意深い',
    '注意深く',
    '注文',
    '注文する',
    '泳ぐ',
    '洋服',
    '洗濯機',
    '活動',
    '浜',
    '海',
    '海外',
    '消しゴム',
    '消滅する',
    '消火器',
    '消費者',
    '涙',
    '深い',
    '混ぜる',
    '減少する',
    '渡す',
    '温かい',
    '温暖な',
    '港',
    '湖',
    '満足',
    '満足させる',
    '準備する',
    '滞在',
    '滞在する',
    '演じる',
    '演奏する',
    '演説',
    '激怒',
    '濡れている',
    '火',
    '火事',
    '火曜日',
    '災害',
    '点',
    '焼く',
    '煙',
    '煮る',
    '熱',
    '熱心',
    '燃やす',
    '爆弾',
    '爆発',
    '父',
    '片付ける',
    '牛肉',
    '物',
    '物語',
    '特別',
    '特定',
    '特徴',
    '特色',
    '状態',
    '状況',
    '狂う',
    '狩る',
    '独特な',
    '独立',
    '猫',
    '王子',
    '王様',
    '現れる',
    '現代的な',
    '現実',
    '理由',
    '理解する',
    '環境',
    '甘い',
    '生',
    '生きる',
    '生き残る',
    '生の',
    '生まれる',
    '生む',
    '生命',
    '生徒',
    '生態系',
    '生活',
    '生物',
    '生産する',
    '用意',
    '田舎',
    '男性',
    '町',
    '画像',
    '画家',
    '番号',
    '異なる',
    '疑問',
    '疲れた',
    '疲れる',
    '病気',
    '病院',
    '痛み',
    '発明',
    '発明する',
    '発明家',
    '発熱',
    '発表',
    '発表する',
    '発見する',
    '発達させる',
    '登る',
    '百',
    '皿',
    '目',
    '目が覚める',
    '目を覚ます',
    '目薬',
    '直接',
    '直面する',
    '相互',
    '看護師',
    '真っすぐ',
    '真ん中',
    '真夜中',
    '眠い',
    '眠る',
    '眼鏡',
    '着ている',
    '着る',
    '瞬間',
    '矢',
    '知る',
    '知恵',
    '知識',
    '短い',
    '石',
    '石油',
    '石炭',
    '砂漠',
    '砂糖',
    '研究',
    '研究する',
    '研究者',
    '破壊する',
    '硬貨',
    '確信',
    '確信する',
    '確実',
    '社会',
    '祖母',
    '祖父',
    '神',
    '神聖な',
    '祭り',
    '禁止する',
    '私',
    '秋',
    '科学',
    '科学技術',
    '科学者',
    '秒',
    '秘密',
    '税金',
    '種',
    '種類',
    '積み木',
    '穴',
    '空',
    '大空',
    '空気清浄機',
    '空港',
    '空腹',
    '突然',
    '立ち上がる',
    '立つ',
    '競走',
    '笑う',
    '笑み',
    '筆',
    '等級',
    '筋肉',
    '答え',
    '答える',
    '算数',
    '管理する',
    '箱',
    '節約する',
    '簡単',
    '簡単な',
    '米',
    '精神',
    '約束',
    '紅茶',
    '紙',
    '紙幣',
    '素早い',
    '紫色',
    '紹介する',
    '終える',
    '終わる',
    '経営者',
    '経験する',
    '結びつける',
    '結婚する',
    '結局',
    '結末',
    '結果',
    '絵',
    '絵の具',
    '絶滅',
    '絶滅する',
    '絹',
    '続く',
    '続ける',
    '網',
    '綴り',
    '緊急事態',
    '緑',
    '線',
    '練習',
    '練習する',
    '置く',
    '羊肉',
    '美',
    '美しい',
    '美術',
    '美術館',
    '群衆',
    '羽',
    '習慣',
    '翻訳',
    '翻訳する',
    '考え',
    '考える',
    '耳',
    '聞く',
    '聞こえる',
    '聴く',
    '職業',
    '肉',
    '肩',
    '育つ',
    '背が高い',
    '脚',
    '脳',
    '脳科学',
    '腕',
    '腕時計',
    '自分自身',
    '自動車',
    '自然',
    '自然の',
    '自由',
    '自由な',
    '自転車',
    '臭う',
    '興味',
    '興味がある',
    '興奮する',
    '舌',
    '船',
    '船員',
    '良い',
    '色彩',
    '花',
    '芸術',
    '芸術的な',
    '若い',
    '若さ',
    '英語',
    '茶色',
    '草',
    '落ちついた',
    '落ちる',
    '落とす',
    '葉',
    '蒸気',
    '蓄える',
    '薬',
    '蜂蜜',
    '血',
    '行う',
    '行く',
    '行事',
    '行動',
    '衣服',
    '表',
    '被害',
    '装置',
    '裕福な',
    '製品',
    '西',
    '覆う',
    '見える',
    '見せる',
    '見つけた',
    '見つける',
    '見る',
    '見逃す',
    '規則',
    '視界',
    '親',
    '親切',
    '観光',
    '観光客',
    '観客',
    '観念',
    '角',
    '角度',
    '解決する',
    '解雇',
    '解雇する',
    '言う',
    '言葉',
    '言語',
    '計画する',
    '討論',
    '記事',
    '記憶',
    '設備',
    '設立する',
    '設計する',
    '許す',
    '試す',
    '試合',
    '試験',
    '詩',
    '詩人',
    '話し合う',
    '話す',
    '誕生日',
    '誤り',
    '誤解する',
    '説明する',
    '読み',
    '読む',
    '読書',
    '調べる',
    '調査',
    '調査する',
    '請求書',
    '謝る',
    '警官',
    '警察',
    '警察官',
    '谷',
    '豆',
    '豚肉',
    '負ける',
    '負担',
    '貧しい',
    '貧乏な',
    '貧困',
    '貴重',
    '買う',
    '買物',
    '貸す',
    '費やす',
    '貿易',
    '資源',
    '賛成する',
    '賞',
    '賞状',
    '質問',
    '贈り物',
    '赤',
    '赤ん坊',
    '走る',
    '起こる',
    '趣味',
    '足',
    '跳ぶ',
    '身体',
    '車庫',
    '転ぶ',
    '転職',
    '軽い',
    '輸入する',
    '輸送する',
    '辞書',
    '農場',
    '農場主',
    '農学',
    '農家',
    '農業',
    '近い',
    '近所',
    '返信する',
    '追い求める',
    '退屈する',
    '送る',
    '送付する',
    '逃げる',
    '通う',
    '通り',
    '通り過ぎる',
    '通常',
    '通話',
    '速い',
    '連れる',
    '連絡する',
    '連絡をとる',
    '逮捕する',
    '週刊',
    '進歩',
    '進歩する',
    '進路',
    '遅く',
    '運',
    '運ぶ',
    '運動',
    '運動する',
    '運転する',
    '運転手',
    '過ぎ去る',
    '過ごす',
    '過去',
    '道',
    '道に迷う',
    '道路',
    '違う',
    '違法な',
    '遠い',
    '遠慮',
    '選ぶ',
    '選手',
    '選択',
    '遺産',
    '避難',
    '避難所',
    '避難訓練',
    '部分',
    '部屋',
    '都会',
    '配る',
    '配達',
    'しょうゆ',
    '重い',
    '重さ',
    '重さがある',
    '重大な',
    '重要',
    '重要な',
    '野球',
    '野生',
    '野菜',
    '量',
    '金属',
    '金持ち',
    '金曜日',
    '鉄',
    '鉄砲',
    '鉄道',
    '鉛筆',
    '銀',
    '鍵',
    '鏡',
    '長い',
    '長さ',
    '閉じる',
    '開く',
    '開ける',
    '開催する',
    '開発',
    '開発者',
    '間',
    '間違う',
    '関係',
    '限られる',
    '陰',
    '陸軍',
    '階',
    '階段',
    '障壁',
    '隣',
    '隣人',
    '集める',
    '集中する',
    '雑誌',
    '離れる',
    '難しい',
    '雨',
    '雨の',
    '雨水',
    '雪',
    '雪の降る',
    '雲',
    '電力',
    '電子',
    '電子機器',
    '電池',
    '電波',
    '電話',
    '電話する',
    '電車',
    '震える',
    '霧',
    '青い',
    '青ざめる',
    '静かな',
    '非常',
    '面',
    '面倒',
    '面白い',
    '革靴',
    '靴',
    '靴下',
    '韓国',
    '音楽',
    '音楽家',
    '音節',
    '韻',
    '順番',
    '頭',
    '頭痛',
    '頭脳',
    '頼む',
    '頼る',
    '題名',
    '顔',
    '顧客',
    '風',
    '風力',
    '風呂',
    '飛ぶ',
    '飛行',
    '飛行機',
    '食べる',
    '食べ物',
    '飲む',
    '飼育する',
    '首',
    '首都',
    '香辛料',
    '馬',
    '馬鹿',
    '駅',
    '駐車場',
    '騒がしい',
    '騒音',
    '驚く',
    '驚くべき',
    '骨',
    '高価な',
    '高校',
    '髪',
    '髪の毛',
    '魚',
    '鳥',
    '鶏肉',
    '黒い',
    '鼻',
];


export const allNames = [
    '佐藤',
    '鈴木',
    '高橋',
    '田中',
    '伊藤',
    '渡辺',
    '山本',
    '中村',
    '小林',
    '加藤',
    '吉田',
    '山田',
    '佐々木',
    '山口',
    '松本',
    '井上',
    '木村',
    '林',
    '斎藤',
    '清水',
    '山崎',
    '森',
    '池田',
    '橋本',
    '阿部',
    '石川',
    '山下',
    '中島',
    '石井',
    '小川',
    '前田',
    '岡田',
    '長谷川',
    '藤田',
    '後藤',
    '近藤',
    '村上',
    '遠藤',
    '青木',
    '坂本',
    '斉藤',
    '福田',
    '太田',
    '西村',
    '藤井',
    '金子',
    '岡本',
    '藤原',
    '中野',
    '三浦',
    '原田',
    '中川',
    '松田',
    '竹内',
    '小野',
    '田村',
    '中山',
    '和田',
    '石田',
    '森田',
    '上田',
    '原',
    '内田',
    '柴田',
    '酒井',
    '宮崎',
    '横山',
    '高木',
    '安藤',
    '宮本',
    '大野',
    '小島',
    '谷口',
    '今井',
    '工藤',
    '高田',
    '増田',
    '丸山',
    '杉山',
    '村田',
    '大塚',
    '新井',
    '小山',
    '平野',
    '藤本',
    '河野',
    '上野',
    '野口',
    '武田',
    '松井',
    '千葉',
    '岩崎',
    '菅原',
    '木下',
    '久保',
    '佐野',
    '野村',
    '松尾',
    '市川',
    '菊地',
    '杉本',
    '古川',
    '大西',
    '島田',
    '水野',
    '桜井',
    '高野',
    '渡部',
    '吉川',
    '山内',
    '西田',
    '飯田',
    '菊池',
    '西川',
    '小松',
    '北村',
    '安田',
    '五十嵐',
    '川口',
    '平田',
    '関',
    '中田',
    '久保田',
    '服部',
    '東',
    '岩田',
    '土屋',
    '川崎',
    '福島',
    '本田',
    '辻',
    '樋口',
    '秋山',
    '田口',
    '永井',
    '山中',
    '中西',
    '吉村',
    '川上',
    '石原',
    '大橋',
    '松岡',
    '馬場',
    '浜田',
    '森本',
    '星野',
    '矢野',
    '浅野',
    '大久保',
    '松下',
    '吉岡',
    '小池',
    '野田',
    '荒木',
    '大谷',
    '内藤',
    '松浦',
    '熊谷',
    '黒田',
    '尾崎',
    '永田',
    '川村',
    '望月',
    '田辺',
    '松村',
    '荒井',
    '堀',
    '大島',
    '平井',
    '早川',
    '菅野',
    '栗原',
    '西山',
    '広瀬',
    '横田',
    '石橋',
    '萩原',
    '岩本',
    '片山',
    '関口',
    '宮田',
    '大石',
    '高山',
    '本間',
    '吉野',
    '須藤',
    '岡崎',
    '小田',
    '伊東',
    '鎌田',
    '上原',
    '篠原',
    '小西',
    '松原',
    '福井',
    '古賀',
    '大森',
    '小泉',
    '成田',
    '南',
    '奥村',
    '内山',
    '沢田',
    '川島',
    '桑原',
    '三宅',
    '片岡',
    '富田',
    '杉浦',
    '岡',
    '八木',
    '奥田',
    '小沢',
    '松永',
    '北川',
    '関根',
    '河合',
    '平山',
    '牧野',
    '白石',
    '今村',
    '寺田',
    '青山',
    '中尾',
    '小倉',
    '渋谷',
    '上村',
    '小野寺',
    '大山',
    '足立',
    '岡村',
    '坂口',
    '天野',
    '多田',
    '佐久間',
    '根本',
    '豊田',
    '田島',
    '飯塚',
    '角田',
    '村山',
    '武藤',
    '西',
    '白井',
    '竹田',
    '宮下',
    '塚本',
    '榎本',
    '神谷',
    '坂田',
    '児玉',
    '水谷',
    '坂井',
    '齋藤',
    '小原',
    '浅井',
    '岡部',
    '森下',
    '小笠原',
    '神田',
    '中井',
    '植田',
    '河村',
    '宮川',
    '稲垣',
    '前川',
    '大川',
    '松崎',
    '長田',
    '若林',
    '飯島',
    '谷',
    '大沢',
    '石塚',
    '安部',
    '堀内',
    '及川',
    '田代',
    '中嶋',
    '江口',
    '山根',
    '中谷',
    '岸本',
    '荒川',
    '本多',
    '西尾',
    '森山',
    '岡野',
    '金井',
    '細川',
    '今野',
    '戸田',
    '稲葉',
    '安達',
    '津田',
    '森川',
    '落合',
    '土井',
    '村松',
    '星',
    '町田',
    '三上',
    '畠山',
    '岩井',
    '長尾',
    '堤',
    '中原',
    '野崎',
    '中沢',
    '金田',
    '米田',
    '松山',
    '杉田',
    '堀田',
    '西野',
    '三好',
    '山岸',
    '佐伯',
    '黒川',
    '西岡',
    '泉',
    '大竹',
    '甲斐',
    '笠原',
    '大木',
    '堀江',
    '岸',
    '徳永',
    '川田',
    '須田',
    '黒木',
    '山川',
    '古田',
    '榊原',
    '梅田',
    '新田',
    '三木',
    '野中',
    '大城',
    '村井',
    '奥山',
    '金城',
    '土田',
    '滝沢',
    '大村',
    '川端',
    '井口',
    '梶原',
    '大場',
    '宮城',
    '長島',
    '比嘉',
    '吉原',
    '宮内',
    '金沢',
    '安井',
    '庄司',
    '大内',
    '茂木',
    '荻野',
    '日高',
    '松島',
    '向井',
    '下田',
    '塚田',
    '石黒',
    '西本',
    '奥野',
    '竹中',
    '広田',
    '嶋田',
    '栗田',
    '藤川',
    '福本',
    '北野',
    '宇野',
    '藤野',
    '川原',
    '谷川',
    '丹羽',
    '小谷',
    '吉本',
    '青柳',
    '藤岡',
    '竹本',
    '竹下',
    '古谷',
    '緒方',
    '藤村',
    '平川',
    '亀井',
    '高島',
    '三輪',
    '藤沢',
    '篠崎',
    '窪田',
    '宮原',
    '高井',
    '根岸',
    '下村',
    '高瀬',
    '山村',
    '川本',
    '柳沢',
    '横井',
    '小澤',
    '出口',
    '吉沢',
    '武井',
    '小森',
    '竹村',
    '長野',
    '宮沢',
    '志村',
    '平松',
    '臼井',
    '福岡',
    '黒沢',
    '溝口',
    '田原',
    '稲田',
    '浅田',
    '筒井',
    '柳田',
    '大原',
    '永野',
    '林田',
    '冨田',
    '大平',
    '瀬戸',
    '手塚',
    '入江',
    '篠田',
    '福永',
    '北原',
    '富永',
    '矢島',
    '小出',
    '湯浅',
    '鶴田',
    '沼田',
    '高松',
    '長岡',
    '堀口',
    '岩瀬',
    '山岡',
    '大田',
    '石崎',
    '大槻',
    '澤田',
    '石山',
    '池上',
    '堀川',
    '二宮',
    '相馬',
    '園田',
    '柏木',
    '島崎',
    '平岡',
    '花田',
    '杉原',
    '加納',
    '村瀬',
    '川野',
    '片桐',
    '内海',
    '長沢',
    '倉田',
    '野沢',
    '河原',
    '福原',
    '秋元',
    '越智',
    '西原',
    '松野',
    '笠井',
    '小坂',
    '田畑',
    '日野',
    '北島',
    '渡邊',
    '谷本',
    '千田',
    '吉井',
    '深沢',
    '西沢',
    '相沢',
    '徳田',
    '原口',
    '小柳',
    '米山',
    '新谷',
    '細谷',
    '田上',
    '今泉',
    '菅',
    '浜野',
    '森岡',
    '畑中',
    '小嶋',
    '大井',
    '磯部',
    '芳賀',
    '秋田',
    '浅見',
    '相原',
    '坪井',
    '大崎',
    '植木',
    '細田',
    '荻原',
    '白川',
    '中本',
    '皆川',
    '岸田',
    '三谷',
    '福山',
    '浜口',
    '辻本',
    '平林',
    '川瀬',
    '木原',
    '畑',
    '島村',
    '大友',
    '塩田',
    '河内',
    '遠山',
    '古屋',
    '神山',
    '中里',
    '齊藤',
    '三井',
    '三橋',
    '大坪',
    '植村',
    '大澤',
    '難波',
    '三村',
    '渡邉',
    '栗山',
    '岩下',
    '松川',
    '井手',
    '吉永',
    '早坂',
    '長井',
    '井出',
    '川畑',
    '佐竹',
    '朝倉',
    '亀山',
    '柳',
    '河田',
    '川合',
    '橘',
    '浜崎',
    '草野',
    '堀井',
    '小宮',
    '保坂',
    '志賀',
    '尾形',
    '村岡',
    '半田',
    '中根',
    '狩野',
    '高村',
    '水上',
    '大津',
    '新垣',
    '若松',
    '高柳',
    '柴崎',
    '亀田',
    '高岡',
    '立石',
    '市村',
    '寺島',
    '玉城',
    '三島',
    '内野',
    '瀬川',
    '奈良',
    '武内',
    '木内',
    '平',
    '藤崎',
    '久野',
    '清野',
    '真鍋',
    '玉井',
    '植松',
    '水口',
    '深谷',
    '江藤',
    '大沼',
    '進藤',
    '守屋',
    '並木',
    '橋口',
    '寺本',
    '高見',
    '菅谷',
    '宮地',
    '織田',
    '藤森',
    '河本',
    '毛利',
    '宮島',
    '大江',
    '有馬',
    '石渡',
    '矢部',
    '市原',
    '有田',
    '下山',
    '高原',
    '那須',
    '鳥居',
    '飯野',
    '関谷',
    '松沢',
    '北山',
    '坂下',
    '板垣',
    '野上',
    '前原',
    '牧',
    '三原',
    '川井',
    '坂上',
    '田崎',
    '立花',
    '平尾',
    '外山',
    '染谷',
    '岩佐',
    '松木',
    '小室',
    '成瀬',
    '三田',
    '高尾',
    '長谷',
    '葛西',
    '山城',
    '阪本',
    '宇佐美',
    '滝本',
    '野本',
    '長瀬',
    '秋葉',
    '板倉',
    '塚原',
    '高崎',
    '小寺',
    '山﨑',
    '滝口',
    '石垣',
    '仲村',
    '山野',
    '高梨',
    '井田',
    '長谷部',
    '秦',
    '海野',
    '江崎',
    '門脇',
    '大倉',
    '和泉',
    '椎名',
    '正木',
    '土居',
    '中澤',
    '相川',
    '今田',
    '都築',
    '平塚',
    '小関',
    '奥',
    '藤木',
    '島',
    '細井',
    '宍戸',
    '堀越',
    '江川',
    '岩永',
    '小杉',
    '木田',
    '西谷',
    '金谷',
    '金山',
    '諏訪',
    '乾',
    '永山',
    '石丸',
    '森谷',
    '田川',
    '金森',
    '下川',
    '細野',
    '黒岩',
    '大熊',
    '大貫',
    '野原',
    '布施',
    '小畑',
    '杉村',
    '梅原',
    '北田',
    '小野田',
    '富樫',
    '山上',
    '島袋',
    '豊島',
    '上山',
    '大矢',
    '石本',
    '赤松',
    '宮脇',
    '米沢',
    '阿久津',
    '末永',
    '本橋',
    '宮野',
    '倉持',
    '古沢',
    '塩谷',
    '今西',
    '風間',
    '水田',
    '麻生',
    '仲田',
    '菅沼',
    '香川',
    '平良',
    '生田',
    '小幡',
    '坂元',
    '大庭',
    '喜多',
    '門田',
    '小久保',
    '西口',
    '井川',
    '須賀',
    '大和田',
    '中林',
    '梅本',
    '富岡',
    '海老原',
    '浦田',
    '永島',
    '佐川',
    '矢口',
    '岩間',
    '杉野',
    '川辺',
    '浜本',
    '土橋',
    '岩渕',
    '木本',
    '日下',
    '砂川',
    '木戸',
    '大滝',
    '波多野',
    '長崎',
    '金丸',
    '雨宮',
    '立川',
    '羽田',
    '小沼',
    '白鳥',
    '依田',
    '平沢',
    '浅川',
    '真田',
    '小椋',
    '目黒',
    '石坂',
    '板橋',
    '神崎',
    '森脇',
    '深田',
    '山形',
    '影山',
    '濱田',
    '大畑',
    '寺尾',
    '宇田川',
    '曽根',
    '矢田',
    '三枝',
    '松元',
    '山元',
    '北沢',
    '高倉',
    '勝又',
    '橋爪',
    '清田',
    '赤木',
    '柳川',
    '梅津',
    '鶴岡',
    '芦田',
    '丹野',
    '櫻井',
    '瀬尾',
    '宇都宮',
    '尾上',
    '岩城',
    '江原',
    '神野',
    '横尾',
    '吉澤',
    '柳原',
    '坂野',
    '池内',
    '桑田',
    '西脇',
    '久米',
    '日比野',
    '栗林',
    '角',
    '梅村',
    '結城',
    '西島',
    '小堀',
    '川西',
    '手島',
    '大嶋',
    '小菅',
    '浦野',
    '森口',
    '飛田',
    '上杉',
    '井原',
    '田端',
    '首藤',
    '赤坂',
    '小高',
    '玉置',
    '青野',
    '柏原',
    '横川',
    '北',
    '浅沼',
    '大林',
    '梅沢',
    '水島',
    '小玉',
    '合田',
    '角谷',
    '宮坂',
    '竹原',
    '有賀',
    '脇田',
    '柴山',
    '三沢',
    '本村',
    '宮里',
    '池本',
    '志田',
    '島津',
    '明石',
    '加瀬',
    '池谷',
    '春日',
    '勝田',
    '妹尾',
    '滝川',
    '大和',
    '大下',
    '富山',
    '前島',
    '楠',
    '中塚',
    '会田',
    '柿沼',
    '恩田',
    '阪口',
    '戸塚',
    '石野',
    '前野',
    '曽我',
    '夏目',
    '磯野',
    '益田',
    '笹川',
    '小栗',
    '古山',
    '野島',
    '谷村',
    '白木',
    '吉崎',
    '川越',
    '菅井',
    '国分',
    '楠本',
    '坂東',
    '日下部',
    '岡山',
    '井本',
    '内村',
    '花岡',
    '持田',
    '高畑',
    '熊田',
    '中',
    '石倉',
    '沖',
    '金澤',
    '神戸',
    '田渕',
    '鬼頭',
    '末吉',
    '玉木',
    '福地',
    '城戸',
    '滝',
    '浜',
    '倉本',
    '田所',
    '若山',
    '東海林',
    '塩見',
    '児島',
    '沖田',
    '寺沢',
    '黒崎',
    '森井',
    '粕谷',
    '八田',
    '沢井',
    '重松',
    '寺西',
    '高嶋',
    '廣瀬',
    '森永',
    '本山',
    '長坂',
    '村木',
    '重田',
    '大浦',
    '深井',
    '大高',
    '高谷',
    '井村',
    '田尻',
    '鵜飼',
    '南部',
    '平賀',
    '玉田',
    '今',
    '川嶋',
    '菊田',
    '知念',
    '米倉',
    '磯崎',
    '秋本',
    '岡島',
    '寺岡',
    '熊倉',
    '猪股',
    '笹原',
    '岩谷',
    '増井',
    '畠中',
    '小俣',
    '冨永',
    '古橋',
    '桜田',
    '氏家',
    '富沢',
    '野呂',
    '長浜',
    '五味',
    '下地',
    '小口',
    '小暮',
    '小平',
    '大関',
    '川添',
    '紺野',
    '梶田',
    '土谷',
    '平本',
    '牧田',
    '西井',
    '花井',
    '安永',
    '新村',
    '西浦',
    '柳瀬',
    '倉橋',
];

export const circleRatioStr = '1415926535897932384626433832795028841971693993751058209749445923078164062862089986280348253421170679'

export const EuropeCapitals = [
    ['アイスランド', 'レイキャヴィーク'],
    ['アイルランド', 'ダブリン'],
    ['アルバニア', 'ティラナ'],
    ['アンドラ', 'アンドラ・ラ・ベリャ'],
    ['イギリス', 'ロンドン'],
    ['イタリア', 'ローマ'],
    ['ウクライナ', 'キーウ'],
    ['エストニア', 'タリン'],
    ['オーストリア', 'ウィーン'],
    ['オランダ', 'アムステルダム'],
    ['北マケドニア', 'スコピエ'],
    ['ギリシャ', 'アテネ'],
    ['クロアチア', 'ザグレブ'],
    ['サンマリノ', 'サンマリノ'],
    ['スイス', 'ベルン'],
    ['スウェーデン', 'ストックホルム'],
    ['スペイン', 'マドリード'],
    ['スロバキア', 'ブラチスラバ'],
    ['スロベニア', 'リュブリャナ'],
    ['セルビア', 'ベオグラード'],
    ['チェコ', 'プラハ'],
    ['デンマーク', 'コペンハーゲン'],
    ['ドイツ', 'ベルリン'],
    ['ノルウェー', 'オスロ'],
    ['ハンガリー', 'ブダペスト'],
    ['フィンランド', 'ヘルシンキ'],
    ['フランス', 'パリ'],
    ['ブルガリア', 'ソフィア'],
    ['ベラルーシ', 'ミンスク'],
    ['ベルギー', 'ブリュッセル'],
    ['ポーランド', 'ワルシャワ'],
    ['ボスニア・ヘルツェゴビナ', 'サラエヴォ'],
    ['ポルトガル', 'リスボン'],
    ['マルタ', 'バレッタ'],
    ['モナコ', 'モナコ'],
    ['モルドバ', 'キシナウ'],
    ['モンテネグロ', 'ポドゴリツァ'],
    ['ラトビア', 'リガ'],
    ['リトアニア', 'ヴィリニュス'],
    ['リヒテンシュタイン', 'ファドゥーツ'],
    ['ルーマニア', 'ブカレスト'],
    ['ルクセンブルク', 'ルクセンブルク'],
    ['ロシア', 'モスクワ'],
];


export const AsiaCapitals = [
    ['アゼルバイジャン', 'バクー'],
    ['アフガニスタン', 'カブール'],
    ['アラブ首長国連邦', 'アブダビ'],
    ['アルメニア', 'エレバン'],
    ['イエメン', 'サナア'],
    ['イスラエル', 'エルサレム'],
    ['イラク', 'バグダード'],
    ['イラン', 'テヘラン'],
    ['インド', 'ニューデリー'],
    ['インドネシア', 'ジャカルタ'],
    ['ウズベキスタン', 'タシュケント'],
    ['オマーン', 'マスカット'],
    ['カザフスタン', 'アスタナ'],
    ['カタール', 'ドーハ'],
    ['韓国', 'ソウル'],
    ['カンボジア', 'プノンペン'],
    ['北朝鮮', '平壌'],
    ['キプロス', 'ニコシア'],
    ['キルギス', 'ビシュケク'],
    ['クウェート', 'クウェート'],
    ['サウジアラビア', 'リヤド'],
    ['ジョージア', 'トビリシ'],
    ['シリア', 'ダマスカス'],
    ['シンガポール', 'シンガポール'],
    ['スリランカ', 'スリジャヤワルダナプラコッテ'],
    ['タイ', 'バンコク'],
    ['タジキスタン', 'ドゥシャンベ'],
    ['中国', '北京'],
    ['トルクメニスタン', 'アシガバート'],
    ['トルコ', 'アンカラ'],
    ['日本', '東京'],
    ['ネパール', 'カトマンズ'],
    ['バーレーン', 'マナーマ'],
    ['パキスタン', 'イスラマバード'],
    ['バングラデシュ', 'ダッカ'],
    ['東ティモール', 'ディリ'],
    ['フィリピン', 'マニラ'],
    ['ブータン', 'ティンプー'],
    ['ブルネイ', 'バンダルスリブガワン'],
    ['ベトナム', 'ハノイ'],
    ['マレーシア', 'クアラルンプール'],
    ['ミャンマー', 'ネピドー'],
    ['モルディブ', 'マレ'],
    ['モンゴル', 'ウランバートル'],
    ['ヨルダン', 'アンマン'],
    ['ラオス', 'ヴィエンチャン'],
    ['レバノン', 'ベイルート'],
];


export const AfricaCapitals = [
    ['アルジェリア', 'アルジェ'],
    ['アンゴラ', 'ルアンダ'],
    ['ウガンダ', 'カンパラ'],
    ['エジプト', 'カイロ'],
    ['エスワティニ', 'ムババーネ'],
    ['エチオピア', 'アディスアベバ'],
    ['エリトリア', 'アスマラ'],
    ['ガーナ', 'アクラ'],
    ['カーボベルデ', 'プライア'],
    ['ガボン', 'リーブルヴィル'],
    ['カメルーン', 'ヤウンデ'],
    ['ガンビア', 'バンジュール'],
    ['ギニア', 'コナクリ'],
    ['ギニアビサウ', 'ビサウ'],
    ['ケニア', 'ナイロビ'],
    ['コートジボワール', 'ヤムスクロ'],
    ['コモロ', 'モロニ'],
    ['コンゴ共和国', 'ブラザヴィル'],
    ['コンゴ民主共和国', 'キンシャサ'],
    ['サントメ・プリンシペ', 'サントメ'],
    ['ザンビア', 'ルサカ'],
    ['シエラレオネ', 'フリータウン'],
    ['ジブチ', 'ジブチ'],
    ['ジンバブエ', 'ハラレ'],
    ['スーダン', 'ハルツーム'],
    ['セーシェル', 'ヴィクトリア'],
    ['赤道ギニア', 'マラボ'],
    ['セネガル', 'ダカール'],
    ['ソマリア', 'モガディシュ'],
    ['タンザニア', 'ドドマ'],
    ['チャド', 'ンジャメナ'],
    ['中央アフリカ', 'バンギ'],
    ['チュニジア', 'チュニス'],
    ['トーゴ', 'ロメ'],
    ['ナイジェリア', 'アブジャ'],
    ['ナミビア', 'ウィントフック'],
    ['ニジェール', 'ニアメ'],
    ['ブルキナファソ', 'ワガドゥグー'],
    ['ブルンジ', 'ギテガ'],
    ['ベナン', 'ポルトノボ'],
    ['ボツワナ', 'ハボローネ'],
    ['マダガスカル', 'アンタナナリボ'],
    ['マラウイ', 'リロングウェ'],
    ['マリ', 'バマコ'],
    ['南アフリカ', 'プレトリア'],
    ['南スーダン', 'ジュバ'],
    ['モーリシャス', 'ポートルイス'],
    ['モーリタニア', 'ヌアクショット'],
    ['モザンビーク', 'マプト'],
    ['モロッコ', 'ラバト'],
    ['リビア', 'トリポリ'],
    ['リベリア', 'モンロビア'],
    ['ルワンダ', 'キガリ'],
    ['レソト', 'マセル'],
];

export const NorthAmericaCapitals = [
    ['アメリカ', 'ワシントンD.C.'],
    ['アンティグア・バーブーダ', 'セントジョンズ'],
    ['エルサルバドル', 'サンサルバドル'],
    ['カナダ', 'オタワ'],
    ['キューバ', 'ハバナ'],
    ['グアテマラ', 'グアテマラシティ'],
    ['グレナダ', 'セントジョージズ'],
    ['コスタリカ', 'サンホセ'],
    ['ジャマイカ', 'キングストン'],
    ['セントクリストファー・ネイビス', 'バセテール'],
    ['セントビンセント・グレナディーン', 'キングスタウン'],
    ['セントルシア', 'カストリーズ'],
    ['ドミニカ共和国', 'サントドミンゴ'],
    ['ドミニカ国', 'ロゾー'],
    ['トリニダード・トバゴ', 'ポートオブスペイン'],
    ['ニカラグア', 'マナグア'],
    ['ハイチ', 'ポルトープランス'],
    ['パナマ', 'パナマシティ'],
    ['バハマ', 'ナッソー'],
    ['バルバドス', 'ブリッジタウン'],
    ['ベリーズ', 'ベルモパン'],
    ['ホンジュラス', 'テグシガルパ'],
    ['メキシコ', 'メキシコシティ'],
];

export const SouthAmericaCapitals = [
    ['アルゼンチン', 'ブエノスアイレス'],
    ['ウルグアイ', 'モンテビデオ'],
    ['エクアドル', 'キト'],
    ['ガイアナ', 'ジョージタウン'],
    ['コロンビア', 'ボゴタ'],
    ['スリナム', 'パラマリボ'],
    ['チリ', 'サンティアゴ'],
    ['パラグアイ', 'アスンシオン'],
    ['ブラジル', 'ブラジリア'],
    ['ベネズエラ', 'カラカス'],
    ['ペルー', 'リマ'],
    ['ボリビア', 'スクレ'],
];

export const OceaniaCapitals = [
    ['オーストラリア', 'キャンベラ'],
    ['キリバス', 'タラワ'],
    ['サモア', 'アピア'],
    ['ソロモン諸島', 'ホニアラ'],
    ['ツバル', 'フナフティ'],
    ['トンガ', 'ヌクアロファ'],
    ['ナウル', 'ヤレン'],
    ['ニュージーランド', 'ウェリントン'],
    ['バヌアツ', 'ポートビラ'],
    ['パプアニューギニア', 'ポートモレスビー'],
    ['パラオ', 'マルキョク'],
    ['フィジー', 'スバ'],
    ['マーシャル諸島', 'マジュロ'],
    ['ミクロネシア連邦', 'パリキール'],
];
