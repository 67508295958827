import React from 'react';
import TermsOfServiceTemplate from '../../templates/TermsOfServiceTemplate/';
import { useAppSelector } from '../../../redux/app/hooks';
import {
    selectLoginState,
} from '../../../redux/slices/loginSlice';
import { selectPaymentState } from '../../../redux/slices/paymentSlice';
// CSSはインポートした順に生成され、後にインポートしたCSSの方が優先されるので、
// まず他のコンポーネントや共通CSSをインポートして、その後でコンポーネントごとのCSSをインストールすること
// つまり、styles.module.cssのインポートはインポート文の複数行のうちの最後に置いておくのが安全
import styles from './styles.module.css';

export type TermsOfServicePageProps = {
    children?: never;
};


const TermsOfServicePage : React.FC<TermsOfServicePageProps> = () => {
    const {
        isLogin,
        isSocialLogin,
        preferredUsername,
    } = useAppSelector(selectLoginState);

    const {
        isPaid,
    } = useAppSelector(selectPaymentState);

    // to_implement
    const profileImg = undefined;

    return (
        <div className={ styles['terms-of-service-page'] }>
          <TermsOfServiceTemplate
            isLogin={isLogin}
            isPaid={isPaid}
            isSocialLogin={isSocialLogin}
            profileImg={profileImg}
            preferredUsername={preferredUsername}
            />
        </div>
    );
};

export default TermsOfServicePage;
