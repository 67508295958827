import React from 'react';
import templateProperties from '../../common/template-properties.module.css';
import { UrlLabel } from '../../../redux/constant';
import HeaderContainer from '../../organisms/HeaderContainer';
import PageTitleArea from '../../layouts/PageTitleArea';
import BreadcrumbBar from '../../organisms/BreadcrumbBar';
import { PageContainer } from '../../layouts/Container';
import ColumnLayout from '../../layouts/ColumnLayout';
import { OneThirdColumn, TwoThirdColumn } from '../../layouts/ColumnArea';
import { CourseLesson } from '../../../lib/props';
import CourseBox from '../../organisms/CourseBox';
import Footer from '../../organisms/Footer';
import CourseList from '../../organisms/CourseList';
// CSSはインポートした順に生成され、後にインポートしたCSSの方が優先されるので、
// まず他のコンポーネントや共通CSSをインポートして、その後でコンポーネントごとのCSSをインストールすること
// つまり、styles.module.cssのインポートはインポート文の複数行のうちの最後に置いておくのが安全
import styles from './styles.module.css';

Object.assign(styles, templateProperties);

const CourseBL: Array<UrlLabel> = [
    { url: '/mypage', label: 'マイページ' },
    { url: '', label: 'コース一覧' },
];

export type CourseTemplateProps = {
    isLogin: boolean;             // ログイン状態
    isPaid: boolean;              // 有料会員かどうか
    isSocialLogin: boolean;       // SNSログイン会員かどうか
    profileImg?: string;          // 会員画像
    preferredUsername?: string;  // 会員名

    basicCourseList?: Array<UrlLabel>;          // 基本コース一覧
    advancedCourseList?: Array<UrlLabel>;       // 応用コース一覧
    courseLessonList?: Array<CourseLesson>;     // 直近視聴したコース名とそのレッスン一覧
    courseDefaultTabIndex?: number;             // 直近視聴したコースに応じたコース一覧の初期タブ選択
};

const CourseTemplate: React.FC<CourseTemplateProps> = ({
    isLogin,
    isPaid,
    isSocialLogin,
    profileImg,
    preferredUsername,
    basicCourseList,
    advancedCourseList,
    courseLessonList,
    courseDefaultTabIndex,
}) => {
    return (
        <div className={styles['course-template'] + ' ' + styles['template-properties-local']}>
            <HeaderContainer isLogin={isLogin} isPaid={isPaid} isSocialLogin={isSocialLogin} profileImg={profileImg} preferredUsername={preferredUsername} />
            <PageTitleArea>コース一覧</PageTitleArea>
            <BreadcrumbBar UrlLabels={CourseBL} />
            <PageContainer>
                <ColumnLayout>
                    <OneThirdColumn isSide={true}>
                        <CourseList basicCourseList={basicCourseList} advancedCourseList={advancedCourseList} courseLessonList={courseLessonList} courseDefaultTabIndex={courseDefaultTabIndex} />
                    </OneThirdColumn>
                    <TwoThirdColumn>
                        {courseLessonList &&
                            courseLessonList.map((courseLessonData, idx) => (
                                <CourseBox
                                    key={idx}
                                    courseTitle={courseLessonData.courseTitle}
                                    courseColor={courseLessonData.courseColor}
                                    lessonBoxList={courseLessonData.lessonBoxList}
                                />
                            ))
                        }
                    </TwoThirdColumn>
                </ColumnLayout>
            </PageContainer>
            <Footer isLogin={isLogin} />
        </div>
    );
};

export default CourseTemplate;
