import React, { ReactNode } from 'react';
import { LogoColor } from '../../atoms/Img';
import Footer from '../../organisms/Footer';
// CSSはインポートした順に生成され、後にインポートしたCSSの方が優先されるので、
// まず他のコンポーネントや共通CSSをインポートして、その後でコンポーネントごとのCSSをインストールすること
// つまり、styles.module.cssのインポートはインポート文の複数行のうちの最後に置いておくのが安全
import styles from './styles.module.css';

export type DoorwayLayoutProps = {
    errorMessage?: string,
    className?: string,
    children?: ReactNode,
};


const DoorwayLayout : React.FC<DoorwayLayoutProps> = ({
    errorMessage = '',
    className = '',
    children,
}) => {
    return (
        <div className={ styles['doorway-layout'] +  ` ${className}` }>
            <h1 className={styles['doorway-logo']}><LogoColor /></h1>
            {children}
            <Footer isLogin={false} />
        </div>
    );
};

export default DoorwayLayout;
