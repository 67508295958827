import React from 'react';
import { CourseColor } from '../../../config/course';
import { CorrectAnswer } from '../../../lib/domSlide';
import { CharactersLessonSlide } from '../../../config/course';
import SlidePracticeBackground from '../../atoms/SlidePracticeBackground';
import FontTitle from '../../atoms/FontTitle';
import Textbox from '../../atoms/Textbox';
import { PrimaryButton } from '../../atoms/Button';
// CSSはインポートした順に生成され、後にインポートしたCSSの方が優先されるので、
// まず他のコンポーネントや共通CSSをインポートして、その後でコンポーネントごとのCSSをインストールすること
// つまり、styles.module.cssのインポートはインポート文の複数行のうちの最後に置いておくのが安全
import styles from './styles.module.css';

export type SlidePracticeCharactersProps = {
    pageIndex: number;
    slideColor: CourseColor;
    isCheckingAnswers: boolean;
    myAnswers: Array<CorrectAnswer>;
    updateMyAnswersWithIndex: (index:number) => (e: React.ChangeEvent<HTMLInputElement>) => void;
    updateIsCheckingAnswersTrue: (e: React.MouseEvent<HTMLButtonElement>) => void;
} & Omit<CharactersLessonSlide, 'type'>;


const SlidePracticeCharacters : React.FC<SlidePracticeCharactersProps> = ({
    pageIndex,
    slideColor,
    memorizationTitle,
    recallTitle,
    resultTitle = '結果を判定します',
    correctAnswers,
    myAnswers,
    isCheckingAnswers,

    updateMyAnswersWithIndex,
    updateIsCheckingAnswersTrue,
}) => {
    // 11個以上覚える場合数字の2ケタの問題である
    let is2Digits = false;
    if (correctAnswers.length > 10) is2Digits = true;

    // 未使用。大丈夫? TODO
    // let answerColumnClass = styles['input-item'];   // 問題・回答欄設定
    // let buttonAreaClass = styles['button-area'];    // ボタン設定

    return (
        <div className={ styles['slide-practice-number-conversion-table'] }>
            <SlidePracticeBackground bgColor={slideColor} className={styles['dom-slide']}>
                {/* 記憶画面 */}
                { pageIndex === 0 &&
                <div className={styles['dom-slide-area']}>
                    <FontTitle className={styles['dom-slide-title']}>{memorizationTitle}</FontTitle>
                    <div className={styles['dom-slide-content']}>
                        <div className={styles['dom-slide-answerarea']}>
                            {correctAnswers.map((correctAnswer, index) => {
                                return (
                                    <React.Fragment key={index}>
                                    {is2Digits // 2桁一組で表示するか
                                        ?
                                        <React.Fragment>
                                        {index%2 === 1 &&
                                            <div className={styles['item-2digits']}>
                                                <span className={styles['input-item']}>{correctAnswers[index-1].words[0]}</span>
                                                <span className={styles['input-item']}>{correctAnswer.words[0]}</span>
                                            </div>
                                        }
                                        </React.Fragment>
                                        :
                                        <span className={styles['input-item']}>{correctAnswer.words[0]}</span>
                                    }
                                    </React.Fragment>
                                );
                            })}
                        </div>
                    </div>
                </div>
                }

                {/* 回答画面 */}
                { pageIndex === 1 && !isCheckingAnswers &&
                <div className={styles['dom-slide-area']}>
                    <FontTitle className={styles['dom-slide-title']}>{recallTitle}</FontTitle>
                    <div className={styles['dom-slide-content']}>
                        <div className={styles['dom-slide-answerarea']}>
                            {correctAnswers.map((correctAnswer, index) => {
                                return (
                                    <React.Fragment key={index}>
                                    {is2Digits // 2桁一組で表示するか
                                        ?
                                        <React.Fragment>
                                        {index%2 === 1 &&
                                            <div className={styles['item-2digits']}>
                                                <Textbox className={styles['input-item']} value={myAnswers[index-1]?.words[0] || ''} onChange={updateMyAnswersWithIndex(index-1)} maxLength={1} />
                                                <Textbox className={styles['input-item']} value={myAnswers[index]?.words[0] || ''} onChange={updateMyAnswersWithIndex(index)} maxLength={1} />
                                            </div>
                                        }
                                        </React.Fragment>
                                        :
                                        <Textbox className={styles['input-item']} value={myAnswers[index]?.words[0] || ''} onChange={updateMyAnswersWithIndex(index)} maxLength={1} />
                                    }
                                    </React.Fragment>
                                );
                            })}
                        </div>
                        <div className={styles['button-area']}>
                            <PrimaryButton className={styles['button']} onClick={updateIsCheckingAnswersTrue}>答え合わせする</PrimaryButton>
                        </div>
                    </div>
                </div>
                }

                {/* 結果画面 */}
                { pageIndex === 1 && isCheckingAnswers &&
                <div className={styles['dom-slide-area']}>
                    <FontTitle className={styles['dom-slide-title']}>{resultTitle}</FontTitle>
                    <div className={styles['dom-slide-content']}>
                        <div className={styles['dom-slide-answerarea']+' '+styles['result']}>
                            {correctAnswers.map((correctAnswer, index) => {
                                const currentMyAnswer = myAnswers[index]?.words[0] || '';
                                const resultClass = (correctAnswer.words.includes(currentMyAnswer))? styles['correct'] : styles['incorrect'];
                                return (
                                    <React.Fragment key={index}>
                                        {is2Digits // 2桁一組で表示するか
                                            ?
                                            <React.Fragment>
                                            {index%2 === 1 &&
                                            <div className={styles['item-group']}>
                                                <div className={styles['item-2digits']}>
                                                    <span className={(myAnswers[index-1]?.words[0]||'')===correctAnswers[index-1]['words'][0]?styles['input-item']+' '+styles['correct']:styles['input-item']+' '+styles['incorrect']}>{myAnswers[index-1]?.words[0] || ''}</span>
                                                    <span className={styles['input-item']+' '+resultClass}>{myAnswers[index]?.words[0] || ''}</span>
                                                </div>
                                                <div className={styles['item-2digits']}>
                                                    <span className={styles['input-item']+' '+styles['correct-answer']}>{correctAnswers[index-1].words[0]}</span>
                                                    <span className={styles['input-item']+' '+styles['correct-answer']}>{correctAnswer.words[0]}</span>
                                                </div>
                                            </div>
                                            }
                                            </React.Fragment>
                                            :
                                            <div className={styles['item-group']}>
                                                <span className={styles['input-item']+' '+resultClass}>{currentMyAnswer}</span>
                                                <span className={styles['input-item']+' '+styles['correct-answer']}>{correctAnswer.words[0]}</span>
                                            </div>
                                        }
                                    </React.Fragment>
                                );
                            })}
                        </div>
                    </div>
                </div>
                }
            </SlidePracticeBackground>
        </div>
    );
};

export default SlidePracticeCharacters;
