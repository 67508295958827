import React from 'react';
import {LessonDone, LessonYet} from '../../atoms/Img';
import FontEn from '../../atoms/FontEn';
import InnerLink from '../../atoms/InnerLink';
// CSSはインポートした順に生成され、後にインポートしたCSSの方が優先されるので、
// まず他のコンポーネントや共通CSSをインポートして、その後でコンポーネントごとのCSSをインストールすること
// つまり、styles.module.cssのインポートはインポート文の複数行のうちの最後に置いておくのが安全
import styles from './styles.module.css';

export type LessonBoxProps = {
    isDone?: boolean;
    isReadable?: boolean;
    lessonUrl: string;
    lessonColor: string;
    lessonNo: number;
    lessonTitle: string;
    lessonPage?: number;
    lessonText?: string;
};


const LessonBox : React.FC<LessonBoxProps> = ({
    isDone = false,
    isReadable = false,
    lessonUrl,
    lessonColor,
    lessonNo,
    lessonTitle,
    lessonPage,
    lessonText,
}) => {
    // ページ数
    let pageTotal = null;
    if (lessonPage) {
        pageTotal = lessonPage.toString()+'p';
    }

    if (isReadable) {   // 受講可能なレッスン
        return (
            <InnerLink to={lessonUrl}>
                <div className={styles['lesson-box']}>
                    <div className={styles['lesson-text-box']}>
                        <FontEn domType='p' className={styles['lesson-number']+' '+styles['color-'+lessonColor]}><span>LESSON</span>{String(lessonNo).padStart(2, '0')}</FontEn>
                        <div className={styles['lesson-text']}>
                            <h4 className={styles['lesson-title']}>{lessonTitle}<FontEn className={styles['lesson-page-number']}>{pageTotal}</FontEn></h4>
                            <p className={styles['lesson-detail']+' '+styles['d-none']+' '+styles['d-sm-block']}>{lessonText}</p>
                        </div>
                    </div>
                    <div className="attendance-img">{isDone?<LessonDone/>:<LessonYet/>}</div>
                </div>
            </InnerLink>
        );
    } else {    // 受講できません
        return (
            <div className={styles['lesson-box']+' '+styles['unreadable']}>
                <div className={styles['lesson-text-box']}>
                    <FontEn domType='p' className={styles['lesson-number']}><span>閲覧不可</span>{String(lessonNo).padStart(2, '0')}</FontEn>
                    <div className={styles['lesson-text']}>
                        <h4 className={styles['lesson-title']}>{lessonTitle}<FontEn className={styles['lesson-page-number']}>{pageTotal}</FontEn></h4>
                        <p className={styles['lesson-detail']+' '+styles['d-none']+' '+styles['d-sm-block']}>{lessonText}</p>
                    </div>
                </div>
                <div className="attendance-img">{isDone?<LessonDone/>:<LessonYet/>}</div>
            </div>
        );
    }
};

export default LessonBox;
