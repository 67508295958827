import React from 'react';
import SendPasswordTemplate from '../../templates/SendPasswordTemplate';
import { useAppSelector, useAppDispatch } from '../../../redux/app/hooks';
import {
    changeEmail,
    changeConfirmCode,
    changeNewPassword,
    changeShowPwdFlag,

    submitEmail,
    submitNewPassword,

    selectResetPasswordState,
} from '../../../redux/slices/resetPasswordSlice';
// CSSはインポートした順に生成され、後にインポートしたCSSの方が優先されるので、
// まず他のコンポーネントや共通CSSをインポートして、その後でコンポーネントごとのCSSをインストールすること
// つまり、styles.module.cssのインポートはインポート文の複数行のうちの最後に置いておくのが安全
// import styles from './styles.module.css';


export type ResetPasswordPageProps = {
    children?: never;
};


const ResetPasswordPage : React.FC<ResetPasswordPageProps> = () => {
    const dispatch = useAppDispatch();

    const {
        resetPasswordPhase,

        email,
        confirmCode,
        newPassword,
        showPwdFlag,

        emailValidationMessage,
        confirmCodeValidationMessage,
        newPasswordValidationMessage,
    } = useAppSelector(selectResetPasswordState);

    const submitEmailParams = {
        email,
        emailValidationMessage,
    };

    const submitNewPasswordParams = {
        email,
        confirmCode,
        newPassword,
        confirmCodeValidationMessage,
        newPasswordValidationMessage,
    };

    return (
        <SendPasswordTemplate
        sendPasswordPhase={resetPasswordPhase}

          email={email}
          confirmCode={confirmCode}
          newPassword={newPassword}
          showPwdFlag={showPwdFlag}

          emailValidationMessage={emailValidationMessage}
          confirmCodeValidationMessage={confirmCodeValidationMessage}
          newPasswordValidationMessage={newPasswordValidationMessage}

          changeEmail={(e) => {dispatch(changeEmail({ email: e.target.value, emailValidationMessage: e.target.validationMessage, }));}}
          changeConfirmCode={ (e) => { dispatch(changeConfirmCode(e.target.value)) } }
          changeNewPassword={ (e) => { dispatch(changeNewPassword(e.target.value)) } }
          toggleShowPwdFlag = {(e) => { dispatch(changeShowPwdFlag(!showPwdFlag)); }}

          submitEmail={ () => { { dispatch(submitEmail(submitEmailParams)) } } }
          submitNewPassword={ () => { dispatch(submitNewPassword(submitNewPasswordParams)) } }
          />
    );
};

export default ResetPasswordPage;
