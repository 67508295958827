import React, {
    useEffect,
    useRef,
} from 'react';
import LoginTemplate from '../../templates/LoginTemplate';
import { Auth } from 'aws-amplify';
import { useNavigate } from 'react-router-dom';
import { useAppSelector, useAppDispatch } from '../../../redux/app/hooks';
import {
    changeEmail,
    changePassword,
    changeShowPwdFlag,
    submit,

    selectLoginState,
} from '../../../redux/slices/loginSlice';
// CSSはインポートした順に生成され、後にインポートしたCSSの方が優先されるので、
// まず他のコンポーネントや共通CSSをインポートして、その後でコンポーネントごとのCSSをインストールすること
// つまり、styles.module.cssのインポートはインポート文の複数行のうちの最後に置いておくのが安全
import styles from './styles.module.css';


type LoginPageProps = {
    children?: never;
};


const LoginPage : React.FC<LoginPageProps> = (
) => {
    const navigate = useNavigate();

    useEffect(() => {
        const affect = async () => {
            try {
                // ログインしている時はnull以外が返る
                const currentUserInfo = await Auth.currentUserInfo();
                // alert(JSON.stringify(currentUserInfo));

                // 会員を無効化した後は、currentUserInfoは{}となる
                if (currentUserInfo && Object.keys(currentUserInfo).length !== 0) {
                    navigate('/mypage');
                    return;
                }
            } catch {
                //
            }
        };

        affect();
    });

    const {
        email,
        password,
        showPwdFlag,
        loginValidationMessage,
    } = useAppSelector(selectLoginState);
    const dispatch = useAppDispatch();

    const params = {
        email,
        password,
        navigate,
    };

    let keyDown = (e:any) => {};

    // add,removeEventListenerするために、
    // 再描画時に関数が変わらないようにrefに入れておく
    const keyDownRef = useRef(keyDown);
    useEffect(() => {
        keyDown = (e:any) => {
            switch(e.code) {
            case 'Enter':
                dispatch(submit(params));
                e.preventDefault();
                break;
            }
        };

        // keyDownRefはparamsで変わるので、毎回更新する
        // 以前のkeyDownRefをremoveEventListenerしてから、新しいkeyDownRefをaddEventListenerする
        document.removeEventListener('keydown', keyDownRef.current);

        keyDownRef.current = keyDown;

        document.addEventListener('keydown', keyDownRef.current, {passive: false});

        return () => {
            document.removeEventListener('keydown', keyDownRef.current);
        };
    }, [dispatch, submit, params]);

    return (
        <div className={ styles['login-page'] }>
        <LoginTemplate
            email={email}
            password={password}
            showPwdFlag={showPwdFlag}

            loginValidationMessage={loginValidationMessage}

            toggleShowPwdFlag = {(e) => { dispatch(changeShowPwdFlag(!showPwdFlag)); }}
            changeEmail={(e) => dispatch(changeEmail(e.target.value))}
            changePassword={(e) => dispatch(changePassword(e.target.value))}
            submit={() => dispatch(submit(params))}
        />
        </div>
    );
};

export default LoginPage;
