import React, {
    useEffect,
} from 'react';
import {
    useLocation,
} from 'react-router-dom';
// CSSはインポートした順に生成され、後にインポートしたCSSの方が優先されるので、
// まず他のコンポーネントや共通CSSをインポートして、その後でコンポーネントごとのCSSをインストールすること
// つまり、styles.module.cssのインポートはインポート文の複数行のうちの最後に置いておくのが安全
// import styles from './styles.module.css';

export type GtagPageProps = {
    children?: never;
};

const GtagPage : React.FC<GtagPageProps> = () => {
  const location = useLocation();

  // ロケーションに変更があったときに処理実行
  useEffect(() => {
    window.gtag('config', 'G-B9BDF68XGS', {
      'page_path': location.pathname,
    });
  }, [location]);


    return (
        <React.Fragment>
        </React.Fragment>
    );
};

export default GtagPage;
