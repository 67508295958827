import React from 'react';
import templateProperties from '../../common/template-properties.module.css';
import { UrlLabel } from '../../../redux/constant';
import HeaderContainer from '../../organisms/HeaderContainer';
import PageTitleArea from '../../layouts/PageTitleArea';
import BreadcrumbBar from '../../organisms/BreadcrumbBar';
import { PageContainer } from '../../layouts/Container';
import ContractPreamble from '../../molecules/ContractPreamble';
import ContractArticle from '../../molecules/ContractArticle';
import ContractSignature from '../../molecules/ContractSignature';
import Footer from '../../organisms/Footer';
import InnerLink from '../../atoms/InnerLink';
// CSSはインポートした順に生成され、後にインポートしたCSSの方が優先されるので、
// まず他のコンポーネントや共通CSSをインポートして、その後でコンポーネントごとのCSSをインストールすること
// つまり、styles.module.cssのインポートはインポート文の複数行のうちの最後に置いておくのが安全
import styles from './styles.module.css';

Object.assign(styles, templateProperties);

const NotationBL: Array<UrlLabel> = [
    { url: '/mypage', label: 'マイページ' },
    { url: '', label: '特定商取引法に基づく表記' },
];

export type NotationTemplateProps = {
    isLogin: boolean;             // ログイン状態
    isPaid: boolean;              // 有料会員かどうか
    isSocialLogin: boolean;       // SNSログイン会員かどうか
    profileImg?: string;          // 会員画像
    preferredUsername? : string;  // 会員名
};

const NotationTemplate : React.FC<NotationTemplateProps> = ({
    isLogin,
    isPaid,
    isSocialLogin,
    profileImg,
    preferredUsername,
}) => {
    return (
        <div className={ styles['notation-template'] + ' ' + styles['template-properties-local'] }>
          <HeaderContainer isLogin={isLogin} isPaid={isPaid} isSocialLogin={isSocialLogin} profileImg={profileImg} preferredUsername={preferredUsername} />
          <PageTitleArea>特定商取引法に基づく表記</PageTitleArea>
          {isLogin && <BreadcrumbBar UrlLabels={NotationBL} />}
          <PageContainer>
            <ContractPreamble>
            事業者 株式会社メモアカ<br/>
            運営責任者 青木 健<br/>
            所在地 〒113-0023 東京都文京区向丘2丁目3-10 東大前HiRAKU GATE 7F<br/>
            ホームページ <InnerLink to='/'>https://www.memoaca.com</InnerLink><br/>
            問い合わせ <InnerLink to='/contact'>お問い合わせフォーム</InnerLink>
            </ContractPreamble>

            <ContractArticle title='利用料金'>
            利用料金は1,490円(月額・税込)です。<br/>
            キャンペーンなどにより料金を変更している場合がありますので、必ず<InnerLink to='/#plan' targetBlank={true}>こちら</InnerLink>をご参照ください。
            </ContractArticle>

            <ContractArticle title='商品以外の必要料金'>
            個人の方は、利用料金以外はかかりません。<br/>
            法人会員の場合、別途サービスに応じて料金がかかる場合があります。
            </ContractArticle>

            <ContractArticle title='支払方法'>
            以下のいずれかのお支払い方法をご利用いただけます。<br/>
            クレジットカード決済 ウォレット決済(個人のみ)<br/>
            銀行振込(法人契約のみ)<br/>
            クレジットカード決済について 一括払いのみとなっております。<br/>
            またお使いいただけるクレジットカードは以下になります。 <br/>
            Visa ・MasterCard ・American Express<br/><br/>
            お使いいただけるウォレット決済は以下になります。<br/>
            ・GooglePay ・ApplePay
            </ContractArticle>

            <ContractArticle title='支払時期'>
            初回の支払いは、有料サービス登録時に実施され、次回支払いは月払いの場合は、翌月の同日同時間(翌月に同日がない場合は、翌月末日の同時間)、年払いの場合は翌年の同月同日同時間(翌年に同日がない場合は、翌年同月末日の同時間)となります。
            </ContractArticle>

            <ContractArticle title='商品の引渡時期'>
            代金決済確認後、直ちにご利用頂けます。
            </ContractArticle>

            <ContractArticle title='返金、キャンセル'>
            <strong>有料サービスの解約方法</strong><br/>
            マイページの「お支払情報」から支払いを停止してください。<br/><br/>
            <strong>クーリング・オフについて</strong><br/>
            本サービスはクーリング・オフ適用対象外です。 <br/><br/>
            <strong>サービス提供開始後の解約について</strong><br/>
            当社所定の規定に従ってご解約頂けます。ただし、すでに支払い完了した代金に関しての返金はお受け出来ません。
            </ContractArticle>

            <ContractArticle title='動作環境(推奨)'>
            有料プランの利用にあたり、推奨する動作環境は以下のとおりです。<br/><br/>
            ● Windows ・OS:Microsoft Windows10以上 ・Webブラウザ:GoogleChrome(最新版)<br/>
            ● Mac ・OS:macOS ・Webブラウザ:GoogleChrome(最新版)<br/>
            </ContractArticle>

            <ContractSignature>
           (二版 2022 年 10月 15日制定)<br/>
           (初版 2022 年 8月 1日制定)
            </ContractSignature>
          </PageContainer>
          <Footer isLogin={isLogin} />
        </div>
    );
};

export default NotationTemplate;
