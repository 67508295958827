import React, { ReactNode } from 'react';
// CSSはインポートした順に生成され、後にインポートしたCSSの方が優先されるので、
// まず他のコンポーネントや共通CSSをインポートして、その後でコンポーネントごとのCSSをインストールすること
// つまり、styles.module.cssのインポートはインポート文の複数行のうちの最後に置いておくのが安全
import styles from './styles.module.css';

export type TitleProps = {
    kind?: string;
    children?: ReactNode;
} & JSX.IntrinsicElements['h1'];

const Title : React.FC<TitleProps> = ({
    kind = '',
    children,
    ...props
}) => {
    if (kind === 'itemized') {
        return (<h2 className={styles['title-itemized']} {...props}>{children}</h2>);
    } else {
        return (<h1 className={styles['title']} {...props}>{children}</h1>);
    }
};

const titleFactory = (kind: string) => {
    return (tProps: Omit<TitleProps, 'kind'>) => {
        return (
            <Title kind={kind} {...tProps} />
        );
    };
}

export default Title;
export const TitleItemized = titleFactory('itemized');
