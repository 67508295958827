import React from 'react';
import WithdrawalTemplate from '../../templates/WithdrawalTemplate';
import { useNavigate } from 'react-router-dom';
import { useAppSelector, useAppDispatch } from '../../../redux/app/hooks';
import { Auth, API } from 'aws-amplify';
import { graphqlOperation, GraphQLResult } from '@aws-amplify/api-graphql';
import {
    CognitoIdentityProviderClient,
    DeleteUserCommand,
} from '@aws-sdk/client-cognito-identity-provider';

import {
    selectLoginState,
} from '../../../redux/slices/loginSlice';
import {
    selectPaymentState,
} from '../../../redux/slices/paymentSlice';
import {
    changeReasonForWithdrawal,
    changeModalIsOpen,

    selectWithdrawalState,
} from '../../../redux/slices/withdrawalSlice';
import awsConfig from '../../../aws-exports.js';
import {
    GetUserQuery,
    GetUserQueryVariables,
    UpdateUserMutation,
    UpdateUserMutationVariables,
} from '../../../API';
import {
    getUser,
} from '../../../graphql/queries';
import {
    updateUser,
} from '../../../graphql/mutations';
import moment from 'moment';
// CSSはインポートした順に生成され、後にインポートしたCSSの方が優先されるので、
// まず他のコンポーネントや共通CSSをインポートして、その後でコンポーネントごとのCSSをインストールすること
// つまり、styles.module.cssのインポートはインポート文の複数行のうちの最後に置いておくのが安全
// import styles from './styles.module.css';

export type WithdrawalPageProps = {
    children?: never;
};

const makeBody = (preferredUsername:string) => {
return `${preferredUsername} 様

メモアカの退会手続きが完了いたしました。
この度は弊社サービスであるメモアカをご利用頂いただきまして、誠にありがとうございました。
またのご利用を心よりお待ち申し上げております。

このメールは配信専用です。
返信されても、内容の確認および回答はできません。
メモアカへのご質問・お問い合わせは、文末のURLをご参照ください。

＜このメールにお心当たりのない方＞
このメールは、お客様にご入力いただいたメールアドレスへ配信しています。
万一、このメールを誤って受信された場合には、破棄していただきますようお願いします。

―――――――――――――――――――――――
株式会社メモアカ
URL
https://www.memoaca.com
ご質問・お問い合わせ
https://www.memoaca.com/contact
〒113-0023
東京都文京区向丘2丁目3-10
東大前HiRAKU GATE 7F
―――――――――――――――――――――――
`;
}

const WithdrawalPage : React.FC<WithdrawalPageProps> = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const {
        isLogin,
        isSocialLogin,
        preferredUsername,
    } = useAppSelector(selectLoginState);

    const {
        isSubscribing,
        isPaid,
        now,
        paymentExpiredOn,
    } = useAppSelector(selectPaymentState);

    const {
        reasonForWithdrawal,
        modalIsOpen,
    } = useAppSelector(selectWithdrawalState);

    const profileImg = undefined;

    const clickOkButton = async () => {
        const region = awsConfig.aws_cognito_region;
        const client = new CognitoIdentityProviderClient({ region, });

        const user = await Auth.currentAuthenticatedUser();
        const token = user.signInUserSession.accessToken.jwtToken;

        const currentUserInfo = await Auth.currentUserInfo();
        const email = currentUserInfo.attributes.email;

        const params = {
            AccessToken: token,
        };

        const command = new DeleteUserCommand(params)

        // 環境ごとに運営のメールアドレスを環境変数で指定する
        // user1@example.com,user2@example.comのようなコンマ区切り文字列
        const joinedToEmails = email;
        const joinedCcEmails : string | undefined = process.env.REACT_APP_ADMIN_EMAILS;
        const joinedBccEmails = '';

        const subject = '[重要]退会手続き完了のお知らせ';
        const body = makeBody(preferredUsername || '');

        const myInit = {
            body: {
                joinedToEmails,
                joinedCcEmails,
                joinedBccEmails,
                subject,
                body,
                emailFrom: 'no-reply@memoaca.com',
            },
        };

        const API_NAME = 'sender';

        const sub : string = currentUserInfo.attributes.sub;
        const getUserQueryVariables : GetUserQueryVariables = {
            sub,
        };


        try {
            await client.send(command);

            const getUserRes = await API.graphql(graphqlOperation(getUser, getUserQueryVariables)) as GraphQLResult<GetUserQuery>;
            const fetchedUser = getUserRes.data?.getUser;

            if (!fetchedUser) {
                throw new Error('会員情報が取得できませんでした');
            }

            const oldVersion = fetchedUser._version;

            const updateUserMutationVariables : UpdateUserMutationVariables = {
                input: {
                    sub,
                    withdrawedDateTime: moment().toISOString(),
                    _version: oldVersion,
                },
            };

            await API.graphql(graphqlOperation(updateUser, updateUserMutationVariables)) as GraphQLResult<UpdateUserMutation>;

            const adminMailContent = `このメールはシステムから送信されています。

会員が退会されました: ${preferredUsername} 様
退会理由: ${reasonForWithdrawal}

sub: ${fetchedUser?.sub}
gender: ${fetchedUser?.gender}
birthDate: ${fetchedUser?.birthDate}
profession: ${fetchedUser?.profession}
prefecture: ${fetchedUser?.prefecture}
firstLoginDateTime: ${fetchedUser?.firstLoginDateTime}
lastLoginDateTime: ${fetchedUser?.lastLoginDateTime}
activeDays: ${fetchedUser?.activeDays}
experienceLevel: ${fetchedUser?.experienceLevel}
experiencePoint: ${fetchedUser?.experiencePoint}
`;

            const myInitReason = {
                body: {
                    joinedToEmails: process.env.REACT_APP_ADMIN_EMAILS || 'inquiry@memoaca.com',
                    joinedCcEmails: '',
                    joinedBccEmails: '',
                    subject: `会員が退会されました: ${preferredUsername} 様`,
                    body: adminMailContent,
                    emailFrom: 'no-reply@memoaca.com',
                },
            };

            await API.post(API_NAME, '/send-email', myInit);
            await API.post(API_NAME, '/send-email', myInitReason);
            navigate('/wait-withdrawal');
        } catch (error) {
            alert('エラーが発生しました。運営にお問い合わせください');
        } finally {
            //
        }
    };

    const clickCancelButton = () => { dispatch(changeModalIsOpen(false)) };

    const submit = () => { dispatch(changeModalIsOpen(true)) };

    return (
        <WithdrawalTemplate
          isLogin={isLogin}
          isPaid={isPaid}
          isSocialLogin={isSocialLogin}
          profileImg={profileImg}
          preferredUsername={preferredUsername}

          isSubscribing={isSubscribing}
          now={now}
          paymentExpiredOn={paymentExpiredOn}

          modalIsOpen={modalIsOpen}

          clickOkButton={clickOkButton}
          clickCancelButton={clickCancelButton}

          reasonForWithdrawal={reasonForWithdrawal}
          changeReasonForWithdrawal={(e) => { dispatch(changeReasonForWithdrawal(e.target.value)) }}

          submit={submit}
          />
    );
};

export default WithdrawalPage;
