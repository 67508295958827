import React from 'react';
import { CourseColor } from '../../../config/course';
import { CorrectAnswer } from '../../../lib/domSlide';
import { ImagesLessonSlide } from '../../../config/course';
import SlidePracticeBackground from '../../atoms/SlidePracticeBackground';
import FontTitle from '../../atoms/FontTitle';
import Img from '../../atoms/Img';
import { PrimaryButton } from '../../atoms/Button';
import Textbox from '../../atoms/Textbox';
// CSSはインポートした順に生成され、後にインポートしたCSSの方が優先されるので、
// まず他のコンポーネントや共通CSSをインポートして、その後でコンポーネントごとのCSSをインストールすること
// つまり、styles.module.cssのインポートはインポート文の複数行のうちの最後に置いておくのが安全
import styles from './styles.module.css';

export type SlidePracticeImagesWithSentenceProps = {
    pageIndex: number;
    slideColor: CourseColor;
    isCheckingAnswers: boolean;
    myAnswers: Array<CorrectAnswer>;
    updateMyAnswersWithIndex: (index:number) => (e: React.ChangeEvent<HTMLInputElement>) => void;
    updateIsCheckingAnswersTrue: (e: React.MouseEvent<HTMLButtonElement>) => void;
} & Omit<ImagesLessonSlide, 'type'>;

// 2023.04.18 by t.nakata
// イメージを3枚まで表示することだけを想定したレイアウト
// イメージを3枚以上表示する際は修正が必要
const SlidePracticeImagesWithSentence : React.FC<SlidePracticeImagesWithSentenceProps> = ({
    pageIndex,
    slideColor,
    memorizationTitle,
    recallTitle,
    resultTitle = '結果を判定します',
    correctAnswers,
    myAnswers,
    isCheckingAnswers,

    updateMyAnswersWithIndex,
    updateIsCheckingAnswersTrue,
}) => {
    let titleClass = styles['dom-slide-title'];           // タイトル設定
    let answerAreaClass = styles['dom-slide-answerarea']; // 問題・回答エリア設定
    let answerColumnClass = styles['input-item'];         // 問題・回答欄設定
    let buttonAreaClass = styles['button-area'];          // ボタン設定

    // 回答、結果表示時の配列準備
    const recallAnswers = correctAnswers?.concat().sort(function(a, b){
        if (typeof a.secondOrder === 'number' && typeof b.secondOrder === 'number') {
            return a.secondOrder - b.secondOrder
        }
        return 0
    });

    // 結果画面
    if (pageIndex === 1 && isCheckingAnswers) {
        answerColumnClass += ' '+ styles['result'];
    }
    return (
        <div className={ styles['slide-practice-images-with-sentence'] }>
            <SlidePracticeBackground bgColor={slideColor} className={styles['dom-slide']}>
                {/* 記憶画面 */}
                { pageIndex === 0 &&
                <div className={styles['dom-slide-area']}>
                    <FontTitle className={titleClass}>{memorizationTitle}</FontTitle>
                    <div className={styles['dom-slide-content']}>
                        <div className={answerAreaClass}>
                            {correctAnswers.map((correctAnswer, idx) => {
                                return (
                                    <div className={styles['item-group']} key={idx}>
                                        <Img fileName={correctAnswer.image} alt={'test_image_'+{idx}} />
                                        <span className={styles['item-label']}>{correctAnswer.words[0]}</span>
                                        <span className={styles['item-label']}>{correctAnswer.sentence}</span>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
                }

                {/* 回答画面 */}
                { pageIndex === 1 && !isCheckingAnswers &&
                <div className={styles['dom-slide-area']}>
                    <FontTitle className={titleClass}>{recallTitle}</FontTitle>
                    <div className={styles['dom-slide-content']}>
                        <div className={answerAreaClass}>
                            {recallAnswers.map((recallAnswer, idx) => {
                                return (
                                    <div className={styles['item-group']+' '+styles['result']} key={idx}>
                                        <Img fileName={recallAnswer.image} alt={'test_image_'+idx} />
                                        <Textbox
                                            id={'pulldown_'+idx}
                                            className={answerColumnClass}
                                            value={myAnswers[idx]?.words[0] || []}
                                            onChange={updateMyAnswersWithIndex(idx)}
                                            maxLength={10}
                                        />
                                        <span className={styles['item-label']}>{recallAnswer.sentence}</span>
                                    </div>
                                );
                            })}
                        </div>
                        <div className={buttonAreaClass}>
                            <PrimaryButton className={styles['button']} onClick={updateIsCheckingAnswersTrue}>答え合わせする</PrimaryButton>
                        </div>
                    </div>
                </div>
                }

                {/* 結果画面 */}
                { pageIndex === 1 && isCheckingAnswers &&
                <div className={styles['dom-slide-area']}>
                    <FontTitle className={titleClass}>{resultTitle}</FontTitle>
                    <div className={styles['dom-slide-content']}>
                        <div className={answerAreaClass}>
                            {recallAnswers.map((recallAnswer, idx) => {
                                const value = myAnswers[idx]?.words[0] || '';
                                const resultClass = (recallAnswer.words.includes(value))? styles['correct'] : styles['incorrect'];
                                return (
                                    <div className={styles['item-group']+' '+styles['result']} key={idx}>
                                        <Img fileName={recallAnswer.image} alt={'test_image_'+idx} />
                                        <span className={answerColumnClass+' '+resultClass}>{myAnswers[idx]?.words[0] || ''}</span>
                                        <span className={answerColumnClass+' '+styles['correct-answer']}>{recallAnswer.words[0]}</span>
                                        <span className={styles['item-label']}>{recallAnswer.sentence}</span>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
                }
            </SlidePracticeBackground>
        </div>
    );
};

export default SlidePracticeImagesWithSentence;
