import React from 'react';
// import { imageResources } from '../../../lib/imageResources';
import { CorrectAnswer } from '../../../lib/domSlide';
import {PrimaryButton} from '../../atoms/Button';
import FontTitle from '../../atoms/FontTitle';
import SlidePracticeBackground from '../../atoms/SlidePracticeBackground';
// import Textarea from '../../atoms/Textarea';
import Textbox from '../../atoms/Textbox';
import { CourseColor } from '../../../config/course';
// CSSはインポートした順に生成され、後にインポートしたCSSの方が優先されるので、
// まず他のコンポーネントや共通CSSをインポートして、その後でコンポーネントごとのCSSをインストールすること
// つまり、styles.module.cssのインポートはインポート文の複数行のうちの最後に置いておくのが安全
import styles from './styles.module.css';

export type SlidePracticeWordListRecallProps = {
    className?: string;
    slideColor: CourseColor;
    recallTitle: string;
    resultTitle?: string;
    numCol: number;
    hasFreeTextarea?: boolean;
    freeTextareaPlaceholder?: string;
    correctAnswers: Array<CorrectAnswer>;
    myAnswers: Array<CorrectAnswer>;
    isCheckingAnswers: boolean;

    updateMyAnswersWithIndex: (index:number) => (e: React.ChangeEvent<HTMLInputElement>) => void;
    updateIsCheckingAnswersTrue: (e: React.MouseEvent<HTMLButtonElement>) => void;
};


const SlidePracticeWordListRecall : React.FC<SlidePracticeWordListRecallProps> = ({
    className = '',
    slideColor,
    recallTitle,
    resultTitle = '結果を判定します',
    numCol,
    hasFreeTextarea = true,
    freeTextareaPlaceholder = '',
    correctAnswers,
    myAnswers,
    isCheckingAnswers,

    updateMyAnswersWithIndex,
    updateIsCheckingAnswersTrue,
}) => {
    // class属性を設定
    let classString = styles['dom-slide-recall'];
    if (className) {
        classString = [classString, className].join(' ');
    }

    // 要素のクラス
    let answerAreaClass = styles['dom-slide-answerarea'];
    let answerColumnClass = styles['input-item'];
    let inputGroupClass = styles['input-group'];
    // let textareaClass = styles['dom-slide-textarea'];
    let buttonAreaClass = styles['button-area'];
    // 横の入力欄数で入力欄の幅を指定する
    switch (numCol) {
        case 3:
            answerColumnClass = [answerColumnClass, styles['three-in-row']].join(' ');
            break;
        case 4:
            answerColumnClass = [answerColumnClass, styles['four-in-row']].join(' ');
            inputGroupClass = [inputGroupClass, styles['four-in-row']].join(' ');
            // 問題総数が4で割り切れない数の場合
            if (correctAnswers.length%4) {
                answerAreaClass = [answerAreaClass, styles['indivisible-columns']].join(' ');
                if (isCheckingAnswers) {
                    inputGroupClass = [inputGroupClass, styles['indivisible-columns']].join(' ');
                } else {
                    answerColumnClass = [answerColumnClass, styles['indivisible-columns']].join(' ');
                }
            }
            break;
        case 5:
            answerColumnClass = [answerColumnClass, styles['five-in-row']].join(' ');
            break;
    }

    // 回答欄のmaxLengthを取得(正答の最大文字数+2)
    let answerMaxLength = 1;        // 最初は仮に1を設定
    for (let i = 0; i < correctAnswers.length; i++) {
        for (let j = 0; j < correctAnswers[i].words.length; j++) {
            if (answerMaxLength < correctAnswers[i].words[j].length) answerMaxLength = correctAnswers[i].words[j].length;
        }
    }
    answerMaxLength += 2;

    return (
        <SlidePracticeBackground bgColor={slideColor} className={classString}>
            <div className={styles['dom-slide-area']}>
                <div className={styles['dom-slide-content']}>
                    <FontTitle className={styles['dom-slide-title']}>{isCheckingAnswers? resultTitle:recallTitle}</FontTitle>
                    <div className={answerAreaClass}>
                        {correctAnswers.map((correctAnswer, idx) => {
                            if (isCheckingAnswers) {
                                const value = myAnswers[idx]?.words[0] || '';
                                const resultClass = (correctAnswer.words.includes(value))? styles['correct'] : styles['incorrect'];
                                // 正誤判定画面
                                return (
                                    <div className={inputGroupClass} key={idx}>
                                        <span className={answerColumnClass+' '+resultClass}>{myAnswers[idx]?.words[0] || ''}</span>
                                        <span className={answerColumnClass+' '+styles['correct-answer']}>{correctAnswer.words[0]}</span>
                                    </div>
                                );
                            } else {
                                // 回答画面
                                return (
                                    <Textbox
                                        key={idx}
                                        className={answerColumnClass}
                                        value={myAnswers[idx]?.words[0] || []}
                                        onChange={updateMyAnswersWithIndex(idx)}
                                        maxLength={answerMaxLength}
                                    />
                                );
                            }
                        })}
                    </div>
                    {/* {!isCheckingAnswers && hasFreeTextarea && <Textarea placeholder={freeTextareaPlaceholder} className={textareaClass} /> } */}
                    {!isCheckingAnswers &&
                    <div className={buttonAreaClass}>
                        <PrimaryButton className={styles['button']} onClick={updateIsCheckingAnswersTrue}>答え合わせする</PrimaryButton>
                    </div>
                    }
                    </div>
            </div>
        </SlidePracticeBackground>
    );
};

export default SlidePracticeWordListRecall;
