import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import type { RootState } from '../app/store';
import { ContactPhase } from '../constant';
import { API } from "aws-amplify"
import xss from 'xss';

interface ContactState {
    [key: string]: any;

    contactPhase: ContactPhase;
    username: string; // 必ずしもpreferredUsernameと一致していなくてもよい
    email: string;
    email2: string;
    usertext: string;

    usernameValidationMessage: string;
    emailValidationMessage: string;
    usertextValidationMessage: string;
};

export const initialState: ContactState = {
    contactPhase: ContactPhase.send,

    username: '',
    email: '',
    email2: '',
    usertext: '',

    usernameValidationMessage: '',
    emailValidationMessage: '',
    usertextValidationMessage: '',
}

interface SendEmailParams {
    joinedToEmails?: string;
    joinedCcEmails?: string;
    joinedBccEmails?: string;
    subject: string;
    body: string;
    emailFrom: string;
}

export const sendEmail = createAsyncThunk(
    'contact/sendEmail',
    async (sendEmailParams: SendEmailParams, thunkAPI) => {
        const  {
            joinedToEmails,
            joinedCcEmails,
            joinedBccEmails,
            subject,
            body,
            emailFrom,
        } = sendEmailParams;

        const myInit = {
            body: {
                joinedToEmails: joinedToEmails || '',
                joinedCcEmails: joinedCcEmails || '',
                joinedBccEmails: joinedBccEmails || '',
                subject,
                body,
                emailFrom,
            },
        };

        const API_NAME = 'sender';
        await API.post(API_NAME, '/send-email', myInit);
    }
);


interface IChangeEmail {
    email: string;
    emailValidationMessage: string;
}

export const contactSlice = createSlice({
   name: 'contact',
    initialState,
    reducers: {
        changeContactPhase: (state, action: PayloadAction<ContactPhase>) => {
            const contactPhase = action.payload;

            if (contactPhase === ContactPhase.confirm) {
                if (state.username === '' || state.email === '' || state.usertext === '') {
                    // 空欄があるので次には進まない
                    if (state.username === '') {
                        state.usernameValidationMessage = 'お名前が空欄です';
                    }

                    if (state.email === '') {
                        state.emailValidationMessage = 'メールアドレスが空欄です';
                    }

                    if (state.usertext === '') {
                        state.usertextValidationMessage = 'お問い合わせ内容が空欄です';
                    }
                }

                if (state.usernameValidationMessage === '' && state.emailValidationMessage === '' && state.usertextValidationMessage === '') {
                    state.contactPhase = ContactPhase.confirm;
                }
            } else if (contactPhase === ContactPhase.postprocess) {
                state.contactPhase = contactPhase;

                // contactPhase以外を初期化
                for (let k of Object.keys(initialState)) {
                    if (k === 'contactPhase') {
                        //
                    } else {
                        state[k] = initialState[k];
                    }
                }
            } else {
                state.contactPhase = contactPhase;
            }
        },
        changeUsername: (state, action: PayloadAction<string>) => {
            const s = xss(action.payload);
            state.username = s;

            if (s === '') {
                state.usernameValidationMessage = 'お名前が空欄です';
            } else {
                state.usernameValidationMessage = '';
            }
        },
        changeEmail: (state, action: PayloadAction<IChangeEmail>) => {
            const { email, emailValidationMessage } = action.payload;

            state.email = email;

            if (emailValidationMessage !== '') {
                state.emailValidationMessage = emailValidationMessage;
            } else if (state.email2 === email) {
                state.emailValidationMessage = '';
            } else {
                state.emailValidationMessage = 'Eメールアドレスが一致しません';
            }
        },
        changeEmail2: (state, action: PayloadAction<IChangeEmail>) => {
            const { email: email2, emailValidationMessage } = action.payload;

            state.email2 = email2;

            if (emailValidationMessage !== '') {
                state.emailValidationMessage = emailValidationMessage;
            } else if (state.email === email2) {
                state.emailValidationMessage = '';
            } else {
                state.emailValidationMessage = 'Eメールアドレスが一致しません';
            }
        },
        changeUsertext: (state, action: PayloadAction<string>) => {
            const s = xss(action.payload);
            state.usertext = s;

            if (s === '') {
                state.usertextValidationMessage = 'お問い合わせ内容が空欄です';
            } else {
                state.usertextValidationMessage = '';
            }
        },
    },
    extraReducers: (builder) => {
        builder.addCase(sendEmail.fulfilled, (state, action) => {
            state.contactPhase = ContactPhase.postprocess;

            // contactPhase以外を初期化
            for (let k of Object.keys(initialState)) {
                if (k === 'contactPhase') {
                    //
                } else {
                    state[k] = initialState[k];
                }
            }
        });

        builder.addCase(sendEmail.rejected, (state, action) => {
             // console.log(JSON.stringify(action.error.message));
            // alert(JSON.stringify(action.error.message));
        });
    },
})

export const {
    changeContactPhase,
    changeUsername,
    changeEmail,
    changeEmail2,
    changeUsertext,
 } = contactSlice.actions;

export const selectContactState = (state: RootState) => {
    return {
        contactPhase: state.contact.contactPhase,

        username: state.contact.username,
        email: state.contact.email,
        email2: state.contact.email2,
        usertext: state.contact.usertext,

        usernameValidationMessage: state.contact.usernameValidationMessage,
        emailValidationMessage: state.contact.emailValidationMessage,
        usertextValidationMessage: state.contact.usertextValidationMessage,
    };
};

export default contactSlice.reducer;
