import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit'
import { enableMapSet } from 'immer';
import type { RootState } from '../app/store'
import { NavigateFunction } from "react-router";
import { Auth } from "aws-amplify"
import { CourseId } from '../../config/course';
import {
    isValidPasswordCharset,
} from '../../lib/textUtils';

// Redux/toolkitで使われるimmerで、Set型を利用可能にする
enableMapSet();

interface LoginState {
    [key: string]: any;
    forceReloadNext: boolean;

    isLogin: boolean;
    isSocialLogin: boolean;
    email: string;
    password: string;
    showPwdFlag: boolean;
    loginValidationMessage: string;

    firstLoginDateTime?: string;
    experienceLevel: number;
    experiencePoint: number;
    addedExperiencePoint: number;
    lessonDoneSet: Set<string>;

    latestCourseId?: string;
    latestLessonNo?: number;

    preferredUsername?: string;
    gender?: string,
    genderOpen?: string,
    birthDate?: string,
    prefecture?: string,
    profession?: string,
    selfIntroduction?: string,
}

export const initialState: LoginState = {
    forceReloadNext: true,

    isLogin: false,
    isSocialLogin: false,
    sub: '',
    email: '',
    password: '',
    showPwdFlag: false,
    loginValidationMessage: '',

    firstLoginDateTime: undefined,
    experienceLevel: 1,
    experiencePoint: 0,
    addedExperiencePoint: 0,
    lessonDoneSet: new Set<string>(),

    latestCourseId: CourseId.introduction,
    latestLessonNo: 1,

    preferredUsername: undefined,
    gender: undefined,
    genderOpen: undefined,
    birthDate: undefined,
    prefecture: undefined,
    profession: undefined,
    selfIntroduction: undefined,
}

interface SubmitParams {
    email: string;
    password: string;
    navigate: NavigateFunction;
}

export const submit = createAsyncThunk(
  'login/submit',
    async (submitParams: SubmitParams, thunkAPI) => {
        const { email, password, navigate } = submitParams;

        await Auth.signIn(email, password);
        const userInfo = await Auth.currentUserInfo();
        await navigate('/mypage');

        return userInfo;
    }
);

export const loginSlice = createSlice({
    name: 'login',
    initialState,
    reducers: {
        changeForceReloadNext: (state, action: PayloadAction<boolean>) => {
            state.forceReloadNext = action.payload;
        },
        changeIsLogin: (state, action: PayloadAction<boolean>) => {
            state.isLogin = action.payload;
        },
        changeIsSocialLogin: (state, action: PayloadAction<string>) => {
            // ソーシャルログインの場合、usernameは 'facebook_' や 'google_' から始まる
            // ソーシャルグインの場合、subとusernameは異なる値となることに注意。
            const username = action.payload;
            const isSocialLogin = username.startsWith('facebook_') || username.startsWith('google_');
            state.isSocialLogin = isSocialLogin;
        },
        changeSub: (state, action: PayloadAction<string>) => {
            state.sub = action.payload;
        },
        changeEmail: (state, action: PayloadAction<string>) => {
            state.email = action.payload;
        },
        changePassword: (state, action: PayloadAction<string>) => {
            const password = action.payload;
            state.password = password;

            if (isValidPasswordCharset(password)) {
                state.loginValidationMessage = '';
            } else {
                state.loginValidationMessage = 'パスワードは8文字以上の半角英数字を入力してください';
            }
        },
        changeShowPwdFlag: (state, action: PayloadAction<boolean>) => {
            state.showPwdFlag = action.payload;
        },
        changeFirstLoginDateTime: (state, action: PayloadAction<string>) => {
            state.firstLoginDateTime = action.payload;
        },
        changeExperienceLevel: (state, action: PayloadAction<number>) => {
            state.experienceLevel = action.payload;
        },
        changeExperiencePoint: (state, action: PayloadAction<number>) => {
            state.experiencePoint = action.payload;
        },
        changeAddedExperiencePoint: (state, action: PayloadAction<number>) => {
            state.addedExperiencePoint = action.payload;
        },
        changeLessonDoneSet: (state, action: PayloadAction<Set<string>>) => {
            const s : Array<string>= [...action.payload.values()]
            state.lessonDoneSet = new Set<string>(s);
        },
        addLessonDoneSet: (state, action: PayloadAction<string>) => {
            const s : Array<string> = [...state.lessonDoneSet.values()];
            const newSet = new Set<string>(s);
            newSet.add(action.payload);
            state.lessonDoneSet = newSet;
        },
        changeLatestCourseId: (state, action: PayloadAction<string|undefined>) => {
            state.latestCourseId = action.payload;
        },
        changeLatestLessonNo: (state, action: PayloadAction<number|undefined>) => {
            state.latestLessonNo = action.payload;
        },
        changePreferredUsername: (state, action: PayloadAction<string|undefined>) => {
            state.preferredUsername = action.payload;
        },
        changeGender: (state, action: PayloadAction<string|undefined>) => {
            state.gender = action.payload;
        },
        changeGenderOpen: (state, action: PayloadAction<string|undefined>) => {
            state.genderOpen = action.payload;
        },
        changeBirthDate: (state, action: PayloadAction<string|undefined>) => {
            state.birthDate = action.payload;
        },
        changePrefecture: (state, action: PayloadAction<string|undefined>) => {
            state.prefecture = action.payload;
        },
        changeProfession: (state, action: PayloadAction<string|undefined>) => {
            state.profession = action.payload;
        },
        changeSelfIntroduction: (state, action: PayloadAction<string|undefined>) => {
            state.selfIntroduction = action.payload;
        },
        resetAllStates: (state, action: PayloadAction<undefined>) => {
            // store.ts内のrootStateで、全てのStateを初期化するという挙動を定義している
        },
    },
    extraReducers: (builder) => {
        builder.addCase(submit.fulfilled, (state, action) => {
            // 新規登録に成功したら状態を初期化する
            // 一度ログインした後はJWTトークンが発行されるので、
            // stateは初期化しても問題ない。
            // 特に、パスワードは意図せず使われると困るので、忘却しておく
            for (let k of Object.keys(initialState)) {
                state[k] = initialState[k];
            }
        });
        builder.addCase(submit.rejected, (state, action) => {
            state.loginValidationMessage = 'メールアドレスかパスワードが正しくありません';
        });
    },
})

export const {
    changeForceReloadNext,
    changeIsLogin,
    changeIsSocialLogin,
    changeEmail,
    changeSub,
    changePassword,
    changeShowPwdFlag,

    changeFirstLoginDateTime,
    changeExperienceLevel,
    changeExperiencePoint,
    changeAddedExperiencePoint,
    changeLessonDoneSet,
    addLessonDoneSet,

    changeLatestCourseId,
    changeLatestLessonNo,

    changePreferredUsername,
    changeGender,
    changeGenderOpen,
    changeBirthDate,
    changePrefecture,
    changeProfession,
    changeSelfIntroduction,

    resetAllStates,
} = loginSlice.actions;

export const selectLoginState = (state: RootState) => {
    return {
        forceReloadNext: state.login.forceReloadNext,

        isLogin: state.login.isLogin,
        isSocialLogin: state.login.isSocialLogin,
        sub: state.login.sub,
        email: state.login.email,
        password: state.login.password,
        showPwdFlag: state.login.showPwdFlag,
        loginValidationMessage: state.login.loginValidationMessage,

        firstLoginDateTime: state.login.firstLoginDateTime,
        experienceLevel: state.login.experienceLevel,
        experiencePoint: state.login.experiencePoint,
        addedExperiencePoint: state.login.addedExperiencePoint,
        lessonDoneSet: state.login.lessonDoneSet,

        latestCourseId: state.login.latestCourseId,
        latestLessonNo: state.login.latestLessonNo,

        preferredUsername: state.login.preferredUsername,
        gender: state.login.gender,
        genderOpen: state.login.genderOpen,
        birthDate: state.login.birthDate,
        prefecture: state.login.prefecture,
        profession: state.login.profession,
        selfIntroduction: state.login.selfIntroduction,
    } as LoginState;
};

export default loginSlice.reducer;
