import React from 'react';
import moment, { MomentInput } from 'moment';
import 'moment/locale/ja';
// CSSはインポートした順に生成され、後にインポートしたCSSの方が優先されるので、
// まず他のコンポーネントや共通CSSをインポートして、その後でコンポーネントごとのCSSをインストールすること
// つまり、styles.module.cssのインポートはインポート文の複数行のうちの最後に置いておくのが安全
import styles from './styles.module.css';

export type TimeProps = {
    className?: string,
    timeValue?: MomentInput, // 時刻文字列
    format?: string         // 表示フォーマット
} & JSX.IntrinsicElements['time'];

const Time : React.FC<TimeProps> = ({
    className='',
    timeValue,
    format='YYYY年MM月DD日 HH:mm',
    ...props
}) => {
    // class属性を設定
    let classString = styles.time;
    if (className) {
        classString = [classString, className].join(' ');
    }

    // 時刻表示を成型
    let timeString, datetimeString;
    try {
        timeString = formatDatetime(timeValue, format);
        datetimeString = formatDatetime(timeValue);
    } catch (error) {
        timeString = '不正な時刻です';
        datetimeString = '0000-00-00T00:00';
    }

    return (
        <time
            className={classString}
            dateTime={datetimeString}
            { ...props }
        >
        {timeString}
        </time>
    );
};

const timeFactory = (timeValue: MomentInput, format: string) => {
    return (tProps: Omit<TimeProps, 'timeValue' | 'format'>) => {
        return (
            <Time timeValue={timeValue} format={format} {...tProps} />
        );
    };
}

function formatDatetime(datetime:MomentInput, format = 'YYYY-MM-DDTHH:mm') {
    const m = moment(datetime);
    if (m.isValid()) {
        return m.format(format);
    } else {
        throw new Error('dateformat error');
    }
}

export default Time;
export const NowYear = timeFactory(moment(), 'YYYY');
