import React from 'react';
import InnerLink from '../../atoms/InnerLink';
import { LogoColor } from '../../atoms/Img';
import FontEn from '../../atoms/FontEn';
// CSSはインポートした順に生成され、後にインポートしたCSSの方が優先されるので、
// まず他のコンポーネントや共通CSSをインポートして、その後でコンポーネントごとのCSSをインストールすること
// つまり、styles.module.cssのインポートはインポート文の複数行のうちの最後に置いておくのが安全
import styles from './styles.module.css';

export type HeaderPresenterProps = {
    isLogin?: boolean;
    isShowMenu?: boolean;
    isPCWindow?: boolean;
    isPaid?: boolean;         // 有料会員かどうか
    isSocialLogin?: boolean;  // SNSアカウントによるログインかどうか
    profileImg?: string;
    preferredUsername? : string;

    changeShowMenuState: (argument:string) => () => void;
};

const HeaderPresenter : React.FC<HeaderPresenterProps> = ({
    isLogin = false,
    isShowMenu = false,
    isPCWindow = true,

    isPaid = false,
    isSocialLogin = false,
    profileImg = "profile_dummy.jpg",
    preferredUsername = "guest",

    changeShowMenuState,
}) => {

    // ロゴクリック時のリンク先
    const logoTo = isLogin? '/mypage':'/';
    return (
        <header className={styles['header-presenter']}>
        <div className={styles['header-logo']}><InnerLink to={logoTo} id='no-login'><LogoColor /></InnerLink></div>

        <nav className={isShowMenu ? styles['gnav']+' '+styles['active'] : styles['gnav']}>
          <div className={styles['sp-nav-logo']}><InnerLink to={logoTo} className={styles['header-logo']} onClick={changeShowMenuState('toggle')}><LogoColor /></InnerLink></div>

          { isLogin &&
          <ul className={styles['gnav-list']}>
            <li className={styles['gnav-item'] + ' ' + styles['course']}><InnerLink to="/course-list" onClick={changeShowMenuState('close')}>コース一覧</InnerLink></li>
            <li className={styles['gnav-item'] + ' ' + styles['training']}><InnerLink to="/mypage#training-list" onClick={changeShowMenuState('close')}>トレーニング一覧</InnerLink></li>

            {isPCWindow &&
            <li className={styles['gnav-item'] + ' ' + styles['profile']}>
              <InnerLink to="#" onClick={changeShowMenuState('toggle')} className={styles['mb-just-click-button']}>
                {/* <Img fileName={profileImg} alt={preferredUsername} className={styles['profile-photo']} /> */}
                <FontEn domType="span">{preferredUsername}</FontEn>
              </InnerLink>
              { isShowMenu &&
              <ul className={styles['gnav-sub-list']}>
                <li className={styles['gnav-sub-item']}><InnerLink to="/mypage" onClick={changeShowMenuState('close')}>マイページ</InnerLink></li>
                <li className={styles['gnav-sub-item']}><InnerLink to="/profile" onClick={changeShowMenuState('close')}>プロフィール設定</InnerLink></li>
                {!isSocialLogin && <li className={styles['gnav-sub-item']} data-testid="pc-pasword-change"><InnerLink to="/change-password" onClick={changeShowMenuState('close')}>パスワード変更</InnerLink></li>}
                <li className={styles['gnav-sub-item']} data-testid="pc-payment"><InnerLink to="/payment" onClick={changeShowMenuState('close')}>お支払い情報</InnerLink></li>
                {isPaid && <li className={styles['gnav-sub-item']}><InnerLink to="/appendix" onClick={changeShowMenuState('close')}>ダウンロード</InnerLink></li>}
                <li className={styles['gnav-sub-item']}><InnerLink to="/logout" onClick={changeShowMenuState('close')}>ログアウト</InnerLink></li>
              </ul>
              }
            </li>
            }

            {!isPCWindow &&
            <li className={styles['gnav-item'] + ' ' + styles['profile']}>
              <ul className={styles['gnav-sub-list']}>
                <li className={styles['gnav-sub-item']}><InnerLink to="/mypage" onClick={changeShowMenuState('close')}>マイページ</InnerLink></li>
                <li className={styles['gnav-sub-item']}><InnerLink to="/profile" onClick={changeShowMenuState('close')}>プロフィール設定</InnerLink></li>
                {!isSocialLogin && <li className={styles['gnav-sub-item']}><InnerLink to="/change-password" onClick={changeShowMenuState('close')}>パスワード変更</InnerLink></li>}
                <li className={styles['gnav-sub-item']} data-testid="mobile-payment"><InnerLink to="/payment" onClick={changeShowMenuState('close')}>お支払い情報</InnerLink></li>
                {isPaid && <li className={styles['gnav-sub-item']}><InnerLink to="/appendix" onClick={changeShowMenuState('close')}>ダウンロード</InnerLink></li>}
                <li className={styles['gnav-sub-item']}><InnerLink to="/logout" onClick={changeShowMenuState('close')}>ログアウト</InnerLink></li>
              </ul>
            </li>
            }
          </ul>
          }

          {!isLogin &&
            <ul className={styles['gnav-list-no-login']}>
               <li className={styles['gnav-item']+' '+styles['login']}><InnerLink to="/login" >ログイン</InnerLink></li>
               <li className={styles['gnav-item']+' '+styles['register']}><InnerLink to="/signup" >無料登録はこちら</InnerLink></li>
            </ul>
          }
        </nav>

        {!isPCWindow &&
          <InnerLink to="#" onClick={changeShowMenuState('toggle')} className={styles['mb-just-click-button']}>
          <div className={isShowMenu ? styles['nav-btn']+' '+styles['active'] : styles['nav-btn']} data-testid="mobile-button">
              <span></span>
              <span></span>
              <span></span>
          </div>
        </InnerLink>
        }
      </header>
    );
};

export default HeaderPresenter;
