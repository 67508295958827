import React, { ReactNode } from 'react';
// CSSはインポートした順に生成され、後にインポートしたCSSの方が優先されるので、
// まず他のコンポーネントや共通CSSをインポートして、その後でコンポーネントごとのCSSをインストールすること
// つまり、styles.module.cssのインポートはインポート文の複数行のうちの最後に置いておくのが安全
import styles from './styles.module.css';

export type ContractPreambleProps = {
    children?: ReactNode;
};


const ContractPreamble : React.FC<ContractPreambleProps> = ({
    children,
}) => {
    return (
        <div className={ styles['contract-preamble'] }>
            {children}
        </div>
    );
};

export default ContractPreamble;
