import React from 'react';
import templateProperties from '../../common/template-properties.module.css';
import LoadingMessage from '../../organisms/LoadingMessage';
// CSSはインポートした順に生成され、後にインポートしたCSSの方が優先されるので、
// まず他のコンポーネントや共通CSSをインポートして、その後でコンポーネントごとのCSSをインストールすること
// つまり、styles.module.cssのインポートはインポート文の複数行のうちの最後に置いておくのが安全
import styles from './styles.module.css';

Object.assign(styles, templateProperties);

export type WaitPaymentActivationTemplateProps = {
};

const WaitPaymentActivationTemplate : React.FC<WaitPaymentActivationTemplateProps> = (
) => {
    return (
        <div className={ styles['wait-payment-activation-template'] + ' ' + styles['template-properties-local'] }>
            <LoadingMessage>
                お手続きありがとうございました！<br/>
                元のページに戻るまでもうしばらくお待ちください
            </LoadingMessage>
        </div>
    );
};

export default WaitPaymentActivationTemplate;
