import React from 'react';
import MyPageTemplate from '../../templates/MyPageTemplate';
import {
    CourseId,
    courseConfig,
} from '../../../config/course';
import {CourseLesson} from '../../../lib/props';
import { useAppSelector } from '../../../redux/app/hooks';
import {
    selectLoginState,
} from '../../../redux/slices/loginSlice';
import {
    selectPaymentState,
} from '../../../redux/slices/paymentSlice';
import moment from 'moment';
import sumBy from 'lodash/sumBy';
import {
    makeCourseIdLessonNoTag,
} from '../../../lib/lessonProgress';
// CSSはインポートした順に生成され、後にインポートしたCSSの方が優先されるので、
// まず他のコンポーネントや共通CSSをインポートして、その後でコンポーネントごとのCSSをインストールすること
// つまり、styles.module.cssのインポートはインポート文の複数行のうちの最後に置いておくのが安全
import styles from './styles.module.css';


type MyPagePageProps = {
    children?: never,
};


const MyPagePage : React.FC<MyPagePageProps> = (
) => {
    const {
        isLogin,
        isSocialLogin,

        firstLoginDateTime,
        experienceLevel,
        experiencePoint,
        lessonDoneSet,

        latestCourseId,
        latestLessonNo,

        preferredUsername,
    } = useAppSelector(selectLoginState);

    const {
        isPaid,
    } = useAppSelector(selectPaymentState);

    // TODO
    // to_implement
    // 本来はここでログイン状況や会員画像・会員名を取得する処理が入る
    const fetchProfileImg = () => undefined;
    const profileImg = fetchProfileImg();

    const lessonTotal = sumBy(courseConfig, (course) => course.lessons.length);
    const registeredDate = firstLoginDateTime ? moment(firstLoginDateTime).format('YYYY.MM.DD') : '';


    // 最後に学習したコースを表示
    // CoursePageのコードと似ている部分があるが、マイページとCoursePageで出したい情報が異なるので
    // いったん別々のコードにしておく
    const courseLessonList : Array<CourseLesson> = [];
    const courseId = latestCourseId || CourseId.introduction;
    const lessonNo = latestLessonNo || 1;
    if (courseId) {
        for (let courseInd = 0; courseInd < courseConfig.length; courseInd++) {
            const course = courseConfig[courseInd];

            if (course.courseId !== courseId) {
                continue;
            }

            for (let lesson of course.lessons) {
                if (lesson.lessonNo !== lessonNo) {
                    continue;
                }

                const p = makeCourseIdLessonNoTag(course.courseId, lesson.lessonNo);

                const isReadable = isPaid || lesson.isFree;
                const lessonBoxList = [
                        {
                            isDone: lessonDoneSet.has(p),
                            isReadable,
                            lessonUrl: `/course-list/${courseId}/${lesson.lessonNo}`,
                            lessonColor: course.courseColor,
                            lessonNo: lesson.lessonNo,
                            lessonTitle: lesson.lessonTitle,
                            lessonText: isReadable ? '前回学習したレッスンです' : '有料会員のみ閲覧可能です',
                        },
                ];

                // もしコース内にlessonNoが1個増えたレッスンが存在したら
                // それも追加する
                const nextLessonNo = lessonNo + 1;
                const nextLesson = course.lessons.find(lesson => lesson.lessonNo === nextLessonNo);

                if (nextLesson) {
                    const nextLessonTag = makeCourseIdLessonNoTag(course.courseId, nextLesson.lessonNo);
                    const isReadable = isPaid || nextLesson.isFree;
                    const nextLessonBox = {
                        isDone: lessonDoneSet.has(nextLessonTag),
                        isReadable,
                        lessonUrl: `/course-list/${courseId}/${nextLesson.lessonNo}`,
                        lessonColor: course.courseColor,
                        lessonNo: nextLesson.lessonNo,
                        lessonTitle: nextLesson.lessonTitle,
                        lessonText: isReadable ? '次のレッスンです' : '有料会員のみ閲覧可能です',
                    }

                    lessonBoxList.push(nextLessonBox);

                    const courseLesson = {
                        courseTitle:course.courseTitle,
                        courseColor: course.courseColor,
                        isAdvanced: course.isAdvanced,
                        lessonBoxList,
                    }
                    courseLessonList.push(courseLesson);
                } else {
                    // まず、今のcourseのlessonBoxはこれ以上は更新しないので、courseLessonListに登録してしまう
                    const courseLesson = {
                        courseTitle:course.courseTitle,
                        courseColor: course.courseColor,
                        isAdvanced: course.isAdvanced,
                        lessonBoxList,
                    }
                    courseLessonList.push(courseLesson);

                    // 次のコースが存在したらそれをcourseLessonに追加
                    // to_implement
                    // 次のコースが利用可能な場合(課金中)
                    const youCanTakeIt = true;
                    if (courseInd + 1 <= courseConfig.length - 1 && youCanTakeIt) {
                        const nextCourse = courseConfig[courseInd + 1];
                        const nextCourseLesson = nextCourse.lessons.find(lesson => lesson.lessonNo === 1);

                        // 必ずあるはず
                        if (nextCourseLesson) {
                            const nextCourseLessonTag = makeCourseIdLessonNoTag(nextCourse.courseId, nextCourseLesson.lessonNo);

                            const isReadable = isPaid || nextCourseLesson.isFree;
                            const courseLessonNext = {
                                courseTitle: nextCourse.courseTitle,
                                courseColor: nextCourse.courseColor,
                                isAdvanced: nextCourse.isAdvanced,
                                lessonBoxList: [
                                    {
                                        isDone: lessonDoneSet.has(nextCourseLessonTag),
                                        isReadable,
                                        lessonUrl: `/course-list/${nextCourse.courseId}/${nextCourseLesson.lessonNo}`,
                                        lessonColor: nextCourse.courseColor,
                                        lessonNo: nextCourseLesson.lessonNo,
                                        lessonTitle: nextCourseLesson.lessonTitle,
                                        lessonText: isReadable ? '次のレッスンです' : '有料会員のみ閲覧可能です',
                                    }
                                ],
                            };
                            courseLessonList.push(courseLessonNext);
                        }
                    }
                }
            }
        }
    }


    return (
        <div className={ styles['my-page-page'] }>
            <MyPageTemplate
                isLogin={isLogin}
                isPaid={isPaid}
                isSocialLogin={isSocialLogin}
                profileImg={profileImg}
                preferredUsername={preferredUsername}
                userLevel={experienceLevel}
                userPoint={experiencePoint}
                lessonTotal={lessonTotal}
                lessonDone={lessonDoneSet.size}
                registeredDate={registeredDate}
                courseLessonList={courseLessonList}
            />
        </div>
    );
};

export default MyPagePage;
