import React from 'react';
import templateProperties from '../../common/template-properties.module.css';
import { UrlLabel } from '../../../redux/constant';
import HeaderContainer from '../../organisms/HeaderContainer';
import PageTitleArea from '../../layouts/PageTitleArea';
import BreadcrumbBar from '../../organisms/BreadcrumbBar';
import { PageContainer } from '../../layouts/Container';
import Button from '../../atoms/Button';
import { DownloadArrow } from '../../atoms/Img';
import InnerLink from '../../atoms/InnerLink';
import Footer from '../../organisms/Footer';
import { pdfResources } from '../../../lib/pdfResources';
// CSSはインポートした順に生成され、後にインポートしたCSSの方が優先されるので、
// まず他のコンポーネントや共通CSSをインポートして、その後でコンポーネントごとのCSSをインストールすること
// つまり、styles.module.cssのインポートはインポート文の複数行のうちの最後に置いておくのが安全
import styles from './styles.module.css';

Object.assign(styles, templateProperties);

const AppendixBL: Array<UrlLabel> = [
    { url: '/mypage', label: 'マイページ' },
    { url: '', label: 'ダウンロード' },
];

export type AppendixTemplateProps = {
    isLogin: boolean;             // ログイン状態
    isPaid: boolean;              // 有料会員かどうか
    isSocialLogin: boolean;       // SNSログイン会員かどうか
    profileImg?: string;          // 会員画像
    preferredUsername? : string;  // 会員名
};

const AppendixTemplate : React.FC<AppendixTemplateProps> = ({
    isLogin,
    isPaid,
    isSocialLogin,
    profileImg,
    preferredUsername,
}) => {
    // Pdf情報取得
    const convNumberInfo = pdfResources.get('conv_number.pdf');
    const convAlphabetInfo = pdfResources.get('conv_alphabet.pdf');
    const visualEngishWordInfo = pdfResources.get('visual_english_words.pdf');
    const convCardInfo = pdfResources.get('conv_card.pdf');
    const advancedPegMethodMapOfJapanInfo = pdfResources.get('advanced_peg_method_map_of_japan.pdf');
    const nationalCapitalInfo = pdfResources.get('national_capitals.pdf');

    return (
        <div className={ styles['appendix-template'] + ' ' + styles['template-properties-local'] }>
          <HeaderContainer isLogin={isLogin} isPaid={isPaid} isSocialLogin={isSocialLogin} profileImg={profileImg} preferredUsername={preferredUsername} />
          <PageTitleArea>ダウンロード</PageTitleArea>
          {isLogin && <BreadcrumbBar UrlLabels={AppendixBL} />}
          <PageContainer className={styles['appendix-area']}>
            <div className={styles['explanation-area']}>
                各ファイルをダウンロードする時はボタン上で右クリックから「リンク先を保存」を選択してください。
            </div>

            {convNumberInfo &&
            <div className={styles['download-area']}>
                <InnerLink to={convNumberInfo.path} target='_blank'><Button className={styles['button']}>2桁数字変換表&nbsp;&nbsp;<DownloadArrow /></Button></InnerLink>
            </div>
            }

            {convAlphabetInfo &&
            <div className={styles['download-area']}>
                <InnerLink to={convAlphabetInfo.path} target='_blank'><Button className={styles['button']}>アルファベット変換表&nbsp;&nbsp;<DownloadArrow /></Button></InnerLink>
            </div>
            }

            {visualEngishWordInfo &&
            <div className={styles['download-area']}>
                <InnerLink to={visualEngishWordInfo.path} target='_blank'><Button className={styles['button']}>英単語記憶表&nbsp;&nbsp;<DownloadArrow /></Button></InnerLink>
            </div>
            }

            {convCardInfo &&
            <div className={styles['download-area']}>
                <InnerLink to={convCardInfo.path} target='_blank'><Button className={styles['button']}>トランプ変換表&nbsp;&nbsp;<DownloadArrow /></Button></InnerLink>
            </div>
            }

            {advancedPegMethodMapOfJapanInfo &&
            <div className={styles['download-area']}>
                <InnerLink to={advancedPegMethodMapOfJapanInfo.path} target='_blank'><Button className={styles['button']}>ペグ法応用編コース用日本地図&nbsp;&nbsp;<DownloadArrow /></Button></InnerLink>
            </div>
            }

            {nationalCapitalInfo &&
            <div className={styles['download-area']}>
                <InnerLink to={nationalCapitalInfo.path} target='_blank'><Button className={styles['button']}>国の名前と首都の覚え方の例&nbsp;&nbsp;<DownloadArrow /></Button></InnerLink>
            </div>
            }

          </PageContainer>
          <Footer isLogin={isLogin} />
        </div>
    );
};

export default AppendixTemplate;
