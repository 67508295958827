export function isHankakuAscii(s: string) : boolean {
    const containsNotAsciiChar = s.match(/[^\x20-\x7E]/);
    return !containsNotAsciiChar;
}

// 半角かつ空白を含まない8文字以上の文字列をパスワードとして使うことができる
export function isValidPasswordCharset(s: string) : boolean {
    const containsSpace = s.match(/\s/);
    return s.length >= 8 && isHankakuAscii(s) && !containsSpace;
}
