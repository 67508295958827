import React from 'react';
import { CourseColor } from '../../../config/course';
import { CorrectAnswer } from '../../../lib/domSlide';
import { RankTableLessonSlide } from '../../../config/course';
import SlidePracticeBackground from '../../atoms/SlidePracticeBackground';
import FontTitle from '../../atoms/FontTitle';
import Textbox from '../../atoms/Textbox';
import { PrimaryButton } from '../../atoms/Button';
// CSSはインポートした順に生成され、後にインポートしたCSSの方が優先されるので、
// まず他のコンポーネントや共通CSSをインポートして、その後でコンポーネントごとのCSSをインストールすること
// つまり、styles.module.cssのインポートはインポート文の複数行のうちの最後に置いておくのが安全
import styles from './styles.module.css';

export type SlidePracticeRankTableProps = {
    pageIndex: number;
    slideColor: CourseColor;
    isCheckingAnswers: boolean;
    myAnswers: Array<CorrectAnswer>;
    updateMyAnswersWithIndex: (index:number) => (e: React.ChangeEvent<HTMLInputElement>) => void;
    updateIsCheckingAnswersTrue: (e: React.MouseEvent<HTMLButtonElement>) => void;
} & Omit<RankTableLessonSlide, 'type'>;


const SlidePracticeRankTable : React.FC<SlidePracticeRankTableProps> = ({
    pageIndex,
    slideColor,
    memorizationTitle,
    recallTitle,
    resultTitle = '結果を判定します',
    correctAnswers,
    myAnswers,
    isCheckingAnswers,

    updateMyAnswersWithIndex,
    updateIsCheckingAnswersTrue,
}) => {
    let answerAreaClass = styles['dom-slide-answerarea'];    // 問題・回答・結果エリア
    let itemRankClass = styles['item-rank'];                 // 各問題・回答・結果エリア

    // 記憶画面の表示内容作成
    const memorizationArr = [];   // 問題欄
    if (pageIndex === 0) {
        // 問題欄生成
        for (let idx = 0; idx < correctAnswers.length; idx += 2) {
            memorizationArr.push(
                <div className={itemRankClass} key={idx}>
                    <label className={styles['label-ordinal']}>{correctAnswers[idx].label}</label>
                    <span className={styles['item-country']}>{correctAnswers[idx].words[0]}</span>
                    <span className={styles['item-number']}>{correctAnswers[idx+1].words[0]}</span>
                    <label className={styles['label-measure-word']}>{correctAnswers[idx+1].label}</label>
                </div>
            );
        }
    }

    // 回答画面の表示内容作成
    const recallArr = [];     // 回答欄
    if (pageIndex === 1 && !isCheckingAnswers) {
        // 回答欄生成
        for (let idx = 0; idx < correctAnswers.length; idx += 2) {
            recallArr.push(
                <div className={itemRankClass} key={idx}>
                    <label className={styles['label-ordinal']}>{correctAnswers[idx].label}</label>
                    <Textbox
                        className={styles['item-country']}
                        value={myAnswers[idx]?.words[0] || []}
                        onChange={updateMyAnswersWithIndex(idx)}
                        maxLength={9}
                    />
                    <Textbox
                        className={styles['item-number']}
                        value={myAnswers[idx+1]?.words[0] || []}
                        onChange={updateMyAnswersWithIndex(idx+1)}
                        maxLength={3}
                    />
                    <label className={styles['label-measure-word']}>{correctAnswers[idx+1].label}</label>
                </div>
            );
        }
    }

    // 結果画面の表示内容作成
    const resultArr = [];     // 結果欄
    if (pageIndex === 1 && isCheckingAnswers) {
        answerAreaClass += ' '+ styles['result'];   // 結果エリア設定
        itemRankClass += ' '+ styles['result'];     // 各結果エリア設定

        // 結果欄生成
        for (let idx = 0; idx < correctAnswers.length; idx += 2) {
            // 結果判定
            const valueCountry = myAnswers[idx]?.words[0] || '';
            const valueNumber = myAnswers[idx+1]?.words[0] || '';
            const resultCountryClass = (correctAnswers[idx].words.includes(valueCountry))? styles['correct'] : styles['incorrect'];
            const resultNumberClass = (correctAnswers[idx+1].words.includes(valueNumber))? styles['correct'] : styles['incorrect'];
            resultArr.push(
                <div className={itemRankClass} key={idx}>
                    <label className={styles['label-ordinal']}>{correctAnswers[idx].label}</label>
                    <div className={styles['result-country']}>
                        <span className={styles['item-country']+' '+resultCountryClass}>{myAnswers[idx]?.words[0] || ''}</span>
                        <span className={styles['correct-country']}>{correctAnswers[idx].words[0]}</span>
                    </div>
                    <div className={styles['result-number']}>
                        <span className={styles['item-number']+' '+resultNumberClass}>{myAnswers[idx+1]?.words[0] || ''}</span>
                        <span className={styles['correct-number']}>{correctAnswers[idx+1].words[0]}</span>
                    </div>
                    <label className={styles['label-measure-word']}>{correctAnswers[idx+1].label}</label>
                </div>
            );
        }
    }

    return (
        <div className={ styles['slide-practice-number-rank-table'] }>
            <SlidePracticeBackground bgColor={slideColor} className={styles['dom-slide']}>
                {/* 記憶画面 */}
                { pageIndex === 0 &&
                <div className={styles['dom-slide-area']}>
                    <FontTitle className={styles['dom-slide-title']}>{memorizationTitle}</FontTitle>
                    <div className={styles['dom-slide-content']}>
                        <div className={answerAreaClass}>{memorizationArr}</div>
                    </div>
                </div>
                }

                {/* 回答画面 */}
                { pageIndex === 1 && !isCheckingAnswers &&
                <div className={styles['dom-slide-area']}>
                    <FontTitle className={styles['dom-slide-title']}>{recallTitle}</FontTitle>
                    <div className={styles['dom-slide-content']}>
                        <div className={answerAreaClass}>{recallArr}</div>
                        <div className={styles['button-area']}>
                            <PrimaryButton className={styles['button']} onClick={updateIsCheckingAnswersTrue}>答え合わせする</PrimaryButton>
                        </div>
                    </div>
                </div>
                }

                {/* 結果画面 */}
                { pageIndex === 1 && isCheckingAnswers &&
                <div className={styles['dom-slide-area']}>
                    <FontTitle className={styles['dom-slide-title']}>{resultTitle}</FontTitle>
                    <div className={styles['dom-slide-content']}>
                        <div className={answerAreaClass}>{resultArr}</div>
                    </div>
                </div>
                }
            </SlidePracticeBackground>
        </div>
    );
};

export default SlidePracticeRankTable;
