import React, { ReactNode } from 'react';
import Img from '../../atoms/Img';
import FontTitle from '../../atoms/FontTitle';
// CSSはインポートした順に生成され、後にインポートしたCSSの方が優先されるので、
// まず他のコンポーネントや共通CSSをインポートして、その後でコンポーネントごとのCSSをインストールすること
// つまり、styles.module.cssのインポートはインポート文の複数行のうちの最後に置いておくのが安全
import styles from './styles.module.css';

export type LoadingMessageProps = {
    children?: ReactNode;
};


const LoadingMessage : React.FC<LoadingMessageProps> = ({
    children,
}) => {
    return (
        <div className={ styles['loading-message'] }>
            <div className={styles['message-area']}>
                <FontTitle domType='div' className={styles['message']}>{children}</FontTitle>
                <div className={styles['spinner']}>
                    <div className={styles['circle']}></div>
                    <div className={styles['circle2']+' '+styles['circle']}></div>
                    <div className={styles['circle3']+' '+styles['circle']}></div>
                    <div className={styles['circle4']+' '+styles['circle']}></div>
                    <div className={styles['circle5']+' '+styles['circle']}></div>
                    <div className={styles['circle6']+' '+styles['circle']}></div>
                    <div className={styles['circle7']+' '+styles['circle']}></div>
                    <div className={styles['circle8']+' '+styles['circle']}></div>
                    <div className={styles['circle9']+' '+styles['circle']}></div>
                    <div className={styles['circle10']+' '+styles['circle']}></div>
                    <div className={styles['circle11']+' '+styles['circle']}></div>
                    <div className={styles['circle12']+' '+styles['circle']}></div>
                    <Img fileName="illust-pao-smile.png" alt="パオゾー"/>
                </div>
            </div>
        </div>
    );
};

export default LoadingMessage;
