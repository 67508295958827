import React from 'react';
import InnerLink from '../../atoms/InnerLink';
import { UrlLabel } from '../../../redux/constant';
import Container from '../../layouts/Container';
// CSSはインポートした順に生成され、後にインポートしたCSSの方が優先されるので、
// まず他のコンポーネントや共通CSSをインポートして、その後でコンポーネントごとのCSSをインストールすること
// つまり、styles.module.cssのインポートはインポート文の複数行のうちの最後に置いておくのが安全
import styles from './styles.module.css';

export type BreadcrumbBarProps = {
    className?: string;
    UrlLabels: Array<UrlLabel>;
    children?: never;
};


const BreadcrumbBar : React.FC<BreadcrumbBarProps> = ({
    className = '',
    UrlLabels,
}) => {
    // class属性を設定
    let classString = styles['breadcrumb-bar'];
    if (className) {
        classString = [classString, className].join(' ');
    }

    return (
        <div className={classString}>
            <Container>
                <ul className={styles['breadcrumb-list']}>
                    { UrlLabels &&
                        UrlLabels.map((UrlLabel, idx) => (
                            <li key={ idx }  className={styles['breadcrumb-item']}>
                                { UrlLabel.url && <InnerLink to={UrlLabel.url }>{UrlLabel.label}</InnerLink> }
                                { !UrlLabel.url && UrlLabel.label }
                            </li>
                        ))
                    }
                </ul>
            </Container>
        </div>
    );
};

export default BreadcrumbBar;
