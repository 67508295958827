import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import store from './redux/app/store'
import { Provider } from 'react-redux'
import Amplify from 'aws-amplify';
import awsConfig from './aws-exports';

// redirectURLが複数存在する場合、エラーになるため対象ドメインに絞り込む
if (awsConfig.oauth.redirectSignIn.includes(',')) {
    const isLocationHost = (url: string) => (new URL(url)).host === window.location.host;

    if (awsConfig?.oauth?.redirectSignIn) {
        awsConfig.oauth.redirectSignIn = awsConfig.oauth.redirectSignIn
            .split(',')
            .filter(isLocationHost)
            .shift()
            || awsConfig.oauth.redirectSignIn;
    }

    if (awsConfig?.oauth?.redirectSignOut) {
        awsConfig.oauth.redirectSignOut = awsConfig.oauth.redirectSignOut
            .split(',')
            .filter(isLocationHost)
            .shift()
            || awsConfig.oauth.redirectSignOut;
        }
}

Amplify.configure(awsConfig);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
