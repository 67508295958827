import React, { ReactNode } from 'react';
// CSSはインポートした順に生成され、後にインポートしたCSSの方が優先されるので、
// まず他のコンポーネントや共通CSSをインポートして、その後でコンポーネントごとのCSSをインストールすること
// つまり、styles.module.cssのインポートはインポート文の複数行のうちの最後に置いておくのが安全
import styles from './styles.module.css';

export type ColumnLayoutProps = {
    className?: string;
    isReverse?: boolean;
    children?: ReactNode;
} & JSX.IntrinsicElements['div'];


const ColumnLayout : React.FC<ColumnLayoutProps> = ({
    className = '',
    isReverse = false,
    children,
}) => {
    // class属性を設定
    let classString = styles['column-layout'];
    if (isReverse) {
        classString = [classString, styles['reverse']].join(' ');
    }
    if (className) {
        classString = [classString, className].join(' ');
    }

    return (
        <div className={classString}>{children}</div>
    );
};

export default ColumnLayout;
