import React from 'react';
import templateProperties from '../../common/template-properties.module.css';
import LoadingMessage from '../../organisms/LoadingMessage';
// CSSはインポートした順に生成され、後にインポートしたCSSの方が優先されるので、
// まず他のコンポーネントや共通CSSをインポートして、その後でコンポーネントごとのCSSをインストールすること
// つまり、styles.module.cssのインポートはインポート文の複数行のうちの最後に置いておくのが安全
import styles from './styles.module.css';

Object.assign(styles, templateProperties);

export type LogoutTemplateProps = {
    children?: never
};

const LogoutTemplate : React.FC<LogoutTemplateProps> = (
) => {
    return (
        <div className={ styles['logout-template'] + ' ' + styles['template-properties-local'] }>
            <LoadingMessage>ただいまログアウト中です。<br/>しばらくお待ち下さい。</LoadingMessage>
        </div>
    );
};

export default LogoutTemplate;
