import React, {
    MutableRefObject,
} from 'react';
import templateProperties from '../../common/template-properties.module.css';
import HeaderContainer from '../../organisms/HeaderContainer';
import { UrlLabel } from '../../../redux/constant';
import ChainedSlides from '../../organisms/ChainedSlides';
import { CourseColor, Lesson } from '../../../config/course';
import { CorrectAnswer } from '../../../lib/domSlide';
import Footer from '../../organisms/Footer';
import BreadcrumbBar from '../../organisms/BreadcrumbBar';
import Container from '../../layouts/Container';
import InnerLink from '../../atoms/InnerLink';
import PointModal from '../../organisms/PointModal';
// CSSはインポートした順に生成され、後にインポートしたCSSの方が優先されるので、
// まず他のコンポーネントや共通CSSをインポートして、その後でコンポーネントごとのCSSをインストールすること
// つまり、styles.module.cssのインポートはインポート文の複数行のうちの最後に置いておくのが安全
import styles from './styles.module.css';

Object.assign(styles, templateProperties);

export type LessonTemplateProps = {
    isLogin: boolean;             // ログイン状態
    isPaid: boolean;              // 有料会員かどうか
    isSocialLogin: boolean;       // SNSログイン会員かどうか
    profileImg?: string;          // 会員画像
    preferredUsername? : string;  // 会員名

    courseId: string;
    courseTitle: string;
    courseColor: CourseColor;
    lessonNo: number;
    lesson?: Lesson;
    defaultPageInd?: number;

    previousLessonUrl?: string;
    nextLessonUrl?: string;

    myAnswers: Array<CorrectAnswer>;
    isCheckingAnswers: boolean;

    pdfPassword?: string;

    modalIsOpen?: boolean;
    modalButtonText?: string;
    experiencePoint: number;
    addedExperiencePoint: number;

    isScaling?: boolean,
    scaleRate?: number,
    chainedSlideRef?: MutableRefObject<HTMLDivElement | null>,

    updateMyAnswersWithIndex: (index:number) => (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void;
    updateIsCheckingAnswersTrue: (e: React.MouseEvent<HTMLButtonElement>) => void;
    lastPageHook: () => void;
    closeModal: () => void;
    scaleAndScroll: () => void;

    children?: never;
};

const LessonTemplate : React.FC<LessonTemplateProps> = ({
    isLogin,
    isPaid,
    isSocialLogin,
    profileImg,
    preferredUsername,

    courseId,
    courseTitle,
    courseColor,
    lessonNo,
    lesson,
    defaultPageInd = 0,

    previousLessonUrl = '',
    nextLessonUrl = '',

    myAnswers,
    isCheckingAnswers,

    pdfPassword,

    modalIsOpen = false,
    modalButtonText = '閉じる',
    experiencePoint,
    addedExperiencePoint,

    isScaling = false,
    scaleRate = 1.0,
    chainedSlideRef,

    updateMyAnswersWithIndex,
    updateIsCheckingAnswersTrue,
    lastPageHook,
    closeModal,
    scaleAndScroll,
}) => {
    // パンくずリスト作成
    let LessonBL: Array<UrlLabel> = [];
    if (lesson) {
        LessonBL = [
          { url: '/mypage', label: 'マイページ' },
          { url: '/course-list/'+courseId.toString(), label: courseTitle },
          { url: '', label: lesson.lessonTitle },
        ];
    }

    const scaleStyle = ((isScaling: boolean, scaleRate: number) => {
        if (isScaling) {
            const ans = {
                transformOrigin: '0 0',
                transform: `scale(${scaleRate})`,
            };

            return ans;
        } else {
            return undefined;
        }
    })(isScaling, scaleRate);

    return (
      <React.Fragment>
        <div className={ styles['lesson-template'] + ' ' + styles['template-properties-local'] } id='modal-target' style={scaleStyle} data-testid="lesson-template" >
          <HeaderContainer isLogin={isLogin} isPaid={isPaid} isSocialLogin={isSocialLogin} profileImg={profileImg} preferredUsername={preferredUsername} />
            { lesson &&
           <>
           <BreadcrumbBar UrlLabels={LessonBL} />
           <div className={styles['slider-area']}>
             <Container>
                <div className={styles['slide-all-wrap']}>
                  <div className={styles['slide-title-wrap']}>
                    <h2 className={styles['slide-title']}>#{lessonNo} {lesson.lessonTitle}</h2>
                    <p className={styles['course-title']+' '+styles['color-'+courseColor]}><span className={styles['bg-color-'+courseColor]}>コース</span>{courseTitle}</p>
                  </div>

                  <ChainedSlides
                    slides={lesson.slides}
                    defaultThroughoutPageIndex={defaultPageInd}
                    myAnswers={myAnswers}
                    isCheckingAnswers={isCheckingAnswers}
                    pdfPassword={pdfPassword}
                    slideColor={courseColor}
                    updateMyAnswersWithIndex={updateMyAnswersWithIndex}
                    updateIsCheckingAnswersTrue={updateIsCheckingAnswersTrue}
                    lastPageHook={lastPageHook}
                    scaleAndScroll={scaleAndScroll}
                    chainedSlideRef={chainedSlideRef}
                  />
                </div>
              </Container>
           </div>
           <Container className={styles['slide-lesson-link']}>
              <div className={styles['btn-wrap']+' '+styles['btn-before-lesson']}>
                {previousLessonUrl ?
                  <InnerLink to={previousLessonUrl} className={styles['button']}>前のレッスン</InnerLink> :
                  <InnerLink to="" className={styles['button']+' '+styles['innerlink-disabled']}>前のレッスン</InnerLink>
                }
              </div>
              <div className={styles['btn-wrap']+' '+styles['btn-next-lesson']}>
                {nextLessonUrl ?
                  <InnerLink to={nextLessonUrl} className={styles['button']}>次のレッスン</InnerLink> :
                  <InnerLink to="" className={styles['button']+' '+styles['innerlink-disabled']}>次のレッスン</InnerLink>
                }
              </div>
              <div className={styles['btn-wrap']+' '+styles['btn-index']}><InnerLink to="/course-list" className={styles['button']}>コース一覧へ</InnerLink></div>
           </Container>
           </>
            }
          <Footer isLogin={isLogin} />
        </div>
        <PointModal
          modalIsOpen={modalIsOpen}
          experiencePoint={experiencePoint}
          addedExperiencePoint={addedExperiencePoint}
          buttonText={modalButtonText}
          clickButton={closeModal}
        />
      </React.Fragment>
   );
};

export default LessonTemplate;
