import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit'
import type { RootState } from '../app/store'
import moment from 'moment';
import { Lesson } from '../../config/course';
import { CorrectAnswer } from '../../lib/domSlide';
import { NavigateFunction } from "react-router";
import { API } from "aws-amplify";
import { graphqlOperation, GraphQLResult } from '@aws-amplify/api-graphql';
import {
    pointToLevel,
} from '../../lib/experience';
import {
    GetUserQuery,
    GetUserQueryVariables,
    UpdateUserMutation,
    UpdateUserMutationVariables,

    GetLessonProgressQuery,
    GetLessonProgressQueryVariables,
    CreateLessonProgressMutation,
    CreateLessonProgressMutationVariables,
    UpdateLessonProgressMutation,
    UpdateLessonProgressMutationVariables,
} from "../../API";
import {
    getUser,
    getLessonProgress,
} from '../../graphql/queries';
import {
    updateUser,

    createLessonProgress,
    updateLessonProgress,
} from '../../graphql/mutations';
import {
    changeExperienceLevel,
    changeExperiencePoint,
    changeAddedExperiencePoint,
    addLessonDoneSet,
    changeLatestCourseId,
    changeLatestLessonNo,
} from './loginSlice';


interface LessonState {
    [key: string]: any;

    // courseId: string;
    // courseTitle: string;
    // lessonNo: number;
    lessonConfig?: Lesson;

    // 1つのコース・レッスンの中の回答のみを保存する
    // 別のコース・レッスンに移る場合は上書きされうる
    myAnswers: Array<CorrectAnswer>;

    isCheckingAnswers: boolean;

    lessonModalIsOpen: boolean;

    isScaling: boolean;
    scaleRate: number;
}

export const initialState: LessonState = {
    lessonConfig: undefined,
    myAnswers: [],
    isCheckingAnswers: false,
    lessonModalIsOpen: false,
    isScaling: false,
    scaleRate: 1.0,
}

export interface CompleteLessonParams {
    sub: string;
    courseId: string;
    lessonNo: number;
    isAdvanced: boolean;
};

export const encodeLessonProgressId = (sub: string, courseId: string, lessonNo: number) => {
    return `${sub}|${courseId}|${String(lessonNo)}`;
}

// スライドの最終ページに辿り着いた時に、レッスンの履歴をポストし、経験値を増やす
export const completeLesson = createAsyncThunk(
  'lesson/completeLesson',
    async (submitParams: CompleteLessonParams, thunkAPI) => {
        const { sub, courseId, lessonNo } = submitParams;

        const getLessonProgressQueryVariables : GetLessonProgressQueryVariables = {
            id: encodeLessonProgressId(sub, courseId, lessonNo),
        };

        const getLessonProgressRes = await API.graphql(graphqlOperation(getLessonProgress, getLessonProgressQueryVariables)) as GraphQLResult<GetLessonProgressQuery>;

        let fetchedLessonProgress = getLessonProgressRes.data?.getLessonProgress;
        let isFirstCompletion = false;
        const now = moment();

        // DBにレッスン結果の情報が存在しない場合は初回登録を行う
        if (!fetchedLessonProgress) {
            // PrimaryKeyであるid (="sub|courseId|lessonNo")を指定しているので、
            // もし複数のデバイスで同時にアクセスがあった場合には片方だけが採用され、レコードが二重登録されることはない
            // また、同時に更新処理をしようとしても、_verionで楽観ロックされているので同時に更新されることはない
            const createLessonProgressMutationVariables : CreateLessonProgressMutationVariables = {
                input: {
                    id: encodeLessonProgressId(sub, courseId, lessonNo),
                    sub,
                    courseId,
                    lessonNo,
                    iteration: 1,
                    firstCompletedDateTime: now.toISOString(),
                    lastCompletedDateTime: now.toISOString(),
                }
            }

            const createLessonProgressRes = await API.graphql(graphqlOperation(createLessonProgress, createLessonProgressMutationVariables)) as GraphQLResult<CreateLessonProgressMutation>;

            fetchedLessonProgress = createLessonProgressRes.data?.createLessonProgress;

            // 少々トリッキーで、AuthorizationPageの会員登録とは違うロジックになってしまうが、
            // 新規にログを作成した時にも経験値が加算されるように、初めて登録したかどうかのフラグを持っておく
            isFirstCompletion = true;
        }

        // fetchedUserには、初回のfetchであればそれが、無ければcreateUserした結果が入っている
        // なので、nullはありえない
        if (!fetchedLessonProgress) {
            throw new Error(`Unexpected case: fetchedLessonProgress = ${JSON.stringify(fetchedLessonProgress)}`);
        }

        // _versionで楽観ロックしているので、
        // 複数端末から同時にアクセスがあっても銀行口座のようなレース問題は起きない見込み
        const oldLastCompletedDateTime = moment(fetchedLessonProgress.lastCompletedDateTime);
        const oldIteration : number = fetchedLessonProgress.iteration;
        const oldLessonProgressVersion = fetchedLessonProgress._version;

        // そのデバイスのタイムゾーンで、前回の学習と日付が異なる場合はiterationをインクリメントする
        // _versionに入れるのは、'今の'レコードのバージョン
        // https://docs.amplify.aws/lib/datastore/how-it-works/q/platform/js/#writing-data-from-the-appsync-console
        const isUpdateIteration = now.format('YYYYMMDD') > oldLastCompletedDateTime.format('YYYYMMDD');
        const newIteration = isUpdateIteration ? oldIteration + 1 : oldIteration;

        const updateLessonProgressMutationVariables : UpdateLessonProgressMutationVariables = {
            input: {
                id: fetchedLessonProgress.id,
                lastCompletedDateTime: now.toISOString(),
                iteration: newIteration,
                _version: oldLessonProgressVersion,
            },
        };

        await API.graphql(graphqlOperation(updateLessonProgress, updateLessonProgressMutationVariables)) as GraphQLResult<UpdateLessonProgressMutation>;

        let newExperienceLevel : number | undefined = undefined;
        let newExperiencePoint : number | undefined = undefined;
        let addedExperiencePoint = 0;

        if (isFirstCompletion || isUpdateIteration) {
        // 初回の学習は経験値を加算する (50p)
        // 2回目以降は、もし今回の学習でiterationの更新が起こる場合に加算する (5p)
            addedExperiencePoint = isFirstCompletion ? 50 : 5;

            const getUserQueryVariables : GetUserQueryVariables = {
                sub,
            };
            const getUserRes = await API.graphql(graphqlOperation(getUser, getUserQueryVariables)) as GraphQLResult<GetUserQuery>;

            const fetchedUser = getUserRes.data?.getUser;

            if (!fetchedUser) {
                return;
            }

            const oldUserVersion = fetchedUser._version;
            const oldExperiencePoint =  fetchedUser.experiencePoint;

            newExperiencePoint = oldExperiencePoint + addedExperiencePoint;

            // 大量に経験値を獲得するケースを処理できるように、次のレベルの必要経験値かどうかを判定するのではなく、
            // 獲得経験値からレベルを再計算する
            newExperienceLevel = pointToLevel(newExperiencePoint);

            const updateUserMutationVariables : UpdateUserMutationVariables = {
                input: {
                    sub,
                    experiencePoint: newExperiencePoint,
                    experienceLevel: newExperienceLevel,
                    _version: oldUserVersion,
                },
            };

            await API.graphql(graphqlOperation(updateUser, updateUserMutationVariables)) as GraphQLResult<UpdateUserMutation>;
        }

        // 再度APIをリクエストしなくていいように、stateを更新しておく
        // experienceLevel,
        // experiencePoint,
        // addedExperiencePoint,
        // courseId,
        // lessonNo,
        // latestCourseId
        // latestLessonNo


        // レッスンが初回でも2回目以降でも、lessonDoneSetはSetなので重複は起こり得ないのでadd()してOK
        await thunkAPI.dispatch(addLessonDoneSet(`${courseId}|${lessonNo}`));
        await thunkAPI.dispatch(changeLatestCourseId(courseId));
        await thunkAPI.dispatch(changeLatestLessonNo(lessonNo));

        if (typeof newExperienceLevel !== 'undefined') {
            await thunkAPI.dispatch(changeExperienceLevel(newExperienceLevel));
        }

        if (typeof newExperiencePoint !== 'undefined') {
            await thunkAPI.dispatch(changeExperiencePoint(newExperiencePoint));
        }

        if (typeof addedExperiencePoint !== 'undefined') {
            await thunkAPI.dispatch(changeAddedExperiencePoint(addedExperiencePoint));
        }

        // 準備ができたのでモーダルを開く
        await thunkAPI.dispatch(updateLessonModalIsOpen(true));
    }
);

export interface CloseModalParams {
    nextLessonUrl: string;
    navigate: NavigateFunction;
};

export const closeModal = createAsyncThunk(
  'lesson/closeModal',
    async (submitParams: CloseModalParams, thunkAPI) => {
        // const {
        //     nextLessonUrl,
        //     navigate,
        // } = submitParams;

        // 最後のスライド、意外と情報量が多いものが多いので
        // ひとまず次のレッスンには自動では飛ばないようにする
        // navigate(nextLessonUrl);
    }
);

interface MyAnswerPayload {
    index: number,
    word: string,
};

export const lessonSlice = createSlice({
    name: 'lesson',
    initialState,
    reducers: {
        initLoadLessonConfig: (state, action: PayloadAction<Lesson>) => {
            // 別のレッスンに遷移した場合を考慮して、lessonConfig以外はinitialStateで更新する
            // initLoadよりも先にupdateScaleRateが動いた時に更新が打ち消されないように、scaleRateも保持する
            // 「Stateがなぜか更新されない」という現象が起こったらこのinitLoadLessonConfigが想定より後に実行されて初期化されている可能性を疑うこと。
            const lessonConfig = action.payload;

            for (let k of Object.keys(initialState)) {
                if (k === 'lessonConfig') {
                    state[k] = lessonConfig;
                } else if (k === 'scaleRate'){
                    // 何も更新せず、元の値を保持する
                } else {
                    state[k] = initialState[k];
                }
            }
        },
        updateMyAnswers: (state, action: PayloadAction<MyAnswerPayload>) => {
            const {index, word} = action.payload;

            state.myAnswers[index] = {
                words: [word],
            };
        },
        updateIsCheckingAnswers: (state, action: PayloadAction<boolean>) => {
            state.isCheckingAnswers = action.payload;
        },
        updateLessonModalIsOpen: (state, action: PayloadAction<boolean>) => {
            state.lessonModalIsOpen = action.payload;
        },
        updateIsScaling: (state, action: PayloadAction<boolean>) => {
            state.isScaling = action.payload;
        },
        updateScaleRate: (state, action: PayloadAction<number>) => {
            state.scaleRate = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(completeLesson.fulfilled, (state, action) => {
            state.lessonModalIsOpen = true;
        });
        builder.addCase(closeModal.fulfilled, (state, action) => {
            state.lessonModalIsOpen = false;
        });
    },
})

export const {
    initLoadLessonConfig,
    updateMyAnswers,
    updateIsCheckingAnswers,
    updateLessonModalIsOpen,
    updateScaleRate,
    updateIsScaling,
 } = lessonSlice.actions;

export const selectLessonState = (state: RootState) => {
    return {
        lessonConfig: state.lesson.lessonConfig,
        myAnswers: state.lesson.myAnswers,
        isCheckingAnswers: state.lesson.isCheckingAnswers,
        lessonModalIsOpen: state.lesson.lessonModalIsOpen,
        scaleRate: state.lesson.scaleRate,
        isScaling: state.lesson.isScaling,
    };
};

export default lessonSlice.reducer;
