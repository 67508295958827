import React from 'react';
import SignupTemplate from '../../templates/SignupTemplate'
import { useNavigate } from 'react-router-dom';
import { useAppSelector, useAppDispatch } from '../../../redux/app/hooks'
import {
    changeEmail,
    changePassword,
    changeShowPwdFlag,
    submit,

    changeCode,
    submitCode,
    changeIsCheckedTOS,
    changeIsCheckedPP,

    selectSignupState,
    changeCodeValidationMessage,
} from '../../../redux/slices/signupSlice'
// CSSはインポートした順に生成され、後にインポートしたCSSの方が優先されるので、
// まず他のコンポーネントや共通CSSをインポートして、その後でコンポーネントごとのCSSをインストールすること
// つまり、styles.module.cssのインポートはインポート文の複数行のうちの最後に置いておくのが安全
import styles from './styles.module.css';

type SignupPageProps = {
    children?: never;
};


const SignupPage: React.FC<SignupPageProps> = (
) => {
    const navigate = useNavigate();

    const {
        email,
        password,
        showPwdFlag,
        isCheckedTOS,
        isCheckedPP,

        signupPhase,
        code,

        emailValidationMessage,
        passwordValidationMessage,
        codeValidationMessage,
        checkTOSValidationMessage,
        checkPPValidationMessage,
    } = useAppSelector(selectSignupState);

    const dispatch = useAppDispatch();

    const params = {
        email,
        password,
        emailValidationMessage,
        passwordValidationMessage,
        isCheckedTOS,
        isCheckedPP,
    };

    const submitCodeParams = {
        email,
        code,
        password,
        navigate,
    };

    const changeCodeAndValidationMessage = (e: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(changeCodeValidationMessage(''));
        dispatch(changeCode(e.target.value));
    };

    return (
        <div className={styles['signup-page']}>
            <SignupTemplate
                email={email}
                password={password}
                showPwdFlag={showPwdFlag}
                isCheckedTOS={isCheckedTOS}
                isCheckedPP={isCheckedPP}

                signupPhase={signupPhase}
                code={code}

                toggleShowPwdFlag={(e) => { dispatch(changeShowPwdFlag(!showPwdFlag)); }}
                emailValidationMessage={emailValidationMessage}
                passwordValidationMessage={passwordValidationMessage}
                checkTOSValidationMessage={checkTOSValidationMessage}
                checkPPValidationMessage={checkPPValidationMessage}
                codeValidationMessage={codeValidationMessage}

                changeEmail={(e) => { dispatch(changeEmail({ email: e.target.value, emailValidationMessage: e.target.validationMessage, })); }}
                changePassword={(e) => dispatch(changePassword(e.target.value))}
                changeCheckTOS={(e) => dispatch(changeIsCheckedTOS(e.target.checked))}
                changeCheckPP={(e) => dispatch(changeIsCheckedPP(e.target.checked))}

                submit={(e) => dispatch(submit(params))}

                changeCodeAndValidationMessage={changeCodeAndValidationMessage}
                submitCode={(e) => dispatch(submitCode(submitCodeParams))}
            />
        </div>
    );
};

export default SignupPage;
