import React from 'react';
import PdfSlide from '../../organisms/PdfSlide';
import { pdfResources } from '../../../lib/pdfResources';
import { CourseColor, LessonSlide } from '../../../config/course';
import { CorrectAnswer } from '../../../lib/domSlide';
import SlidePracticeWordList from '../SlidePracticeWordList';
import SlidePracticeConversionTable from '../SlidePracticeConversionTable';
import SlidePracticeCardConversionTable from '../SlidePracticeCardConversionTable';
import SlidePracticeSDGsConversionTable from '../SlidePracticeSDGsConversionTable';
import SlidePracticeCharacters from '../SlidePracticeCharacters';
import SlidePracticeImages from '../SlidePracticeImages';
import SlidePracticeImagesWithSentence from '../SlidePracticeImagesWithSentence';
import SlidePracticeCardImages from '../SlidePracticeCardImages';
import SlidePracticeHistricalDates from '../SlidePracticeHistricalDates';
import SlidePracticeSDGsKeyword from '../SlidePracticeSDGsKeyword';
import SlidePracticeFillInTheBlank from '../SlidePracticeFillInTheBlank';
import SlidePracticeManyImages from '../SlidePracticeManyImages';
import SlidePracticeFillInTheBlankArticle from '../SlidePracticeFillInTheBlankArticle';
import SlidePracticeRankTable from '../SlidePracticeRankTable';
import SlidePracticeSentence from '../SlidePracticeSentence';
import SlidePracticeKeywords from '../SlidePracticeKeywords';
import SlidePracticeLongWords from '../SlidePracticeLongWords';
// CSSはインポートした順に生成され、後にインポートしたCSSの方が優先されるので、
// まず他のコンポーネントや共通CSSをインポートして、その後でコンポーネントごとのCSSをインストールすること
// つまり、styles.module.cssのインポートはインポート文の複数行のうちの最後に置いておくのが安全
import styles from './styles.module.css';

export type Document = {
    numPages: number;
};

export type SlideProps = {
    slide: LessonSlide;
    pageIndex: number;
    onDocumentLoadSuccess?: (doc: Document) => void;

    myAnswers: Array<CorrectAnswer>;
    isCheckingAnswers: boolean;

    pdfPassword?: string;

    slideColor: CourseColor;

    updateMyAnswersWithIndex: (index:number) => (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void;
    updateIsCheckingAnswersTrue: (e: React.MouseEvent<HTMLButtonElement>) => void;

    children?: never;
};

const Slide : React.FC<SlideProps> = ({
    slide,
    pageIndex,
    onDocumentLoadSuccess,

    myAnswers,
    isCheckingAnswers,

    pdfPassword,

    slideColor,

    updateMyAnswersWithIndex,
    updateIsCheckingAnswersTrue,
}) => {
    if (slide?.type === 'pdf') {
        const pdf = pdfResources.get(slide.fileName);

        return (
            <PdfSlide
              file={pdf?.path || ''}
              onDocumentLoadSuccess={onDocumentLoadSuccess}
              pdfPassword={pdfPassword}
              pageIndex={pageIndex}
              slideColor={slideColor}
              />
        );
    } else if (slide?.type === 'wordList') {
        const {
            hasMemorizationSlide,
            memorizationTitle,
            recallTitle,
            numCol,
            hasFreeTextarea,
            freeTextareaPlaceholder,
            correctAnswers,
        } = slide;

        return (
             <SlidePracticeWordList
                slideColor={slideColor}
                pageIndex={pageIndex}
                hasMemorizationSlide={hasMemorizationSlide}
                memorizationTitle={memorizationTitle}
                recallTitle={recallTitle}
                numCol={numCol}
                hasFreeTextarea={hasFreeTextarea}
                freeTextareaPlaceholder={freeTextareaPlaceholder}
                correctAnswers={correctAnswers}
                myAnswers={myAnswers}
                isCheckingAnswers={isCheckingAnswers}
                updateMyAnswersWithIndex={updateMyAnswersWithIndex}
                updateIsCheckingAnswersTrue={updateIsCheckingAnswersTrue}
             />
        );
    } else if (slide?.type === 'conversionTable') {
        const {
            memorizationTitle,
            recallTitle,
            resultTitle,
            correctAnswers,
        } = slide;

        return (
             <SlidePracticeConversionTable
                pageIndex={pageIndex}
                slideColor={slideColor}
                memorizationTitle={memorizationTitle}
                recallTitle={recallTitle}
                resultTitle={resultTitle}
                correctAnswers={correctAnswers}
                myAnswers={myAnswers}
                isCheckingAnswers={isCheckingAnswers}
                updateMyAnswersWithIndex={updateMyAnswersWithIndex}
                updateIsCheckingAnswersTrue={updateIsCheckingAnswersTrue}
             />
        );
    } else if (slide?.type === 'cardConversionTable') {
        const {
            memorizationTitle,
            recallTitle,
            resultTitle,
            correctAnswers,
        } = slide;

        return (
             <SlidePracticeCardConversionTable
                pageIndex={pageIndex}
                slideColor={slideColor}
                memorizationTitle={memorizationTitle}
                recallTitle={recallTitle}
                resultTitle={resultTitle}
                correctAnswers={correctAnswers}
                myAnswers={myAnswers}
                isCheckingAnswers={isCheckingAnswers}
                updateMyAnswersWithIndex={updateMyAnswersWithIndex}
                updateIsCheckingAnswersTrue={updateIsCheckingAnswersTrue}
             />
        );
    } else if (slide?.type === 'sdgsConversionTable') {
        const {
            memorizationTitle,
            recallTitle,
            resultTitle,
            correctAnswers,
        } = slide;

        return (
             <SlidePracticeSDGsConversionTable
                pageIndex={pageIndex}
                slideColor={slideColor}
                memorizationTitle={memorizationTitle}
                recallTitle={recallTitle}
                resultTitle={resultTitle}
                correctAnswers={correctAnswers}
                myAnswers={myAnswers}
                isCheckingAnswers={isCheckingAnswers}
                updateMyAnswersWithIndex={updateMyAnswersWithIndex}
                updateIsCheckingAnswersTrue={updateIsCheckingAnswersTrue}
             />
        );
    } else if (slide?.type === 'characters') {
        const {
            memorizationTitle,
            recallTitle,
            resultTitle,
            correctAnswers,
        } = slide;

        return (
             <SlidePracticeCharacters
                pageIndex={pageIndex}
                slideColor={slideColor}
                memorizationTitle={memorizationTitle}
                recallTitle={recallTitle}
                resultTitle={resultTitle}
                correctAnswers={correctAnswers}
                myAnswers={myAnswers}
                isCheckingAnswers={isCheckingAnswers}
                updateMyAnswersWithIndex={updateMyAnswersWithIndex}
                updateIsCheckingAnswersTrue={updateIsCheckingAnswersTrue}
             />
        );
    } else if (slide?.type === 'images') {
        const {
            memorizationTitle,
            recallTitle,
            resultTitle,
            correctAnswers,
        } = slide;

        return (
             <SlidePracticeImages
                pageIndex={pageIndex}
                slideColor={slideColor}
                memorizationTitle={memorizationTitle}
                recallTitle={recallTitle}
                resultTitle={resultTitle}
                correctAnswers={correctAnswers}
                myAnswers={myAnswers}
                isCheckingAnswers={isCheckingAnswers}
                updateMyAnswersWithIndex={updateMyAnswersWithIndex}
                updateIsCheckingAnswersTrue={updateIsCheckingAnswersTrue}
             />
        );
    } else if (slide?.type === 'imagesWithSentence') {
        const {
            memorizationTitle,
            recallTitle,
            resultTitle,
            correctAnswers,
        } = slide;

        return (
             <SlidePracticeImagesWithSentence
                pageIndex={pageIndex}
                slideColor={slideColor}
                memorizationTitle={memorizationTitle}
                recallTitle={recallTitle}
                resultTitle={resultTitle}
                correctAnswers={correctAnswers}
                myAnswers={myAnswers}
                isCheckingAnswers={isCheckingAnswers}
                updateMyAnswersWithIndex={updateMyAnswersWithIndex}
                updateIsCheckingAnswersTrue={updateIsCheckingAnswersTrue}
             />
        );
    } else if (slide?.type === 'manyImages') {
        const {
            memorizationTitle,
            recallTitle,
            resultTitle,
            correctAnswers,
        } = slide;

        return (
             <SlidePracticeManyImages
                pageIndex={pageIndex}
                slideColor={slideColor}
                memorizationTitle={memorizationTitle}
                recallTitle={recallTitle}
                resultTitle={resultTitle}
                correctAnswers={correctAnswers}
                myAnswers={myAnswers}
                isCheckingAnswers={isCheckingAnswers}
                updateMyAnswersWithIndex={updateMyAnswersWithIndex}
                updateIsCheckingAnswersTrue={updateIsCheckingAnswersTrue}
             />
        );
    } else if (slide?.type === 'cardImages') {
        const {
            memorizationTitle,
            recallTitle,
            resultTitle,
            correctAnswers,
        } = slide;

        return (
             <SlidePracticeCardImages
                pageIndex={pageIndex}
                slideColor={slideColor}
                memorizationTitle={memorizationTitle}
                recallTitle={recallTitle}
                resultTitle={resultTitle}
                correctAnswers={correctAnswers}
                myAnswers={myAnswers}
                isCheckingAnswers={isCheckingAnswers}
                updateMyAnswersWithIndex={updateMyAnswersWithIndex}
                updateIsCheckingAnswersTrue={updateIsCheckingAnswersTrue}
             />
        );
    } else if (slide?.type === 'histricalDates') {
        const {
            memorizationTitle,
            recallTitle,
            resultTitle,
            correctAnswers,
        } = slide;

        return (
             <SlidePracticeHistricalDates
                pageIndex={pageIndex}
                slideColor={slideColor}
                memorizationTitle={memorizationTitle}
                recallTitle={recallTitle}
                resultTitle={resultTitle}
                correctAnswers={correctAnswers}
                myAnswers={myAnswers}
                isCheckingAnswers={isCheckingAnswers}
                updateMyAnswersWithIndex={updateMyAnswersWithIndex}
                updateIsCheckingAnswersTrue={updateIsCheckingAnswersTrue}
             />
        );
    } else if (slide?.type === 'sdgsKeyword') {
        const {
            memorizationTitle,
            recallTitle,
            resultTitle,
            has6Rows,
            correctAnswers,
        } = slide;

        return (
             <SlidePracticeSDGsKeyword
                pageIndex={pageIndex}
                slideColor={slideColor}
                memorizationTitle={memorizationTitle}
                recallTitle={recallTitle}
                resultTitle={resultTitle}
                has6Rows={has6Rows}
                correctAnswers={correctAnswers}
                myAnswers={myAnswers}
                isCheckingAnswers={isCheckingAnswers}
                updateMyAnswersWithIndex={updateMyAnswersWithIndex}
                updateIsCheckingAnswersTrue={updateIsCheckingAnswersTrue}
             />
        );
    } else if (slide?.type === 'fillInTheBlank') {
        const {
            memorizationTitle,
            recallTitle,
            resultTitle,
            correctAnswers,
        } = slide;

        return (
             <SlidePracticeFillInTheBlank
                pageIndex={pageIndex}
                slideColor={slideColor}
                memorizationTitle={memorizationTitle}
                recallTitle={recallTitle}
                resultTitle={resultTitle}
                correctAnswers={correctAnswers}
                myAnswers={myAnswers}
                isCheckingAnswers={isCheckingAnswers}
                updateMyAnswersWithIndex={updateMyAnswersWithIndex}
                updateIsCheckingAnswersTrue={updateIsCheckingAnswersTrue}
             />
        );
    } else if (slide?.type === 'fillInTheBlankArticle') {
        const {
            memorizationTitle,
            recallTitle,
            resultTitle,
            correctAnswers,
        } = slide;

        return (
             <SlidePracticeFillInTheBlankArticle
                pageIndex={pageIndex}
                slideColor={slideColor}
                memorizationTitle={memorizationTitle}
                recallTitle={recallTitle}
                resultTitle={resultTitle}
                correctAnswers={correctAnswers}
                myAnswers={myAnswers}
                isCheckingAnswers={isCheckingAnswers}
                updateMyAnswersWithIndex={updateMyAnswersWithIndex}
                updateIsCheckingAnswersTrue={updateIsCheckingAnswersTrue}
             />
        );
    } else if (slide?.type === 'rankTable') {
        const {
            memorizationTitle,
            recallTitle,
            resultTitle,
            correctAnswers,
        } = slide;

        return (
             <SlidePracticeRankTable
                pageIndex={pageIndex}
                slideColor={slideColor}
                memorizationTitle={memorizationTitle}
                recallTitle={recallTitle}
                resultTitle={resultTitle}
                correctAnswers={correctAnswers}
                myAnswers={myAnswers}
                isCheckingAnswers={isCheckingAnswers}
                updateMyAnswersWithIndex={updateMyAnswersWithIndex}
                updateIsCheckingAnswersTrue={updateIsCheckingAnswersTrue}
             />
        );
    } else if (slide?.type === 'sentence') {
        const {
            memorizationTitle,
            recallTitle,
            resultTitle,
            correctAnswers,
        } = slide;

        return (
             <SlidePracticeSentence
                pageIndex={pageIndex}
                slideColor={slideColor}
                memorizationTitle={memorizationTitle}
                recallTitle={recallTitle}
                resultTitle={resultTitle}
                correctAnswers={correctAnswers}
                myAnswers={myAnswers}
                isCheckingAnswers={isCheckingAnswers}
                updateMyAnswersWithIndex={updateMyAnswersWithIndex}
                updateIsCheckingAnswersTrue={updateIsCheckingAnswersTrue}
             />
        );
    } else if (slide?.type === 'keywords') {
        const {
            memorizationTitle,
            recallTitle,
            resultTitle,
            hasMemorizationSlide,
            has6Rows,
            correctAnswers,
        } = slide;

        return (
             <SlidePracticeKeywords
                pageIndex={pageIndex}
                slideColor={slideColor}
                memorizationTitle={memorizationTitle}
                recallTitle={recallTitle}
                resultTitle={resultTitle}
                hasMemorizationSlide={hasMemorizationSlide}
                has6Rows={has6Rows}
                correctAnswers={correctAnswers}
                myAnswers={myAnswers}
                isCheckingAnswers={isCheckingAnswers}
                updateMyAnswersWithIndex={updateMyAnswersWithIndex}
                updateIsCheckingAnswersTrue={updateIsCheckingAnswersTrue}
             />
        );
    } else if (slide?.type === 'longWords') {
        const {
            memorizationTitle,
            recallTitle,
            resultTitle,
            hasMemorizationSlide,
            correctAnswers,
        } = slide;

        return (
             <SlidePracticeLongWords
                pageIndex={pageIndex}
                slideColor={slideColor}
                memorizationTitle={memorizationTitle}
                recallTitle={recallTitle}
                resultTitle={resultTitle}
                hasMemorizationSlide={hasMemorizationSlide}
                correctAnswers={correctAnswers}
                myAnswers={myAnswers}
                isCheckingAnswers={isCheckingAnswers}
                updateMyAnswersWithIndex={updateMyAnswersWithIndex}
                updateIsCheckingAnswersTrue={updateIsCheckingAnswersTrue}
             />
        );
    } else {
        return (
            <div className={ styles['slide'] } />
        );
    }
};

export default Slide;
