import React from 'react';
import templateProperties from '../../common/template-properties.module.css';
import HeaderContainer from '../../organisms/HeaderContainer';
import PageTitleArea from '../../layouts/PageTitleArea';
import { PageContainer } from '../../layouts/Container';
import { RequiredBadge } from '../../atoms/Badge';
import { ErrorMessageBar } from '../../atoms/MessageBar';
import Textbox from '../../atoms/Textbox';
import { WrapButton, PrimaryWrapButton, GrayWrapButton } from '../../atoms/Button';
import Textarea from '../../atoms/Textarea';
import InnerLink from '../../atoms/InnerLink';
import Footer from '../../organisms/Footer';
import { ContactPhase } from '../../../redux/constant';
// CSSはインポートした順に生成され、後にインポートしたCSSの方が優先されるので、
// まず他のコンポーネントや共通CSSをインポートして、その後でコンポーネントごとのCSSをインストールすること
// つまり、styles.module.cssのインポートはインポート文の複数行のうちの最後に置いておくのが安全
import styles from './styles.module.css';

Object.assign(styles, templateProperties);

export type ContactTemplateProps = {
  isLogin: boolean;             // ログイン状態
  isPaid: boolean;              // 有料会員かどうか
  isSocialLogin: boolean;       // SNSログイン会員かどうか
  profileImg?: string;          // 会員画像
  preferredUsername?: string;  // 会員名

  contactPhase: ContactPhase;   // 画面状態

  username?: string;
  email?: string;
  email2?: string;
  usertext?: string;
  // attachedFileName?: string;

  usernameValidationMessage?: string;
  emailValidationMessage?: string;
  usertextValidationMessage?: string;
  // attachedFileValidationMessage?: string;

  changeUsername: (event: React.ChangeEvent<HTMLInputElement>) => void;
  changeEmail: (event: React.ChangeEvent<HTMLInputElement>) => void;
  changeEmail2: (event: React.ChangeEvent<HTMLInputElement>) => void;
  changeUsertext: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  // changeAttachedFile: (event: React.ChangeEvent<HTMLInputElement>) => void;
  submit: (event: React.MouseEvent<HTMLButtonElement>) => void;
  confirm: (event: React.MouseEvent<HTMLButtonElement>) => void;
  back: (event: React.MouseEvent<HTMLButtonElement>) => void;
};

const newLine2Br = (msg: String) => {
  const texts = msg.split("\n").map((item, index) => {
    // <></> は key を設定できないので、<React.Fragment /> を使う
    return (
      <React.Fragment key={index}>{item}<br /></React.Fragment>
    );
  });
  return <>{texts}</>;
}

const ContactTemplate: React.FC<ContactTemplateProps> = ({
  isLogin,
  isPaid,
  isSocialLogin,
  profileImg,
  preferredUsername,

  contactPhase,

  username = preferredUsername,
  email,
  email2,
  usertext,
  // attachedFileName,

  usernameValidationMessage,
  emailValidationMessage,
  usertextValidationMessage,
  // attachedFileValidationMessage,

  changeUsername,
  changeEmail,
  changeEmail2,
  changeUsertext,
  // changeAttachedFile,
  submit,
  confirm,
  back,
}) => {
  return (
    <div className={styles['contact-template'] + ' ' + styles['template-properties-local']}>
      <HeaderContainer isLogin={isLogin} isPaid={isPaid} isSocialLogin={isSocialLogin} profileImg={profileImg} preferredUsername={preferredUsername} />
      <PageTitleArea>お問い合わせ</PageTitleArea>
      <PageContainer>

        {contactPhase === ContactPhase.send && // 送信画面
          <React.Fragment>
            <p className={styles['message']}>
              ※オンラインサービス「メモアカ」以外に関する営業などのお問い合わせは
              <a href="https://corp.memoaca.com/contact" target="_blank" rel="noreferrer">こちらのお問い合わせ</a>
              からお願いいたします。
            </p>
            <p>
              オンラインサービス「メモアカ」に関するお問い合わせは、以下のフォームに入力して送信してください。
            </p>
            <div className={styles["form-item"]}>
              <label className={styles["form-item-label"]} htmlFor="username">お名前<RequiredBadge /></label>
              <Textbox id="username" placeholder="お名前を入力してください" value={username} onChange={changeUsername} />
            </div>
            {usernameValidationMessage && <ErrorMessageBar>{usernameValidationMessage}</ErrorMessageBar>}
            <div className={styles["form-item"]}>
              <label className={styles["form-item-label"]} htmlFor="email">メールアドレス<RequiredBadge /></label>
              <Textbox id="email" type="email" placeholder="メールアドレスを入力してください" value={email} onChange={changeEmail} />
            </div>
            <div className={styles["form-item"]}>
              <label className={styles["form-item-label"]} htmlFor="email2">メールアドレス(再入力)<RequiredBadge /></label>
              <Textbox id="email2" type="email" placeholder="メールアドレスを再入力してください" value={email2} onChange={changeEmail2} />
            </div>
            {emailValidationMessage && <ErrorMessageBar>{emailValidationMessage}</ErrorMessageBar>}
            <div className={styles["form-item"]}>
              <label className={styles["form-item-label"]} htmlFor="usertext">お問い合わせ内容<RequiredBadge /></label>
              <Textarea id="usertext" className="form-item-textarea" placeholder="お問い合わせ内容を入力してください" value={usertext} onChange={changeUsertext} />
            </div>
            {usertextValidationMessage && <ErrorMessageBar>{usertextValidationMessage}</ErrorMessageBar>}
            {/* <div className={styles["form-item"]}>
                <label className={styles["form-item-label"]} htmlFor="attached-file">添付ファイル</label>
                <Textbox id="attached-file" type="file" onChange={changeAttachedFile} />
              </div>
              { attachedFileValidationMessage && <ErrorMessageBar>{attachedFileValidationMessage}</ErrorMessageBar> } */}
            {(username && email && email2 && usertext && !usernameValidationMessage && !emailValidationMessage && !usertextValidationMessage)
              ? (<PrimaryWrapButton className={styles['button']} onClick={confirm}>確認</PrimaryWrapButton>)
              : (<GrayWrapButton className={styles['button']} disabled>確認</GrayWrapButton>)}
          </React.Fragment>
        }

        {contactPhase === ContactPhase.confirm && // 確認画面
          <React.Fragment>
            <div className={styles["form-item"]}>
              <label className={styles["form-item-label"]} htmlFor="username">お名前<RequiredBadge /></label>
              {username}
            </div>
            <div className={styles["form-item"]}>
              <label className={styles["form-item-label"]} htmlFor="email">メールアドレス<RequiredBadge /></label>
              {email}
            </div>
            <div className={styles["form-item"]}>
              <label className={styles["form-item-label"]} htmlFor="usertext">お問い合わせ内容<RequiredBadge /></label>
              <p>
                {usertext && newLine2Br(usertext)}
              </p>
            </div>
            {/* <div className={styles["form-item"]}>
                <label className={styles["form-item-label"]} htmlFor="attached-file">添付ファイル</label>
                {attachedFileName? {attachedFileName}:'なし'}
              </div> */}
            <PrimaryWrapButton className={styles['button']} onClick={submit}>送信</PrimaryWrapButton>
            <WrapButton className={styles['button']} onClick={back}>戻る</WrapButton>
          </React.Fragment>
        }

        {contactPhase === ContactPhase.postprocess && // 後処理画面画面
          <React.Fragment>
            <p className={styles['post-process']}>
              お問い合せありがとうございます。<br />
              お問い合わせ内容は自動的に送信者の方にも控えとして送信されます。<br />
              2営業日以内に担当者の方から回答させていただきます。<br />
              よろしくお願いいたします。
            </p>
            <p className={styles['post-process']}>
              {isLogin ?
                <InnerLink to='/mypage'>マイページに移動する</InnerLink> :
                <InnerLink to='/'>トップに移動する</InnerLink>
              }
            </p>
          </React.Fragment>
        }

      </PageContainer>
      <Footer isLogin={isLogin} />
    </div>
  );
};

export default ContactTemplate;
