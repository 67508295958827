import React from 'react';
import FontTitle from '../../atoms/FontTitle';
import NewsList from '../../molecules/NewsList';
import InnerLink from '../../atoms/InnerLink';
import { MoreButton } from '../../atoms/Button';
// import Anchor from '../../atoms/Anchor';
// CSSはインポートした順に生成され、後にインポートしたCSSの方が優先されるので、
// まず他のコンポーネントや共通CSSをインポートして、その後でコンポーネントごとのCSSをインストールすること
// つまり、styles.module.cssのインポートはインポート文の複数行のうちの最後に置いておくのが安全
import styles from './styles.module.css';

export type NewsAreaProps = {
};


const NewsArea : React.FC<NewsAreaProps> = () => {
    return (
        <div className={ styles['news-area'] }>
            <div className={styles['news-title-box']}>
                <div className={styles['news-title-inner']}>
                    <h2 className={styles['news-title']}><FontTitle>メモアカからの</FontTitle><FontTitle>お知らせ</FontTitle></h2>
                    <div className={styles['text-center']}><InnerLink to='/news'><MoreButton>一覧へ</MoreButton></InnerLink></div>
                </div>
            </div>

            <div className={styles['news-body']}>
                <div className={styles['news-wrap']}>
                  <NewsList date='2024.06.29'>「国の名前と首都」のトレーニングの回答時間を30分に延長しました。</NewsList>
                </div>
                <div className={styles['news-wrap']}>
                  <NewsList date='2024.05.11'>新たに「国の名前と首都」のトレーニングを追加しました!<br/>有料会員の方は出題される全ての国の首都の覚え方の例を右上のメニューからダウンロードできます!</NewsList>
                </div>
                <div className={styles['news-wrap']}>
                  <NewsList date='2024.03.03'>新たに「円周率記憶法」のコースを追加しました!</NewsList>
                </div>
                <div className={styles['news-wrap']}>
                  <NewsList date='2024.01.30'>新たに「円周率記憶」のトレーニングを追加しました!</NewsList>
                </div>
                <div className={styles['news-wrap']}>
                  <NewsList date='2023.11.28'>「英単語記憶法」の内容を更新しました!</NewsList>
                </div>
            </div>
        </div>
    );
};

export default NewsArea;
