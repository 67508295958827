import React from 'react';
import SlidePracticeBackground from '../../atoms/SlidePracticeBackground';
import { CorrectAnswer } from '../../../lib/domSlide';
import Textarea from '../../atoms/Textarea';
import FontTitle from '../../atoms/FontTitle';
import { CourseColor } from '../../../config/course';
// import { title } from 'process';
// CSSはインポートした順に生成され、後にインポートしたCSSの方が優先されるので、
// まず他のコンポーネントや共通CSSをインポートして、その後でコンポーネントごとのCSSをインストールすること
// つまり、styles.module.cssのインポートはインポート文の複数行のうちの最後に置いておくのが安全
import styles from './styles.module.css';

export type SlidePracticeWordListMemorizationProps = {
    className?: string;
    slideColor: CourseColor;
    memorizationTitle: string;
    numCol: number;
    hasFreeTextarea?: boolean;
    freeTextareaPlaceholder?: string;
    correctAnswers: Array<CorrectAnswer>;
};


const SlidePracticeWordListMemorization : React.FC<SlidePracticeWordListMemorizationProps> = ({
    className = '',
    slideColor,
    memorizationTitle,
    numCol,
    hasFreeTextarea = false,
    freeTextareaPlaceholder = '',
    correctAnswers,
}) => {
    // class属性を設定
    let classString = styles['dom-slide-memorization'];
    if (className) {
        classString = [classString, className].join(' ');
    }

    // memorizationモードではテキストボックスが編集できないので
    // 本来はupdateMyAnswersWithIndex()が発火することはないが、
    // テストカバレッジが足りないので便宜的に空打ちする
    // わずかに本番動作に影響するのでできるだけjest実行時だけ動かしたいが、
    // 一方でこのためだけにexportするのもよくない気がしていている
    const nop = () => () => {};
    nop()();

    // 要素のクラス
    let titleClass = styles['dom-slide-title'];
    let answerAreaClass = styles['dom-slide-answerarea'];
    let answerColumnClass = styles['input-item'];
    let textareaClass = styles['dom-slide-textarea'];
    // 横の入力欄数で入力欄の幅を指定する
    switch (numCol) {
        case 3:
            answerColumnClass = [answerColumnClass, styles['three-in-row']].join(' ');
            break;
        case 4:
            answerColumnClass = [answerColumnClass, styles['four-in-row']].join(' ');
            // 問題総数が4で割り切れない数の場合
            if (correctAnswers.length%4 !== 0) {
                answerAreaClass = [answerAreaClass, styles['indivisible-columns']].join(' ');
                answerColumnClass = [answerColumnClass, styles['indivisible-columns']].join(' ');
            }
            break;
        case 5:
            answerColumnClass = [answerColumnClass, styles['five-in-row']].join(' ');
            break;
    }
    // 問題の総個数とテキストエリアの有無で縦の幅を修正する
    if (!hasFreeTextarea) {
        titleClass = [titleClass, styles['no-textarea']].join(' ');
        answerColumnClass = [answerColumnClass, styles['no-textarea']].join(' ');
    } else if (hasFreeTextarea && correctAnswers.length > 10) {
        answerColumnClass = [answerColumnClass, styles['many-columns']].join(' ');
        textareaClass = [textareaClass, styles['many-columns']].join(' ');
    }

    return (
        <SlidePracticeBackground bgColor={slideColor} className={classString}>
            <div className={styles['dom-slide-area']}>
                <div className={styles['dom-slide-content']}>
                    <FontTitle className={titleClass}>{memorizationTitle}</FontTitle>
                    <div className={answerAreaClass}>
                        {correctAnswers.map((correctAnswer, idx) => {
                            return (
                                <span key={idx} className={answerColumnClass}>{correctAnswer.words[0]}</span>
                            );
                        })}
                    </div>
                    { hasFreeTextarea && <Textarea placeholder={freeTextareaPlaceholder} className={textareaClass} /> }
                </div>
            </div>
        </SlidePracticeBackground>
    );
};

export default SlidePracticeWordListMemorization;
