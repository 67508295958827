import React from 'react';
import { imageResources } from '../../../lib/imageResources';
// CSSはインポートした順に生成され、後にインポートしたCSSの方が優先されるので、
// まず他のコンポーネントや共通CSSをインポートして、その後でコンポーネントごとのCSSをインストールすること
// つまり、styles.module.cssのインポートはインポート文の複数行のうちの最後に置いておくのが安全
import styles from './styles.module.css';


export type ImgProps = {
    className?: string,
    fileName?: string,
    alt: string,
} & JSX.IntrinsicElements['img'];

const Img : React.FC<ImgProps> = ({
    className = '',
    fileName = '',
    alt,
    ...props
}) => {
   // class属性を設定
   let classString = styles.img;
   if (className) {
       classString = [classString, className].join(' ');
   }

    const fileInfo = imageResources.get(fileName);
    if (fileInfo) {
        return (
            <img
                src={fileInfo.path}
                className={classString}
                alt={alt}
                {...props}
            />
        );
    } else {
        return (
            <img
                src={fileName}
                className={classString}
                alt={alt}
                {...props}
            />
        );
    }
};

const imgFactory = (fileName: string, alt: string) => {
    return (iProps: Omit<ImgProps, 'fileName' | 'alt'>) => {
        return (
            <Img fileName={fileName} alt={alt} {...iProps} />
        );
    };
}

export default Img;
export const LogoColor = imgFactory('logo_color.svg', 'メモアカロゴ');
export const LogoWhite = imgFactory('logo_white.svg', 'メモアカロゴ');
export const LogoFacebook = imgFactory('logo_facebook.svg', 'facebookロゴ');
export const LogoGoogle = imgFactory('logo_google.svg', 'Googleロゴ');

export const IconEyeOff = imgFactory('icon_eye_off.svg', 'テキスト非表示');
export const IconEyeOn = imgFactory('icon_eye_on.svg', 'テキスト表示');

export const IconCourse = imgFactory('icon_course.svg', 'コース一覧');
export const IconCourseOn = imgFactory('icon_course_on.svg', 'コース一覧');

export const LessonDone = imgFactory('icon_done.svg', '受講済');
export const LessonYet = imgFactory('icon_yet.svg', '未受講');

export const DownloadArrow = imgFactory('download-arrow.svg', 'ダウンロード');
