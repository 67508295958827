import React from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { PDFDocumentProxy, PDFPageProxy } from 'pdfjs-dist/types/src/display/api';
import DomSlideEmpty from '../../atoms/DomSlideEmpty';
import { CourseColor } from '../../../config/course';
// CSSはインポートした順に生成され、後にインポートしたCSSの方が優先されるので、
// まず他のコンポーネントや共通CSSをインポートして、その後でコンポーネントごとのCSSをインストールすること
// つまり、styles.module.cssのインポートはインポート文の複数行のうちの最後に置いておくのが安全
import styles from './styles.module.css';

pdfjs.GlobalWorkerOptions.workerSrc ='/pdf.worker.js';

export type PdfSlideProps = {
    file: string;
    pageIndex: number;
    slideColor: CourseColor;
    onDocumentLoadSuccess?: (pdf: PDFDocumentProxy) => void;
    onPageLoadSuccess?: (page: PDFPageProxy) => void;
    pdfPassword?: string;
    children?: never;
};

export const onPassword = (pdfPassword?: string) => (callback: (_:string) => any) => { return callback(pdfPassword || '') };

const PdfSlide : React.FC<PdfSlideProps> = ({
    file,
    pageIndex,
    slideColor,
    onDocumentLoadSuccess,
    onPageLoadSuccess,
    pdfPassword,
}) => {
    return (
        <Document
            className={ styles['pdf-slide'] }
            file={ file }
            onPassword={ onPassword(pdfPassword) }
            onLoadSuccess={ onDocumentLoadSuccess }
            loading={<DomSlideEmpty bgColor={slideColor} />}
        >
            <Page
                pageIndex={ pageIndex }
                onLoadSuccess={ onPageLoadSuccess }
                loading=""
            />
        </Document>
    );
};

export default PdfSlide;
