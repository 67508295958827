import React from 'react';
import ReactDatePicker from 'react-datepicker';
import {ja} from 'date-fns/locale';
import "react-datepicker/dist/react-datepicker.css";
import "./date-picker.customized.css";       // 上書きcss
// CSSはインポートした順に生成され、後にインポートしたCSSの方が優先されるので、
// まず他のコンポーネントや共通CSSをインポートして、その後でコンポーネントごとのCSSをインストールすること
// つまり、styles.module.cssのインポートはインポート文の複数行のうちの最後に置いておくのが安全
import styles from './styles.module.css';

export type InlineDatePickerProps = {
    userDate?: Date;
    changeDatePicker: (newDate:Date) => void;
};

const InlineDatePicker : React.FC<InlineDatePickerProps> = ({
    userDate = null,
    changeDatePicker,
}) => {
    // 初期選択年月日準備 指定がなければ今日
    const today = new Date();
    const startDate = userDate? userDate:today;

    return (
        <div className="inline-date-picker" >
            <ReactDatePicker
                locale={ja}
                className={styles['date-input']}
                selected={startDate}
                maxDate={today}
                onChange={(newDate:Date) => changeDatePicker(newDate)}
                inline
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
            />
        </div>
    );
};

export default InlineDatePicker;
