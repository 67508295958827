import React, { useEffect } from 'react';
import moment from 'moment';
import sortBy from 'lodash/sortBy';
import { useNavigate } from 'react-router-dom';
import { Auth, API } from 'aws-amplify';
import { graphqlOperation, GraphQLResult } from '@aws-amplify/api-graphql';
import { useAppSelector, useAppDispatch } from '../../../redux/app/hooks';
import { CourseId } from '../../../config/course';
import {
    ModelSortDirection,
    GetUserQuery,
    GetUserQueryVariables,
    CreateUserMutation,
    CreateUserMutationVariables,
    UpdateUserMutation,
    UpdateUserMutationVariables,
    SearchLessonProgressesByUserBylastCompletedDateTimeQuery,
    SearchLessonProgressesByUserBylastCompletedDateTimeQueryVariables,
} from '../../../API';
import {
    setIsNotPaid,
    fetchMySubscriptions,
} from '../../../redux/slices/paymentSlice';
import {
    changeForceReloadNext,
    changeIsLogin,
    changeIsSocialLogin,
    changeSub,

    changeFirstLoginDateTime,
    changeExperienceLevel,
    changeExperiencePoint,
    changeLessonDoneSet,

    changeLatestCourseId,
    changeLatestLessonNo,

    changePreferredUsername,
    changeGender,
    changeGenderOpen,
    changeBirthDate,
    changePrefecture,
    changeProfession,
    changeSelfIntroduction,

    selectLoginState,
} from '../../../redux/slices/loginSlice';
import {
    getUser,
    searchLessonProgressesByUserBylastCompletedDateTime,
} from '../../../graphql/queries';
import {
    createUser,
    updateUser,
} from '../../../graphql/mutations';
import {
    makeCourseIdLessonNoTag,
} from '../../../lib/lessonProgress';
// CSSはインポートした順に生成され、後にインポートしたCSSの方が優先されるので、
// まず他のコンポーネントや共通CSSをインポートして、その後でコンポーネントごとのCSSをインストールすること
// つまり、styles.module.cssのインポートはインポート文の複数行のうちの最後に置いておくのが安全
import styles from './styles.module.css';


export type AuthorizationPageProps = {
    children?: never,
};


const AuthorizationPage : React.FC<AuthorizationPageProps> = (
) => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const {
        forceReloadNext,
    } = useAppSelector(selectLoginState);

    const affect = async () => {
        try {
            // ログインしていない場合はnullが返る
            const currentUserInfo = await Auth.currentUserInfo();
            if (!currentUserInfo || Object.keys(currentUserInfo).length === 0) {
                throw new Error('Not logged in.');
            }

            dispatch(changeIsLogin(true));

            const username : string = currentUserInfo.username;
            dispatch(changeIsSocialLogin(username));

            const sub : string = currentUserInfo.attributes.sub;
            dispatch(changeSub(sub));

            dispatch(fetchMySubscriptions({}));

            const getUserQueryVariables : GetUserQueryVariables = {
                sub,
            };
            const getUserRes = await API.graphql(graphqlOperation(getUser, getUserQueryVariables)) as GraphQLResult<GetUserQuery>;


            let fetchedUser = getUserRes.data?.getUser;
            const now = moment();
            // DBに会員の情報が存在しない場合は初回登録を行う
            if (!fetchedUser) {
                // PrimaryKeyであるsubを指定しているので、もし複数のデバイスで同時にアクセスがあった場合には片方だけが採用され、レコードが二重登録されることはない
                // また、同時に更新処理をしようとしても、_verionで楽観ロックされているので同時に更新されることはない
                const createUserMutationVariables : CreateUserMutationVariables = {
                    input: {
                        sub,
                        email: currentUserInfo.attributes.email,
                        firstLoginDateTime: now.toISOString(),
                        lastLoginDateTime: now.toISOString(),
                        activeDays: 1,
                        experienceLevel: 1,
                        experiencePoint: 0,
                    },
                };

                const createUserRes = await API.graphql(graphqlOperation(createUser, createUserMutationVariables)) as GraphQLResult<CreateUserMutation>;

                // alert(JSON.stringify(createUserRes));
                fetchedUser = createUserRes.data?.createUser;
            }

            // fetchedUserには、初回のfetchであればそれが、無ければcreateUserした結果が入っている
            // なので、nullはありえない
            if (!fetchedUser) {
                throw new Error(`Unexpected case: fetchedUser = ${JSON.stringify(fetchedUser)}`);
            }

            if (!fetchedUser.preferredUsername) {
                navigate('/setup-profile');
                return;
            }

            // _versionで楽観ロックしているので、
            // 複数端末から同時にアクセスがあっても銀行口座のようなレース問題は起きない見込み
            const oldLastLoginDateTime = moment(fetchedUser.lastLoginDateTime);
            const oldActiveDays : number = fetchedUser.activeDays;
            const oldVersion = fetchedUser._version;

            // そのデバイスのタイムゾーンで、前回のログインと日付が異なる場合はactiveDaysをインクリメントする
            // _versionに入れるのは、'今の'レコードのバージョン
            // https://docs.amplify.aws/lib/datastore/how-it-works/q/platform/js/#writing-data-from-the-appsync-console
            const newActiveDays = now.format('YYYYMMDD') > oldLastLoginDateTime.format('YYYYMMDD') ? oldActiveDays + 1 : oldActiveDays;
            const updateUserMutationVariables : UpdateUserMutationVariables = {
                input: {
                    sub,
                    lastLoginDateTime: now.toISOString(),
                    activeDays: newActiveDays,
                    _version: oldVersion,
                },
            };

            await API.graphql(graphqlOperation(updateUser, updateUserMutationVariables)) as GraphQLResult<UpdateUserMutation>;

            // レッスン完了数取得
            // デフォルトだとQuery.listLessonProgresses.req.vtlに書いてある通りに、limit = 100
            // これは、なんでもいいから100件取得して、その後でwhere句で絞るという意味。
            // limitを増やすだけだとメモアカの会員数が増えた時に対応できなくなるため、ページングで対応する。
            // 型は決まっているが、長いので省略… 型推論してほしい
            const items : Array<any> = [];

            // 1回は必ずリクエストするので、do-while
            let nextToken : string | undefined | null = null;
            let newItems : Array<any> = [];
            do {
                const listLessonProgressesQueryVariables : SearchLessonProgressesByUserBylastCompletedDateTimeQueryVariables = {
                    sub,
                    sortDirection: ModelSortDirection.DESC,
                    limit: 100,
                    nextToken,
                };

                const listLessonProgressesQueryRes = await API.graphql(graphqlOperation(searchLessonProgressesByUserBylastCompletedDateTime, listLessonProgressesQueryVariables)) as GraphQLResult<SearchLessonProgressesByUserBylastCompletedDateTimeQuery>;

                nextToken = listLessonProgressesQueryRes.data?.searchLessonProgressesByUserBylastCompletedDateTime?.nextToken;
                newItems = listLessonProgressesQueryRes.data?.searchLessonProgressesByUserBylastCompletedDateTime?.items || [];

                // 破壊的に結合
                Array.prototype.push.apply(items, newItems);
            } while (nextToken !== null && typeof(nextToken) !== 'undefined')

            // 最後に学習したコースIDを取得
            const itemsForTag = items.map(item => {
                if (item) {
                    const {
                        courseId,
                        lessonNo,
                        lastCompletedDateTime,
                    } = item;

                    return {
                        courseId,
                        lessonNo,
                        lastCompletedDateTime,
                    };
                } else {
                    return {
                        courseId: CourseId.introduction,
                        lessonNo: 1,
                        lastCompletedDateTime: moment(0).toISOString(),
                    };
                }
            });
            const sorted = sortBy(itemsForTag, (item) => item.lastCompletedDateTime);
            const latestCourseId = (sorted.length > 0 ? sorted.slice(-1)[0] : undefined)?.courseId;
            const latestLessonNo = (sorted.length > 0 ? sorted.slice(-1)[0] : undefined)?.lessonNo;

            dispatch(changeLatestCourseId(latestCourseId));
            dispatch(changeLatestLessonNo(latestLessonNo));

            const lessonDoneSet : Set<string> = new Set(items.map(item => {
                const i = item ? item.courseId : '';
                const l = item ? item.lessonNo : 0;
                return makeCourseIdLessonNoTag(i, l);
            }));
            dispatch(changeLessonDoneSet(lessonDoneSet));

            dispatch(changeFirstLoginDateTime(fetchedUser.firstLoginDateTime));
            dispatch(changeExperienceLevel(fetchedUser.experienceLevel));
            dispatch(changeExperiencePoint(fetchedUser.experiencePoint));

            dispatch(changePreferredUsername(fetchedUser.preferredUsername));
            dispatch(changeGender(fetchedUser.gender || undefined));
            dispatch(changeGenderOpen(fetchedUser.genderOpen === false ? '公開しない' : '公開する'));
            dispatch(changeBirthDate(moment(fetchedUser.birthDate).format('YYYY-MM-DD')));
            dispatch(changePrefecture(fetchedUser.prefecture || undefined));
            dispatch(changeProfession(fetchedUser.profession || undefined));
            dispatch(changeSelfIntroduction(fetchedUser.selfIntroduction || undefined));
        } catch {
            // console.log(e);
            dispatch(changeIsLogin(false));
            dispatch(setIsNotPaid());
            navigate('/login');
        }
    };

    useEffect(() => {
        if (forceReloadNext) {
            dispatch(changeForceReloadNext(false));
            affect();
        }

        // 基本的にログインに成功した後は認証が要らないため、基本的には初回のみ発火する。
        // ただし、DBで経験値などの情報が更新した時にStateを更新するために、
        // forceReloadNextがtrueの時は強制的に発火させる
    }, [affect, forceReloadNext]);

    return (
        <span className={ styles['authorization-page'] } />
    );
};

export default AuthorizationPage;
