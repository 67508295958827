import React from 'react';
import { Line, } from 'rc-progress';
import {
    levelToPoint,
    pointToLevel,
} from '../../../lib/experience';
// CSSはインポートした順に生成され、後にインポートしたCSSの方が優先されるので、
// まず他のコンポーネントや共通CSSをインポートして、その後でコンポーネントごとのCSSをインストールすること
// つまり、styles.module.cssのインポートはインポート文の複数行のうちの最後に置いておくのが安全
import styles from './styles.module.css';

export type ExperienceInfoProps = {
    experiencePoint: number;        // 現在の総経験値ポイント
    addedExperiencePoint: number;   // 今回獲得したポイント

    children?: never;
};

export const calcPercent = (acquiredPointInCurrentLevel:number, requiredPointToNextLevel:number, addedExperiencePoint: number) => {
    // 最大レベルに達して次のレベルまでの必要経験値が形式上0の時は、0を返す
    if (requiredPointToNextLevel === 0) {
        return 0;
    }

    if (acquiredPointInCurrentLevel >= addedExperiencePoint) {
        return Math.min(100, Math.floor(1.0 * acquiredPointInCurrentLevel / requiredPointToNextLevel * 100));
    }

    // 今回でレベルアップした場合には100%の表記にする
    return 100;
}

const ExperienceInfo : React.FC<ExperienceInfoProps> = ({
    experiencePoint,
    addedExperiencePoint,
    children,
}) => {
    const experienceLevel = pointToLevel(experiencePoint);              // 現在のレベルになるのに必要なポイント
    const currentLevelRequiredPoint = levelToPoint(experienceLevel);    // 現在のレベルになるのに必要なポイント
    const nextLevelRequiredPoint = levelToPoint(experienceLevel + 1);   // 次のレベルになるのに必要なポイント

    const acquiredPointInCurrentLevel = experiencePoint - currentLevelRequiredPoint;       // 現在のレベルで獲得したポイント
    const requiredPointToNextLevel = nextLevelRequiredPoint - currentLevelRequiredPoint;   // 現在のレベルで次のレベルになるのに必要なポイント

    const percent = calcPercent(acquiredPointInCurrentLevel, requiredPointToNextLevel, addedExperiencePoint);

    const isLevelUp = (acquiredPointInCurrentLevel >= addedExperiencePoint)? false: true;

    if (requiredPointToNextLevel === 0) { // 最高レベルの場合
        return (
            <div className={ styles['experience-info'] }>
                <p className={styles['experience-content']}>
                現在、最高レベルの レベル{experienceLevel} です！<br/>
                経験値は合計 {experiencePoint}pt です<br/>
                </p>
            </div>
        );
    } else {    // 最高レベルでない場合
        return (
            <div className={ styles['experience-info'] }>
                <p className={styles['experience-content']}>
                    {isLevelUp?
                        <>おめでとう！<br/>レベル{experienceLevel} にレベルアップ！！</>:
                        <>現在、レベル{experienceLevel} です<br/>次のレベルまで、あと {requiredPointToNextLevel - acquiredPointInCurrentLevel}pt ！</>
                    }
                </p>
                <p className={styles['experience-content']}>
                    <Line percent={percent} strokeWidth={4} trailWidth={3} strokeColor="#32CD32" />
                </p>
            </div>
        );
    }
};

export default ExperienceInfo;