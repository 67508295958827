import React from 'react';
import templateProperties from '../../common/template-properties.module.css';
import DoorwayLayout from '../../layouts/DoorwayLayout';
import DoorwayFormLayout from '../../layouts/DoorwayFormLayout';
// CSSはインポートした順に生成され、後にインポートしたCSSの方が優先されるので、
// まず他のコンポーネントや共通CSSをインポートして、その後でコンポーネントごとのCSSをインストールすること
// つまり、styles.module.cssのインポートはインポート文の複数行のうちの最後に置いておくのが安全
import styles from './styles.module.css';

Object.assign(styles, templateProperties);

export type MaintenanceTemplateProps = {
};

const MaintenanceTemplate : React.FC<MaintenanceTemplateProps> = () => {
    return (
        <DoorwayLayout className={ styles['template-properties-local'] }>
            <DoorwayFormLayout title='ただいまメンテナンス中です'>
                <p className={styles['explanation-text'] + ' ' + styles['template-properties-local']}>
                    <strong>
                    2022年9月3日(土)9:00 - 12:00<br/>
                    現在メンテナンス中です<br/>
                    終了次第、サービスを再開します<br/>
                    <br/>
                    ご不便をおかけしますが、<br/>
                    ご理解のほどお願いいたします
                    </strong>
                </p>
            </DoorwayFormLayout>
        </DoorwayLayout>
    );
};

export default MaintenanceTemplate;
