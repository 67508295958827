import React, {
    useRef,
    useEffect,
} from 'react';
import {
    Location,
    useLocation,
} from 'react-router-dom';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
// CSSはインポートした順に生成され、後にインポートしたCSSの方が優先されるので、
// まず他のコンポーネントや共通CSSをインポートして、その後でコンポーネントごとのCSSをインストールすること
// つまり、styles.module.cssのインポートはインポート文の複数行のうちの最後に置いておくのが安全
import styles from './styles.module.css';

export type MaintenanceRedirectorPageProps = {
    maintenanceStartsAt: string;
    children?: never;
};


const MaintenanceRedirectorPage : React.FC<MaintenanceRedirectorPageProps> = ({
    maintenanceStartsAt,
}) => {
    const location = useLocation();
    const url = useRef<undefined|Location>(undefined);
    const navigate = useNavigate();

    useEffect(() => {
        url.current = location;
        const now = moment();
        if (maintenanceStartsAt && now.isAfter(moment(maintenanceStartsAt))) {
            navigate('/maintenance');
            return;
        }
    })

    return (
        <React.Fragment/>
    );
};

export default MaintenanceRedirectorPage;
