/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getUser = /* GraphQL */ `
  query GetUser($sub: String!) {
    getUser(sub: $sub) {
      sub
      email
      iconUrl
      preferredUsername
      gender
      genderOpen
      birthDate
      profession
      prefecture
      rankingOpen
      selfIntroduction
      firstLoginDateTime
      lastLoginDateTime
      activeDays
      experienceLevel
      experiencePoint
      withdrawedDateTime
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $sub: String
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listUsers(
      sub: $sub
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        sub
        email
        iconUrl
        preferredUsername
        gender
        genderOpen
        birthDate
        profession
        prefecture
        rankingOpen
        selfIntroduction
        firstLoginDateTime
        lastLoginDateTime
        activeDays
        experienceLevel
        experiencePoint
        withdrawedDateTime
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const syncUsers = /* GraphQL */ `
  query SyncUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncUsers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        sub
        email
        iconUrl
        preferredUsername
        gender
        genderOpen
        birthDate
        profession
        prefecture
        rankingOpen
        selfIntroduction
        firstLoginDateTime
        lastLoginDateTime
        activeDays
        experienceLevel
        experiencePoint
        withdrawedDateTime
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const getLessonProgress = /* GraphQL */ `
  query GetLessonProgress($id: ID!) {
    getLessonProgress(id: $id) {
      id
      sub
      user {
        sub
        email
        iconUrl
        preferredUsername
        gender
        genderOpen
        birthDate
        profession
        prefecture
        rankingOpen
        selfIntroduction
        firstLoginDateTime
        lastLoginDateTime
        activeDays
        experienceLevel
        experiencePoint
        withdrawedDateTime
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      courseId
      lessonNo
      iteration
      firstCompletedDateTime
      lastCompletedDateTime
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const listLessonProgresses = /* GraphQL */ `
  query ListLessonProgresses(
    $id: ID
    $filter: ModelLessonProgressFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listLessonProgresses(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        sub
        user {
          sub
          email
          iconUrl
          preferredUsername
          gender
          genderOpen
          birthDate
          profession
          prefecture
          rankingOpen
          selfIntroduction
          firstLoginDateTime
          lastLoginDateTime
          activeDays
          experienceLevel
          experiencePoint
          withdrawedDateTime
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        courseId
        lessonNo
        iteration
        firstCompletedDateTime
        lastCompletedDateTime
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const syncLessonProgresses = /* GraphQL */ `
  query SyncLessonProgresses(
    $filter: ModelLessonProgressFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncLessonProgresses(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        sub
        user {
          sub
          email
          iconUrl
          preferredUsername
          gender
          genderOpen
          birthDate
          profession
          prefecture
          rankingOpen
          selfIntroduction
          firstLoginDateTime
          lastLoginDateTime
          activeDays
          experienceLevel
          experiencePoint
          withdrawedDateTime
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        courseId
        lessonNo
        iteration
        firstCompletedDateTime
        lastCompletedDateTime
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const searchLessonProgressesByUserBylastCompletedDateTime = /* GraphQL */ `
  query SearchLessonProgressesByUserBylastCompletedDateTime(
    $sub: String!
    $lastCompletedDateTime: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelLessonProgressFilterInput
    $limit: Int
    $nextToken: String
  ) {
    searchLessonProgressesByUserBylastCompletedDateTime(
      sub: $sub
      lastCompletedDateTime: $lastCompletedDateTime
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        sub
        user {
          sub
          email
          iconUrl
          preferredUsername
          gender
          genderOpen
          birthDate
          profession
          prefecture
          rankingOpen
          selfIntroduction
          firstLoginDateTime
          lastLoginDateTime
          activeDays
          experienceLevel
          experiencePoint
          withdrawedDateTime
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        courseId
        lessonNo
        iteration
        firstCompletedDateTime
        lastCompletedDateTime
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const getTrainingLog = /* GraphQL */ `
  query GetTrainingLog($id: ID!) {
    getTrainingLog(id: $id) {
      id
      sub
      user {
        sub
        email
        iconUrl
        preferredUsername
        gender
        genderOpen
        birthDate
        profession
        prefecture
        rankingOpen
        selfIntroduction
        firstLoginDateTime
        lastLoginDateTime
        activeDays
        experienceLevel
        experiencePoint
        withdrawedDateTime
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      trainingMode
      numberOfMemorization
      numberOfCorrect
      memorizationSec
      recallSec
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const listTrainingLogs = /* GraphQL */ `
  query ListTrainingLogs(
    $id: ID
    $filter: ModelTrainingLogFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listTrainingLogs(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        sub
        user {
          sub
          email
          iconUrl
          preferredUsername
          gender
          genderOpen
          birthDate
          profession
          prefecture
          rankingOpen
          selfIntroduction
          firstLoginDateTime
          lastLoginDateTime
          activeDays
          experienceLevel
          experiencePoint
          withdrawedDateTime
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        trainingMode
        numberOfMemorization
        numberOfCorrect
        memorizationSec
        recallSec
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const syncTrainingLogs = /* GraphQL */ `
  query SyncTrainingLogs(
    $filter: ModelTrainingLogFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncTrainingLogs(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        sub
        user {
          sub
          email
          iconUrl
          preferredUsername
          gender
          genderOpen
          birthDate
          profession
          prefecture
          rankingOpen
          selfIntroduction
          firstLoginDateTime
          lastLoginDateTime
          activeDays
          experienceLevel
          experiencePoint
          withdrawedDateTime
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        trainingMode
        numberOfMemorization
        numberOfCorrect
        memorizationSec
        recallSec
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
