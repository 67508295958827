import React from 'react';
import templateProperties from '../../common/template-properties.module.css';
import DoorwayLayout from '../../layouts/DoorwayLayout';
import DoorwayFormLayout from '../../layouts/DoorwayFormLayout';
import { ErrorMessageBar } from '../../atoms/MessageBar';
import { PrimaryWrapButton, GrayWrapButton } from '../../atoms/Button';
import Textlabel from '../../atoms/Textlabel';
import Textbox from '../../atoms/Textbox';
import PasswordBox from '../../molecules/PasswordBox';
import InnerLink from '../../atoms/InnerLink';
import { SendPasswordPhase } from '../../../redux/constant';
// CSSはインポートした順に生成され、後にインポートしたCSSの方が優先されるので、
// まず他のコンポーネントや共通CSSをインポートして、その後でコンポーネントごとのCSSをインストールすること
// つまり、styles.module.cssのインポートはインポート文の複数行のうちの最後に置いておくのが安全
import styles from './styles.module.css';

Object.assign(styles, templateProperties);

export type SendPasswordTemplateProps = {
    sendPasswordPhase: SendPasswordPhase,

    email: string;
    confirmCode: string,
    newPassword: string,
    showPwdFlag: boolean;

    emailValidationMessage: string;
    confirmCodeValidationMessage: string;
    newPasswordValidationMessage: string;

    changeEmail: (event: React.ChangeEvent<HTMLInputElement>) => void;
    changeConfirmCode: (event: React.ChangeEvent<HTMLInputElement>) => void;
    changeNewPassword: (event: React.ChangeEvent<HTMLInputElement>) => void;
    toggleShowPwdFlag: (event: React.MouseEvent<HTMLButtonElement>) => void;

    submitEmail: (event: React.MouseEvent<HTMLButtonElement>) => void;
    submitNewPassword: (event: React.MouseEvent<HTMLButtonElement>) => void;

    children?: never;
};

const SendPasswordTemplate : React.FC<SendPasswordTemplateProps> = ({
    sendPasswordPhase,
    email,
    confirmCode,
    newPassword,
    showPwdFlag,

    emailValidationMessage,
    confirmCodeValidationMessage,
    newPasswordValidationMessage,

    changeEmail,
    changeConfirmCode,
    changeNewPassword,
    toggleShowPwdFlag,

    submitEmail,
    submitNewPassword,
}) => {

    return (
        <DoorwayLayout className={ styles['template-properties-local'] }>
        { sendPasswordPhase === SendPasswordPhase.sendCode &&
            <DoorwayFormLayout title='パスワード再設定のための確認コード送付'>
            <span>登録したメールアドレスを入力してください。<br/>新パスワード設定に必要な確認コードを送ります。</span>
            { emailValidationMessage && <ErrorMessageBar>{emailValidationMessage}</ErrorMessageBar> }
            <div className={styles['form-component-wrap']}>
              <Textlabel htmlFor="user-email">メールアドレス</Textlabel>
              <Textbox id="user-email" placeholder='email' type="email" value={email} onChange={changeEmail} />
            </div>
            { ( email && !emailValidationMessage ) ? (
              <PrimaryWrapButton className={styles['button']} onClick={submitEmail}>メールアドレスに確認コードを送る</PrimaryWrapButton>
            ) : (
              <GrayWrapButton className={styles['button']} disabled>メールアドレスに確認コードを送る</GrayWrapButton>
            )
            }
          </DoorwayFormLayout>
        }

        { sendPasswordPhase === SendPasswordPhase.newPassword &&
            <DoorwayFormLayout title='新パスワード設定'>
            <p>送られたメール中に指定された確認コードを入力し、<br/>新パスワードを設定してください。</p>
            <div className={styles['form-component-wrap']}>
              <Textlabel htmlFor="user-code">確認コード</Textlabel>
              <Textbox id="user-code" value={confirmCode} onChange={changeConfirmCode} />
            </div>
            { confirmCodeValidationMessage && <ErrorMessageBar>{confirmCodeValidationMessage}</ErrorMessageBar> }
            <div className={styles['form-component-wrap']}>
              <Textlabel htmlFor="user-pw">新パスワード</Textlabel>
              <PasswordBox className={styles['password-box']} id="user-pw" value={newPassword} showPwdFlag={showPwdFlag} onChange={changeNewPassword}  toggleShowPwdFlag={toggleShowPwdFlag}/>
            </div>
            <div className={styles['small-font']}>パスワードは8文字以上の半角英数字で設定してください</div>
            { newPasswordValidationMessage && <ErrorMessageBar>{newPasswordValidationMessage}</ErrorMessageBar> }
            {( newPassword && confirmCode && !newPasswordValidationMessage && !confirmCodeValidationMessage ) ? (
              <PrimaryWrapButton className={styles['button']} onClick={submitNewPassword}>新パスワードを設定する</PrimaryWrapButton>
            ) : (
              <GrayWrapButton className={styles['button']} disabled>新パスワードを設定する</GrayWrapButton>
            )}
          </DoorwayFormLayout>
        }


        { sendPasswordPhase === SendPasswordPhase.postprocess &&
            <DoorwayFormLayout title='パスワード再設定完了'>
            <span>新しいパスワードに再設定しました。<br/>ログイン画面から新しいパスワードでログインしてください。</span>
            <div className={styles['button-wrap']}>
              <InnerLink to='/login'>ログイン画面に移動する</InnerLink>
            </div>
          </DoorwayFormLayout>
        }

        </DoorwayLayout>
    );
};

export default SendPasswordTemplate;
