import React from 'react';
import { useParams } from "react-router-dom";
import CourseTemplate from '../../templates/CourseTemplate';
import {CourseLesson} from '../../../lib/props';
import { UrlLabel } from '../../../redux/constant';
import {
    Course,
    courseConfig,
} from '../../../config/course';
import { useAppSelector } from '../../../redux/app/hooks';
import {
    selectLoginState,
} from '../../../redux/slices/loginSlice';
import {
    selectPaymentState,
} from '../../../redux/slices/paymentSlice';
import {
    makeCourseIdLessonNoTag,
} from '../../../lib/lessonProgress';

export type CoursePageProps = {
    children?: never;
};


const CoursePage : React.FC<CoursePageProps> = () => {
    const {
        isLogin,
        isSocialLogin,
        lessonDoneSet,
        latestCourseId,
        preferredUsername,
    } = useAppSelector(selectLoginState);

    const {
        isPaid,
    } = useAppSelector(selectPaymentState);

    // URLでcourseId指定されている場合はそれを優先、無ければ最後に学習したcourseIdを使う
    const params = useParams();
    const courseId = params.courseId || latestCourseId;

    // TODO
    // to_implement
    // 本来はここでログイン状況や会員画像・会員名を取得する処理が入る
    const fetchProfileImg = () => undefined;
    const profileImg = fetchProfileImg();

    const makeUrlLabel = (c: Course) => {
        // コースに[無料][一部無料]表記を付けるかどうかの判定
        const courseIsFree = c.lessons.every(lesson => lesson.isFree);
        const courseHasFreeLesson = c.lessons.some(lesson => lesson.isFree);
        let courseTitle = c.courseTitle;
        if (!isPaid && courseHasFreeLesson) {
            if (courseIsFree) {
                courseTitle = '[無料]' + courseTitle;
            } else {
                courseTitle = '[一部無料]' + courseTitle;
            }
        }

        return {
            url: `/course-list/${c.courseId}`,
            label: courseTitle,
        };
    };

    const basicCourseList : Array<UrlLabel> = courseConfig.map(c => {
        // 基本コースを判定
        if (!c.isAdvanced) {
            return makeUrlLabel(c);
        } else {
            return {
                url: '',
                label: '',
            }
        }
    }).filter(function(c) {
        return c.url !== '';
    });

    const advancedCourseList : Array<UrlLabel> = courseConfig.map(c => {
        // 応用コースを判定
        if (c.isAdvanced) {
            return makeUrlLabel(c);
        } else {
            return {
                url: '',
                label: '',
            }
        }
    }).filter(function(c) {
        return c.url !== '';
    });

    let courseLessonList : Array<CourseLesson> = [];
    if (courseId) {
        for (let course of courseConfig) {
            if (course.courseId !== courseId) {
                continue;
            }

            courseLessonList = [
                {
                    courseTitle: course.courseTitle,
                    courseColor: course.courseColor,
                    isAdvanced: course.isAdvanced,
                    lessonBoxList: course.lessons.map((lesson) => {
                        const p = makeCourseIdLessonNoTag(course.courseId, lesson.lessonNo);

                        const isReadable = isPaid || lesson.isFree;
                        return {
                            isDone: lessonDoneSet.has(p),
                            isReadable,
                            lessonUrl: `/course-list/${courseId}/${lesson.lessonNo}`,
                            lessonColor: course.courseColor,
                            lessonNo: lesson.lessonNo,
                            lessonTitle: lesson.lessonTitle,
                            lessonText: isReadable ? '' : '有料会員のみ閲覧可能です', // to_implement
                        }
                    }),
                }
            ];
        }
    }

    // コース一覧の初期タブ選択
    let courseDefaultTabIndex : number = 0;
    if (courseId) {
        for (let course of courseConfig) {
            if (course.courseId !== courseId) {
                continue;
            } else {
                // 応用コースならコースリストの応用コースのタブを初期指定
                if (course.isAdvanced === true) courseDefaultTabIndex = 1;
                break;
            }
        }
    }

    return (
        <CourseTemplate
            isLogin={isLogin}
            isPaid={isPaid}
            isSocialLogin={isSocialLogin}
            profileImg={profileImg}
            preferredUsername={preferredUsername}
            basicCourseList={basicCourseList}
            advancedCourseList={advancedCourseList}
            courseLessonList={courseLessonList}
            courseDefaultTabIndex={courseDefaultTabIndex}
        />
    );
};

export default CoursePage;
