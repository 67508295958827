import {
    createSlice,
    PayloadAction,
    // createAsyncThunk,
} from '@reduxjs/toolkit';
import type { RootState } from '../app/store';

interface WithdrawalState {
    reasonForWithdrawal: string;
    modalIsOpen: boolean;
};

export const initialState: WithdrawalState = {
    reasonForWithdrawal: '',
    modalIsOpen: false,
};

export const withdrawalSlice = createSlice({
    name: 'withdrawal',
    initialState,
    reducers: {
        changeReasonForWithdrawal: (state, action: PayloadAction<string>) => {
            state.reasonForWithdrawal = action.payload.slice(0, 1000);
        },
        changeModalIsOpen: (state, action: PayloadAction<boolean>) => {
            state.modalIsOpen = action.payload;
        },
    },
});

export const {
    changeReasonForWithdrawal,
    changeModalIsOpen,
} = withdrawalSlice.actions;

export const selectWithdrawalState = (state: RootState) => {
    return {
        reasonForWithdrawal: state.withdrawal.reasonForWithdrawal,
        modalIsOpen: state.withdrawal.modalIsOpen,
    } as WithdrawalState;
};

export default withdrawalSlice.reducer;



