import React,
{
    useEffect,
} from 'react';
import ChangePasswordTemplate from '../../templates/ChangePasswordTemplate';
import { useAppSelector, useAppDispatch } from '../../../redux/app/hooks';
import { useNavigate } from 'react-router-dom';
import {
    selectLoginState,
} from '../../../redux/slices/loginSlice';
import {
    selectPaymentState,
} from '../../../redux/slices/paymentSlice';
import {
    changePasswordNow,
    changeNowShowPwdFlag,

    changePasswordNew,
    changeNewShowPwdFlag,

    submit,
    selectChangePasswordState,
} from '../../../redux/slices/changePasswordSlice';
// CSSはインポートした順に生成され、後にインポートしたCSSの方が優先されるので、
// まず他のコンポーネントや共通CSSをインポートして、その後でコンポーネントごとのCSSをインストールすること
// つまり、styles.module.cssのインポートはインポート文の複数行のうちの最後に置いておくのが安全
// import styles from './styles.module.css';


export type ChangePasswordPageProps = {
    children?: never;
};

const ChangePasswordPage : React.FC<ChangePasswordPageProps> = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const {
        isLogin,
        isSocialLogin,
        preferredUsername,
    } = useAppSelector(selectLoginState);

    const {
        isPaid,
    } = useAppSelector(selectPaymentState);

    const {
        passwordNow,
        passwordNowValidationMessage,
        nowShowPwdFlag,

        passwordNew,
        passwordNewValidationMessage,
        newShowPwdFlag,

        successMessage,
    } = useAppSelector(selectChangePasswordState);

    const params = {
        passwordNow,
        passwordNowValidationMessage,
        passwordNew,
        passwordNewValidationMessage,
    };

    // ソーシャルログインしている会員がURL直叩きでページを訪れた時に、マイページに遷移させる
    useEffect(() => {
        if (isSocialLogin) {
            navigate('/mypage');
        }
    }, [isSocialLogin, navigate]);

    return (
            <ChangePasswordTemplate
              isLogin={isLogin}
              isPaid={isPaid}
              isSocialLogin={isSocialLogin}
              preferredUsername={preferredUsername}
              successMessage={successMessage}

              passwordNow={passwordNow}
              nowShowPwdFlag={nowShowPwdFlag}
              changePasswordNow={(e) => { dispatch(changePasswordNow(e.target.value)) } }
              toggleNowShowPwdFlag={(e) => { dispatch(changeNowShowPwdFlag(!nowShowPwdFlag)) } }
              passwordNowValidationMessage={passwordNowValidationMessage}

              passwordNew={passwordNew}
              newShowPwdFlag={newShowPwdFlag}
              changePasswordNew={(e) => { dispatch(changePasswordNew(e.target.value)) } }
              toggleNewShowPwdFlag={(e) => { dispatch(changeNewShowPwdFlag(!newShowPwdFlag)) } }
              passwordNewValidationMessage={passwordNewValidationMessage}

              submit={() => dispatch(submit(params))}
            />
    );
};

export default ChangePasswordPage;
