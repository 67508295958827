import React, { ReactNode } from 'react';
// CSSはインポートした順に生成され、後にインポートしたCSSの方が優先されるので、
// まず他のコンポーネントや共通CSSをインポートして、その後でコンポーネントごとのCSSをインストールすること
// つまり、styles.module.cssのインポートはインポート文の複数行のうちの最後に置いておくのが安全
import styles from './styles.module.css';

export type ContainerProps = {
    containerType?: string;
    className?: string;
    children?: ReactNode;
} & JSX.IntrinsicElements['div'];


const Container : React.FC<ContainerProps> = ({
    containerType = '',
    className = '',
    children,
}) => {
    // class属性を設定
    let classString = styles['container'];
    if (containerType) {
        classString = [classString, containerType].join(' ');
    }
    if (className) {
        classString = [classString, className].join(' ');
    }

    return (
        <div className={ classString }>
            {children}
        </div>
    );
};

const containerFactory = (containerType: string) => {
    return (cProps: Omit<ContainerProps, 'containerType'>) => {
        return (
            <Container containerType={containerType} {...cProps} />
        );
    };
}

export default Container;
export const PageContainer = containerFactory(styles['page-content-area']);