import React, { ReactNode } from 'react';
import { imageResources } from '../../../lib/imageResources';
import { CourseColor } from '../../../config/course';
// CSSはインポートした順に生成され、後にインポートしたCSSの方が優先されるので、
// まず他のコンポーネントや共通CSSをインポートして、その後でコンポーネントごとのCSSをインストールすること
// つまり、styles.module.cssのインポートはインポート文の複数行のうちの最後に置いておくのが安全
import styles from './styles.module.css';

export type DomSlideEmptyProps = {
    bgColor: CourseColor;
    children?: ReactNode;
};


const DomSlideEmpty : React.FC<DomSlideEmptyProps> = ({
    bgColor,
    children,
}) => {
    const emptySlideBlueImage = imageResources.get('empty_slide_blue.png')?.path;
    const emptySlideRedImage = imageResources.get('empty_slide_red.png')?.path;
    const emptySlideYellowImage = imageResources.get('empty_slide_yellow.png')?.path;

    let emptySlideBackground;
    if (bgColor === CourseColor.red) {
        emptySlideBackground = emptySlideRedImage;
    } else if (bgColor === CourseColor.yellow) {
        emptySlideBackground = emptySlideYellowImage;
    } else {
        emptySlideBackground = emptySlideBlueImage;
    }

    const style = {
        backgroundImage: `url(${emptySlideBackground})`,
    };

    return (
        <div className={ styles['dom-slide-empty'] } style={style} />
    );
};

export default DomSlideEmpty;
