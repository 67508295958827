import React, { ReactNode } from 'react';
// CSSはインポートした順に生成され、後にインポートしたCSSの方が優先されるので、
// まず他のコンポーネントや共通CSSをインポートして、その後でコンポーネントごとのCSSをインストールすること
// つまり、styles.module.cssのインポートはインポート文の複数行のうちの最後に置いておくのが安全
import styles from './styles.module.css';

export type ContractArticleProps = {
    title: string;
    children?: ReactNode;
};


const ContractArticle : React.FC<ContractArticleProps> = ({
    title,
    children,
}) => {
    return (
        <React.Fragment>
        <h2 className={styles['contract-article-title']}>{title}</h2>
        <p className={styles['contract-article-content']}>
            {children}
        </p>
        </React.Fragment>
    );
};

export default ContractArticle;
