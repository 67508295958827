import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit'
import type { RootState } from '../app/store'
import { Auth } from 'aws-amplify';
import {
    isValidPasswordCharset,
} from '../../lib/textUtils';


interface ChangePasswordState {
    [key: string]: any;

    passwordNow: string;
    nowShowPwdFlag: boolean;
    passwordNowValidationMessage: string;

    passwordNew: string;
    newShowPwdFlag: boolean;
    passwordNewValidationMessage: string;

    successMessage: string;
}

export const initialState: ChangePasswordState = {
    passwordNow: '',
    nowShowPwdFlag: false,
    passwordNowValidationMessage: '',

    passwordNew: '',
    newShowPwdFlag: false,
    passwordNewValidationMessage: '',

    successMessage: '',
}

export interface SubmitParams {
    passwordNow: string;
    passwordNew: string;

    passwordNowValidationMessage: string;
    passwordNewValidationMessage: string;
}

export const submit = createAsyncThunk(
  'changePassword/submit',
    async (submitParams: SubmitParams, thunkAPI) => {
        const {
            passwordNow,
            passwordNew,

            passwordNowValidationMessage,
            passwordNewValidationMessage,
        } = submitParams;

        let isValid = true;
        if (passwordNow === '') {
            await thunkAPI.dispatch(changePasswordSlice.actions.changePasswordNow(''));
            isValid = false;
        }

        if (passwordNew === '') {
            await thunkAPI.dispatch(changePasswordSlice.actions.changePasswordNew(''));
            isValid = false;
        }

        if (passwordNowValidationMessage !== '' || passwordNewValidationMessage !== '') {
            isValid = false;
        }

        if (!isValid) {
            throw new Error('入力に誤りがあります');
        }

        try {
            const user = await Auth.currentAuthenticatedUser();
            await Auth.changePassword(user, passwordNow, passwordNew);
        } catch (e) {
            if (e instanceof Error) {
                const msg = e.message;

                const COGNITO_INCORRECT_PASSWORD = 'Incorrect username or password.';
                if (msg === COGNITO_INCORRECT_PASSWORD) {
                    await thunkAPI.dispatch(changePasswordNowValidationMessage('現在のパスワードが異なります'));
                    return Promise.reject();
                } else {
                    await thunkAPI.dispatch(changePasswordNewValidationMessage(msg));
                    return Promise.reject();
                }
            }
        }

        return Promise.resolve();
    });

export const changePasswordSlice = createSlice({
    name: 'changePassword',
    initialState,
    reducers: {
        changePasswordNow: (state, action: PayloadAction<string>) => {
            const password = action.payload;
            state.passwordNow = password;
            state.successMessage = '';

            if (!isValidPasswordCharset(password)) {
                state.passwordNowValidationMessage = 'パスワードは8文字以上の半角英数字です';
            } else if (state.passwordNew === password) {
                state.passwordNowValidationMessage = '新しいパスワードと現在のパスワードが同じです';
            } else {
                state.passwordNowValidationMessage = '';
            }
        },
        changeNowShowPwdFlag: (state, action: PayloadAction<boolean>) => {
            state.nowShowPwdFlag = action.payload;
        },
        changePasswordNew: (state, action: PayloadAction<string>) => {
            const password = action.payload;
            state.passwordNew = password;
            state.successMessage = '';

            if (!isValidPasswordCharset(password)) {
                state.passwordNewValidationMessage = 'パスワードは8文字以上の半角英数字で設定してください';
                return;
            }

            if (state.passwordNow === password) {
                state.passwordNewValidationMessage = '新しいパスワードと現在のパスワードが同じです';
                return;
            }
            state.passwordNewValidationMessage = '';
        },
        changeNewShowPwdFlag: (state, action: PayloadAction<boolean>) => {
            state.newShowPwdFlag = action.payload;
        },
        changePasswordNowValidationMessage: (state, action: PayloadAction<string>) => {
            state.passwordNowValidationMessage = action.payload;
        },
        changePasswordNewValidationMessage: (state, action: PayloadAction<string>) => {
            state.passwordNewValidationMessage = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(submit.fulfilled, (state, action) => {
            // 新規登録に成功したら状態を初期化する
            // ただし、successMessageだけは成功した旨の文字列を代入する
            for (let k of Object.keys(initialState)) {
                state[k] = initialState[k];
            }

            state.successMessage = 'パスワードを変更しました';
        });
    },
});

export const {
    changePasswordNow,
    changeNowShowPwdFlag,
    changePasswordNew,
    changeNewShowPwdFlag,
    changePasswordNowValidationMessage,
    changePasswordNewValidationMessage,
} = changePasswordSlice.actions;

export const selectChangePasswordState = (state: RootState) => {
    return {
        passwordNow: state.changePassword.passwordNow,
        nowShowPwdFlag: state.changePassword.nowShowPwdFlag,
        passwordNowValidationMessage: state.changePassword.passwordNowValidationMessage,

        passwordNew: state.changePassword.passwordNew,
        newShowPwdFlag: state.changePassword.newShowPwdFlag,
        passwordNewValidationMessage: state.changePassword.passwordNewValidationMessage,

        successMessage: state.changePassword.successMessage,
    };
};

export default changePasswordSlice.reducer;
