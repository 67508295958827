import React from 'react';
// CSSはインポートした順に生成され、後にインポートしたCSSの方が優先されるので、
// まず他のコンポーネントや共通CSSをインポートして、その後でコンポーネントごとのCSSをインストールすること
// つまり、styles.module.cssのインポートはインポート文の複数行のうちの最後に置いておくのが安全
import styles from './styles.module.css';

export type CheckboxProps = {
    id?: string,
    className?: string,
} & JSX.IntrinsicElements['input'];


const Checkbox : React.FC<CheckboxProps> = ({
    id='',
    className='',
    ...props
}) => {
    // class属性を設定
    let classString = styles.checkbox;
    if (className) {
        classString = [classString, className].join(' ');
    }

    return (
        <input
            type="checkbox"
            id={ id }
            className={ classString }
            {...props}
        />
    );
};

export default Checkbox;
