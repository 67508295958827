import React from 'react';
import { CourseColor } from '../../../config/course';
import { CorrectAnswer } from '../../../lib/domSlide';
import { ConversionTableLessonSlide } from '../../../config/course';
import SlidePracticeBackground from '../../atoms/SlidePracticeBackground';
import FontTitle from '../../atoms/FontTitle';
import Textbox from '../../atoms/Textbox';
import { PrimaryButton } from '../../atoms/Button';
// CSSはインポートした順に生成され、後にインポートしたCSSの方が優先されるので、
// まず他のコンポーネントや共通CSSをインポートして、その後でコンポーネントごとのCSSをインストールすること
// つまり、styles.module.cssのインポートはインポート文の複数行のうちの最後に置いておくのが安全
import styles from './styles.module.css';

export type SlidePracticeConversionTableProps = {
    pageIndex: number;
    slideColor: CourseColor;
    isCheckingAnswers: boolean;
    myAnswers: Array<CorrectAnswer>;
    updateMyAnswersWithIndex: (index:number) => (e: React.ChangeEvent<HTMLInputElement>) => void;
    updateIsCheckingAnswersTrue: (e: React.MouseEvent<HTMLButtonElement>) => void;
} & Omit<ConversionTableLessonSlide, 'type'>;


const SlidePracticeConversionTable : React.FC<SlidePracticeConversionTableProps> = ({
    pageIndex,
    slideColor,
    memorizationTitle,
    recallTitle,
    resultTitle = '結果を判定します',
    correctAnswers,
    myAnswers,
    isCheckingAnswers,

    updateMyAnswersWithIndex,
    updateIsCheckingAnswersTrue,
}) => {

    const domSlideClass = styles['dom-slide'];              // スライド領域
    let titleClass = styles['dom-slide-title'];             // タイトル設定
    let answerAreaClass = styles['dom-slide-answerarea'];   // 問題・回答エリア設定
    let itemGroupClass = styles['item-group'];            // 問題 ラベルと回答欄を合わせたエリア
    let itemLabelClass = styles['item-label'];            // 問題ラベル設定 
    let answerColumnClass = styles['input-item'];           // 問題・回答欄設定
    let buttonAreaClass = styles['button-area'];            // ボタン設定

    // 変換の対象が日本語の単語か
    let japaneseWordAnswerFlag = false;
    for(let i = 0; i < correctAnswers.length; i++) {
        if (correctAnswers[i].label?.match(/[^\x01-\x7E]/)) {
            japaneseWordAnswerFlag = true;
            break;
        }
    }
    if (japaneseWordAnswerFlag) {
        itemLabelClass += ' ' + styles['word-label'];
        answerColumnClass += ' ' + styles['word-label'];
    }

    // 問題数が6
    if (correctAnswers.length === 6) {
        titleClass += ' ' + styles['three-in-column'];
        answerAreaClass += ' '+ styles['three-in-column'];
        buttonAreaClass += ' '+ styles['three-in-column'];
    }

    // 回答欄のmaxLengthを取得(正答の最大文字数+2)
    let answerMaxLength = 1;        // 最初は仮に1を設定
    for (let i = 0; i < correctAnswers.length; i++) {
        for (let j = 0; j < correctAnswers[i].words.length; j++) {
            if (answerMaxLength < correctAnswers[i].words[j].length) answerMaxLength = correctAnswers[i].words[j].length;
        }
    }
    answerMaxLength += 2;

    // 結果画面
    if (pageIndex === 1 && isCheckingAnswers) {
        answerColumnClass += ' '+ styles['result'];
    }

    return (
        <div className={ styles['slide-practice-number-conversion-table'] }>
            <SlidePracticeBackground bgColor={slideColor} className={domSlideClass}>
                {/* 記憶画面 */}
                { pageIndex === 0 &&
                <div className={styles['dom-slide-area']}>
                    <FontTitle className={titleClass}>{memorizationTitle}</FontTitle>
                    <div className={styles['dom-slide-content']}>
                        <div className={answerAreaClass}>
                            {correctAnswers.map((correctAnswer, idx) => {
                                return (
                                    <div className={itemGroupClass} key={idx}>
                                        <label className={itemLabelClass}>{correctAnswer.label}</label>
                                        <span className={answerColumnClass}>{correctAnswer.words[0]}</span>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
                }

                {/* 回答画面 */}
                { pageIndex === 1 && !isCheckingAnswers &&
                <div className={styles['dom-slide-area']}>
                    <FontTitle className={titleClass}>{recallTitle}</FontTitle>
                    <div className={styles['dom-slide-content']}>
                        <div className={answerAreaClass}>
                            {correctAnswers.map((correctAnswer, idx) => {
                                return (
                                    <div className={itemGroupClass} key={idx}>
                                        <label className={itemLabelClass}>{correctAnswer.label}</label>
                                        <Textbox
                                        key={idx}
                                        className={answerColumnClass}
                                        value={myAnswers[idx]?.words[0] || []}
                                        onChange={updateMyAnswersWithIndex(idx)}
                                        maxLength={answerMaxLength}
                                    />
                                    </div>
                                );
                            })}
                        </div>
                        <div className={buttonAreaClass}>
                            <PrimaryButton className={styles['button']} onClick={updateIsCheckingAnswersTrue}>答え合わせする</PrimaryButton>
                        </div>
                    </div>
                </div>
                }

                {/* 結果画面 */}
                { pageIndex === 1 && isCheckingAnswers &&
                <div className={styles['dom-slide-area']}>
                    <FontTitle className={titleClass}>{resultTitle}</FontTitle>
                    <div className={styles['dom-slide-content']}>
                        <div className={answerAreaClass}>
                            {correctAnswers.map((correctAnswer, idx) => {
                                const value = myAnswers[idx]?.words[0] || '';
                                const resultClass = (correctAnswer.words.includes(value))? styles['correct'] : styles['incorrect'];
                                return (
                                    <div className={itemGroupClass} key={idx}>
                                        <label className={itemLabelClass}>{correctAnswer.label}</label>
                                        <span className={answerColumnClass+' '+resultClass}>{myAnswers[idx]?.words[0] || ''}</span>
                                        <span className={answerColumnClass+' '+styles['correct-answer']}>{correctAnswer.words[0]}</span>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
                }
            </SlidePracticeBackground>
        </div>
    );
};

export default SlidePracticeConversionTable;
