import React from 'react';
// CSSはインポートした順に生成され、後にインポートしたCSSの方が優先されるので、
// まず他のコンポーネントや共通CSSをインポートして、その後でコンポーネントごとのCSSをインストールすること
// つまり、styles.module.cssのインポートはインポート文の複数行のうちの最後に置いておくのが安全
import styles from './styles.module.css';

export type TextboxProps = {
    className?: string,
    placeholder?: string,
    type?: string,
} & JSX.IntrinsicElements['input']

const Textbox : React.FC<TextboxProps> = ({
    className='',
    placeholder='',
    type='text',
    ...props
}) => {
    // class属性を設定
    let classString = styles.textbox;
    if (className) {
        classString = [classString, className].join(' ');
    }

    return (
            <input
              className={ classString }
              placeholder={ placeholder }
              type={ type }
              {...props}
            />
    );
};

export default Textbox;
