import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit'
import type { RootState } from '../app/store'
import { NavigateFunction } from "react-router";
import { Auth } from "aws-amplify"
import { SignUpParams } from "@aws-amplify/auth/lib-esm/types";
import { SignupPhase } from '../constant';
import {
    isValidPasswordCharset,
} from '../../lib/textUtils';


interface SignupState {
    [key: string]: any;

    email: string;
    password: string;
    showPwdFlag: boolean;
    isCheckedTOS: boolean;
    isCheckedPP: boolean;

    signupPhase: SignupPhase,

    code: string;

    emailValidationMessage: string;
    passwordValidationMessage: string;
    codeValidationMessage: string;
    checkTOSValidationMessage: string;
    checkPPValidationMessage: string;
}

export const initialState: SignupState = {
    email: '',
    password: '',
    showPwdFlag: false,
    isCheckedTOS: false,
    isCheckedPP: false,

    signupPhase: SignupPhase.signup,

    code: '',

    emailValidationMessage: '',
    passwordValidationMessage: '',
    codeValidationMessage: '',
    checkTOSValidationMessage: '',
    checkPPValidationMessage: '',
}

interface SubmitParams {
    email: string;
    password: string;
    emailValidationMessage: string;
    passwordValidationMessage: string;
    isCheckedTOS: boolean;
    isCheckedPP: boolean;
}

interface IChangeEmail {
    email: string;
    emailValidationMessage: string;
}

// https://redux-toolkit.js.org/api/createAsyncThunk
// * (must) Cognitoに存在しない会員の場合、メールが送信され、コード入力画面に遷移すること
// * (must) Cognitoに存在するが未認証会員の場合、メールが送信され、コード入力画面に遷移すること
// * (must) Cognitoに存在する認証済み会員の場合、「エラー:既に登録されている会員です」と表示されること
export const submit = createAsyncThunk(
    'signup/submit',
    async (submitParams: SubmitParams, thunkAPI) => {
        const  {
            email,
            password,
            emailValidationMessage,
            passwordValidationMessage,
            isCheckedTOS,
            isCheckedPP,
        } = submitParams;

        if (!isCheckedTOS || !isCheckedPP || emailValidationMessage !== '' || passwordValidationMessage !== '') {
            return Promise.reject();
        }

        // もしコードが送れたら、それは既にCognitoにデータがある会員ということ
        try {
            return await Auth.resendSignUp(email);
        } catch (e: unknown) {
            if (e instanceof Error) {
                const msg = e.message;
                // alert(msg);
                const COGNITO_USER_ALREADY_CONFIRMED_MESSAGE = 'User is already confirmed.';

                const COGNITO_ATTEMPT_LIMIT_EXCEEDED_MESSAGE = 'Attempt limit exceeded, please try after some time.';

                const COGNITO_USER_NOT_FOUND_MESSAGE = 'Username/client id combination not found.';

                if (msg === COGNITO_USER_ALREADY_CONFIRMED_MESSAGE) {
                    await thunkAPI.dispatch(changeEmailValidationMessage('既に登録されている会員です'));
                    return Promise.reject();
                } else if (msg === COGNITO_ATTEMPT_LIMIT_EXCEEDED_MESSAGE) {
                    await thunkAPI.dispatch(changeEmailValidationMessage('試行回数が上限に達しました。1時間程度待ってからやり直してください'));
                    return Promise.reject();
                } else if (msg === COGNITO_USER_NOT_FOUND_MESSAGE) {
                    // 会員が存在しない場合は、この後の新規登録の処理に進むので
                    // エラー表示せずにスキップ
                } else {
                    await thunkAPI.dispatch(changeEmailValidationMessage(msg));
                    return Promise.reject();
                }
            }
        }

        // コードが送れなかったら、新規登録の会員ということ
        const params: SignUpParams = {
            username: email,
            password,
        };

        let res;
        try {
            res = await Auth.signUp(params);
            // alert(JSON.stringify(res));
        } catch (e) {
            // alert(JSON.stringify(e));
            return Promise.reject(e);
        }

        return res;
    }
);

interface SubmitCodeParams {
    email: string,
    code: string;
    password: string,
    navigate: NavigateFunction,
}


export const submitCode = createAsyncThunk(
  'signup/submitCode',
    async (submitCodeParams: SubmitCodeParams, thunkAPI) => {
        const { email, code, password, navigate } = submitCodeParams;

        try {
            await Auth.confirmSignUp(email, code);
        } catch (e) {
            if (e instanceof Error) {
                const msg = e.message;

                const COGNITO_INVALID_CODE_MESSAGE = 'Invalid verification code provided, please try again.';
                if (msg === COGNITO_INVALID_CODE_MESSAGE) {
                    await thunkAPI.dispatch(changeCodeValidationMessage('確認コードが正しくありません'));
                    return Promise.reject();
                } else {
                    await thunkAPI.dispatch(changeCodeValidationMessage(msg));
                    return Promise.reject();
                }
            }
            return Promise.reject(e);
        }

        let userInfo;
        try {
            await Auth.signIn(email, password);
            userInfo = await Auth.currentUserInfo();
        } catch (e) {
            if (e instanceof Error) {
                const msg = e.message;

                const COGNITO_INVALID_PASSWORD_MESSAGE = 'Incorrect username or password.';
                if (msg === COGNITO_INVALID_PASSWORD_MESSAGE) {
                    await thunkAPI.dispatch(changeCodeValidationMessage('以前に仮登録した時とパスワードが異なります。5秒後にパスワード再設定画面に遷移します'));

                    const sleep = (msec:number) => new Promise((resolve) => setTimeout(resolve, msec));
                    await sleep(5000);
                    await navigate('/forgot-password');

                    return Promise.reject();
                } else {
                    await thunkAPI.dispatch(changeCodeValidationMessage(msg));
                    return Promise.reject();
                }
            }
            return Promise.reject(e);
        }

        await navigate('/setup-profile');

        return userInfo;
    }
)

export const signupSlice = createSlice({
    name: 'signup',
    initialState,
    reducers: {
        changeEmail: (state, action: PayloadAction<IChangeEmail>) => {
            const { email, emailValidationMessage } = action.payload;

            state.email = email;
            state.emailValidationMessage = emailValidationMessage;
        },
        changePassword: (state, action: PayloadAction<string>) => {
            const password = action.payload;

            state.password = password;
            if (!isValidPasswordCharset(password)) {
                state.passwordValidationMessage = 'パスワードは8文字以上の半角英数字で設定してください';
            } else {
                state.passwordValidationMessage = '';
            }
        },
        changeShowPwdFlag: (state, action: PayloadAction<boolean>) => {
            state.showPwdFlag = action.payload;
        },
        changeIsCheckedTOS: (state, action: PayloadAction<boolean>) => {
            const newIsCheckedTOS = action.payload;
            state.isCheckedTOS = newIsCheckedTOS;

            if (newIsCheckedTOS) {
                state.checkTOSValidationMessage = '';
            }
        },
        changeIsCheckedPP: (state, action: PayloadAction<boolean>) => {
            const newIsCheckedPP = action.payload;
            state.isCheckedPP = newIsCheckedPP;

            if (newIsCheckedPP) {
                state.checkPPValidationMessage = '';
            }
        },
        changeCode: (state, action: PayloadAction<string>) => {
            state.code = action.payload;
        },
        changeEmailValidationMessage: (state, action: PayloadAction<string>) => {
            state.emailValidationMessage = action.payload;
        },
        changeCodeValidationMessage: (state, action: PayloadAction<string>) => {
            state.codeValidationMessage = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(submit.fulfilled, (state, action) => {
            state.signupPhase = SignupPhase.confirm;
        });

        builder.addCase(submit.rejected, (state, action) => {
            // console.log(JSON.stringify(action.error.message));

            if (!state.isCheckedTOS) {
                state.checkTOSValidationMessage = '利用規約を読んでチェックしてください';
            }

            if (!state.isCheckedPP) {
                state.checkPPValidationMessage = 'プライバシーポリシーを読んでチェックしてください';
            }
        });

        builder.addCase(submitCode.fulfilled, (state, action) => {
            // 新規登録に成功したら状態を初期化する
            // 一度ログインした後はJWTトークンが発行されるので、
            // stateは初期化しても問題ない。
            // 特に、パスワードは意図せず使われると困るので、忘却しておく

            for (let k of Object.keys(initialState)) {
                state[k] = initialState[k];
            }
        });

        builder.addCase(submitCode.rejected, (state, action) => {
            // alert(JSON.stringify(action.error.message));
        });
    },
})

export const {
    changeEmail,
    changePassword,
    changeShowPwdFlag,
    changeCode,
    changeIsCheckedTOS,
    changeIsCheckedPP,
    changeEmailValidationMessage,
    changeCodeValidationMessage,
} = signupSlice.actions;

export const selectSignupState = (state: RootState) => {
    return {
        email: state.signup.email,
        password: state.signup.password,
        showPwdFlag: state.signup.showPwdFlag,
        isCheckedTOS: state.signup.isCheckedTOS,
        isCheckedPP: state.signup.isCheckedPP,
        signupPhase: state.signup.signupPhase,
        code: state.signup.code,
        emailValidationMessage: state.signup.emailValidationMessage,
        passwordValidationMessage: state.signup.passwordValidationMessage,
        codeValidationMessage: state.signup.codeValidationMessage,
        checkTOSValidationMessage: state.signup.checkTOSValidationMessage,
        checkPPValidationMessage: state.signup.checkPPValidationMessage,
    } as SignupState;
};

export default signupSlice.reducer;
