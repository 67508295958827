import svg_arrow from '../assets/image/svg/arrow.svg';
import svg_comment_image from '../assets/image/svg/comment-image.svg';
import svg_cta_arrow from '../assets/image/svg/cta-arrow.svg';
import svg_download_arrow from '../assets/image/svg/download-arrow.svg';
import svg_front_logo from '../assets/image/svg/front-logo.svg';
import svg_icon_caret_right_red from '../assets/image/svg/icon_caret_right_red.svg';
import svg_icon_course from '../assets/image/svg/icon_course.svg';
import svg_icon_course_on from '../assets/image/svg/icon_course_on.svg';
import svg_icon_done from '../assets/image/svg/icon_done.svg';
import svg_icon_eye_off from '../assets/image/svg/icon_eye_off.svg';
import svg_icon_eye_on from '../assets/image/svg/icon_eye_on.svg';
import svg_icon_fullscreen from '../assets/image/svg/icon_fullscreen.svg';
import svg_icon_training from '../assets/image/svg/icon_training.svg';
import svg_icon_training_alphabet from '../assets/image/svg/icon_training_alphabet.svg';
import svg_icon_training_face from '../assets/image/svg/icon_training_face.svg';
import svg_icon_training_number from '../assets/image/svg/icon_training_number.svg';
import svg_icon_training_on from '../assets/image/svg/icon_training_on.svg';
import svg_icon_training_word from '../assets/image/svg/icon_training_word.svg';
import svg_icon_training_pi from '../assets/image/svg/icon_training_pi.svg';
import svg_icon_training_national_capitals from '../assets/image/svg/icon_training_national_capitals.svg';
import svg_icon_trg from '../assets/image/svg/icon_trg.svg';
import svg_icon_trg02 from '../assets/image/svg/icon_trg02.svg';
import svg_icon_trg03 from '../assets/image/svg/icon_trg03.svg';
import svg_icon_trg04 from '../assets/image/svg/icon_trg04.svg';
import svg_icon_yet from '../assets/image/svg/icon_yet.svg';
import svg_logo_color from '../assets/image/svg/logo_color.svg';
import svg_logo_facebook from '../assets/image/svg/logo_facebook.svg';
import svg_logo_google from '../assets/image/svg/logo_google.svg';
import svg_logo_white from '../assets/image/svg/logo_white.svg';
import svg_recommend_image01 from '../assets/image/svg/recommend-image01.svg';
import svg_recommend_image02 from '../assets/image/svg/recommend-image02.svg';
import png_blue_ptn from '../assets/image/png/blue_ptn.png';
import png_checkmark from '../assets/image/png/checkmark.png';
import png_empty_slide_blue from '../assets/image/png/empty_slide_blue.png';
import png_empty_slide_red from '../assets/image/png/empty_slide_red.png';
import png_empty_slide_yellow from '../assets/image/png/empty_slide_yellow.png';
import png_favicon from '../assets/image/png/favicon.png';
import png_front_logo_white from '../assets/image/png/front-logo-white.png';
import png_illust_pao_front from '../assets/image/png/illust-pao-front.png';
import png_illust_pao_side from '../assets/image/png/illust-pao-side.png';
import png_illust_pao_smile from '../assets/image/png/illust-pao-smile.png';
import png_paozo_01 from '../assets/image/png/paozo_01.png';
import png_paozo_delighted from '../assets/image/png/paozo_delighted.png';
import png_slide_blue from '../assets/image/png/slide_blue.png';
import png_slide_card_06_1 from '../assets/image/png/slide_card_06_1.png';
import png_slide_card_06_2 from '../assets/image/png/slide_card_06_2.png';
import png_slide_card_06_3 from '../assets/image/png/slide_card_06_3.png';
import png_slide_card_06_4 from '../assets/image/png/slide_card_06_4.png';
import png_slide_card_06_5 from '../assets/image/png/slide_card_06_5.png';
import png_slide_card_09_1 from '../assets/image/png/slide_card_09_1.png';
import png_slide_card_09_10 from '../assets/image/png/slide_card_09_10.png';
import png_slide_card_09_2 from '../assets/image/png/slide_card_09_2.png';
import png_slide_card_09_3 from '../assets/image/png/slide_card_09_3.png';
import png_slide_card_09_4 from '../assets/image/png/slide_card_09_4.png';
import png_slide_card_09_5 from '../assets/image/png/slide_card_09_5.png';
import png_slide_card_09_6 from '../assets/image/png/slide_card_09_6.png';
import png_slide_card_09_7 from '../assets/image/png/slide_card_09_7.png';
import png_slide_card_09_8 from '../assets/image/png/slide_card_09_8.png';
import png_slide_card_09_9 from '../assets/image/png/slide_card_09_9.png';
import png_slide_red from '../assets/image/png/slide_red.png';
import png_slide_yellow from '../assets/image/png/slide_yellow.png';
import png_tit_ptn from '../assets/image/png/tit_ptn.png';
import png_top_mv_sp from '../assets/image/png/top_mv_sp.png';
import jpg_bsa_image_sp from '../assets/image/jpg/bsa-image-sp.jpg';
import jpg_bsa_image from '../assets/image/jpg/bsa-image.jpg';
import jpg_comment_bg_sp from '../assets/image/jpg/comment-bg-sp.jpg';
import jpg_comment_bg from '../assets/image/jpg/comment-bg.jpg';
import jpg_comment_image from '../assets/image/jpg/comment-image.jpg';
import jpg_feature_image01 from '../assets/image/jpg/feature-image01.jpg';
import jpg_feature_image02 from '../assets/image/jpg/feature-image02.jpg';
import jpg_feature_image03 from '../assets/image/jpg/feature-image03.jpg';
import jpg_feature_image04 from '../assets/image/jpg/feature-image04.jpg';
import jpg_profile_dummy from '../assets/image/jpg/profile_dummy.jpg';
import jpg_slide_memory_sports_06_1 from '../assets/image/jpg/slide_memory_sports_06_1.jpg';
import jpg_slide_memory_sports_06_2 from '../assets/image/jpg/slide_memory_sports_06_2.jpg';
import jpg_slide_memory_sports_06_3 from '../assets/image/jpg/slide_memory_sports_06_3.jpg';
import jpg_slide_memory_sports_06_4 from '../assets/image/jpg/slide_memory_sports_06_4.jpg';
import jpg_slide_memory_sports_06_5 from '../assets/image/jpg/slide_memory_sports_06_5.jpg';
import jpg_slide_world_heritage_03_1 from '../assets/image/jpg/slide_world_heritage_03_1.jpg';
import jpg_slide_world_heritage_03_2 from '../assets/image/jpg/slide_world_heritage_03_2.jpg';
import jpg_slide_world_heritage_03_3 from '../assets/image/jpg/slide_world_heritage_03_3.jpg';
import jpg_slide_world_heritage_05_1 from '../assets/image/jpg/slide_world_heritage_05_1.jpg';
import jpg_slide_world_heritage_08_1 from '../assets/image/jpg/slide_world_heritage_08_1.jpg';
import jpg_slide_world_heritage_08_2 from '../assets/image/jpg/slide_world_heritage_08_2.jpg';
import jpg_slide_world_heritage_08_3 from '../assets/image/jpg/slide_world_heritage_08_3.jpg';
import jpg_slide_world_heritage_08_4 from '../assets/image/jpg/slide_world_heritage_08_4.jpg';
import jpg_slide_world_heritage_08_5 from '../assets/image/jpg/slide_world_heritage_08_5.jpg';
import jpg_slide_world_heritage_08_6 from '../assets/image/jpg/slide_world_heritage_08_6.jpg';
import jpg_slide_world_heritage_08_7 from '../assets/image/jpg/slide_world_heritage_08_7.jpg';
import jpg_slide_world_heritage_08_8 from '../assets/image/jpg/slide_world_heritage_08_8.jpg';
import mp4_top_movie from '../assets/video/mp4/top_movie.mp4';

export type ImageResource = {
    path: string;
};

export const imageResources: Map<string, ImageResource> = new Map(
    [
        [
            'arrow.svg',
            {
                path: svg_arrow,
            },
        ],
        [
            'comment-image.svg',
            {
                path: svg_comment_image,
            },
        ],
        [
            'cta-arrow.svg',
            {
                path: svg_cta_arrow,
            },
        ],
        [
            'download-arrow.svg',
            {
                path: svg_download_arrow,
            },
        ],
        [
            'front-logo.svg',
            {
                path: svg_front_logo,
            },
        ],
        [
            'icon_caret_right_red.svg',
            {
                path: svg_icon_caret_right_red,
            },
        ],
        [
            'icon_course.svg',
            {
                path: svg_icon_course,
            },
        ],
        [
            'icon_course_on.svg',
            {
                path: svg_icon_course_on,
            },
        ],
        [
            'icon_done.svg',
            {
                path: svg_icon_done,
            },
        ],
        [
            'icon_eye_off.svg',
            {
                path: svg_icon_eye_off,
            },
        ],
        [
            'icon_eye_on.svg',
            {
                path: svg_icon_eye_on,
            },
        ],
        [
            'icon_fullscreen.svg',
            {
                path: svg_icon_fullscreen,
            },
        ],
        [
            'icon_training.svg',
            {
                path: svg_icon_training,
            },
        ],
        [
            'icon_training_alphabet.svg',
            {
                path: svg_icon_training_alphabet,
            },
        ],
        [
            'icon_training_face.svg',
            {
                path: svg_icon_training_face,
            },
        ],
        [
            'icon_training_number.svg',
            {
                path: svg_icon_training_number,
            },
        ],
        [
            'icon_training_on.svg',
            {
                path: svg_icon_training_on,
            },
        ],
        [
            'icon_training_word.svg',
            {
                path: svg_icon_training_word,
            },
        ],
        [
            'icon_training_pi.svg',
            {
                path: svg_icon_training_pi,
            },
        ],
        [
            'icon_training_national_capitals.svg',
            {
                path: svg_icon_training_national_capitals,
            },
        ],
        [
            'icon_trg.svg',
            {
                path: svg_icon_trg,
            },
        ],
        [
            'icon_trg02.svg',
            {
                path: svg_icon_trg02,
            },
        ],
        [
            'icon_trg03.svg',
            {
                path: svg_icon_trg03,
            },
        ],
        [
            'icon_trg04.svg',
            {
                path: svg_icon_trg04,
            },
        ],
        [
            'icon_yet.svg',
            {
                path: svg_icon_yet,
            },
        ],
        [
            'logo_color.svg',
            {
                path: svg_logo_color,
            },
        ],
        [
            'logo_facebook.svg',
            {
                path: svg_logo_facebook,
            },
        ],
        [
            'logo_google.svg',
            {
                path: svg_logo_google,
            },
        ],
        [
            'logo_white.svg',
            {
                path: svg_logo_white,
            },
        ],
        [
            'recommend-image01.svg',
            {
                path: svg_recommend_image01,
            },
        ],
        [
            'recommend-image02.svg',
            {
                path: svg_recommend_image02,
            },
        ],
        [
            'blue_ptn.png',
            {
                path: png_blue_ptn,
            },
        ],
        [
            'checkmark.png',
            {
                path: png_checkmark,
            },
        ],
        [
            'empty_slide_blue.png',
            {
                path: png_empty_slide_blue,
            },
        ],
        [
            'empty_slide_red.png',
            {
                path: png_empty_slide_red,
            },
        ],
        [
            'empty_slide_yellow.png',
            {
                path: png_empty_slide_yellow,
            },
        ],
        [
            'favicon.png',
            {
                path: png_favicon,
            },
        ],
        [
            'front-logo-white.png',
            {
                path: png_front_logo_white,
            },
        ],
        [
            'illust-pao-front.png',
            {
                path: png_illust_pao_front,
            },
        ],
        [
            'illust-pao-side.png',
            {
                path: png_illust_pao_side,
            },
        ],
        [
            'illust-pao-smile.png',
            {
                path: png_illust_pao_smile,
            },
        ],
        [
            'paozo_01.png',
            {
                path: png_paozo_01,
            },
        ],
        [
            'paozo_delighted.png',
            {
                path: png_paozo_delighted,
            },
        ],
        [
            'slide_blue.png',
            {
                path: png_slide_blue,
            },
        ],
        [
            'slide_card_06_1.png',
            {
                path: png_slide_card_06_1,
            },
        ],
        [
            'slide_card_06_2.png',
            {
                path: png_slide_card_06_2,
            },
        ],
        [
            'slide_card_06_3.png',
            {
                path: png_slide_card_06_3,
            },
        ],
        [
            'slide_card_06_4.png',
            {
                path: png_slide_card_06_4,
            },
        ],
        [
            'slide_card_06_5.png',
            {
                path: png_slide_card_06_5,
            },
        ],
        [
            'slide_card_09_1.png',
            {
                path: png_slide_card_09_1,
            },
        ],
        [
            'slide_card_09_10.png',
            {
                path: png_slide_card_09_10,
            },
        ],
        [
            'slide_card_09_2.png',
            {
                path: png_slide_card_09_2,
            },
        ],
        [
            'slide_card_09_3.png',
            {
                path: png_slide_card_09_3,
            },
        ],
        [
            'slide_card_09_4.png',
            {
                path: png_slide_card_09_4,
            },
        ],
        [
            'slide_card_09_5.png',
            {
                path: png_slide_card_09_5,
            },
        ],
        [
            'slide_card_09_6.png',
            {
                path: png_slide_card_09_6,
            },
        ],
        [
            'slide_card_09_7.png',
            {
                path: png_slide_card_09_7,
            },
        ],
        [
            'slide_card_09_8.png',
            {
                path: png_slide_card_09_8,
            },
        ],
        [
            'slide_card_09_9.png',
            {
                path: png_slide_card_09_9,
            },
        ],
        [
            'slide_red.png',
            {
                path: png_slide_red,
            },
        ],
        [
            'slide_yellow.png',
            {
                path: png_slide_yellow,
            },
        ],
        [
            'tit_ptn.png',
            {
                path: png_tit_ptn,
            },
        ],
        [
            'top_mv_sp.png',
            {
                path: png_top_mv_sp,
            },
        ],
        [
            'bsa-image-sp.jpg',
            {
                path: jpg_bsa_image_sp,
            },
        ],
        [
            'bsa-image.jpg',
            {
                path: jpg_bsa_image,
            },
        ],
        [
            'comment-bg-sp.jpg',
            {
                path: jpg_comment_bg_sp,
            },
        ],
        [
            'comment-bg.jpg',
            {
                path: jpg_comment_bg,
            },
        ],
        [
            'comment-image.jpg',
            {
                path: jpg_comment_image,
            },
        ],
        [
            'feature-image01.jpg',
            {
                path: jpg_feature_image01,
            },
        ],
        [
            'feature-image02.jpg',
            {
                path: jpg_feature_image02,
            },
        ],
        [
            'feature-image03.jpg',
            {
                path: jpg_feature_image03,
            },
        ],
        [
            'feature-image04.jpg',
            {
                path: jpg_feature_image04,
            },
        ],
        [
            'profile_dummy.jpg',
            {
                path: jpg_profile_dummy,
            },
        ],
        [
            'slide_memory_sports_06_1.jpg',
            {
                path: jpg_slide_memory_sports_06_1,
            },
        ],
        [
            'slide_memory_sports_06_2.jpg',
            {
                path: jpg_slide_memory_sports_06_2,
            },
        ],
        [
            'slide_memory_sports_06_3.jpg',
            {
                path: jpg_slide_memory_sports_06_3,
            },
        ],
        [
            'slide_memory_sports_06_4.jpg',
            {
                path: jpg_slide_memory_sports_06_4,
            },
        ],
        [
            'slide_memory_sports_06_5.jpg',
            {
                path: jpg_slide_memory_sports_06_5,
            },
        ],
        [
            'slide_world_heritage_03_1.jpg',
            {
                path: jpg_slide_world_heritage_03_1,
            },
        ],
        [
            'slide_world_heritage_03_2.jpg',
            {
                path: jpg_slide_world_heritage_03_2,
            },
        ],
        [
            'slide_world_heritage_03_3.jpg',
            {
                path: jpg_slide_world_heritage_03_3,
            },
        ],
        [
            'slide_world_heritage_05_1.jpg',
            {
                path: jpg_slide_world_heritage_05_1,
            },
        ],
        [
            'slide_world_heritage_08_1.jpg',
            {
                path: jpg_slide_world_heritage_08_1,
            },
        ],
        [
            'slide_world_heritage_08_2.jpg',
            {
                path: jpg_slide_world_heritage_08_2,
            },
        ],
        [
            'slide_world_heritage_08_3.jpg',
            {
                path: jpg_slide_world_heritage_08_3,
            },
        ],
        [
            'slide_world_heritage_08_4.jpg',
            {
                path: jpg_slide_world_heritage_08_4,
            },
        ],
        [
            'slide_world_heritage_08_5.jpg',
            {
                path: jpg_slide_world_heritage_08_5,
            },
        ],
        [
            'slide_world_heritage_08_6.jpg',
            {
                path: jpg_slide_world_heritage_08_6,
            },
        ],
        [
            'slide_world_heritage_08_7.jpg',
            {
                path: jpg_slide_world_heritage_08_7,
            },
        ],
        [
            'slide_world_heritage_08_8.jpg',
            {
                path: jpg_slide_world_heritage_08_8,
            },
        ],
        [
            'top_movie.mp4',
            {
                path: mp4_top_movie,
            },
        ],
    ]
);
