import React, {
    RefObject,
    MutableRefObject,
} from 'react';
import { TrainingMode, TrainingPhase, getMaxCell } from '../../../redux/constant';
import Img from '../../atoms/Img';
// CSSはインポートした順に生成され、後にインポートしたCSSの方が優先されるので、
// まず他のコンポーネントや共通CSSをインポートして、その後でコンポーネントごとのCSSをインストールすること
// つまり、styles.module.cssのインポートはインポート文の複数行のうちの最後に置いておくのが安全
import styles from './styles.module.css';
import { JustClickButton, PrimaryWrapButton } from '../../atoms/Button';

export type TrainingAreaProps = {
    className?: string;
    trainingMode: TrainingMode;                 // トレーニングモード
    trainingPhase: TrainingPhase;               // 画面状態
    memorizationFaceImages?: Array<string>;     // 顔と名前 問題時顔画像
    recallFaceImages?: Array<string>;           // 顔と名前 回答時顔画像
    memorizationNames?: Array<string>;           // 顔と名前 問題時名前
    correctAnswers: Array<string>;              // 問題＝正解(単語、アルファベット、数字)正解(顔と名前)
    correctNationalCapitals?: Array<Array<string>> // 国と首都 正解
    myAnswers: Array<string>;                   // 会員の回答
    recallInputRefs?: MutableRefObject<RefObject<HTMLInputElement>[]>;
    currentPage: number;

    changePrePage: (index: number) => (e: React.MouseEvent<HTMLButtonElement>) => void;
    changeNextPage: (currentPage: number, maxPage: number) => (e: React.MouseEvent<HTMLButtonElement>) => void;
    changeMyAnswer: (index: number) => (e: React.ChangeEvent<HTMLInputElement>) => void;
    moveFocus: (index: number) => (e: React.KeyboardEvent<HTMLInputElement>) => void;
};


const TrainingArea: React.FC<TrainingAreaProps> = ({
    className = '',
    trainingMode,
    trainingPhase,
    memorizationFaceImages = [],
    recallFaceImages = [],
    correctNationalCapitals = [],
    memorizationNames,
    correctAnswers,
    myAnswers,
    recallInputRefs,
    currentPage,

    changePrePage,
    changeNextPage,
    changeMyAnswer,
    moveFocus,
}) => {
    // class属性を設定
    let classString = styles['training-area'];
    if (className) {
        classString = [classString, className].join(' ');
    }

    // 1ページ当たりの最大表示数
    const maxCell = getMaxCell(trainingMode);

    let maxPage = 1;
    // 最大のページ数を算出
    if (trainingMode === TrainingMode.nationalCapitals) {
        maxPage = Math.ceil(correctNationalCapitals.length / maxCell);
    } else {
        maxPage = Math.ceil(correctAnswers.length / maxCell);
    }

    return (
        <div className={classString}>
            {trainingPhase === TrainingPhase.memorization &&
                <><div className={styles['training-input-area']}>
                    {trainingMode === TrainingMode.word &&
                        correctAnswers.slice((currentPage - 1) * maxCell, currentPage * maxCell).map((correctAnswer, index) => (
                            <div className={styles['training-input-group'] + ' ' + styles['word']} key={index + (currentPage - 1) * maxCell}>
                                <div className={styles['training-input-item'] + ' ' + styles['word']}><span>{correctAnswer}</span></div>
                            </div>
                        ))}
                    {trainingMode === TrainingMode.alphabet &&
                        correctAnswers.slice((currentPage - 1) * maxCell, currentPage * maxCell).map((correctAnswer, index) => (
                            <div className={styles['training-input-group'] + ' ' + styles['alphabet']} key={index + (currentPage - 1) * maxCell}>
                                <div className={styles['training-input-item'] + ' ' + styles['alphabet']}><span>{correctAnswer}</span></div>
                            </div>
                        ))}
                    {trainingMode === TrainingMode.number &&
                        correctAnswers.slice((currentPage - 1) * maxCell, currentPage * maxCell).map((correctAnswer, index) => (
                            <React.Fragment key={index}>
                                {index % 2 === 1 &&
                                    <div className={styles['training-input-group'] + ' ' + styles['number']}>
                                        <div className={styles['training-input-item'] + ' ' + styles['number']}><span>{correctAnswers[index - 1 + (currentPage - 1) * maxCell]}</span></div>
                                        <div className={styles['training-input-item'] + ' ' + styles['number']}><span>{correctAnswer}</span></div>
                                    </div>}
                            </React.Fragment>
                        ))}
                    {trainingMode === TrainingMode.nameAndFace && memorizationNames &&
                        memorizationNames.slice((currentPage - 1) * maxCell, currentPage * maxCell).map((memorizationName, index) => (
                            <div className={styles['training-input-group'] + ' ' + styles['name-and-face']} key={index + (currentPage - 1) * maxCell}>
                                <div className={styles['training-input-item'] + ' ' + styles['name-and-face']}>
                                    <Img fileName={memorizationFaceImages[index]} alt="" className={styles['training-face-photo']} />
                                    <span>{memorizationName}</span>
                                </div>
                            </div>
                        ))}
                    {trainingMode === TrainingMode.circleRatio &&
                        <>
                            {currentPage === 1 &&
                                <div className={styles['group-for-circle-ratio-3'] + ' ' + styles['number']}>
                                    <div className={styles['item-for-circle-ratio-3'] + ' ' + styles['number']}><span>3.</span></div>
                                </div>
                            }
                            {correctAnswers.slice((currentPage - 1) * maxCell, currentPage * maxCell).map((correctAnswer, index) => (
                                <React.Fragment key={index}>
                                    {index % 2 === 1 &&
                                        <div className={styles['training-input-group'] + ' ' + styles['number']}>
                                            <div className={styles['circle-ratio-digits-and-item'] + ' ' + styles['flex-flow-column']}>
                                                <div className={styles['circle-ratio-digits']}><span>{index + (currentPage - 1) * maxCell}</span></div>
                                                <div className={styles['training-input-item'] + ' ' + styles['number']}><span>{correctAnswers[index - 1 + (currentPage - 1) * maxCell]}</span></div>
                                            </div>
                                            <div className={styles['circle-ratio-digits-and-item'] + ' ' + styles['flex-flow-column']}>
                                                <div className={styles['circle-ratio-digits']}><span>{index + 1 + (currentPage - 1) * maxCell}</span></div>
                                                <div className={styles['training-input-item'] + ' ' + styles['number']}><span>{correctAnswer}</span></div>
                                            </div>
                                        </div>
                                    }
                                </React.Fragment>
                            ))}
                        </>}
                    {trainingMode === TrainingMode.nationalCapitals && correctNationalCapitals &&
                        correctNationalCapitals.slice((currentPage - 1) * maxCell, currentPage * maxCell).map((correctAnswer, index) => (
                            <div className={styles['training-input-group'] + ' ' + styles['name-and-face']} key={index + (currentPage - 1) * maxCell}>
                                <div className={styles['training-input-item'] + ' ' + styles['name-and-face']}>
                                    <div className={styles['training-input-item'] + ' ' + styles['number']}><span>{correctAnswer[0]}</span></div>
                                    <div className={styles['training-input-item'] + ' ' + styles['number']}><span>{correctAnswer[1]}</span></div>
                                </div>
                            </div>
                        ))}
                </div>
                <div className={styles['paging-area']}>
                        {maxPage !== 1 && (
                            <div className={styles['paging-group']}>
                                <JustClickButton className={styles['btn-before'] + ' ' + (currentPage === 1 ? styles['dead-end'] : '')} onClick={changePrePage(currentPage)}>
                                    <Img fileName="icon_trg03.svg" alt="前へ" />
                                </JustClickButton>
                                <div className={styles['paging-item']}><span>{currentPage}/{maxPage}</span></div>
                                <JustClickButton className={styles['btn-next'] + ' ' + (currentPage === maxPage ? styles['dead-end'] : '')} onClick={changeNextPage(currentPage, maxPage)}>
                                    <Img fileName="icon_trg03.svg" alt="次へ" />
                                </JustClickButton>
                            </div>
                        )}
                    </div></>
            }

            {trainingPhase === TrainingPhase.recall &&
                <><div className={styles['training-input-area']}>
                    {trainingMode === TrainingMode.word &&
                        myAnswers.slice((currentPage - 1) * maxCell, currentPage * maxCell).map((myAnswer, index) => {

                            const autoFocus = index === 0;

                            return (
                                <div className={styles['training-input-group']} key={index + (currentPage - 1) * maxCell}>
                                    <div className={styles['training-input-item'] + ' ' + styles['word']}><input type="text" id={"my-answer-" + String(index + (currentPage - 1) * maxCell)} value={myAnswer} onChange={changeMyAnswer(index + (currentPage - 1) * maxCell)} onKeyDown={moveFocus(index)} autoFocus={autoFocus} ref={recallInputRefs?.current[index + (currentPage - 1) * maxCell]} maxLength={7} autoComplete="off" /></div>
                                </div>
                            );
                        })}
                    {trainingMode === TrainingMode.alphabet &&
                        myAnswers.slice((currentPage - 1) * maxCell, currentPage * maxCell).map((myAnswer, index) => {

                            const autoFocus = index === 0;
                            return (
                                <React.Fragment key={index + (currentPage - 1) * maxCell}>
                                    <div className={styles['training-input-group'] + ' ' + styles['alphabet']} key={index + (currentPage - 1) * maxCell}>
                                        <div className={styles['training-input-item'] + ' ' + styles['alphabet']}><input type="text" id={"my-answer-" + String(index + (currentPage - 1) * maxCell)} value={myAnswer} onChange={changeMyAnswer(index + (currentPage - 1) * maxCell)} onKeyDown={moveFocus(index)} maxLength={1} autoFocus={autoFocus} ref={recallInputRefs?.current[index + (currentPage - 1) * maxCell]} autoComplete="off" /></div>
                                    </div>
                                </React.Fragment>
                            );
                        })}
                    {trainingMode === TrainingMode.number &&
                        myAnswers.slice((currentPage - 1) * maxCell, currentPage * maxCell).map((myAnswer, index) => {
                            // レンダリング時にフォーカスが先頭のマス目に当たるようにする
                            // ただし、2個ずつセットでFragment内に入れる関係で、indexは1の時が最初。
                            // (index=0,index=1)の組について、index=1の時に描画するため。
                            const autoFocus = index === 1;

                            return (
                                <React.Fragment key={index + (currentPage - 1) * maxCell}>
                                    {index % 2 === 1 &&
                                        <div className={styles['training-input-group'] + ' ' + styles['number']}>
                                            <div className={styles['training-input-item'] + ' ' + styles['number']}><input type="text" id={"my-answer-" + String(index - 1 + (currentPage - 1) * maxCell)} value={myAnswers[index - 1 + (currentPage - 1) * maxCell]} onChange={changeMyAnswer(index - 1 + (currentPage - 1) * maxCell)} onKeyDown={moveFocus(index - 1)} maxLength={1} autoFocus={autoFocus} ref={recallInputRefs?.current[index - 1 + (currentPage - 1) * maxCell]} autoComplete="off" /></div>
                                            <div className={styles['training-input-item'] + ' ' + styles['number']}><input type="text" id={"my-answer-" + String(index + (currentPage - 1) * maxCell)} value={myAnswer} onChange={changeMyAnswer(index + (currentPage - 1) * maxCell)} onKeyDown={moveFocus(index)} maxLength={1} ref={recallInputRefs?.current[index + (currentPage - 1) * maxCell]} autoComplete="off" /></div>
                                        </div>}
                                </React.Fragment>
                            );
                        })}
                    {trainingMode === TrainingMode.nameAndFace &&
                        myAnswers.slice((currentPage - 1) * maxCell, currentPage * maxCell).map((myAnswer, index) => {

                            const autoFocus = index === 0;

                            return (
                                <div className={styles['training-input-group'] + ' ' + styles['name-and-face']} key={index + (currentPage - 1) * maxCell}>
                                    <div className={styles['training-input-item'] + ' ' + styles['name-and-face']}>
                                        <Img fileName={recallFaceImages[index + (currentPage - 1) * maxCell]} alt="" className={styles['training-face-photo']} />
                                        <input type="text" id={"my-answer-" + String(index + (currentPage - 1) * maxCell)} value={myAnswer} onChange={changeMyAnswer(index + (currentPage - 1) * maxCell)} onKeyDown={moveFocus(index)} autoFocus={autoFocus} maxLength={7} ref={recallInputRefs?.current[index + (currentPage - 1) * maxCell]} autoComplete="off" />
                                    </div>
                                </div>
                            );
                        })}
                    {trainingMode === TrainingMode.circleRatio &&
                        myAnswers.slice((currentPage - 1) * maxCell, currentPage * maxCell).map((myAnswer, index) => {
                            // レンダリング時にフォーカスが先頭のマス目に当たるようにする
                            // ただし、2個ずつセットでFragment内に入れる関係で、indexは1の時が最初。
                            // (index=0,index=1)の組について、index=1の時に描画するため。
                            const autoFocus = index === 1;

                            return (
                                <React.Fragment key={index + (currentPage - 1) * maxCell}>
                                    {index === 0 && currentPage === 1 &&
                                        <div className={styles['group-for-circle-ratio-3'] + ' ' + styles['number']}>
                                            <div className={styles['item-for-circle-ratio-3'] + ' ' + styles['number']}><span>3.</span></div>
                                        </div>}
                                    {index % 2 === 1 &&
                                        <div className={styles['training-input-group'] + ' ' + styles['number']}>
                                            <div className={styles['circle-ratio-digits-and-item'] + ' ' + styles['flex-flow-column']}>
                                                <div className={styles['circle-ratio-digits']}><span>{index + (currentPage - 1) * maxCell}</span></div>
                                                <div className={styles['training-input-item'] + ' ' + styles['number']}><input type="text" id={"my-answer-" + String(index - 1 + (currentPage - 1) * maxCell)} value={myAnswers[index - 1 + (currentPage - 1) * maxCell]} onChange={changeMyAnswer(index - 1 + (currentPage - 1) * maxCell)} onKeyDown={moveFocus(index - 1)} maxLength={1} autoFocus={autoFocus} ref={recallInputRefs?.current[index - 1 + (currentPage - 1) * maxCell]} autoComplete="off" /></div>
                                            </div>
                                            <div className={styles['circle-ratio-digits-and-item'] + ' ' + styles['flex-flow-column']}>
                                                <div className={styles['circle-ratio-digits']}><span>{index + 1 + (currentPage - 1) * maxCell}</span></div>
                                                <div className={styles['training-input-item'] + ' ' + styles['number']}><input type="text" id={"my-answer-" + String(index + (currentPage - 1) * maxCell)} value={myAnswer} onChange={changeMyAnswer(index + (currentPage - 1) * maxCell)} onKeyDown={moveFocus(index)} maxLength={1} ref={recallInputRefs?.current[index + (currentPage - 1) * maxCell]} autoComplete="off" /></div>
                                            </div>
                                        </div>
                                    }
                                </React.Fragment>
                            );
                        })}
                    {trainingMode === TrainingMode.nationalCapitals &&
                        myAnswers.slice((currentPage - 1) * maxCell, currentPage * maxCell).map((myAnswer, index) => {

                            const autoFocus = index === 0;

                            return (
                                <div className={styles['training-input-group'] + ' ' + styles['national-capitals'] + ' ' + styles['flex-flow-column']} key={index + (currentPage - 1) * maxCell}>
                                        <div className={styles['training-input-item'] + ' ' + styles['national-capitals']}><span>{correctNationalCapitals[index + (currentPage - 1) * maxCell][0]}</span></div>
                                        <div className={styles['training-input-item'] + ' ' + styles['national-capitals']}><input type="text" id={"my-answer-" + String(index + (currentPage - 1) * maxCell)} value={myAnswer} onChange={changeMyAnswer(index + (currentPage - 1) * maxCell)} onKeyDown={moveFocus(index)} autoFocus={autoFocus} ref={recallInputRefs?.current[index + (currentPage - 1) * maxCell]} autoComplete="off" /></div>
                                </div>
                            );
                        })}
                </div><div className={styles['paging-area']}>
                        {maxPage !== 1 && (
                            <div className={styles['paging-group']}>
                                <JustClickButton className={styles['btn-before'] + ' ' + (currentPage === 1 ? styles['dead-end'] : '')} onClick={changePrePage(currentPage)}>
                                    <Img fileName="icon_trg03.svg" alt="前へ" />
                                </JustClickButton>
                                <div className={styles['paging-item']}><span>{currentPage}/{maxPage}</span></div>
                                <JustClickButton className={styles['btn-next'] + ' ' + (currentPage === maxPage ? styles['dead-end'] : '')} onClick={changeNextPage(currentPage, maxPage)}>
                                    <Img fileName="icon_trg03.svg" alt="次へ" />
                                </JustClickButton>
                            </div>
                        )}
                    </div></>
            }
            {trainingPhase === TrainingPhase.result &&
                <><div className={styles['training-input-area']}>
                    {trainingMode === TrainingMode.word &&
                        myAnswers.slice((currentPage - 1) * maxCell, currentPage * maxCell).map((myAnswer, index) => (
                            <div className={styles['training-input-group'] + ' ' + styles['flex-flow-column']} key={index + (currentPage - 1) * maxCell}>
                                <div className={styles['training-input-item'] + ' ' + styles['word']}><span className={myAnswer === correctAnswers[index + (currentPage - 1) * maxCell] ? styles['correct'] : styles['incorrect']}>{myAnswer}</span></div>
                                <div className={styles['training-input-item'] + ' ' + styles['word']}><span className={styles['correct-answer']}>{correctAnswers[index + (currentPage - 1) * maxCell]}</span></div>
                            </div>
                        ))}
                    {trainingMode === TrainingMode.alphabet &&
                        myAnswers.slice((currentPage - 1) * maxCell, currentPage * maxCell).map((myAnswer, index) => (
                            <div className={styles['training-input-group'] + ' ' + styles['alphabet'] + ' ' + styles['flex-flow-column']} key={index + (currentPage - 1) * maxCell}>
                                <div className={styles['training-input-item'] + ' ' + styles['alphabet']}><span className={myAnswer === correctAnswers[index + (currentPage - 1) * maxCell] ? styles['correct'] : styles['incorrect']}>{myAnswer}</span></div>
                                <div className={styles['training-input-item'] + ' ' + styles['alphabet']}><span className={styles['correct-answer']}>{correctAnswers[index + (currentPage - 1) * maxCell]}</span></div>
                            </div>
                        ))}
                    {trainingMode === TrainingMode.number &&
                        myAnswers.slice((currentPage - 1) * maxCell, currentPage * maxCell).map((myAnswer, index) => (
                            <React.Fragment key={index}>
                                {index % 2 === 1 &&
                                    <div className={styles['training-input-group'] + ' ' + styles['number'] + ' ' + styles['flex-flow-column']} key={index + (currentPage - 1) * maxCell}>
                                        <div className={styles['inner-group']}>
                                            <div className={styles['training-input-item'] + ' ' + styles['number']}><span className={myAnswers[index - 1 + (currentPage - 1) * maxCell] === correctAnswers[index - 1 + (currentPage - 1) * maxCell] ? styles['correct'] : styles['incorrect']}>{myAnswers[index - 1 + (currentPage - 1) * maxCell]}</span></div>
                                            <div className={styles['training-input-item'] + ' ' + styles['number']}><span className={myAnswer === correctAnswers[index + (currentPage - 1) * maxCell] ? styles['correct'] : styles['incorrect']}>{myAnswer}</span></div>
                                        </div>
                                        <div className={styles['inner-group']}>
                                            <div className={styles['training-input-item'] + ' ' + styles['number']}><span className={styles['correct-answer']}>{correctAnswers[index - 1 + (currentPage - 1) * maxCell]}</span></div>
                                            <div className={styles['training-input-item'] + ' ' + styles['number']}><span className={styles['correct-answer']}>{correctAnswers[index + (currentPage - 1) * maxCell]}</span></div>
                                        </div>
                                    </div>}
                            </React.Fragment>
                        ))}
                    {trainingMode === TrainingMode.nameAndFace &&
                        myAnswers.slice((currentPage - 1) * maxCell, currentPage * maxCell).map((myAnswer, index) => (
                            <div className={styles['training-input-group'] + ' ' + styles['name-and-face']} key={index + (currentPage - 1) * maxCell}>
                                <div className={styles['training-input-item'] + ' ' + styles['name-and-face']}>
                                    <Img fileName={recallFaceImages[index + (currentPage - 1) * maxCell]} alt="" className={styles['training-face-photo']} />
                                    <span className={myAnswer === correctAnswers[index + (currentPage - 1) * maxCell] ? styles['correct'] : styles['incorrect']}>{myAnswer}</span>
                                    <span className={styles['correct-answer']}>{correctAnswers[index + (currentPage - 1) * maxCell]}</span>
                                </div>
                            </div>
                        ))}
                    {trainingMode === TrainingMode.circleRatio &&
                        myAnswers.slice((currentPage - 1) * maxCell, currentPage * maxCell).map((myAnswer, index) => (
                            <React.Fragment key={index}>
                                {index === 0 && currentPage === 1 &&
                                    <div className={styles['group-for-circle-ratio-3'] + ' ' + styles['number']}>
                                        <div className={styles['item-for-circle-ratio-3'] + ' ' + styles['number']}><span>3.</span></div>
                                    </div>}
                                {index % 2 === 1 &&
                                    <div className={styles['training-input-group'] + ' ' + styles['number'] + ' ' + styles['flex-flow-column']} key={index + (currentPage - 1) * maxCell}>
                                        <div className={styles['inner-group']}>
                                            <div className={styles['circle-ratio-digits-and-item'] + ' ' + styles['flex-flow-column']}>
                                                <div className={styles['circle-ratio-digits']}><span>{index + (currentPage - 1) * maxCell}</span></div>
                                                <div className={styles['training-input-item'] + ' ' + styles['number']}><span className={myAnswers[index - 1 + (currentPage - 1) * maxCell] === correctAnswers[index - 1 + (currentPage - 1) * maxCell] ? styles['correct'] : styles['incorrect']}>{myAnswers[index - 1 + (currentPage - 1) * maxCell]}</span></div>
                                            </div>
                                            <div className={styles['circle-ratio-digits-and-item'] + ' ' + styles['flex-flow-column']}>
                                                <div className={styles['circle-ratio-digits']}><span>{index + 1 + (currentPage - 1) * maxCell}</span></div>
                                                <div className={styles['training-input-item'] + ' ' + styles['number']}><span className={myAnswer === correctAnswers[index + (currentPage - 1) * maxCell] ? styles['correct'] : styles['incorrect']}>{myAnswer}</span></div>
                                            </div>
                                        </div>
                                        <div className={styles['inner-group']}>
                                            <div className={styles['training-input-item'] + ' ' + styles['number']}><span className={styles['correct-answer']}>{correctAnswers[index - 1 + (currentPage - 1) * maxCell]}</span></div>
                                            <div className={styles['training-input-item'] + ' ' + styles['number']}><span className={styles['correct-answer']}>{correctAnswers[index + (currentPage - 1) * maxCell]}</span></div>
                                        </div>
                                    </div>}
                            </React.Fragment>
                        ))}
                    {trainingMode === TrainingMode.nationalCapitals &&
                        myAnswers.slice((currentPage - 1) * maxCell, currentPage * maxCell).map((myAnswer, index) => (
                            <div className={styles['training-input-group'] + ' ' + styles['national-capitals'] + ' ' + styles['flex-flow-column']} key={index + (currentPage - 1) * maxCell}>
                                    <div className={styles['training-input-item'] + ' ' + styles['national-capitals']}><span>{correctNationalCapitals[index + (currentPage - 1) * maxCell][0]}</span></div>
                                    <div className={styles['training-input-item'] + ' ' + styles['national-capitals']}><span className={myAnswer === correctNationalCapitals[index + (currentPage - 1) * maxCell][1] ? styles['correct'] : styles['incorrect']}>{myAnswer}</span></div>
                                    <div className={styles['training-input-item'] + ' ' + styles['national-capitals']}><span className={styles['correct-answer']}>{correctNationalCapitals[index + (currentPage - 1) * maxCell][1]}</span></div>
                            </div>
                        ))}
                </div><div className={styles['paging-area']}>
                        {maxPage !== 1 && (
                            <div className={styles['paging-group']}>
                                <JustClickButton className={styles['btn-before'] + ' ' + (currentPage === 1 ? styles['dead-end'] : '')} onClick={changePrePage(currentPage)}>
                                    <Img fileName="icon_trg03.svg" alt="前へ" />
                                </JustClickButton>
                                <div className={styles['paging-item']}><span>{currentPage}/{maxPage}</span></div>
                                <JustClickButton className={styles['btn-next'] + ' ' + (currentPage === maxPage ? styles['dead-end'] : '')} onClick={changeNextPage(currentPage, maxPage)}>
                                    <Img fileName="icon_trg03.svg" alt="次へ" />
                                </JustClickButton>
                            </div>
                        )}
                    </div></>
            }
        </div >
    );
};

export default TrainingArea;
