import React from 'react';
import templateProperties from '../../common/template-properties.module.css';
import HeaderContainer from '../../organisms/HeaderContainer';
import { UrlLabel } from '../../../redux/constant';
import PageTitleArea from '../../layouts/PageTitleArea';
import BreadcrumbBar from '../../organisms/BreadcrumbBar';
import { PageContainer } from '../../layouts/Container';
import { SuccessMessageBar, ErrorMessageBar } from '../../atoms/MessageBar';
import PasswordBox from '../../molecules/PasswordBox';
import { PrimaryWrapButton, GrayWrapButton } from '../../atoms/Button';
import Footer from '../../organisms/Footer';
// CSSはインポートした順に生成され、後にインポートしたCSSの方が優先されるので、
// まず他のコンポーネントや共通CSSをインポートして、その後でコンポーネントごとのCSSをインストールすること
// つまり、styles.module.cssのインポートはインポート文の複数行のうちの最後に置いておくのが安全
import styles from './styles.module.css';

Object.assign(styles, templateProperties);

const ChangePasswordBL: Array<UrlLabel> = [
    { url: '/mypage', label: 'マイページ' },
    { url: '', label: 'パスワード変更' },
];

export type ChangePasswordTemplateProps = {
    isLogin: boolean;             // ログイン状態
    isPaid: boolean;              // 有料会員かどうか
    isSocialLogin: boolean;       // SNSログイン会員かどうか
    profileImg?: string;          // 会員画像
    preferredUsername? : string;  // 会員名

    successMessage: string;

    passwordNow: string;
    nowShowPwdFlag: boolean;
    changePasswordNow: (event: React.ChangeEvent<HTMLInputElement>) => void;
    toggleNowShowPwdFlag: (event: React.MouseEvent<HTMLButtonElement>) => void;
    passwordNowValidationMessage: string;

    passwordNew: string;
    newShowPwdFlag: boolean;
    changePasswordNew: (event: React.ChangeEvent<HTMLInputElement>) => void;
    toggleNewShowPwdFlag: (event: React.MouseEvent<HTMLButtonElement>) => void;
    passwordNewValidationMessage: string;

    submit: (event: React.MouseEvent<HTMLButtonElement>) => void;
};


const ChangePasswordTemplate : React.FC<ChangePasswordTemplateProps> = ({
    isLogin,
    isPaid,
    isSocialLogin,
    profileImg,
    preferredUsername,

    successMessage,

    passwordNow,
    nowShowPwdFlag,
    changePasswordNow,
    toggleNowShowPwdFlag,
    passwordNowValidationMessage,

    passwordNew,
    newShowPwdFlag,
    changePasswordNew,
    toggleNewShowPwdFlag,
    passwordNewValidationMessage,

    submit,
}) => {
    return (
        <div className={ styles['change-password-template'] + ' ' + styles['template-properties-local'] } >
          <HeaderContainer isLogin={isLogin} isPaid={isPaid} isSocialLogin={isSocialLogin} profileImg={profileImg} preferredUsername={preferredUsername} />
          <PageTitleArea>パスワード変更</PageTitleArea>
          <BreadcrumbBar UrlLabels={ChangePasswordBL} />
          <PageContainer className={styles['form-area']}>
            {successMessage && <SuccessMessageBar>{successMessage}</SuccessMessageBar>}

            <div className={styles['explanation-text']}>
                確認のために現在のパスワードを入力して、<br/>新しいパスワードを設定してください。
            </div>

            <div className={styles["form-item"]}>
                <label className={styles["form-item-label"]} htmlFor="password-now">現在のパスワード</label>
                    <PasswordBox className={styles['password-box']} id='password-now' placeholder='現在のパスワードを入力してください' value={passwordNow} onChange={changePasswordNow}  showPwdFlag={nowShowPwdFlag} toggleShowPwdFlag={toggleNowShowPwdFlag} />
            </div>
            {passwordNowValidationMessage && <ErrorMessageBar>{passwordNowValidationMessage}</ErrorMessageBar>}

            <div className={styles["form-item"]}>
                <label className={styles["form-item-label"]} htmlFor="password-new">新しいパスワード</label>
                <PasswordBox className={styles['password-box']} id='password-new' placeholder='新しいパスワードを入力してください' value={passwordNew} onChange={changePasswordNew}  showPwdFlag={newShowPwdFlag} toggleShowPwdFlag={toggleNewShowPwdFlag} />
            </div>
            <div className={styles['notation-text']}>パスワードは8文字以上の半角英数字で設定してください</div>
            {passwordNewValidationMessage && <ErrorMessageBar>{passwordNewValidationMessage}</ErrorMessageBar>}
            {( passwordNow && passwordNew && !passwordNowValidationMessage && !passwordNewValidationMessage) ? (
            <PrimaryWrapButton className={styles['button']} onClick={submit}>パスワード変更登録</PrimaryWrapButton>
            ) : (
            <GrayWrapButton className={styles['button']} disabled>パスワード変更登録</GrayWrapButton>
            )}
          </PageContainer>
          <Footer isLogin={isLogin} />
        </div>
    );
};

export default ChangePasswordTemplate;
