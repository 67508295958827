import React, { ReactNode } from 'react';
import { HashLink, HashLinkProps } from 'react-router-hash-link';
// CSSはインポートした順に生成され、後にインポートしたCSSの方が優先されるので、
// まず他のコンポーネントや共通CSSをインポートして、その後でコンポーネントごとのCSSをインストールすること
// つまり、styles.module.cssのインポートはインポート文の複数行のうちの最後に置いておくのが安全
import styles from './styles.module.css';

export type InnerLinkProps = {
    className?: string;
    to: string;
    targetBlank?: boolean;
    children?: ReactNode;
} & Omit<HashLinkProps, 'to'>;

const InnerLink : React.FC<InnerLinkProps> = ({
    className = '',
    to,
    targetBlank = false,
    children,
    ...props
}) => {
    // class属性を設定
    let classString = styles['inner-link'];
    if (className) {
        classString = [classString, className].join(' ');
    }

    // HashLinkの機能により遷移後に自動的に画面上部が描画できるように、#がついていないリンクの場合は末尾に付与する
    const hashedTo = to.indexOf('#') === -1 ? `${to}#` : to;

    return (
        <React.Fragment>
        <HashLink
            className={classString}
            to={hashedTo}
            // smooth={true}
            { ...props }
        >
        {children}
        </HashLink>
        {/* {targetBlank ?
        <a
            className={classString}
            href={to}
            target="_blank"
        >
        {children}
        </a>
        :
        <HashLink
            className={classString}
            to={hashedTo}
            // smooth={true}
            { ...props }
        >
        {children}
        </HashLink>
        } */}
        </React.Fragment>
    );
};

export default InnerLink;
